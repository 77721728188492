/* LAYOUT */
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH';
export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME';
export const CHANGE_SIDEBAR_TYPE = 'CHANGE_SIDEBAR_TYPE';

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR';
export const SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR';
export const HIDE_RIGHT_SIDEBAR = 'HIDE_RIGHT_SIDEBAR';

/* LOADING */
export const LOADING = 'LOADING';
export const RESET_LOADING = 'RESET_LOADING';
