import { iso8601Format, toISO } from 'helpers/dateFormat';
import { mapValues } from './utils';

export const formatDataSubmit = (data) => {
  if (data === null || typeof data === 'string') {
    return data;
  }

  return mapValues(data, (item) => {
    if (item === null || typeof item === 'string') {
      return item;
    }
    if (item instanceof Date) {
      return toISO(item.setDate(item.getDate() + 1), iso8601Format);
    }
    if (Array.isArray(item) && item.length) {
      if (item[0] instanceof Date) {
        return toISO(item[0].setDate(item[0].getDate() + 1), iso8601Format);
      }

      return item.map((i) => formatDataSubmit(i));
    }
    if (!Array.isArray(item) && typeof item === 'object') {
      if (['value', 'label'].every((v) => Object.keys(item).includes(v))) {
        return item.value;
      }

      return formatDataSubmit(item);
    }

    return item;
  });
};

export const convertLengthToArrayIndex = (length) => {
  if (length) {
    const arrayIndex = [];
    let i = 0;

    while (i < length) {
      arrayIndex.push(i);
      i += 1;
    }

    return arrayIndex;
  }
  return [0];
};

export const toFormData = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((e) => {
    if (Array.isArray(data[e])) {
      data[e].forEach((v) => formData.append(e, v));
    } else if (data[e] !== null && data[e] !== undefined) {
      formData.append(e, data[e]);
    }
  });

  return formData;
};
