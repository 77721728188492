// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { handleLoading } from 'redux/layout/actions';
import * as type from './constants';
import * as actions from './actions';
import * as api from './api';

function* fetchImportJobs(action) {
  yield put(handleLoading(true));
  const { dataType, jobStatus, page, itemPerPage } = action.payload;

  try {
    const response = yield call(api.fetchImportJobs, dataType, jobStatus, page, itemPerPage);

    yield put(actions.fetchImportJobsSuccessed(response));
  } catch (error) {
    yield put(actions.fetchImportJobsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchImportJobInfo(action) {
  yield put(handleLoading(true));
  const { id } = action.payload;

  try {
    const response = yield call(api.fetchImportJobInfo, id);

    yield put(actions.fetchImportJobInfoSuccessed(response));
  } catch (error) {
    yield put(actions.fetchImportJobInfoFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchImportJobContent(action) {
  yield put(handleLoading(true));
  const { id, page, pageSize, filter } = action.payload;

  try {
    const response = yield call(api.fetchImportJobContent, id, page, pageSize, filter);

    yield put(actions.fetchImportJobContentSuccessed(response));
  } catch (error) {
    yield put(actions.fetchImportJobContentFailed(error));
  }

  yield put(handleLoading(false));
}

function* importData(action) {
  yield put(handleLoading(true));
  const { data, fileName, dataType, executedBy } = action.payload;

  try {
    const response = yield call(api.importData, data, fileName, dataType, executedBy);

    yield put(actions.fetchImportJobs(dataType, null, 0, 10));
    yield put(actions.importDataSuccessed(response));
  } catch (error) {
    yield put(actions.importDataFailed(error));
  }

  yield put(handleLoading(false));
}

function* checkImportData(action) {
  yield put(handleLoading(true));
  const { data, dataType } = action.payload;

  try {
    const response = yield call(api.checkImportData, data, dataType);

    yield put(actions.checkImportDataSuccessed(response));
  } catch (error) {
    yield put(actions.checkImportDataFailed(error));
  }

  yield put(handleLoading(false));
}

export function* watchImport() {
  yield takeEvery(type.FETCH_IMPORT_JOBS, fetchImportJobs);
  yield takeEvery(type.FETCH_IMPORT_JOB_INFO, fetchImportJobInfo);
  yield takeEvery(type.FETCH_IMPORT_JOB_CONTENT, fetchImportJobContent);
  yield takeEvery(type.CHECK_IMPORT_DATA, checkImportData);
  yield takeEvery(type.IMPORT_DATA, importData);
}

function* importSaga() {
  yield all([fork(watchImport)]);
}

export default importSaga;
