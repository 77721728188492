import { ComponentType, ReactElement } from 'react';
import { isUserAuthenticated, setLoggedIn, setLoggedInUser } from 'helpers/authUtils';

import { loginUserSuccess } from 'redux/actions';

const Login = lazy(() => import('pages/auth/login'));

/**
 * A custom public route component that wraps the Route component and checks for
 * user authentication.
 * If the user is already authenticated, they are redirected to the homepage.
 * Otherwise, it renders the passed Component.
 */

interface PublicRouteProps {
  Component: ComponentType;
}

function PublicRoute({ Component }: PublicRouteProps): ReactElement {
  const props = useParams();
  const params = new URLSearchParams(useLocation().search);
  const dispatch = useAppDispatch();

  // Embedded page auto login
  if (!isUserAuthenticated() && params.has('access_token') && params.has('user_id')) {
    const userState = {
      loggedIn: { access_token: params.get('access_token') },
      user: { id: params.get('user_id'), isEmbedded: true },
    };
    setLoggedIn(userState.loggedIn);
    setLoggedInUser(userState.user);
    dispatch(loginUserSuccess(userState));
  }

  if (Component === Login && isUserAuthenticated()) {
    return <Navigate to="/" />;
  }

  return <Component {...props} />;
}

export default PublicRoute;
