import { fetchJSON, fetchJWT, formatParams } from 'helpers/api';
import { ENP, LOGIN_PARAMS } from 'constants/api';

export const login = (payload) => {
  const options = {
    body: formatParams({ ...payload, ...LOGIN_PARAMS }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return fetchJWT(`${ENP.AUTH_API}/token`, options);
};

export const refreshToken = (payload) => {
  const options = {
    body: JSON.stringify(payload),
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  return fetchJWT(`${ENP.AUTH_API}/token`, options);
};

export const logout = (payload) => {
  const options = {
    body: JSON.stringify(payload),
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  return fetchJWT(`${ENP.AUTH_API}/logout`, options);
};

export const getLoggedInUser = (payload) => {
  const options = {
    headers: {
      Authorization: `Bearer ${payload}`,
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/account/profile`, options);
};

export const updateProfile = (profile) => {
  const options = {
    body: JSON.stringify(profile),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetchJSON(`${ENP.SYS_ACC_API}/account/profile`, options);
};

export const updateAvatar = (avatar) => {
  const formData = new FormData();
  formData.append('avatarFile', avatar);

  const options = {
    body: formData,
    method: 'PUT',
    headers: {},
  };
  return fetchJSON(`${ENP.SYS_ACC_API}/account/profile/avatar`, options);
};

export const changePassword = (payload) => {
  const options = {
    body: JSON.stringify(payload),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetchJSON(`${ENP.SYS_ACC_API}/account/password/change`, options);
};

export const getStringeeToken = () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetchJWT(`${ENP.STRINGEE_AUTH_API}/auth/token`, options);
};
