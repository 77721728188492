// @flow
import { DEFAULT_LIST_RESPONSE } from 'constants/api';
import * as type from './constants';

const INIT_STATE = {
  groups: [],
  customers: DEFAULT_LIST_RESPONSE,
  group: {},
  // chi tiết 1 khách hàng
  customer: {
    documents: [],
  },
  // ds hợp đồng - của khách hàng
  listContract: [],
  isOpenCreateCustomer: false,
};

const Customer = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* customer group cases */
    case type.FETCH_GROUPS:
      return { ...state };
    case type.FETCH_GROUPS_SUCCESSED:
      return { ...state, groups: action.payload, error: null };
    case type.FETCH_GROUPS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_GROUP:
      return { ...state };
    case type.FETCH_GROUP_SUCCESSED:
      return { ...state, group: action.payload, error: null };
    case type.FETCH_GROUP_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_GROUP:
      return { ...state };
    case type.CREATE_GROUP_SUCCESSED:
      return { ...state };
    case type.CREATE_GROUP_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_GROUP:
      return { ...state };
    case type.UPDATE_GROUP_SUCCESSED:
      return { ...state };
    case type.UPDATE_GROUP_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_GROUP:
      return { ...state };
    case type.DELETE_GROUP_SUCCESSED:
      return { ...state };
    case type.DELETE_GROUP_FAILED:
      return { ...state, error: action.payload };

    /* customer cases */
    case type.FETCH_CUSTOMERS:
      return { ...state };
    case type.FETCH_CUSTOMERS_SUCCESSED:
      return { ...state, customers: action.payload, error: null };
    case type.FETCH_CUSTOMERS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CUSTOMER:
      return { ...state };
    case type.FETCH_CUSTOMER_SUCCESSED:
      return { ...state, customer: action.payload, error: null };
    case type.FETCH_CUSTOMER_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_LIST_CONTRACT_CUSTOMER_SUCCESSED:
      return { ...state, listContract: action.payload, error: null };
    case type.FETCH_LIST_CONTRACT_CUSTOMER_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_CUSTOMER:
      return { ...state };
    case type.CREATE_CUSTOMER_SUCCESSED:
      return { ...state };
    case type.CREATE_CUSTOMER_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_CUSTOMER:
      return { ...state };
    case type.UPDATE_CUSTOMER_SUCCESSED:
      return { ...state };
    case type.UPDATE_CUSTOMER_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_CUSTOMER:
      return { ...state };
    case type.DELETE_CUSTOMER_SUCCESSED:
      return { ...state };
    case type.DELETE_CUSTOMER_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_DOCUMENTS:
      return { ...state };
    case type.FETCH_DOCUMENTS_SUCCESSED:
      return { ...state, customer: { ...state.customer, documents: action.payload }, error: null };
    case type.FETCH_DOCUMENTS_FAILED:
      return { ...state, error: action.payload };

    case type.UPLOAD_DOCUMENT:
      return { ...state };
    case type.UPLOAD_DOCUMENT_SUCCESSED:
      return { ...state };
    case type.UPLOAD_DOCUMENT_FAILED:
      return { ...state, error: action.payload };

    case type.SET_OPEN_CREATE_CUSTOMER:
      return { ...state, isOpenCreateCustomer: action.payload, error: action.payload };

    default:
      return { ...state };
  }
};

export default Customer;
