import { Reducer, UnknownAction, combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import Admin from './admin/reducers';
import Collection from './collection/reducers';
import Matrix from './matrix/reducers';
import Partner from './partner/reducers';
import Campaign from './campaign/reducers';
import Customer from './customer/reducers';
import Lead from './lead/reducers';
import Common from './common/reducers';
import Kpi from './kpi/reducers';
import Import from './import/reducers';
import Product from './product/slice';
import { activityCodeApi } from './activity-code/apiSlice';
import { leadApi } from './lead/apiSlice';
import { productApi } from './product/apiSlice';
import { customFieldApi } from './custom-field/apiSlice';
import { documentApi } from './document/apiSlice';
import { reportApi } from './report/apiSlice';
import { settingApi } from './settings/apiSlice';
import { commonApi } from './common/apiSlice';
import { partnerApi } from './partner/apiSlice';
import { demandCustomFieldApi } from './demand-custom-field/apiSlice';
import { datasourceApi } from './datasource/apiSlice';
import { mappingFieldApi } from './mapping/apiSlice';
import { adminApi } from './admin/apiSlice';

const appReducer = combineReducers({
  Auth,
  Layout,
  Admin,
  Collection,
  Matrix,
  Partner,
  Campaign,
  Customer,
  Lead,
  Common,
  Import,
  Product,
  Kpi,
  [activityCodeApi.reducerPath]: activityCodeApi.reducer,
  [customFieldApi.reducerPath]: customFieldApi.reducer,
  [demandCustomFieldApi.reducerPath]: demandCustomFieldApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [leadApi.reducerPath]: leadApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [settingApi.reducerPath]: settingApi.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [partnerApi.reducerPath]: partnerApi.reducer,
  [datasourceApi.reducerPath]: datasourceApi.reducer,
  [mappingFieldApi.reducerPath]: mappingFieldApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
});

const rootReducer: Reducer = (state: RootState, action: UnknownAction) => {
  if (action.type === 'auth/LOGOUT_USER') {
    return {
      Layout: state.Layout,
    };
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
