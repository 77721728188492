// @flow
import * as type from './constants';

/* Manage User actions */
export const fetchUsers = (pagination) => ({ type: type.FETCH_USERS, payload: pagination });
export const fetchUsersSuccessed = (data) => ({ type: type.FETCH_USERS_SUCCESSED, payload: data });
export const fetchUsersFailed = (error) => ({ type: type.FETCH_USERS_FAILED, payload: error });

export const fetchUsersIncludeDeleted = (pagination) => ({
  type: type.FETCH_USERS_INCLUDE_DELETED,
  payload: pagination,
});
export const fetchUsersIncludeDeletedSuccessed = (data) => ({
  type: type.FETCH_USERS_INCLUDE_DELETED_SUCCESSED,
  payload: data,
});
export const fetchUsersIncludeDeletedFailed = (error) => ({
  type: type.FETCH_USERS_INCLUDE_DELETED_FAILED,
  payload: error,
});

export const fetchUsersByFilter = (filter, pagination) => ({
  type: type.FETCH_USERS_BY_FILTER,
  payload: { filter, pagination },
});
export const fetchUsersByFilterSuccessed = (data) => ({
  type: type.FETCH_USERS_BY_FILTER_SUCCESSED,
  payload: data,
});
export const fetchUsersByFilterFailed = (error) => ({
  type: type.FETCH_USERS_BY_FILTER_FAILED,
  payload: error,
});

export const fetchUserDetail = (user) => ({ type: type.FETCH_USER_DETAIL, payload: user });
export const fetchUserDetailSuccessed = (data) => ({
  type: type.FETCH_USER_DETAIL_SUCCESSED,
  payload: data,
});
export const fetchUserDetailFailed = (error) => ({
  type: type.FETCH_USER_DETAIL_FAILED,
  payload: error,
});

export const clearUserDetail = () => ({ type: type.CLEAR_USER_DETAIL });

export const fetchUsersByGroup = (group, options = {}) => ({
  type: type.FETCH_USERS_BY_GROUP,
  payload: { group, options },
});
export const fetchUsersByGroupSuccessed = (data) => ({
  type: type.FETCH_USERS_BY_GROUP_SUCCESSED,
  payload: data,
});
export const fetchUsersByGroupFailed = (error) => ({
  type: type.FETCH_USERS_BY_GROUP_FAILED,
  payload: error,
});

export const fetchUsersByRoles = (role) => ({ type: type.FETCH_USERS_BY_ROLES, payload: role });
export const fetchUsersByRolesSuccessed = (data) => ({
  type: type.FETCH_USERS_BY_ROLES_SUCCESSED,
  payload: data,
});
export const fetchUsersByRolesFailed = (error) => ({
  type: type.FETCH_USERS_BY_ROLES_FAILED,
  payload: error,
});

export const fetchManagedMembers = () => ({ type: type.FETCH_MANAGED_MEMBERS });
export const fetchManagedMembersSuccessed = (data) => ({
  type: type.FETCH_MANAGED_MEMBERS_SUCCESSED,
  payload: data,
});
export const fetchManagedMembersFailed = (error) => ({
  type: type.FETCH_MANAGED_MEMBERS_FAILED,
  payload: error,
});

export const fetchManagedMembersIncludeDeleted = () => ({
  type: type.FETCH_MANAGED_MEMBERS_INCULDE_DELETED,
});
export const fetchManagedMembersIncludeDeletedSuccessed = (data) => ({
  type: type.FETCH_MANAGED_MEMBERS_INCULDE_DELETED_SUCCESSED,
  payload: data,
});
export const fetchManagedMembersIncludeDeletedFailed = (error) => ({
  type: type.FETCH_MANAGED_MEMBERS_INCULDE_DELETED_FAILED,
  payload: error,
});

export const createUser = (data, pagination, filter) => ({
  type: type.CREATE_USER,
  payload: { data, pagination, filter },
});
export const createUserSuccessed = (data) => ({ type: type.CREATE_USER_SUCCESSED, payload: data });
export const createUserFailed = (error) => ({ type: type.CREATE_USER_FAILED, payload: error });

export const updateUser = (id, data, pagination, filter) => ({
  type: type.UPDATE_USER,
  payload: { id, data, pagination, filter },
});
export const updateUserSuccessed = (data) => ({ type: type.UPDATE_USER_SUCCESSED, payload: data });
export const updateUserFailed = (error) => ({ type: type.UPDATE_USER_FAILED, payload: error });

export const updateStatusUser = (data) => ({ type: type.UPDATE_STATUS_USER, payload: data });
export const updateStatusUserSuccessed = (data) => ({
  type: type.UPDATE_STATUS_USER_SUCCESSED,
  payload: data,
});
export const updateStatusUserFailed = (error) => ({
  type: type.UPDATE_STATUS_USER_FAILED,
  payload: error,
});

export const changePassword = (id, pwd, pagination, filter) => ({
  type: type.CHANGE_PWD_USER,
  payload: { id, pwd, pagination, filter },
});
export const changePasswordSuccessed = (data) => ({
  type: type.CHANGE_PWD_USER_SUCCESSED,
  payload: data,
});
export const changePasswordFailed = (error) => ({
  type: type.CHANGE_PWD_USER_FAILED,
  payload: error,
});

export const activeUser = (id, pagination, filter) => ({
  type: type.ACTIVE_USER,
  payload: { id, pagination, filter },
});
export const activeUserSuccessed = (data) => ({ type: type.ACTIVE_USER_SUCCESSED, payload: data });
export const activeUserFailed = (error) => ({ type: type.ACTIVE_USER_FAILED, payload: error });

export const deactiveUser = (id, pagination, filter) => ({
  type: type.DEACTIVE_USER,
  payload: { id, pagination, filter },
});
export const deactiveUserSuccessed = (data) => ({
  type: type.DEACTIVE_USER_SUCCESSED,
  payload: data,
});
export const deactiveUserFailed = (error) => ({ type: type.DEACTIVE_USER_FAILED, payload: error });

export const deleteUser = (id, pagination, filter) => ({
  type: type.DELETE_USER,
  payload: { id, pagination, filter },
});
export const deleteUserSuccessed = (data) => ({ type: type.DELETE_USER_SUCCESSED, payload: data });
export const deleteUserFailed = (error) => ({ type: type.DELETE_USER_FAILED, payload: error });

export const restoreUser = (id, pagination, filter) => ({
  type: type.RESTORE_USER,
  payload: { id, pagination, filter },
});
export const restoreUserSuccessed = (data) => ({
  type: type.RESTORE_USER_SUCCESSED,
  payload: data,
});
export const restoreUserFailed = (error) => ({ type: type.RESTORE_USER_FAILED, payload: error });

export const setLeaderUser = (userId, groupId) => ({
  type: type.SET_LEADER_USER,
  payload: { userId, groupId },
});
export const setLeaderUserSuccessed = (data) => ({
  type: type.SET_LEADER_USER_SUCCESSED,
  payload: data,
});
export const setLeaderUserFailed = (error) => ({
  type: type.SET_LEADER_USER_FAILED,
  payload: error,
});

export const updateUserPermissions = (id, data, pagination, filter) => ({
  type: type.UPDATE_USER_PERMISSIONS,
  payload: { id, data, pagination, filter },
});
export const updateUserPermissionsSuccessed = (data) => ({
  type: type.UPDATE_USER_PERMISSIONS_SUCCESSED,
  payload: data,
});
export const updateUserPermissionsFailed = (error) => ({
  type: type.UPDATE_USER_PERMISSIONS_FAILED,
  payload: error,
});

/* Manage role actions */
export const fetchRoles = () => ({ type: type.FETCH_ROLES });
export const fetchRolesSuccessed = (data) => ({ type: type.FETCH_ROLES_SUCCESSED, payload: data });
export const fetchRolesFailed = (error) => ({ type: type.FETCH_ROLES_FAILED, payload: error });

export const createRole = (data) => ({ type: type.CREATE_ROLE, payload: data });
export const createRoleSuccessed = (data) => ({ type: type.CREATE_ROLE_SUCCESSED, payload: data });
export const createRoleFailed = (error) => ({ type: type.CREATE_ROLE_FAILED, payload: error });

export const updateRole = (id, data) => ({ type: type.UPDATE_ROLE, payload: { id, data } });
export const updateRoleSuccessed = (data) => ({ type: type.UPDATE_ROLE_SUCCESSED, payload: data });
export const updateRoleFailed = (error) => ({ type: type.UPDATE_ROLE_FAILED, payload: error });

export const deleteRole = (data) => ({ type: type.DELETE_ROLE, payload: data });
export const deleteRoleSuccessed = (data) => ({ type: type.DELETE_ROLE_SUCCESSED, payload: data });
export const deleteRoleFailed = (error) => ({ type: type.DELETE_ROLE_FAILED, payload: error });

export const updateRolesPermission = (data) => ({
  type: type.UPDATE_ROLES_PERMISSION,
  payload: data,
});
export const updateRolesPermissionSuccessed = (data) => ({
  type: type.UPDATE_ROLES_PERMISSION_SUCCESSED,
  payload: data,
});
export const updateRolesPermissionFailed = (error) => ({
  type: type.UPDATE_ROLES_PERMISSION_FAILED,
  payload: error,
});

/* Manage group actions */
export const fetchGroupTypes = () => ({ type: type.FETCH_GROUP_TYPES });
export const fetchGroupTypesSuccessed = (data) => ({
  type: type.FETCH_GROUP_TYPES_SUCCESSED,
  payload: data,
});

export const fetchGroupsByType = (data) => ({ type: type.FETCH_GROUPS_BY_TYPE, payload: data });
export const fetchGroupsByTypeSuccessed = (data) => ({
  type: type.FETCH_GROUPS_BY_TYPE_SUCCESSED,
  payload: data,
});
export const fetchGroupsByTypeFailed = (error) => ({
  type: type.FETCH_GROUPS_BY_TYPE_FAILED,
  payload: error,
});

export const fetchGroups = (role) => ({ type: type.FETCH_GROUPS, payload: role });
export const fetchGroupsSuccessed = (data) => ({
  type: type.FETCH_GROUPS_SUCCESSED,
  payload: data,
});
export const fetchGroupsFailed = (error) => ({ type: type.FETCH_GROUPS_FAILED, payload: error });

export const createGroup = (data) => ({ type: type.CREATE_GROUP, payload: data });
export const createGroupSuccessed = (data) => ({
  type: type.CREATE_GROUP_SUCCESSED,
  payload: data,
});
export const createGroupFailed = (error) => ({ type: type.CREATE_GROUP_FAILED, payload: error });

export const updateGroup = (id, data) => ({ type: type.UPDATE_GROUP, payload: { id, data } });
export const updateGroupSuccessed = (data) => ({
  type: type.UPDATE_GROUP_SUCCESSED,
  payload: data,
});
export const updateGroupFailed = (error) => ({ type: type.UPDATE_GROUP_FAILED, payload: error });

export const deleteGroup = (data) => ({ type: type.DELETE_GROUP, payload: data });
export const deleteGroupSuccessed = (data) => ({
  type: type.DELETE_GROUP_SUCCESSED,
  payload: data,
});
export const deleteGroupFailed = (error) => ({ type: type.DELETE_GROUP_FAILED, payload: error });

export const fetchGroupsWithUsers = (role) => ({
  type: type.FETCH_GROUPS_WITH_USERS,
  payload: role,
});
export const fetchGroupsWithUsersSuccessed = (data) => ({
  type: type.FETCH_GROUPS_WITH_USERS_SUCCESSED,
  payload: data,
});
export const fetchGroupsWithUsersFailed = (error) => ({
  type: type.FETCH_GROUPS_WITH_USERS_FAILED,
  payload: error,
});

export const fetchGroupsTree = (role) => ({ type: type.FETCH_GROUP_TREE, payload: role });
export const fetchGroupsTreeSuccessed = (data) => ({
  type: type.FETCH_GROUP_TREE_SUCCESSED,
  payload: data,
});
export const fetchGroupsTreeFailed = (error) => ({
  type: type.FETCH_GROUP_TREE_FAILED,
  payload: error,
});

/* Manage resource actions */

export const fetchClientRoles = () => ({ type: type.FETCH_CLIENT_ROLES });
export const fetchClientRolesSuccessed = (data) => ({
  type: type.FETCH_CLIENT_ROLES_SUCCESSED,
  payload: data,
});
export const fetchClientRolesFailed = (error) => ({
  type: type.FETCH_CLIENT_ROLES_FAILED,
  payload: error,
});

export const updateClientRole = (data) => ({ type: type.UPDATE_CLIENT_ROLE, payload: data });
export const updateClientRoleSuccessed = (data) => ({
  type: type.UPDATE_CLIENT_ROLE_SUCCESSED,
  payload: data,
});
export const updateClientRoleFailed = (error) => ({
  type: type.UPDATE_CLIENT_ROLE_FAILED,
  payload: error,
});

/* Param table actions */
export const fetchParamTables = () => ({ type: type.FETCH_PARAM_TABLES });
export const fetchParamTablesSuccessed = (data) => ({
  type: type.FETCH_PARAM_TABLES_SUCCESSED,
  payload: data,
});
export const fetchParamTablesFailed = (error) => ({
  type: type.FETCH_PARAM_TABLES_FAILED,
  payload: error,
});

export const fetchParamTableDetail = (id) => ({ type: type.FETCH_PARAM_TABLE_DETAIL, payload: id });
export const fetchParamTableDetailSuccessed = (data) => ({
  type: type.FETCH_PARAM_TABLE_DETAIL_SUCCESSED,
  payload: data,
});
export const fetchParamTableDetailFailed = (error) => ({
  type: type.FETCH_PARAM_TABLE_DETAIL_FAILED,
  payload: error,
});

export const clearParamTableDetail = () => ({ type: type.CLEAR_PARAM_TABLE_DETAIL });

export const updateParamTable = (table, data) => ({
  type: type.UPDATE_PARAM_TABLE,
  payload: { table, data },
});
export const updateParamTableSuccessed = (data) => ({
  type: type.UPDATE_PARAM_TABLE_SUCCESSED,
  payload: data,
});
export const updateParamTableFailed = (error) => ({
  type: type.UPDATE_PARAM_TABLE_FAILED,
  payload: error,
});

export const deleteParamTable = (id) => ({ type: type.DELETE_PARAM_TABLE, payload: id });
export const deleteParamTableSuccessed = () => ({ type: type.DELETE_PARAM_TABLE_SUCCESSED });
export const deleteParamTableFailed = (error) => ({
  type: type.DELETE_PARAM_TABLE_FAILED,
  payload: error,
});

export const createParamTable = (data) => ({ type: type.CREATE_PARAM_TABLE, payload: data });
export const createParamTableSuccessed = (data) => ({
  type: type.CREATE_PARAM_TABLE_SUCCESSED,
  payload: data,
});
export const createParamTableFailed = (error) => ({
  type: type.CREATE_PARAM_TABLE_FAILED,
  payload: error,
});

export const fetchParamTableEntries = (id) => ({
  type: type.FETCH_PARAM_TABLE_ENTRIES,
  payload: id,
});
export const fetchParamTableEntriesSuccessed = (id, data) => ({
  type: type.FETCH_PARAM_TABLE_ENTRIES_SUCCESSED,
  payload: { id, data },
});
export const fetchParamTableEntriesFailed = (error) => ({
  type: type.FETCH_PARAM_TABLE_ENTRIES_FAILED,
  payload: error,
});

export const updateParamTableEntries = (table, entry, data) => ({
  type: type.UPDATE_PARAM_TABLE_ENTRIES,
  payload: { table, entry, data },
});
export const updateParamTableEntriesSuccessed = (data) => ({
  type: type.UPDATE_PARAM_TABLE_ENTRIES_SUCCESSED,
  payload: data,
});
export const updateParamTableEntriesFailed = (error) => ({
  type: type.UPDATE_PARAM_TABLE_ENTRIES_FAILED,
  payload: error,
});

export const deleteParamTableEntries = (table, entry) => ({
  type: type.DELETE_PARAM_TABLE_ENTRIES,
  payload: { table, entry },
});
export const deleteParamTableEntriesSuccessed = () => ({
  type: type.DELETE_PARAM_TABLE_ENTRIES_SUCCESSED,
});
export const deleteParamTableEntriesFailed = (error) => ({
  type: type.DELETE_PARAM_TABLE_ENTRIES_FAILED,
  payload: error,
});

export const createParamTableEntries = (table, data) => ({
  type: type.CREATE_PARAM_TABLE_ENTRIES,
  payload: { table, data },
});
export const createParamTableEntriesSuccessed = (data) => ({
  type: type.CREATE_PARAM_TABLE_ENTRIES_SUCCESSED,
  payload: data,
});
export const createParamTableEntriesFailed = (error) => ({
  type: type.CREATE_PARAM_TABLE_ENTRIES_FAILED,
  payload: error,
});

// Notification
export const fetchNotificationTemplate = (params) => ({
  type: type.FETCH_NOTIFICATION_TEMPLATE,
  payload: { params },
});
export const fetchNotificationTemplateSuccessed = (data) => ({
  type: type.FETCH_NOTIFICATION_TEMPLATE_SUCCESSED,
  payload: data,
});
export const fetchNotificationTemplateFailed = (error) => ({
  type: type.FETCH_NOTIFICATION_TEMPLATE_FAILED,
  payload: error,
});

export const createTemplate = (data, setState = () => {}) => ({
  type: type.CREATE_TEMPLATE,
  payload: { data, setState },
});
export const createTemplateSuccessed = (data) => ({
  type: type.CREATE_TEMPLATE_SUCCESSED,
  payload: data,
});
export const createTemplateFailed = (error) => ({
  type: type.CREATE_TEMPLATE_FAILED,
  payload: error,
});

export const updateTemplate = (id, data, setState = () => {}) => ({
  type: type.UPDATE_TEMPLATE,
  payload: { id, data, setState },
});
export const updateTemplateSuccessed = (data) => ({
  type: type.UPDATE_TEMPLATE_SUCCESSED,
  payload: data,
});
export const updateTemplateFailed = (error) => ({
  type: type.UPDATE_TEMPLATE_FAILED,
  payload: error,
});

export const deleteNotificationTemplate = (data) => ({ type: type.DELETE_TEMPLATE, payload: data });
export const deleteNotificationTemplateSuccessed = (data) => ({
  type: type.DELETE_TEMPLATE_SUCCESSED,
  payload: data,
});
export const deleteNotificationTemplateFailed = (error) => ({
  type: type.DELETE_TEMPLATE_FAILED,
  payload: error,
});

// Activity Log
export const fetchActivityTypes = (params) => ({
  type: type.FETCH_ACTIVITY_TYPE,
  payload: { params },
});
export const fetchActivityTypesSuccessed = (data) => ({
  type: type.FETCH_ACTIVITY_TYPE_SUCCESSED,
  payload: data,
});
export const fetchActivityTypesFailed = (error) => ({
  type: type.FETCH_ACTIVITY_TYPE_FAILED,
  payload: error,
});

export const fetchActivityLogs = (params) => ({
  type: type.FETCH_ACTIVITY_LOGS,
  payload: { params },
});
export const fetchActivityLogsSuccessed = (data) => ({
  type: type.FETCH_ACTIVITY_LOGS_SUCCESSED,
  payload: data,
});
export const fetchActivityLogsFailed = (error) => ({
  type: type.FETCH_ACTIVITY_LOGS_FAILED,
  payload: error,
});

export const fetchActivityLogsByFilter = (data, params) => ({
  type: type.FETCH_ACTIVITY_LOGS_BY_FILTER,
  payload: { data, params },
});
export const fetchActivityLogsByFilterSuccessed = (data) => ({
  type: type.FETCH_ACTIVITY_LOGS_BY_FILTER_SUCCESSED,
  payload: data,
});
export const fetchActivityLogsByFilterFailed = (error) => ({
  type: type.FETCH_ACTIVITY_LOGS_BY_FILTER_FAILED,
  payload: error,
});

// Table flow
export const fetchCurrentLeadFlow = () => ({ type: type.FETCH_CURRENT_LEAD_FLOW });
export const fetchCurrentLeadFlowSuccessed = (data) => ({
  type: type.FETCH_CURRENT_LEAD_FLOW_SUCCESSED,
  payload: data,
});
export const fetchCurrentLeadFlowFailed = (error) => ({
  type: type.FETCH_CURRENT_LEAD_FLOW_FAILED,
  payload: error,
});

export const createLeadFlow = (jsonPayload, xmlFile, isDeploy, setStatus = () => {}) => ({
  type: type.CREATE_LEAD_FLOW,
  payload: { jsonPayload, xmlFile, isDeploy, setStatus },
});
export const createLeadFlowSuccessed = (data) => ({
  type: type.CREATE_LEAD_FLOW_SUCCESSED,
  payload: data,
});
export const createLeadFlowFailed = (error) => ({
  type: type.CREATE_LEAD_FLOW_FAILED,
  payload: error,
});

// Workflow
export const fetchWorkflows = (data, options) => ({
  type: type.FETCH_WORKFLOWS,
  payload: { data, options: options ?? {} },
});
export const fetchWorkflowsSuccessed = (data) => ({
  type: type.FETCH_WORKFLOWS_SUCCESSED,
  payload: data,
});
export const fetchWorkflowsFailed = (error) => ({
  type: type.FETCH_WORKFLOWS_FAILED,
  payload: error,
});

export const fetchAllWorkflows = (options) => ({
  type: type.FETCH_ALL_WORKFLOWS,
  payload: { options: options ?? {} },
});
export const fetchAllWorkflowsSuccessed = (data) => ({
  type: type.FETCH_ALL_WORKFLOWS_SUCCESSED,
  payload: data,
});

export const fetchWorkflow = (id) => ({ type: type.FETCH_WORKFLOW, payload: id });
export const fetchWorkflowSuccessed = (data) => ({
  type: type.FETCH_WORKFLOW_SUCCESSED,
  payload: data,
});
export const fetchWorkflowFailed = (error) => ({
  type: type.FETCH_WORKFLOW_FAILED,
  payload: error,
});

export const deployWorkflow = (bpmn) => ({ type: type.DEPLOY_WORKFLOW, payload: bpmn });
export const deployWorkflowSuccessed = (data) => ({
  type: type.DEPLOY_WORKFLOW_SUCCESSED,
  payload: data,
});
export const deployWorkflowFailed = (error) => ({
  type: type.DEPLOY_WORKFLOW_FAILED,
  payload: error,
});

export const activateWorkflow = (id) => ({ type: type.ACTIVATE_WORKFLOW, payload: id });
export const activateWorkflowSuccessed = (data) => ({
  type: type.ACTIVATE_WORKFLOW_SUCCESSED,
  payload: data,
});
export const activateWorkflowFailed = (error) => ({
  type: type.ACTIVATE_WORKFLOW_FAILED,
  payload: error,
});

export const deactivateWorkflow = (id) => ({ type: type.DEACTIVATE_WORKFLOW, payload: id });
export const deactivateWorkflowSuccessed = (data) => ({
  type: type.DEACTIVATE_WORKFLOW_SUCCESSED,
  payload: data,
});
export const deactivateWorkflowFailed = (error) => ({
  type: type.DEACTIVATE_WORKFLOW_FAILED,
  payload: error,
});

/* Calendar settings */
export const fetchDayOffEvent = (data) => ({ type: type.FETCH_DAY_OFF_EVENT, payload: data });
export const fetchDayOffEventSuccessed = (data) => ({
  type: type.FETCH_DAY_OFF_EVENT_SUCCESSED,
  payload: data,
});
export const fetchDayOffEventFailed = (error) => ({
  type: type.FETCH_DAY_OFF_EVENT_FAILED,
  payload: error,
});

export const createDayOffEvent = (event, timeRange) => ({
  type: type.CREATE_DAY_OFF_EVENT,
  payload: { event, timeRange },
});
export const createDayOffEventSuccessed = (data) => ({
  type: type.CREATE_DAY_OFF_EVENT_SUCCESSED,
  payload: data,
});
export const createDayOffEventFailed = (error) => ({
  type: type.CREATE_DAY_OFF_EVENT_FAILED,
  payload: error,
});

export const deleteDayOffEvent = (id, timeRange) => ({
  type: type.DELETE_DAY_OFF_EVENT,
  payload: { id, timeRange },
});
export const deleteDayOffEventSuccessed = (data) => ({
  type: type.DELETE_DAY_OFF_EVENT_SUCCESSED,
  payload: data,
});
export const deleteDayOffEventFailed = (error) => ({
  type: type.DELETE_DAY_OFF_EVENT_FAILED,
  payload: error,
});

export const fetchCalendarSetting = (data) => ({
  type: type.FETCH_CALENDAR_SETTING,
  payload: data,
});
export const fetchCalendarSettingSuccessed = (data) => ({
  type: type.FETCH_CALENDAR_SETTING_SUCCESSED,
  payload: data,
});
export const fetchCalendarSettingFailed = (error) => ({
  type: type.FETCH_CALENDAR_SETTING_FAILED,
  payload: error,
});

export const setCalendarSetting = (key, value) => ({
  type: type.SET_CALENDAR_SETTING,
  payload: { key, value },
});
export const setCalendarSettingSuccessed = (data) => ({
  type: type.SET_CALENDAR_SETTING_SUCCESSED,
  payload: data,
});
export const setCalendarSettingFailed = (error) => ({
  type: type.SET_CALENDAR_SETTING_FAILED,
  payload: error,
});

/** Settings */
export const fetchAllKpi = (data) => ({ type: type.FETCH_ALL_KPI, payload: data });
export const fetchAllKpiSuccessed = (data) => ({
  type: type.FETCH_ALL_KPI_SUCCESSED,
  payload: data,
});
export const fetchAllKpiFailed = (error) => ({ type: type.FETCH_ALL_KPI_FAILED, payload: error });

export const setKpi = (data) => ({ type: type.SET_KPI, payload: data });
export const setKpiSuccessed = (data) => ({ type: type.SET_KPI_SUCCESSED, payload: data });
export const setKpiFailed = (error) => ({ type: type.SET_KPI_FAILED, payload: error });

export const fetchAllBucket = (data) => ({ type: type.FETCH_ALL_BUCKET, payload: data });
export const fetchAllBucketSuccessed = (data) => ({
  type: type.FETCH_ALL_BUCKET_SUCCESSED,
  payload: data,
});
export const fetchAllBucketFailed = (error) => ({
  type: type.FETCH_ALL_BUCKET_FAILED,
  payload: error,
});

export const setBucket = (data) => ({ type: type.SET_BUCKET, payload: data });
export const setBucketSuccessed = (data) => ({ type: type.SET_BUCKET_SUCCESSED, payload: data });
export const setBucketFailed = (error) => ({ type: type.SET_BUCKET_FAILED, payload: error });

export const fetchAllActivityCode = (data) => ({
  type: type.FETCH_ALL_ACTIVITY_CODE,
  payload: data,
});
export const fetchAllActivityCodeSuccessed = (data) => ({
  type: type.FETCH_ALL_ACTIVITY_CODE_SUCCESSED,
  payload: data,
});
export const fetchAllActivityCodeFailed = (error) => ({
  type: type.FETCH_ALL_ACTIVITY_CODE_FAILED,
  payload: error,
});

export const setActivityCode = (data) => ({ type: type.SET_ACTIVITY_CODE, payload: data });
export const setActivityCodeSuccessed = (data) => ({
  type: type.SET_ACTIVITY_CODE_SUCCESSED,
  payload: data,
});
export const setActivityCodeFailed = (error) => ({
  type: type.SET_ACTIVITY_CODE_FAILED,
  payload: error,
});

export const fetchAllLeadActivityCode = (data) => ({
  type: type.FETCH_ALL_LEAD_ACTIVITY_CODE,
  payload: data,
});
export const fetchAllLeadActivityCodeSuccessed = (data) => ({
  type: type.FETCH_ALL_LEAD_ACTIVITY_CODE_SUCCESSED,
  payload: data,
});
export const fetchAllLeadActivityCodeFailed = (error) => ({
  type: type.FETCH_ALL_LEAD_ACTIVITY_CODE_FAILED,
  payload: error,
});

export const setLeadActivityCode = (data) => ({ type: type.SET_LEAD_ACTIVITY_CODE, payload: data });
export const setLeadActivityCodeSuccessed = (data) => ({
  type: type.SET_LEAD_ACTIVITY_CODE_SUCCESSED,
  payload: data,
});
export const setLeadActivityCodeFailed = (error) => ({
  type: type.SET_LEAD_ACTIVITY_CODE_FAILED,
  payload: error,
});

export const fetchAllRelationship = (data) => ({
  type: type.FETCH_ALL_RELATIONSHIP,
  payload: data,
});
export const fetchAllRelationshipSuccessed = (data) => ({
  type: type.FETCH_ALL_RELATIONSHIP_SUCCESSED,
  payload: data,
});
export const fetchAllRelationshipFailed = (error) => ({
  type: type.FETCH_ALL_RELATIONSHIP_FAILED,
  payload: error,
});

export const setRelationship = (data) => ({ type: type.SET_RELATIONSHIP, payload: data });
export const setRelationshipSuccessed = (data) => ({
  type: type.SET_RELATIONSHIP_SUCCESSED,
  payload: data,
});
export const setRelationshipFailed = (error) => ({
  type: type.SET_RELATIONSHIP_FAILED,
  payload: error,
});

export const fetchAllCheckInLocation = (data) => ({
  type: type.FETCH_ALL_CHECK_IN_LOCATION,
  payload: data,
});
export const fetchAllCheckInLocationSuccessed = (data) => ({
  type: type.FETCH_ALL_CHECK_IN_LOCATION_SUCCESSED,
  payload: data,
});
export const fetchAllCheckInLocationFailed = (error) => ({
  type: type.FETCH_ALL_CHECK_IN_LOCATION_FAILED,
  payload: error,
});

export const setCheckInLocation = (data) => ({ type: type.SET_CHECK_IN_LOCATION, payload: data });
export const setCheckInLocationSuccessed = (data) => ({
  type: type.SET_CHECK_IN_LOCATION_SUCCESSED,
  payload: data,
});
export const setCheckInLocationFailed = (error) => ({
  type: type.SET_CHECK_IN_LOCATION_FAILED,
  payload: error,
});

export const fetchOrgInfo = (data) => ({ type: type.FETCH_ORG_INFO, payload: data });
export const fetchOrgInfoSuccessed = (data) => ({
  type: type.FETCH_ORG_INFO_SUCCESSED,
  payload: data,
});
export const fetchOrgInfoFailed = (error) => ({ type: type.FETCH_ORG_INFO_FAILED, payload: error });

export const fetchOrgAvatar = (data) => ({ type: type.FETCH_ORG_AVATAR, payload: data });
export const fetchOrgAvatarSuccessed = (data) => ({
  type: type.FETCH_ORG_AVATAR_SUCCESSED,
  payload: data,
});
export const fetchOrgAvatarFailed = (error) => ({
  type: type.FETCH_ORG_AVATAR_FAILED,
  payload: error,
});

export const updateOrgInfo = (data, file) => ({
  type: type.UPDATE_ORG_INFO,
  payload: { data, file },
});
export const updateOrgInfoSuccessed = (data) => ({
  type: type.UPDATE_ORG_INFO_SUCCESSED,
  payload: data,
});
export const updateOrgInfoFailed = (error) => ({
  type: type.UPDATE_ORG_INFO_FAILED,
  payload: error,
});

export const fetchAllGroupLocal = (id) => ({ type: type.FETCH_ALL_GROUP_LOCAL, payload: id });
export const fetchAllGroupLocalSuccessed = (data) => ({
  type: type.FETCH_ALL_GROUP_LOCAL_SUCCESSED,
  payload: data,
});
export const fetchAllGroupLocalFailed = (error) => ({
  type: type.FETCH_ALL_GROUP_LOCAL_FAILED,
  payload: error,
});

export const fetchAllLocation = (data) => ({ type: type.FETCH_ALL_LOCATION, payload: data });
export const fetchAllLocationSuccessed = (data) => ({
  type: type.FETCH_ALL_LOCATION_SUCCESSED,
  payload: data,
});
export const fetchAllLocationFailed = (error) => ({
  type: type.FETCH_ALL_LOCATION_FAILED,
  payload: error,
});

export const fetchNestedDistrict = (districtId) => ({
  type: type.FETCH_NESTED_DISTRICT,
  payload: districtId,
});
export const fetchNestedDistrictSuccessed = (data) => ({
  type: type.FETCH_NESTED_DISTRICT_SUCCESSED,
  payload: data,
});
export const fetchNestedDistrictFailed = (error) => ({
  type: type.FETCH_NESTED_DISTRICT_FAILED,
  payload: error,
});

export const fetchNestedProvince = (regionId) => ({
  type: type.FETCH_NESTED_PROVINCE,
  payload: regionId,
});
export const fetchNestedProvinceSuccessed = (data) => ({
  type: type.FETCH_NESTED_PROVINCE_SUCCESSED,
  payload: data,
});
export const fetchNestedProvinceFailed = (error) => ({
  type: type.FETCH_NESTED_PROVINCE_FAILED,
  payload: error,
});

export const fetchNestedWard = (districtId) => ({
  type: type.FETCH_NESTED_WARD,
  payload: districtId,
});
export const fetchNestedWardSuccessed = (data) => ({
  type: type.FETCH_NESTED_WARD_SUCCESSED,
  payload: data,
});
export const fetchNestedWardFailed = (error) => ({
  type: type.FETCH_NESTED_WARD_FAILED,
  payload: error,
});

export const updateLocation = (payload) => ({ type: type.UPDATE_LOCATION, payload });
export const updateLocationSuccessed = (data) => ({
  type: type.UPDATE_LOCATION_SUCCESSED,
  payload: data,
});
export const updateLocationFailed = (error) => ({
  type: type.UPDATE_LOCATION_FAILED,
  payload: error,
});

export const fetchRecursionProvince = (regionId, cb) => ({
  type: type.FETCH_RECURSION_PROVINCE,
  payload: { regionId, cb },
});
export const fetchRecursionProvinceSuccessed = (data) => ({
  type: type.FETCH_RECURSION_PROVINCE_SUCCESSED,
  payload: data,
});
export const fetchRecursionProvinceFailed = (error) => ({
  type: type.FETCH_RECURSION_PROVINCE_FAILED,
  payload: error,
});

export const updateUserStatus = (payload) => ({ type: type.UPDATE_USER_STATUS, payload });
export const updateUserStatusSuccessed = (data) => ({
  type: type.UPDATE_USER_STATUS_SUCCESSED,
  payload: data,
});
export const updateUserStatusFailed = (error) => ({
  type: type.UPDATE_USER_STATUS_FAILED,
  payload: error,
});

export const getUserStatus = () => ({ type: type.GET_USER_STATUS });
export const getUserStatusSuccessed = (data) => ({
  type: type.GET_USER_STATUS_SUCCESSED,
  payload: data,
});
export const getUserStatusFailed = (error) => ({
  type: type.GET_USER_STATUS_FAILED,
  payload: error,
});

export const getUserByRole = (role) => ({ type: type.GET_USERS_BY_ROLES, payload: role });
export const getUserByRoleSuccessed = (data) => ({
  type: type.GET_USERS_BY_ROLES_SUCCESSED,
  payload: data,
});
export const getUserByRoleFailed = (error) => ({
  type: type.GET_USERS_BY_ROLES_FAILED,
  payload: error,
});

export const updateLeader = (accountId, data) => ({
  type: type.UPDATE_LEADER,
  payload: { accountId, data },
});
export const updateLeaderSuccessed = (data) => ({
  type: type.UPDATE_LEADER_SUCCESSED,
  payload: data,
});
export const updateLeaderFailed = (error) => ({ type: type.UPDATE_LEADER_FAILED, payload: error });

export const getAllTypeFunnel = (data) => ({ type: type.GET_ALL_TYPE_FUNNEL, payload: { data } });
export const getAllTypeFunnelSuccessed = (data) => ({
  type: type.GET_ALL_TYPE_FUNNEL_SUCCESSED,
  payload: data,
});
export const getAllTypeFunnelFailed = (error) => ({
  type: type.GET_ALL_TYPE_FUNNEL_FAILED,
  payload: error,
});
