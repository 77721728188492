import * as type from './constants';

const INIT_STATE = {
  kpis: [],
  months: [],
  kpi: null,
  logs: [],
  validProducts: [],
};

const Kpi = (state = INIT_STATE, action) => {
  switch (action.type) {
    case type.GET_ALL_KPI_SUCCESSED:
      return { ...state, kpis: action.payload };

    case type.UPDATE_KPI_SUCCESSED:
      return {
        ...state,
        kpis: state.kpis.map((e) => (e.id === action.payload.id ? action.payload : e)),
      };

    case type.GET_DETAIL_KPI_SUCCESSED:
      return { ...state, kpi: action.payload || null };
    case type.GET_DETAIL_KPI_FAILED:
      return { ...state, kpi: null || null };

    case type.GET_ALL_NUMBER_KPI_SUCCESSED:
      return { ...state, months: action.payload };

    case type.CREATE_KPI_NUMBER_SUCCESSED:
      return { ...state, kpi: action.payload };

    case type.GET_LOG_FILTER_SUCCESSED:
      return { ...state, logs: action.payload };

    case type.GET_ALL_NUMBER_SUCCESSED:
      return { ...state, validProducts: action.payload };

    default:
      return { ...state };
  }
};

export default Kpi;
