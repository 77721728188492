import { fetchJSON } from 'helpers/api';
import { ENP, IPIFY_GEO_API, IPIFY_IP_API, IPIFY_TOKEN } from 'constants/api';
import { defaultRouteConfig } from './config';

/* Address API */

export const fetchDistricts = (provinceId) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.COMMON_API}/address/province/${provinceId}/district`, options);
};

export const fetchWards = (districtId) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.COMMON_API}/address/district/${districtId}/ward`, options);
};

/* Category common */

export const fetchCategories = () => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.COMMON_API}/category`, options);
};

export const fetchCategoryValues = (categoryId) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.COMMON_API}/category/values/${categoryId}`, options);
};

export const createCategory = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COMMON_API}/category/value`, options);
};

export const updateCategory = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COMMON_API}/category/value`, options);
};

export const deleteCategory = (id) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COMMON_API}/category/value/${id}`, options);
};

/* Ip Geo API */

export const fetchIP = () => {
  const params = {
    format: 'json',
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(IPIFY_IP_API, options, params);
};

export const fetchIPGeo = (ipAddress) => {
  const params = {
    apiKey: IPIFY_TOKEN,
    ipAddress,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(IPIFY_GEO_API, options, params);
};

export const fetchAppList = () => {
  const options = {
    method: 'GET',
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/app/available`, options);
};

export const fetchNotifications = () => {
  const options = {
    method: 'GET',
    headers: {},
  };

  return fetchJSON(`${ENP.NOTIFICATION_API}/noti`, options);
};

export const fetchUnseenNotifications = () => {
  const options = {
    method: 'GET',
    headers: {},
  };

  return fetchJSON(`${ENP.NOTIFICATION_API}/noti/unseen`, options);
};

export const subscribeUser = (tokens) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tokens),
  };

  return fetchJSON(`${ENP.NOTIFICATION_API}/noti/subscribe`, options);
};

export const unsubscribeUser = (tokens) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tokens),
  };

  return fetchJSON(`${ENP.NOTIFICATION_API}/noti/unsubscribe`, options);
};

export const updateNotification = (id) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.NOTIFICATION_API}/noti/seen/${id}`, options);
};

export const clearCache = (params) => {
  const options = {
    method: 'GET',
    headers: {},
  };
  return fetchJSON(`${ENP.COLLECTION_API}/clear-cached`, options, params);
};

export const fetchRouteConfig = async () => {
  return new Promise((resolve) => {
    // setTimeout(() => {
    resolve(defaultRouteConfig);
    // }, 500);
  });
};
