// @flow
import * as type from './constants';

const INIT_STATE = {
  importJobs: {},
  importJob: {},
  importJobContent: {},
  importData: [],
};

const Import = (state = INIT_STATE, action) => {
  switch (action.type) {
    case type.FETCH_IMPORT_JOBS:
      return { ...state };
    case type.FETCH_IMPORT_JOBS_SUCCESSED:
      return { ...state, importJobs: action.payload };
    case type.FETCH_IMPORT_JOBS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_IMPORT_JOB_INFO:
      return { ...state };
    case type.FETCH_IMPORT_JOB_INFO_SUCCESSED:
      return { ...state, importJob: action.payload };
    case type.FETCH_IMPORT_JOB_INFO_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_IMPORT_JOB_CONTENT:
      return { ...state };
    case type.FETCH_IMPORT_JOB_CONTENT_SUCCESSED:
      return { ...state, importJobContent: action.payload };
    case type.FETCH_IMPORT_JOB_CONTENT_FAILED:
      return { ...state, error: action.payload };

    case type.IMPORT_DATA:
      return { ...state };
    case type.IMPORT_DATA_SUCCESSED:
      return { ...state, importJob: action.payload };
    case type.IMPORT_DATA_FAILED:
      return { ...state, error: action.payload };

    case type.CHECK_IMPORT_DATA:
      return { ...state };
    case type.CHECK_IMPORT_DATA_SUCCESSED:
      return {
        ...state,
        importData: action.payload.map((item, index) => {
          return {
            ...state.importData[index],
            ...item,
            valid_info: item.valid ? null : item.errors.join('\n'),
          };
        }),
      };
    case type.CHECK_IMPORT_DATA_FAILED:
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};

export default Import;
