import { getAccessToken } from 'helpers/api';
import { isAvailableArray } from 'helpers/arrayUtils';
import { fetchRouteConfig, updateRouteConfig } from 'redux/actions';
import { decrypt, encrypt } from 'helpers/cryptoUtils';
import { createLCStorageKey } from 'helpers/keyUtils';

import { ReduxActionOptions } from 'redux/types';
import { MenuItem, Page, RouteConfig } from 'router/routes/types';

interface Output {
  config: RouteConfig;
  getHomePage: () => string;
  hasMenu: () => boolean;
  getMenu: () => MenuItem[];
  getPages: () => Page[];
  getLocalStorageRouteConfig: () => RouteConfig | null;
  setLocalStorageRouteConfig: (config: RouteConfig) => void;
  fetchConfig: (options: ReduxActionOptions<RouteConfig>) => void;
  setReduxRouteConfig: (config: RouteConfig) => void;
}

const ROUTE_CONFIG_KEY = createLCStorageKey('r_c');

const getLocalStorageRouteConfig = (): RouteConfig | null => {
  try {
    const encryptedData = localStorage.getItem(ROUTE_CONFIG_KEY);
    if (encryptedData) {
      const bytes = decrypt(encryptedData);
      const data = JSON.parse(bytes.toString());

      const accessToken = getAccessToken();

      if (accessToken && data?.access_token === accessToken) {
        return isAvailableArray(data.config?.menu) ? data.config : null;
      }
    }
  } catch (error) {
    console.error(error);
  }

  localStorage.removeItem(ROUTE_CONFIG_KEY);
  return null;
};

const setLocalStorageRouteConfig = (config: RouteConfig): void => {
  const accessToken = getAccessToken();

  const data = {
    access_token: accessToken,
    config,
  };

  const cipherText = encrypt(JSON.stringify(data)).toString();

  localStorage.setItem(ROUTE_CONFIG_KEY, cipherText);
};

function useRouteConfig(): Output {
  const dispatch = useAppDispatch();
  const config = useAppSelector((state: any) => state.Common.routeConfig);

  const methods = useMemo(
    () => ({
      hasMenu: () => config != null && config.menu.length > 0,
      getMenu: () => (config != null ? config.menu : []),
      getPages: () => (config != null ? config.pages : []),
      getHomePage: () => (config != null ? config.homePage : '/dashboard'),
    }),
    [config]
  );

  const fetchConfig = useCallback(
    (options: ReduxActionOptions<RouteConfig> | null) => {
      dispatch(fetchRouteConfig(options));
    },
    [dispatch]
  );

  const setReduxRouteConfig = useCallback(
    (config: RouteConfig) => {
      if (config) {
        dispatch(updateRouteConfig(config));
      }
    },
    [dispatch]
  );

  return {
    config,
    ...methods,
    getLocalStorageRouteConfig,
    setLocalStorageRouteConfig,
    fetchConfig,
    setReduxRouteConfig,
  };
}

export default useRouteConfig;
