/* LEADs */
export const DELETE_LEAD = 'lead/DELETE_LEAD';
export const DELETE_LEAD_SUCCESSED = 'lead/DELETE_LEAD_SUCCESSED';
export const DELETE_LEAD_FAILED = 'lead/DELETE_LEAD_FAILED';

export const UPDATE_LEAD_STATUS = 'lead/UPDATE_LEAD_STATUS';
export const UPDATE_LEAD_STATUS_SUCCESSED = 'lead/UPDATE_LEAD_STATUS_SUCCESSED';
export const UPDATE_LEAD_STATUS_FAILED = 'lead/UPDATE_LEAD_STATUS_FAILED';

export const UPDATE_LEAD_ASSIGNEE = 'lead/UPDATE_LEAD_ASSIGNEE';
export const UPDATE_LEAD_ASSIGNEE_SUCCESSED = 'lead/UPDATE_LEAD_ASSIGNEE_SUCCESSED';
export const UPDATE_LEAD_ASSIGNEE_FAILED = 'lead/UPDATE_LEAD_ASSIGNEE_FAILED';

export const FETCH_LEAD_LIST_START_STATUS = 'lead/FETCH_LEAD_LIST_START_STATUS';
export const FETCH_LEAD_LIST_START_STATUS_SUCCESSED = 'lead/FETCH_LEAD_LIST_START_STATUS_SUCCESSED';
export const FETCH_LEAD_LIST_START_STATUS_FAILED = 'lead/FETCH_LEAD_LIST_START_STATUS_FAILED';

export const FETCH_LEAD_LIST_STATUS = 'lead/FETCH_LEAD_LIST_STATUS';
export const FETCH_LEAD_LIST_STATUS_SUCCESSED = 'lead/FETCH_LEAD_LIST_STATUS_SUCCESSED';
export const FETCH_LEAD_LIST_STATUS_FAILED = 'lead/FETCH_LEAD_LIST_STATUS_FAILED';

export const FETCH_LEAD_LIST_RESULT = 'lead/FETCH_LEAD_LIST_RESULT';
export const FETCH_LEAD_LIST_RESULT_SUCCESSED = 'lead/FETCH_LEAD_LIST_RESULT_SUCCESSED';
export const FETCH_LEAD_LIST_RESULT_FAILED = 'lead/FETCH_LEAD_LIST_RESULT_FAILED';

export const UPDATE_LEAD_DEMAND = 'lead/UPDATE_LEAD_DEMAND';
export const UPDATE_LEAD_DEMAND_SUCCESSED = 'lead/UPDATE_LEAD_DEMAND_SUCCESSED';
export const UPDATE_LEAD_DEMAND_FAILED = 'lead/UPDATE_LEAD_DEMAND_FAILED';

export const ASSIGN_LEAD = 'lead/ASSIGN_LEAD';
export const ASSIGN_LEAD_SUCCESSED = 'lead/ASSIGN_LEAD_SUCCESSED';
export const ASSIGN_LEAD_FAILED = 'lead/ASSIGN_LEAD_FAILED';

export const AUTO_ASSIGN_LEAD = 'lead/AUTO_ASSIGN_LEAD';
export const AUTO_ASSIGN_LEAD_SUCCESSED = 'lead/AUTO_ASSIGN_LEAD_SUCCESSED';
export const AUTO_ASSIGN_LEAD_FAILED = 'lead/AUTO_ASSIGN_LEAD_FAILED';

export const SET_OPEN_CREATE_LEAD = 'lead/SET_OPEN_CREATE_LEAD';

/* LEAD IMPORT */

export const FETCH_LEAD_IMPORT_HISTORY = 'lead/FETCH_LEAD_IMPORT_HISTORY';
export const FETCH_LEAD_IMPORT_HISTORY_SUCCESSED = 'lead/FETCH_LEAD_IMPORT_HISTORY_SUCCESSED';
export const FETCH_LEAD_IMPORT_HISTORY_FAILED = 'lead/FETCH_LEAD_IMPORT_HISTORY_FAILED';

export const FETCH_LEAD_IMPORT_HISTORY_ITEMS = 'lead/FETCH_LEAD_IMPORT_HISTORY_ITEMS';
export const FETCH_LEAD_IMPORT_HISTORY_ITEMS_SUCCESSED = 'lead/FETCH_LEAD_IMPORT_HISTORY_ITEMS_SUCCESSED';
export const FETCH_LEAD_IMPORT_HISTORY_ITEMS_FAILED = 'lead/FETCH_LEAD_IMPORT_HISTORY_ITEMS_FAILED';

/* LEAD SETTING */
export const LEAD_SETTINGS_FETCH_FAILED = '/lead/settings/LEAD_SETTINGS_FETCH_FAILED';

export const LEAD_SETTINGS_FETCH_CHECKLIST = '/lead/settings/LEAD_SETTINGS_FETCH_CHECKLIST';
export const LEAD_SETTINGS_FETCH_CHECKLIST_SUCCESSED = '/lead/settings/LEAD_SETTINGS_FETCH_CHECKLIST_SUCCESSED';

export const LEAD_SETTINGS_CREATE_CHECKLIST = 'lead/settings/LEAD_SETTINGS_CREATE_CHECKLIST';
export const LEAD_SETTINGS_UPDATE_CHECKLIST = 'lead/settings/LEAD_SETTINGS_UPDATE_CHECKLIST';
export const LEAD_SETTINGS_DELETE_CHECKLIST = 'lead/settings/LEAD_SETTINGS_DELETE_CHECKLIST';

export const LEAD_SETTINGS_CREATE_CHECKLIST_FAILED = 'lead/settings/LEAD_SETTINGS_CREATE_CHECKLIST_FAILED';
export const LEAD_SETTINGS_UPDATE_CHECKLIST_FAILED = 'lead/settings/LEAD_SETTINGS_UPDATE_CHECKLIST_FAILED';
export const LEAD_SETTINGS_DELETE_CHECKLIST_FAILED = 'lead/settings/LEAD_SETTINGS_DELETE_CHECKLIST_FAILED';

export const LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY = '/lead/settings/LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY';
export const LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY_SUCCESSED =
  '/lead/settings/LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY_SUCCESSED';
export const LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY_FAILED = '/lead/settings/LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY_FAILED';

export const LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY = '/lead/settings/LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY';
export const LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY_SUCCESSED =
  '/lead/settings/LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY_SUCCESSED';
export const LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY_FAILED = '/lead/settings/LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY_FAILED';

/* LEAD DOCUMENT */

export const FETCH_LEAD_DOCUMENTS = 'lead/FETCH_LEAD_DOCUMENTS';
export const FETCH_LEAD_DOCUMENTS_SUCCESSED = 'lead/FETCH_LEAD_DOCUMENTS_SUCCESSED';
export const FETCH_LEAD_DOCUMENTS_FAILED = 'lead/FETCH_LEAD_DOCUMENTS_FAILED';

export const FETCH_REQUIRED_DOCUMENT_TYPES = 'lead/FETCH_REQUIRED_DOCUMENT_TYPES';
export const FETCH_REQUIRED_DOCUMENT_TYPES_SUCCESSED = 'lead/FETCH_REQUIRED_DOCUMENT_TYPES_SUCCESSED';
export const FETCH_REQUIRED_DOCUMENT_TYPES_FAILED = 'lead/FETCH_REQUIRED_DOCUMENT_TYPES_FAILED';

export const UPLOAD_LEAD_DOCUMENT = 'lead/UPLOAD_LEAD_DOCUMENT';
export const UPLOAD_LEAD_DOCUMENT_SUCCESSED = 'lead/UPLOAD_LEAD_DOCUMENT_SUCCESSED';

export const FETCH_LEAD_REPORT_COUNT = 'lead/FETCH_LEAD_REPORT_COUNT';
export const FETCH_LEAD_REPORT_COUNT_SUCCESSED = 'lead/FETCH_LEAD_REPORT_COUNT_SUCCESSED';
export const FETCH_LEAD_REPORT_COUNT_FAILED = 'lead/FETCH_LEAD_REPORT_COUNT_FAILED';

export const FETCH_ALL_PRODUCT = 'lead/FETCH_ALL_PRODUCT';
export const FETCH_ALL_PRODUCT_SUCCESSED = 'lead/FETCH_ALL_PRODUCT_SUCCESSED';
export const FETCH_ALL_PRODUCT_FAILED = 'lead/FETCH_ALL_PRODUCT_FAILED';

export const FETCH_MISSION = 'lead/FETCH_MISSION';
export const FETCH_MISSION_SUCCESSED = 'lead/FETCH_MISSION_SUCCESSED';
export const FETCH_MISSION_FAILED = 'lead/FETCH_MISSION_FAILED';

/* LEAD RULE */

export const UPDATE_AUTO_ASSIGN_UNALLOCATED = 'lead/UPDATE_AUTO_ASSIGN_UNALLOCATED';
export const UPDATE_AUTO_ASSIGN_UNALLOCATED_SUCCESSED = 'lead/UPDATE_AUTO_ASSIGN_UNALLOCATED_SUCCESSED';
export const UPDATE_AUTO_ASSIGN_UNALLOCATED_FAILED = 'lead/UPDATE_AUTO_ASSIGN_UNALLOCATED_FAILED';

export const UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER = 'lead/UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER';
export const UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER_SUCCESSED = 'lead/UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER_SUCCESSED';
export const UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER_FAILED = 'lead/UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER_FAILED';

export const FETCH_LEAD_FOLLOWING = 'lead/FETCH_LEAD_FOLLOWING';
export const FETCH_LEAD_FOLLOWING_SUCCESSED = 'lead/FETCH_LEAD_FOLLOWING_SUCCESSED';
export const FETCH_LEAD_FOLLOWING_FAILED = 'lead/FETCH_LEAD_FOLLOWING_FAILED';

/* LEAD BLACKLIST */
export const FETCH_LEAD_BLACK_LIST = 'lead/FETCH_LEAD_BLACK_LIST';
export const FETCH_LEAD_BLACK_LIST_SUCCESSED = 'lead/FETCH_LEAD_BLACK_LIST_SUCCESSED';
export const FETCH_LEAD_BLACK_LIST_FAILED = 'lead/FETCH_LEAD_BLACK_LIST_FAILED';

/* LEAD CLOSE */
export const FETCH_LEAD_CLOSE = 'lead/FETCH_LEAD_CLOSE';
export const FETCH_LEAD_CLOSE_SUCCESSED = 'lead/FETCH_LEAD_CLOSE_SUCCESSED';
export const FETCH_LEAD_CLOSE_FAILED = 'lead/FETCH_LEAD_CLOSE_FAILED';

/* LEAD RULE */
export const FETCH_RULE_ACTIVE = 'lead/FETCH_RULE_ACTIVE';
export const FETCH_RULE_ACTIVE_SUCCESSED = 'lead/FETCH_RULE_ACTIVE_SUCCESSED';
export const FETCH_RULE_ACTIVE_FAILED = 'lead/FETCH_RULE_ACTIVE_FAILED';

export const FETCH_ASSIGN_LEAD_AUTO = 'lead/FETCH_ASSIGN_LEAD_AUTO';
export const FETCH_ASSIGN_LEAD_AUTO_SUCCESSED = 'lead/FETCH_ASSIGN_LEAD_AUTO_SUCCESSED';
export const FETCH_ASSIGN_LEAD_AUTO_FAILED = 'lead/FETCH_ASSIGN_LEAD_AUTO_FAILED';

export const FETCH_ASSIGN_LEAD_MANUAL = 'lead/FETCH_ASSIGN_LEAD_MANUAL';
export const FETCH_ASSIGN_LEAD_MANUAL_SUCCESSED = 'lead/FETCH_ASSIGN_LEAD_MANUAL_SUCCESSED';
export const FETCH_ASSIGN_LEAD_MANUAL_FAILED = 'lead/FETCH_ASSIGN_LEAD_MANUAL_FAILED';

/* LEAD FILTER RECALL */
export const FETCH_RECALL_LIST = 'lead/FETCH_RECALL_LIST';
export const FETCH_RECALL_LIST_SUCCESSED = 'lead/FETCH_RECALL_LIST_SUCCESSED';
export const FETCH_RECALL_LIST_FAILED = 'lead/FETCH_RECALL_LIST_FAILED';

export const FETCH_RECALL_RESULT = 'lead/FETCH_RECALL_RESULT';
export const FETCH_RECALL_RESULT_SUCCESSED = 'lead/FETCH_RECALL_RESULT_SUCCESSED';
export const FETCH_RECALL_RESULT_FAILED = 'lead/FETCH_RECALL_RESULT_FAILED';

export const SETTING_AUTO_ASSIGN = 'lead/SETTING_AUTO_ASSIGN';
export const SETTING_AUTO_ASSIGN_SUCCESSED = 'lead/SETTING_AUTO_ASSIGN_SUCCESSED';
export const SETTING_AUTO_ASSIGN_FAILED = 'lead/SETTING_AUTO_ASSIGN_FAILED';

export const FETCH_VARIABLE = 'lead/FETCH_VARIABLE';
export const FETCH_VARIABLE_SUCCESSED = 'lead/FETCH_VARIABLE_SUCCESSED';
export const FETCH_VARIABLE_FAILED = 'lead/FETCH_VARIABLE_FAILED';

export const SEND_EMAIL_ACTIVITY = 'lead/SEND_EMAIL_ACTIVITY';
export const SEND_EMAIL_ACTIVITY_SUCCESSED = 'lead/SEND_EMAIL_ACTIVITY_SUCCESSED';
export const SEND_EMAIL_ACTIVITY_FAILED = 'lead/SEND_EMAIL_ACTIVITY_FAILED';

export const GET_NUMBER_TARGET_KPI = 'lead/GET_NUMBER_TARGET_KPI';
export const GET_NUMBER_TARGET_KPI_SUCCESSED = 'lead/GET_NUMBER_TARGET_KPI_SUCCESSED';
export const GET_NUMBER_TARGET_KPI_FAILED = 'lead/GET_NUMBER_TARGET_KPI_FAILED';

export const REPORT_LEAD = 'lead/REPORT_LEAD';
export const REPORT_LEAD_SUCCESSED = 'lead/REPORT_LEAD_SUCCESSED';
export const REPORT_LEAD_FAILED = 'lead/REPORT_LEAD_FAILED';

export const REPORT_LEAD_ACTIVITY = 'lead/REPORT_LEAD_ACTIVITY';
export const REPORT_LEAD_ACTIVITY_SUCCESSED = 'lead/REPORT_LEAD_ACTIVITY_SUCCESSED';
export const REPORT_LEAD_ACTIVITY_FAILED = 'lead/REPORT_LEAD_ACTIVITY_FAILED';

export const REPORT_LEAD_MCAS = 'lead/REPORT_LEAD_MCAS';
export const REPORT_LEAD_MCAS_SUCCESSED = 'lead/REPORT_LEAD_MCAS_SUCCESSED';
export const REPORT_LEAD_MCAS_FAILED = 'lead/REPORT_LEAD_MCAS_FAILED';

export const REPORT_LEAD_DISTRIBUTED_LEAD = 'lead/REPORT_LEAD_DISTRIBUTED_LEAD';
export const REPORT_LEAD_DISTRIBUTED_LEAD_SUCCESSED = 'lead/REPORT_LEAD_DISTRIBUTED_LEAD_SUCCESSED';
export const REPORT_LEAD_DISTRIBUTED_LEAD_FAILED = 'lead/REPORT_LEAD_DISTRIBUTED_LEAD_FAILED';

export const REPORT_LEAD_DISTRIBUTED_AMOUNT = 'lead/REPORT_LEAD_DISTRIBUTED_AMOUNT';
export const REPORT_LEAD_DISTRIBUTED_AMOUNT_SUCCESSED = 'lead/REPORT_LEAD_DISTRIBUTED_AMOUNT_SUCCESSED';
export const REPORT_LEAD_DISTRIBUTED_AMOUNT_FAILED = 'lead/REPORT_LEAD_DISTRIBUTED_AMOUNT_FAILED';

export const REPORT_LEAD_PARTNER = 'lead/REPORT_LEAD_PARTNER';
export const REPORT_LEAD_PARTNER_SUCCESSED = 'lead/REPORT_LEAD_PARTNER_SUCCESSED';
export const REPORT_LEAD_PARTNER_FAILED = 'lead/REPORT_LEAD_PARTNER_FAILED';

export const GET_DASHBOARD_SUMMARY = 'lead/GET_DASHBOARD_SUMMARY';
export const GET_DASHBOARD_SUMMARY_SUCCESSED = 'lead/GET_DASHBOARD_SUMMARY_SUCCESSED';
export const GET_DASHBOARD_SUMMARY_FAILED = 'lead/GET_DASHBOARD_SUMMARY_FAILED';

export const GET_DASHBOARD_EMPLOYEE_SUMMARY = 'lead/GET_DASHBOARD_EMPLOYEE_SUMMARY';
export const GET_DASHBOARD_EMPLOYEE_SUMMARY_SUCCESSED = 'lead/GET_DASHBOARD_EMPLOYEE_SUMMARY_SUCCESSED';
export const GET_DASHBOARD_EMPLOYEE_SUMMARY_FAILED = 'lead/GET_DASHBOARD_EMPLOYEE_SUMMARY_FAILED';

export const GET_DASHBOARD_POS_SUMMARY = 'lead/GET_DASHBOARD_POS_SUMMARY';
export const GET_DASHBOARD_POS_SUMMARY_SUCCESSED = 'lead/GET_DASHBOARD_POS_SUMMARY_SUCCESSED';
export const GET_DASHBOARD_POS_SUMMARY_FAILED = 'lead/GET_DASHBOARD_POS_SUMMARY_FAILED';

export const GET_DASHBOARD_DISBURSEMENT = 'lead/GET_DASHBOARD_DISBURSEMENT';
export const GET_DASHBOARD_DISBURSEMENT_SUCCESSED = 'lead/GET_DASHBOARD_DISBURSEMENT_SUCCESSED';
export const GET_DASHBOARD_DISBURSEMENT_FAILED = 'lead/GET_DASHBOARD_DISBURSEMENT_FAILED';

export const GET_DASHBOARD_DISBURSEMENT_SALES = 'lead/GET_DASHBOARD_DISBURSEMENT_SALES';
export const GET_DASHBOARD_DISBURSEMENT_SALES_SUCCESSED = 'lead/GET_DASHBOARD_DISBURSEMENT_SALES_SUCCESSED';
export const GET_DASHBOARD_DISBURSEMENT_SALES_FAILED = 'lead/GET_DASHBOARD_DISBURSEMENT_SALES_FAILED';

export const GET_KPI_MONTHLY = 'kpi/GET_KPI_MONTHLY';
export const GET_KPI_MONTHLY_SUCCESSED = 'kpi/GET_KPI_MONTHLY_SUCCESSED';

export const GET_DASHBOARD_DISBURSEMENT_PRODUCT = 'lead/GET_DASHBOARD_DISBURSE_PRODUCT_SUM';
export const GET_DASHBOARD_DISBURSEMENT_PRODUCT_SUCCESSED = 'lead/GET_DASHBOARD_DISBURSE_PRODUCT_SUM_SUCCESSED';
export const GET_DASHBOARD_DISBURSEMENT_PRODUCT_FAILED = 'lead/GET_DASHBOARD_DISBURSEMENT_PRODUCT_FAILED';

export const GET_DASHBOARD_DISBURSEMENT_COUNT = 'lead/GET_DASHBOARD_DISBURSEMENT_COUNT';
export const GET_DASHBOARD_DISBURSEMENT_COUNT_SUCCESSED = 'lead/GET_DASHBOARD_DISBURSEMENT_COUNT_SUCCESSED';
export const GET_DASHBOARD_DISBURSEMENT_COUNT_FAILED = 'lead/GET_DASHBOARD_DISBURSEMENT_COUNT_FAILED';

// TT
export const GET_DASHBOARD_EMPLOYEE_RATING = 'lead/GET_DASHBOARD_EMPLOYEE_RATING';
export const GET_DASHBOARD_EMPLOYEE_RATING_SUCCESSED = 'lead/GET_DASHBOARD_EMPLOYEE_RATING_SUCCESSED';
export const GET_DASHBOARD_EMPLOYEE_RATING_FAILED = 'lead/GET_DASHBOARD_EMPLOYEE_RATING_FAILED';

export const GET_DASHBOARD_POS_RATING = 'lead/GET_DASHBOARD_POS_RATING';
export const GET_DASHBOARD_POS_RATING_SUCCESSED = 'lead/GET_DASHBOARD_POS_RATING_SUCCESSED';
export const GET_DASHBOARD_POS_RATING_FAILED = 'lead/GET_DASHBOARD_POS_RATING_FAILED';

export const FETCH_LEAD_FIELD_NOTE = 'lead/FETCH_LEAD_FIELD_NOTE';
export const FETCH_LEAD_FIELD_NOTE_SUCCESSED = 'lead/FETCH_LEAD_FIELD_NOTE_SUCCESSED';
export const FETCH_LEAD_FIELD_NOTE_FAILED = 'lead/FETCH_LEAD_FIELD_NOTE_FAILED';

export const UPDATE_LEAD_FIELD_NOTE = 'lead/UPDATE_LEAD_FIELD_NOTE';
export const UPDATE_LEAD_FIELD_NOTE_SUCCESSED = 'lead/UPDATE_LEAD_FIELD_NOTE_SUCCESSED';
export const UPDATE_LEAD_FIELD_NOTE_FAILED = 'lead/UPDATE_LEAD_FIELD_NOTE_FAILED';

export const FETCH_ALL_INSURANCE = 'lead/FETCH_ALL_INSURANCE';
export const FETCH_ALL_INSURANCE_SUCCESSED = 'lead/FETCH_ALL_INSURANCE_SUCCESSED';
export const FETCH_ALL_INSURANCE_FAILED = 'lead/FETCH_ALL_INSURANCE_FAILED';

// ***********************************************************************************
// LEAD CAR REGISTER
export const FETCH_CAR_REGISTER = 'lead/FETCH_CAR_REGISTER';
export const FETCH_CAR_REGISTER_SUCCESSED = 'lead/FETCH_CAR_REGISTER_SUCCESSED';
export const FETCH_CAR_REGISTER_FAILED = 'lead/FETCH_CAR_REGISTER_FAILED';

// LEAD CAR DAMAGE
export const FETCH_CAR_DAMAGE = 'lead/FETCH_CAR_DAMAGE';
export const FETCH_CAR_DAMAGE_SUCCESSED = 'lead/FETCH_CAR_DAMAGE_SUCCESSED';
export const FETCH_CAR_DAMAGE_FAILED = 'lead/FETCH_CAR_DAMAGE_FAILED';

export const FETCH_CAR_FRONT = 'lead/FETCH_CAR_FRONT';
export const FETCH_CAR_FRONT_SUCCESSED = 'lead/FETCH_CAR_FRONT_SUCCESSED';
export const FETCH_CAR_FRONT_FAILED = 'lead/FETCH_CAR_FRONT_FAILED';

export const FETCH_CAR_BACK = 'lead/FETCH_CAR_BACK';
export const FETCH_CAR_BACK_SUCCESSED = 'lead/FETCH_CAR_BACK_SUCCESSED';
export const FETCH_CAR_BACK_FAILED = 'lead/FETCH_CAR_BACK_FAILED';

export const FETCH_CAR_LEFT = 'lead/FETCH_CAR_LEFT';
export const FETCH_CAR_LEFT_SUCCESSED = 'lead/FETCH_CAR_LEFT_SUCCESSED';
export const FETCH_CAR_LEFT_FAILED = 'lead/FETCH_CAR_LEFT_FAILED';

export const FETCH_CAR_RIGHT = 'lead/FETCH_CAR_RIGHT';
export const FETCH_CAR_RIGHT_SUCCESSED = 'lead/FETCH_CAR_RIGHT_SUCCESSED';
export const FETCH_CAR_RIGHT_FAILED = 'lead/FETCH_CAR_RIGHT_FAILED';
