// @flow
import * as type from './constants';

/* Calendar */
export const fetchCalendarEvents = (data) => ({
  type: type.FETCH_CALENDAR_EVENTS,
  payload: data,
});
export const fetchCalendarEventsSuccessed = (data) => ({
  type: type.FETCH_CALENDAR_EVENTS_SUCCESSED,
  payload: data,
});
export const fetchCalendarEventsFailed = (error) => ({
  type: type.FETCH_CALENDAR_EVENTS_FAILED,
  payload: error,
});

export const fetchCalendarEventsByDate = (data, callback = () => {}) => ({
  type: type.FETCH_CALENDAR_EVENTS_BY_DATE,
  payload: { data, callback },
});
export const fetchCalendarEventsByDateSuccessed = (data) => ({
  type: type.FETCH_CALENDAR_EVENTS_BY_DATE_SUCCESSED,
  payload: data,
});
export const fetchCalendarEventsByDateFailed = (error) => ({
  type: type.FETCH_CALENDAR_EVENTS_BY_DATE_FAILED,
  payload: error,
});

export const fetchCalendarEvent = (eventId) => ({
  type: type.FETCH_CALENDAR_EVENT,
  payload: eventId,
});
export const fetchCalendarEventSuccessed = (data) => ({
  type: type.FETCH_CALENDAR_EVENT_SUCCESSED,
  payload: data,
});
export const fetchCalendarEventFailed = (error) => ({
  type: type.FETCH_CALENDAR_EVENT_FAILED,
  payload: error,
});

export const createCalendarEvent = (data, setState = () => {}) => ({
  type: type.CREATE_CALENDAR_EVENT,
  payload: { data, setState },
});
export const createCalendarEventSuccessed = (data) => ({
  type: type.CREATE_CALENDAR_EVENT_SUCCESSED,
  payload: data,
});
export const createCalendarEventFailed = (error) => ({
  type: type.CREATE_CALENDAR_EVENT_FAILED,
  payload: error,
});

export const updateCalendarEvent = (eventId, data, setState = () => {}) => ({
  type: type.UPDATE_CALENDAR_EVENT,
  payload: { eventId, data, setState },
});
export const updateCalendarEventSuccessed = (data) => ({
  type: type.UPDATE_CALENDAR_EVENT_SUCCESSED,
  payload: data,
});
export const updateCalendarEventFailed = (error) => ({
  type: type.UPDATE_CALENDAR_EVENT_FAILED,
  payload: error,
});

export const deleteCalendarEvent = (eventId, setState = () => {}) => ({
  type: type.DELETE_CALENDAR_EVENT,
  payload: { eventId, setState },
});
export const deleteCalendarEventSuccessed = (data) => ({
  type: type.DELETE_CALENDAR_EVENT_SUCCESSED,
  payload: data,
});
export const deleteCalendarEventFailed = (error) => ({
  type: type.DELETE_CALENDAR_EVENT_FAILED,
  payload: error,
});
