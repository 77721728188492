/* Accounts */
export const FETCH_ACCOUNTS = 'account/FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_SUCCESSED = 'account/FETCH_ACCOUNTS_SUCCESSED';
export const FETCH_ACCOUNTS_FAILED = 'account/FETCH_ACCOUNTS_FAILED';
export const CLEAR_ACCOUNTS = 'account/CLEAR_ACCOUNTS';

export const FETCH_PAYMENTS = 'account/FETCH_PAYMENTS';
export const FETCH_PAYMENTS_SUCCESSED = 'account/FETCH_PAYMENTS_SUCCESSED';
export const FETCH_PAYMENTS_FAILED = 'account/FETCH_PAYMENTS_FAILED';
export const CLEAR_PAYMENTS = 'account/CLEAR_PAYMENTS';

export const FETCH_LEAD_VALID = 'account/FETCH_LEAD_VALID';
export const FETCH_LEAD_VALID_SUCCESSED = 'account/FETCH_LEAD_VALID_SUCCESSED';
export const FETCH_LEAD_VALID_FAILED = 'account/FETCH_LEAD_VALID_FAILED';

export const FETCH_LEAD_STATUS = 'account/FETCH_LEAD_STATUS';
export const FETCH_LEAD_STATUS_SUCCESSED = 'account/FETCH_LEAD_STATUS_SUCCESSED';
export const FETCH_LEAD_STATUS_FAILED = 'account/FETCH_LEAD_STATUS_FAILED';

export const FETCH_LEAD_REPORT_DAY_COUNT = 'account/FETCH_LEAD_REPORT_DAY_COUNT';
export const FETCH_LEAD_REPORT_DAY_COUNT_SUCCESSED = 'account/FETCH_LEAD_REPORT_DAY_COUNT_SUCCESSED';
export const FETCH_LEAD_REPORT_DAY_COUNT_FAILED = 'account/FETCH_LEAD_REPORT_DAY_COUNT_FAILED';

export const FETCH_LEAD_DISBURSED_MONTH = 'account/FETCH_LEAD_DISBURSED_MONTH';
export const FETCH_LEAD_DISBURSED_MONTH_SUCCESSED = 'account/FETCH_LEAD_DISBURSED_MONTH_SUCCESSED';
export const FETCH_LEAD_DISBURSED_MONTH_FAILED = 'account/FETCH_LEAD_DISBURSED_MONTH_FAILED';

export const FETCH_LEAD_DISBURSEMENT = 'account/FETCH_LEAD_DISBURSEMENT';
export const FETCH_LEAD_DISBURSEMENT_SUCCESSED = 'account/FETCH_LEAD_DISBURSEMENT_SUCCESSED';
export const FETCH_LEAD_DISBURSEMENT_FAILED = 'account/FETCH_LEAD_DISBURSEMENT_FAILED';

// getEffectivePartner
export const GET_EFFECTIVE_PARTNER = 'account/GET_EFFECTIVE_PARTNER';
export const GET_EFFECTIVE_PARTNER_SUCCESSED = 'account/GET_EFFECTIVE_PARTNER_SUCCESSED';
export const GET_EFFECTIVE_PARTNER_FAILED = 'account/GET_EFFECTIVE_PARTNER_FAILED';
