// @flow
import { getNotificationSubscribedToken } from 'helpers/authUtils';
import * as type from './constants';

const INIT_STATE = {
  regions: [],
  provinces: [],
  provincesByRegion: [],
  provinceMap: {
    // key is province id
  },
  districts: [],
  wards: [],
  ipGeo: {},
  appList: {},

  categories: [],
  categoryValues: [],

  notifications: [],
  unseenNotifications: [],
  notificationSubscribedToken: getNotificationSubscribedToken(),

  routeConfig: {
    homePage: '',
    menu: [],
    pages: [],
  },
  activeMenuKeys: [],
};

const Common = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Address cases */
    case type.FETCH_DISTRICTS:
      return { ...state };
    case type.FETCH_DISTRICTS_SUCCESSED:
      return { ...state, districts: action.payload, error: null, wards: [] };
    case type.FETCH_DISTRICTS_FAILED:
      return { ...state, error: action.payload };
    case type.CLEAR_DISTRICTS:
      return { ...state, districts: [] };
    case type.SAVE_DISTRICTS:
      return {
        ...state,
        provinceMap: {
          ...state.provinceMap,
          [action.payload.provinceId]: action.payload.districts,
        },
      };

    case type.FETCH_WARDS:
      return { ...state };
    case type.FETCH_WARDS_SUCCESSED:
      return { ...state, wards: action.payload, error: null };
    case type.FETCH_WARDS_FAILED:
      return { ...state, error: action.payload };

    case type.CLEAR_WARDS:
      return { ...state };
    case type.CLEAR_WARDS_SUCCESSED:
      return { ...state, wards: [] };

    /* Category common */

    case type.FETCH_COMMON_CATEGORY:
      return { ...state };
    case type.FETCH_COMMON_CATEGORY_SUCCESSED:
      return { ...state, categories: action.payload, error: null };
    case type.FETCH_COMMON_CATEGORY_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CATEGORY_VALUES:
      return { ...state };
    case type.FETCH_CATEGORY_VALUES_SUCCESSED:
      return { ...state, categoryValues: action.payload, error: null };
    case type.FETCH_CATEGORY_VALUES_FAILED:
      return { ...state, error: action.payload };

    /* IP Geo case */
    case type.FETCH_IP_GEO:
      return { ...state };
    case type.FETCH_IP_GEO_SUCCESSED:
      return { ...state, ipGeo: action.payload, error: null };
    case type.FETCH_IP_GEO_FAILED:
      return { ...state, error: action.payload };
    case type.CLEAR_IP_GEO:
      return { ...state, ipGeo: [] };

    case type.FETCH_APP_LIST:
      return { ...state };
    case type.FETCH_APP_LIST_SUCCESS:
      return { ...state, appList: action.payload };

    case type.FETCH_NOTIFICATIONS:
      return { ...state };
    case type.FETCH_NOTIFICATIONS_SUCCESS:
      return { ...state, notifications: action.payload };

    case type.FETCH_UNSEEN_NOTIFICATIONS:
      return { ...state };
    case type.FETCH_UNSEEN_NOTIFICATIONS_SUCCESS:
      return { ...state, unseenNotifications: action.payload };

    case type.SUBSCRIBE_USER:
      return { ...state };
    case type.SUBSCRIBE_USER_SUCCESS:
      return { ...state, notificationSubscribedToken: action.payload };
    case type.SUBSCRIBE_USER_FAILED:
      return { ...state, error: action.payload };

    case type.UNSUBSCRIBE_USER:
      return { ...state };
    case type.UNSUBSCRIBE_USER_SUCCESS:
      return { ...state, notificationSubscribedToken: null };
    case type.UNSUBSCRIBE_USER_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_CATEGORY_SUCCESSED:
      return {
        ...state,
        categoryValues: state.categoryValues.filter((e) => e.id !== action.payload),
      };

    case type.FETCH_ROUTE_CONFIG_SUCCESSED:
      return { ...state, routeConfig: action.payload };
    case type.UPDATE_ROUTE_CONFIG:
      return { ...state, routeConfig: action.payload };

    case type.UPDATE_ACTIVE_MENU_KEYS:
      return { ...state, activeMenuKeys: action.payload };

    default:
      return { ...state };
  }
};

export default Common;
