// @flow
import * as type from './constants';

const INIT_STATE = {
  campaigns: {
    entries: [],
    pagination: {
      skipCount: 0,
      maxItems: 20,
      count: 8,
      hasMoreItems: false,
      totalItems: 8,
    },
  },
  campaign: {
    referral: [],
    promotion: [],
  },
  campaignFilterConfig: null,

  partners: [],
  groups: {
    entries: [],
    pagination: {
      skipCount: 0,
      maxItems: 20,
      count: 8,
      hasMoreItems: false,
      totalItems: 8,
    },
  },
  group: {},
  isOpenCreateCampaign: false,
};

const Campaign = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Campaign cases */
    case type.FETCH_CAMPAIGNS:
      return { ...state };
    case type.FETCH_CAMPAIGNS_SUCCESSED:
      return { ...state, campaigns: action.payload, error: null };
    case type.FETCH_CAMPAIGNS_FAILED:
      return { ...state, error: action.payload };
    case type.CLEAR_CAMPAIGNS:
      return { ...state, campaigns: { entries: [] }, error: null };

    case type.FETCH_CAMPAIGNS_BY_FILTER:
      return { ...state };

    case type.FETCH_CAMPAIGN_DETAIL:
      return { ...state };
    case type.FETCH_CAMPAIGN_DETAIL_SUCCESSED:
      return { ...state, campaign: action.payload, error: null };
    case type.FETCH_CAMPAIGN_DETAIL_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_CAMPAIGN:
      return { ...state };
    case type.CREATE_CAMPAIGN_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_CAMPAIGN_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_CAMPAIGN:
      return { ...state };
    case type.UPDATE_CAMPAIGN_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_CAMPAIGN_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_CAMPAIGN:
      return { ...state };
    case type.DELETE_CAMPAIGN_SUCCESSED:
      return { ...state, error: null };
    case type.DELETE_CAMPAIGN_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CAMPAIGN_CODE:
      return { ...state };
    case type.FETCH_CAMPAIGN_CODE_SUCCESSED: {
      const { cate, data } = action.payload;
      const newCampaign = { ...state.campaign };
      newCampaign[cate] = data;

      return { ...state, campaign: newCampaign };
    }
    case type.FETCH_CAMPAIGN_CODE_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_CAMPAIGN_CODE:
      return { ...state };
    case type.CREATE_CAMPAIGN_CODE_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_CAMPAIGN_CODE_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CAMPAIGN_GROUPS:
      return { ...state };
    case type.FETCH_CAMPAIGN_GROUPS_SUCCESSED:
      return { ...state, groups: action.payload, error: null };
    case type.FETCH_CAMPAIGN_GROUPS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CAMPAIGN_GROUP_DETAIL:
      return { ...state };
    case type.FETCH_CAMPAIGN_GROUP_DETAIL_SUCCESSED:
      return { ...state, group: action.payload, error: null };
    case type.FETCH_CAMPAIGN_GROUP_DETAIL_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_CAMPAIGN_GROUP:
      return { ...state };
    case type.CREATE_CAMPAIGN_GROUP_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_CAMPAIGN_GROUP_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_CAMPAIGN_GROUP:
      return { ...state };
    case type.UPDATE_CAMPAIGN_GROUP_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_CAMPAIGN_GROUP_FAILED:
      return { ...state, error: action.payload };

    case type.SET_OPEN_CREATE_CAMPAIGN:
      return { ...state, isOpenCreateCampaign: action.payload, error: action.payload };

    case type.STORE_CAMPAIGN_FILTER_CONFIG:
      return { ...state, campaignFilterConfig: action.payload };
    // Campaign partner
    case type.FETCH_CAMPAIGN_PARTNERS:
      return { ...state };
    case type.FETCH_CAMPAIGN_PARTNERS_SUCCESSED:
      return { ...state, partners: action.payload, error: null };
    case type.FETCH_CAMPAIGN_PARTNERS_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_CAMPAIGN_PARTNER:
      return { ...state };
    case type.CREATE_CAMPAIGN_PARTNER_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_CAMPAIGN_PARTNER_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_CAMPAIGN_PARTNER:
      return { ...state };
    case type.UPDATE_CAMPAIGN_PARTNER_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_CAMPAIGN_PARTNER_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_CAMPAIGN_PARTNER:
      return { ...state };
    case type.DELETE_CAMPAIGN_PARTNER_SUCCESSED:
      return { ...state, error: null };
    case type.DELETE_CAMPAIGN_PARTNER_FAILED:
      return { ...state, error: action.payload };

    case type.REPORT_TOP_PARTNER_COUNT_SUCCESSED:
      return { ...state, reportTopPartner: action.payload, error: null };
    case type.LEAD_STATUS_COUNT_SUCCESSED:
      return { ...state, leadStatus: action.payload, error: null };

    case type.DISBURSEMENT_SUCCESSED:
      return { ...state, disbursement: action.payload, error: null };
    case type.LEAD_VALID_COUNT_SUCCESSED:
      return { ...state, leadValidCount: action.payload, error: null };

    case type.LEAD_STATUS_COUNT_FAILED:
    case type.REPORT_TOP_PARTNER_COUNT_FAILED:
    case type.DISBURSEMENT_FAILED:
    case type.LEAD_VALID_COUNT_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_CAMPAIGN_STATUS:
      return { ...state };
    default:
      return { ...state };
  }
};

export default Campaign;
