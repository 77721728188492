/* CAMPAIGNs */
export const FETCH_CAMPAIGNS = 'campaign/FETCH_CAMPAIGNS';
export const FETCH_CAMPAIGNS_SUCCESSED = 'campaign/FETCH_CAMPAIGNS_SUCCESSED';
export const FETCH_CAMPAIGNS_FAILED = 'campaign/FETCH_CAMPAIGNS_FAILED';
export const CLEAR_CAMPAIGNS = 'campaign/CLEAR_CAMPAIGNS';

export const FETCH_CAMPAIGNS_BY_FILTER = 'campaign/FETCH_CAMPAIGNS_BY_FILTER';

export const FETCH_CAMPAIGN_DETAIL = 'campaign/FETCH_CAMPAIGN_DETAIL';
export const FETCH_CAMPAIGN_DETAIL_SUCCESSED = 'campaign/FETCH_CAMPAIGN_DETAIL_SUCCESSED';
export const FETCH_CAMPAIGN_DETAIL_FAILED = 'campaign/FETCH_CAMPAIGN_DETAIL_FAILED';

export const CREATE_CAMPAIGN = 'campaign/CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESSED = 'campaign/CREATE_CAMPAIGN_SUCCESSED';
export const CREATE_CAMPAIGN_FAILED = 'campaign/CREATE_CAMPAIGN_FAILED';

export const UPDATE_CAMPAIGN = 'campaign/UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_SUCCESSED = 'campaign/UPDATE_CAMPAIGN_SUCCESSED';
export const UPDATE_CAMPAIGN_FAILED = 'campaign/UPDATE_CAMPAIGN_FAILED';

export const DELETE_CAMPAIGN = 'campaign/DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_SUCCESSED = 'campaign/DELETE_CAMPAIGN_SUCCESSED';
export const DELETE_CAMPAIGN_FAILED = 'campaign/DELETE_CAMPAIGN_FAILED';

export const FETCH_CAMPAIGN_CODE = 'campaign/FETCH_CAMPAIGN_CODE';
export const FETCH_CAMPAIGN_CODE_SUCCESSED = 'campaign/FETCH_CAMPAIGN_CODE_SUCCESSED';
export const FETCH_CAMPAIGN_CODE_FAILED = 'campaign/FETCH_CAMPAIGN_CODE_FAILED';

export const CREATE_CAMPAIGN_CODE = 'campaign/CREATE_CAMPAIGN_CODE';
export const CREATE_CAMPAIGN_CODE_SUCCESSED = 'campaign/CREATE_CAMPAIGN_CODE_SUCCESSED';
export const CREATE_CAMPAIGN_CODE_FAILED = 'campaign/CREATE_CAMPAIGN_CODE_FAILED';

export const UPDATE_CAMPAIGN_CODE = 'campaign/UPDATE_CAMPAIGN_CODE';
export const UPDATE_CAMPAIGN_CODE_SUCCESSED = 'campaign/UPDATE_CAMPAIGN_CODE_SUCCESSED';
export const UPDATE_CAMPAIGN_CODE_FAILED = 'campaign/UPDATE_CAMPAIGN_CODE_FAILED';

export const FETCH_CAMPAIGN_GROUPS = 'campaign/FETCH_CAMPAIGN_GROUPS';
export const FETCH_CAMPAIGN_GROUPS_SUCCESSED = 'campaign/FETCH_CAMPAIGN_GROUPS_SUCCESSED';
export const FETCH_CAMPAIGN_GROUPS_FAILED = 'campaign/FETCH_CAMPAIGN_GROUPS_FAILED';

export const FETCH_CAMPAIGN_GROUP_DETAIL = 'campaign/FETCH_CAMPAIGN_GROUP_DETAIL';
export const FETCH_CAMPAIGN_GROUP_DETAIL_SUCCESSED = 'campaign/FETCH_CAMPAIGN_GROUP_DETAIL_SUCCESSED';
export const FETCH_CAMPAIGN_GROUP_DETAIL_FAILED = 'campaign/FETCH_CAMPAIGN_GROUP_DETAIL_FAILED';

export const CREATE_CAMPAIGN_GROUP = 'campaign/CREATE_CAMPAIGN_GROUP';
export const CREATE_CAMPAIGN_GROUP_SUCCESSED = 'campaign/CREATE_CAMPAIGN_GROUP_SUCCESSED';
export const CREATE_CAMPAIGN_GROUP_FAILED = 'campaign/CREATE_CAMPAIGN_GROUP_FAILED';

export const UPDATE_CAMPAIGN_GROUP = 'campaign/UPDATE_CAMPAIGN_GROUP';
export const UPDATE_CAMPAIGN_GROUP_SUCCESSED = 'campaign/UPDATE_CAMPAIGN_GROUP_SUCCESSED';
export const UPDATE_CAMPAIGN_GROUP_FAILED = 'campaign/UPDATE_CAMPAIGN_GROUP_FAILED';

export const SET_OPEN_CREATE_CAMPAIGN = 'campaign/SET_OPEN_CREATE_CAMPAIGN';

export const UPDATE_CAMPAIGN_STATUS = 'campaign/UPDATE_CAMPAIGN_STATUS';

export const STORE_CAMPAIGN_FILTER_CONFIG = 'campaign/STORE_CAMPAIGN_FILTER_CONFIG';

// Campaign partner
export const FETCH_CAMPAIGN_PARTNERS = 'campaign/FETCH_CAMPAIGN_PARTNERS';
export const FETCH_CAMPAIGN_PARTNERS_SUCCESSED = 'campaign/FETCH_CAMPAIGN_PARTNERS_SUCCESSED';
export const FETCH_CAMPAIGN_PARTNERS_FAILED = 'campaign/FETCH_CAMPAIGN_PARTNERS_FAILED';

export const CREATE_CAMPAIGN_PARTNER = 'campaign/CREATE_CAMPAIGN_PARTNER';
export const CREATE_CAMPAIGN_PARTNER_SUCCESSED = 'campaign/CREATE_CAMPAIGN_PARTNER_SUCCESSED';
export const CREATE_CAMPAIGN_PARTNER_FAILED = 'campaign/CREATE_CAMPAIGN_PARTNER_FAILED';

export const UPDATE_CAMPAIGN_PARTNER = 'campaign/UPDATE_CAMPAIGN_PARTNER';
export const UPDATE_CAMPAIGN_PARTNER_SUCCESSED = 'campaign/UPDATE_CAMPAIGN_PARTNER_SUCCESSED';
export const UPDATE_CAMPAIGN_PARTNER_FAILED = 'campaign/UPDATE_CAMPAIGN_PARTNER_FAILED';

export const DELETE_CAMPAIGN_PARTNER = 'campaign/DELETE_CAMPAIGN_PARTNER';
export const DELETE_CAMPAIGN_PARTNER_SUCCESSED = 'campaign/DELETE_CAMPAIGN_PARTNER_SUCCESSED';
export const DELETE_CAMPAIGN_PARTNER_FAILED = 'campaign/DELETE_CAMPAIGN_PARTNER_FAILED';

export const REPORT_TOP_PARTNER_COUNT_SUCCESSED = 'campaign/REPORT_TOP_PARTNER_COUNT_SUCCESSED';
export const REPORT_TOP_PARTNER_COUNT_FAILED = 'campaign/REPORT_TOP_PARTNER_COUNT_FAILED';

export const LEAD_STATUS_COUNT_SUCCESSED = 'campaign/LEAD_STATUS_COUNT_SUCCESSED';
export const LEAD_STATUS_COUNT_FAILED = 'campaign/LEAD_STATUS_COUNT_FAILED';

export const DISBURSEMENT_SUCCESSED = 'campaign/DISBURSEMENT_SUCCESSED';
export const DISBURSEMENT_FAILED = 'campaign/DISBURSEMENT_FAILED';

export const LEAD_VALID_COUNT_SUCCESSED = 'campaign/LEAD_VALID_COUNT_SUCCESSED';
export const LEAD_VALID_COUNT_FAILED = 'campaign/LEAD_VALID_COUNT_FAILED';
