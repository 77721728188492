import { handleLoading } from 'redux/layout/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import * as type from './constants';
import * as actions from './actions';
import * as api from './api';

function* fetchAllKpi(action) {
  yield put(handleLoading(true));
  // const { dataType, jobStatus, page, itemPerPage } = action.payload;

  try {
    const response = yield call(api.fetchAllKpi);

    yield put(actions.getAllKpiSuccess(response));
  } catch (error) {
    yield put(actions.getAllKpiFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchAllMonthOfKpi(action) {
  const { data, options } = action.payload;
  const { loading, onSuccess, onFailure } = options;

  loading && loading(true);
  try {
    const response = yield call(api.fetchAllMonthOfKpi, data);
    yield put(actions.getAllMonthKpiSuccess(response));
    onSuccess && onSuccess(response);
  } catch (error) {
    // toast.error('Có lỗi xảy ra. Không lấy được thông tin kpi.');
    yield put(actions.getAllMonthKpiFailed(error));
    onFailure && onFailure(error);
  }
  loading && loading(true);
}

function* updateKpi(action) {
  const { data, options } = action.payload;
  const { id, payload } = data;
  const { loading, onSuccess, onFailure } = options;

  loading && loading(true);
  try {
    const response = yield call(api.updateKpi, id, payload);

    yield put(actions.updateKpiSuccess(response));
    onSuccess && onSuccess(response);
    toast.success('Cập nhật kpi thành công!');
  } catch (error) {
    yield put(actions.updateKpiFailed(error));
    onFailure && onFailure(error);
    toast.error('Cập nhật kpi thất bại!');
  }
  loading && loading(false);
}

function* getDetailKpi(action) {
  const { data, options } = action.payload;
  const { loading, onSuccess, onFailure } = options;

  loading && loading(true);
  try {
    const response = yield call(api.getDetailApi, data);

    yield put(actions.getDetailKpiSuccess(response));
    onSuccess && onSuccess(response);
  } catch (error) {
    yield put(actions.getDetailKpiFailed(error));
    onFailure && onFailure(error);
  }

  loading && loading(false);
}

function* createKpiNumber(action) {
  const { data, options } = action.payload;
  const { loading, onSuccess, onFailure } = options;

  loading && loading(true);
  try {
    const response = yield call(api.createKpiNumber, data);

    onSuccess && onSuccess(response);
    toast.success('Tạo kpi thành công!');
    yield put(actions.createKpiNumberSuccess(data));
  } catch (error) {
    onFailure && onFailure(error);
    yield put(actions.createKpiNumberFailed(error));
  }

  loading && loading(false);
}

function* getLogFilter(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.getLogNumber, action.payload);

    yield put(actions.getLogFilterSuccess(response));
    // toast.success("Tạo kpi thành công!");
  } catch (error) {
    yield put(actions.getLogFilterFailed(error));
    // toast.error("Tạo kpi thất bại!");
  }

  yield put(handleLoading(false));
}

function* getAllNumber(action) {
  try {
    const response = yield call(api.getItemByMonth, action.payload);

    yield put(actions.getAllNumberSuccess(response));
  } catch (error) {
    yield put(actions.getAllNumberFailed(error));
  }
}

export function* watchKpi() {
  yield takeEvery(type.GET_ALL_KPI, fetchAllKpi);
  yield takeEvery(type.GET_ALL_NUMBER_KPI, fetchAllMonthOfKpi);
  yield takeEvery(type.UPDATE_KPI, updateKpi);
  yield takeEvery(type.GET_DETAIL_KPI, getDetailKpi);
  yield takeEvery(type.CREATE_KPI_NUMBER, createKpiNumber);
  yield takeEvery(type.GET_LOG_FILTER, getLogFilter);
  yield takeEvery(type.GET_ALL_NUMBER, getAllNumber);
}

function* kpiSaga() {
  yield all([fork(watchKpi)]);
}

export default kpiSaga;
