// @flow
import * as type from './constants';

const EMPTY_LEAD = {
  notes: [],
  events: {},
  activityLogs: {},
  taskLogs: {},
  aioLogs: {},
  cicLogs: {},

  demand: {},
  // logs: {},
  documents: {},
  requiredDocumentTypes: [],

  s37: {},
  cic: {},
};

const INIT_STATE = {
  leads: {
    entries: [],
    pagination: {
      skipCount: 0,
      maxItems: 20,
      count: 8,
      hasMoreItems: false,
      totalItems: 8,
    },
  },
  lead: EMPTY_LEAD,
  settings: {
    leadChecklist: [],
    assignStrategy: '',
  },
  leadImportHistory: {},
  leadImportHistoryItems: {},
  listStartStatus: [],
  listStatus: [],
  listResult: [],
  insuranceList: [],
  documentGroupTypes: [],
  documentTypes: [],
  isOpenCreateLead: false,
  leadReportCount: {},
  products: [],
  missions: [],
  rules: [],
  assigned: null,
  manualAssigned: null,
  variable: [],
  numberTargetKPIAll: {},
  leadReport: null,
  leadReportMcas: null,
  leadReportActivity: null,
  leadReportLead: null,
  leadReportAmount: null,
  leadReportPartner: null,
  dashboardSummary: null,
  dashboardEmployeeSummary: null,
  dashboardPosSummary: null,
  dashboardDisbursement: [],
  dashboardDisbursementKpiMonthSum: [],
  dashboardDisbursementProduct: [],
  dashboardEmployeeRating: [],
  dashboardPosRating: [],
  carRegister: null,
  carDamage: {
    imageFront: null,
    imageBack: null,
    imageLeft: null,
    imageRight: null,
    imageDamage: null,
  },
};

const Lead = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* lead cases */

    case type.DELETE_LEAD:
      return { ...state };
    case type.DELETE_LEAD_SUCCESSED:
      return { ...state, error: null };
    case type.DELETE_LEAD_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_LEAD_STATUS:
      return { ...state };
    case type.UPDATE_LEAD_STATUS_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_LEAD_STATUS_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_LEAD_ASSIGNEE:
      return { ...state };
    case type.UPDATE_LEAD_ASSIGNEE_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_LEAD_ASSIGNEE_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_LEAD_LIST_START_STATUS:
      return { ...state };
    case type.FETCH_LEAD_LIST_START_STATUS_SUCCESSED:
      return { ...state, listStartStatus: action.payload, error: null };
    case type.FETCH_LEAD_LIST_START_STATUS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_LEAD_LIST_STATUS:
      return { ...state };
    case type.FETCH_LEAD_LIST_STATUS_SUCCESSED:
      return { ...state, listStatus: action.payload, error: null };
    case type.FETCH_LEAD_LIST_STATUS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_LEAD_LIST_RESULT:
      return { ...state };
    case type.FETCH_LEAD_LIST_RESULT_SUCCESSED:
      return { ...state, listResult: action.payload, error: null };
    case type.FETCH_LEAD_LIST_RESULT_FAILED:
      return { ...state, error: action.payload };

    case type.ASSIGN_LEAD:
      return { ...state };
    case type.ASSIGN_LEAD_SUCCESSED:
      return { ...state, error: null };
    case type.ASSIGN_LEAD_FAILED:
      return { ...state, error: action.payload };

    case type.AUTO_ASSIGN_LEAD:
      return { ...state };
    case type.AUTO_ASSIGN_LEAD_SUCCESSED:
      return { ...state, error: null };
    case type.AUTO_ASSIGN_LEAD_FAILED:
      return { ...state, error: action.payload };

    case type.SET_OPEN_CREATE_LEAD:
      return {
        ...state,
        isOpenCreateLead: action.payload,
        error: action.payload,
      };

    /* LEAD IMPORT */

    case type.FETCH_LEAD_IMPORT_HISTORY:
      return { ...state };
    case type.FETCH_LEAD_IMPORT_HISTORY_SUCCESSED:
      return { ...state, leadImportHistory: action.payload, error: null };
    case type.FETCH_LEAD_IMPORT_HISTORY_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_LEAD_IMPORT_HISTORY_ITEMS:
      return { ...state };
    case type.FETCH_LEAD_IMPORT_HISTORY_ITEMS_SUCCESSED:
      return { ...state, leadImportHistoryItems: action.payload, error: null };
    case type.FETCH_LEAD_IMPORT_HISTORY_ITEMS_FAILED:
      return { ...state, error: action.payload };

    /* LEAD SETTING */

    case type.LEAD_SETTINGS_FETCH_FAILED:
      return { ...state, error: action.payload };

    case type.LEAD_SETTINGS_FETCH_CHECKLIST:
      return { ...state };
    case type.LEAD_SETTINGS_FETCH_CHECKLIST_SUCCESSED:
      return {
        ...state,
        settings: { ...state.settings, leadChecklist: action.payload },
        error: null,
      };

    case type.LEAD_SETTINGS_CREATE_CHECKLIST:
      return { ...state };
    case type.LEAD_SETTINGS_UPDATE_CHECKLIST:
      return { ...state };
    case type.LEAD_SETTINGS_DELETE_CHECKLIST:
      return { ...state };

    case type.LEAD_SETTINGS_CREATE_CHECKLIST_FAILED:
      return { ...state, error: action.payload };
    case type.LEAD_SETTINGS_UPDATE_CHECKLIST_FAILED:
      return { ...state, error: action.payload };
    case type.LEAD_SETTINGS_DELETE_CHECKLIST_FAILED:
      return { ...state, error: action.payload };

    case type.LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY:
      return { ...state };
    case type.LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY_SUCCESSED:
      return {
        ...state,
        settings: { ...state.settings, assignStrategy: action.payload },
        error: null,
      };
    case type.LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY_FAILED:
      return { ...state, error: action.payload };

    case type.LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY:
      return { ...state };
    case type.LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY_SUCCESSED:
      return {
        ...state,
        settings: { ...state.settings, assignStrategy: action.payload },
        error: null,
      };
    case type.LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY_FAILED:
      return { ...state, error: action.payload };

    /* LEAD DOCUMENT */

    case type.FETCH_LEAD_DOCUMENTS:
      return {
        ...state,
        lead: { ...state.lead, documents: {} },
        error: null,
      };
    case type.FETCH_LEAD_DOCUMENTS_SUCCESSED:
      return {
        ...state,
        lead: { ...state.lead, documents: action.payload },
        error: null,
      };
    case type.FETCH_LEAD_DOCUMENTS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_REQUIRED_DOCUMENT_TYPES:
      return { ...state };
    case type.FETCH_REQUIRED_DOCUMENT_TYPES_SUCCESSED:
      return {
        ...state,
        lead: { ...state.lead, requiredDocumentTypes: action.payload },
        error: null,
      };
    case type.FETCH_REQUIRED_DOCUMENT_TYPES_FAILED:
      return { ...state, error: action.payload };

    case type.UPLOAD_LEAD_DOCUMENT:
      return { ...state };
    case type.UPLOAD_LEAD_DOCUMENT_SUCCESSED:
      // lead: { ...state.lead, documents: action.payload },
      return {
        ...state,
        error: null,
        lead: {
          ...state.lead,
          documents: {
            ...state.lead.documents,
            list: {
              ...state.lead.documents.lead,
              entries: [...state.lead.documents.list.entries, { entry: action.payload }],
            },
          },
        },
      };

    case type.FETCH_LEAD_REPORT_COUNT_SUCCESSED:
      return { ...state, leadReportCount: action.payload };

    case type.FETCH_ALL_PRODUCT_SUCCESSED:
      return { ...state, products: action.payload };

    case type.FETCH_MISSION_SUCCESSED:
      return { ...state, missions: action.payload };

    case type.UPDATE_AUTO_ASSIGN_UNALLOCATED_SUCCESSED:
      return { ...state, assigned: action.payload, manualAssigned: null };

    case type.UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER_SUCCESSED:
      return { ...state, manualAssigned: action.payload, assigned: null };

    case type.FETCH_LEAD_FOLLOWING:
      return { ...state, error: null };
    case type.FETCH_LEAD_FOLLOWING_SUCCESSED:
      return { ...state, leads: action.payload.list, error: null };
    case type.FETCH_LEAD_FOLLOWING_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_LEAD_BLACK_LIST:
      return { ...state, error: null };
    case type.FETCH_LEAD_BLACK_LIST_SUCCESSED:
      return { ...state, leads: action.payload.list, error: null };
    case type.FETCH_LEAD_BLACK_LIST_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_LEAD_CLOSE:
      return { ...state, error: null };
    case type.FETCH_LEAD_CLOSE_SUCCESSED:
      return { ...state, leads: action.payload.list, error: null };
    case type.FETCH_LEAD_CLOSE_FAILED:
      return { ...state, error: action.payload };

    /* Rule */
    case type.FETCH_RULE_ACTIVE:
      return { ...state };
    case type.FETCH_RULE_ACTIVE_SUCCESSED:
      return { ...state, ruleActive: action.payload, error: null };
    case type.FETCH_RULE_ACTIVE_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ASSIGN_LEAD_AUTO:
      return { ...state };
    case type.FETCH_ASSIGN_LEAD_AUTO_SUCCESSED:
      return { ...state, assignAuto: action.payload, error: null };
    case type.FETCH_ASSIGN_LEAD_AUTO_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ASSIGN_LEAD_MANUAL:
      return { ...state };
    case type.FETCH_ASSIGN_LEAD_MANUAL_SUCCESSED:
      return { ...state, assignManual: action.payload, error: null };
    case type.FETCH_ASSIGN_LEAD_MANUAL_FAILED:
      return { ...state, error: action.payload };

    /* Recall list */
    case type.FETCH_RECALL_LIST:
      return { ...state };
    case type.FETCH_RECALL_LIST_SUCCESSED:
      return { ...state, recallList: action.payload, error: null };
    case type.FETCH_RECALL_LIST_FAILED:
      return { ...state, error: action.payload };

    /* recall result */
    case type.FETCH_RECALL_RESULT:
      return { ...state };
    case type.FETCH_RECALL_RESULT_SUCCESSED:
      return { ...state, recallResult: action.payload, error: null };
    case type.FETCH_RECALL_RESULT_FAILED:
      return { ...state, error: action.payload };

    case type.SETTING_AUTO_ASSIGN_SUCCESSED:
      return { ...state };

    // variable
    case type.FETCH_VARIABLE:
      return { ...state };
    case type.FETCH_VARIABLE_SUCCESSED:
      return { ...state, variable: action.payload };
    case type.FETCH_VARIABLE_FAILED:
      return { ...state, error: action.payload };

    // report kpi
    case type.GET_NUMBER_TARGET_KPI_SUCCESSED:
      const newStateNumberTargetKPI = { ...state };
      newStateNumberTargetKPI.numberTargetKPIAll[action.payload.kpiInfo] = { ...action.payload };
      return newStateNumberTargetKPI;

    case type.REPORT_LEAD_SUCCESSED:
      return { ...state, leadReport: action.payload };

    case type.REPORT_LEAD_ACTIVITY_SUCCESSED:
      return { ...state, leadReportActivity: action.payload };

    case type.REPORT_LEAD_MCAS_SUCCESSED:
      return { ...state, leadReportMcas: action.payload };

    case type.REPORT_LEAD_DISTRIBUTED_AMOUNT_SUCCESSED:
      return { ...state, leadReportAmount: action.payload };

    case type.REPORT_LEAD_DISTRIBUTED_LEAD_SUCCESSED:
      return { ...state, leadReportLead: action.payload };

    case type.REPORT_LEAD_PARTNER_SUCCESSED:
      return { ...state, leadReportPartner: action.payload };

    case type.GET_DASHBOARD_SUMMARY_SUCCESSED:
      return { ...state, dashboardSummary: action.payload };

    case type.GET_DASHBOARD_EMPLOYEE_SUMMARY_SUCCESSED:
      return { ...state, dashboardEmployeeSummary: action.payload };

    case type.GET_DASHBOARD_POS_SUMMARY_SUCCESSED:
      return { ...state, dashboardPosSummary: action.payload };

    case type.GET_DASHBOARD_DISBURSEMENT_SUCCESSED:
      return { ...state, dashboardDisbursement: action.payload };

    case type.GET_KPI_MONTHLY:
      return { ...state, dashboardDisbursementKpiMonthSum: [] };
    case type.GET_KPI_MONTHLY_SUCCESSED:
      return { ...state, dashboardDisbursementKpiMonthSum: action.payload };

    case type.GET_DASHBOARD_DISBURSEMENT_PRODUCT_SUCCESSED:
      return { ...state, dashboardDisbursementProduct: action.payload };

    // TT
    case type.GET_DASHBOARD_EMPLOYEE_RATING_SUCCESSED:
      return { ...state, dashboardEmployeeRating: action.payload };
    case type.GET_DASHBOARD_POS_RATING_SUCCESSED:
      return { ...state, dashboardPosRating: action.payload };

    case type.FETCH_LEAD_FIELD_NOTE:
      return { ...state };
    case type.FETCH_LEAD_FIELD_NOTE_SUCCESSED:
      return {
        ...state,
        lead: { ...state.lead, fieldNote: action.payload },
        error: null,
      };
    case type.FETCH_LEAD_FIELD_NOTE_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_LEAD_FIELD_NOTE:
      return { ...state };
    case type.UPDATE_LEAD_FIELD_NOTE_SUCCESSED:
      return {
        ...state,
        lead: { ...state.lead, fieldNote: action.payload },
        error: null,
      };
    case type.UPDATE_LEAD_FIELD_NOTE_FAILED:
      return { ...state, error: action.payload };

    // TODO: move to product redux
    case type.FETCH_ALL_INSURANCE:
      return { ...state };
    case type.FETCH_ALL_INSURANCE_SUCCESSED:
      return {
        ...state,
        insuranceList: action.payload,
        error: null,
      };
    case type.FETCH_ALL_INSURANCE_FAILED:
      return { ...state, error: action.payload };

    // TODO: new redux
    // ******************************************************************************
    // CAR REGISTER
    case type.FETCH_CAR_REGISTER:
      return { ...state };
    case type.FETCH_CAR_REGISTER_SUCCESSED:
      return { ...state, carRegister: action.payload, error: null };
    case type.FETCH_CAR_REGISTER_FAILED:
      return { ...state, error: action.payload };

    // CAR DAMAGE
    case type.FETCH_CAR_DAMAGE:
      return { ...state };
    case type.FETCH_CAR_DAMAGE_SUCCESSED:
      return {
        ...state,
        carDamage: { ...state.carDamage, imageDamage: action.payload },
        error: null,
      };
    case type.FETCH_CAR_DAMAGE_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CAR_LEFT:
      return { ...state };
    case type.FETCH_CAR_LEFT_SUCCESSED:
      return {
        ...state,
        carDamage: { ...state.carDamage, imageLeft: action.payload },
        error: null,
      };
    case type.FETCH_CAR_LEFT_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CAR_FRONT:
      return { ...state };
    case type.FETCH_CAR_FRONT_SUCCESSED:
      return {
        ...state,
        carDamage: { ...state.carDamage, imageFront: action.payload },
        error: null,
      };
    case type.FETCH_CAR_FRONT_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CAR_BACK:
      return { ...state };
    case type.FETCH_CAR_BACK_SUCCESSED:
      return {
        ...state,
        carDamage: { ...state.carDamage, imageBack: action.payload },
        error: null,
      };
    case type.FETCH_CAR_BACK_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CAR_RIGHT:
      return { ...state };
    case type.FETCH_CAR_RIGHT_SUCCESSED:
      return {
        ...state,
        carDamage: { ...state.carDamage, imageRight: action.payload },
        error: null,
      };
    case type.FETCH_CAR_RIGHT_FAILED:
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};

export default Lead;
