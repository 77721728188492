// @flow
import * as type from './constants';

const INIT_STATE = {
  accounts: {
    entries: [],
    pagination: {
      skipCount: 0,
      maxItems: 20,
      count: 8,
      hasMoreItems: false,
      totalItems: 8,
    },
  },
  partners: {
    entries: [],
    pagination: {
      skipCount: 0,
      maxItems: 20,
      count: 8,
      hasMoreItems: false,
      totalItems: 8,
    },
  },
  partner: {},
  payments: {
    entries: [],
    pagination: {
      skipCount: 0,
      maxItems: 20,
      count: 8,
      hasMoreItems: false,
      totalItems: 8,
    },
  },
  leadCount: [],
  leadValid: [],
  leadStatus: [],
  leadDisbursement: [],
  effectivePartner: 0,
};

const Partner = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Account cases */
    case type.FETCH_ACCOUNTS:
      return { ...state };
    case type.FETCH_ACCOUNTS_SUCCESSED:
      return { ...state, accounts: action.payload, error: null };
    case type.FETCH_ACCOUNTS_FAILED:
      return { ...state, error: action.payload };
    case type.CLEAR_ACCOUNTS:
      return { ...state, accounts: { entries: [] }, error: null };

    case type.FETCH_PAYMENTS:
      return { ...state };
    case type.FETCH_PAYMENTS_SUCCESSED:
      return { ...state, payments: action.payload, error: null };
    case type.FETCH_PAYMENTS_FAILED:
      return { ...state, error: action.payload };
    case type.CLEAR_PAYMENTS:
      return { ...state, payments: { entries: [] }, error: null };

    case type.FETCH_LEAD_VALID_SUCCESSED:
      return { ...state, leadValid: action.payload };

    case type.FETCH_LEAD_STATUS_SUCCESSED:
      return { ...state, leadStatus: action.payload };

    case type.FETCH_LEAD_REPORT_DAY_COUNT_SUCCESSED:
      return { ...state, leadCount: action.payload };

    case type.FETCH_LEAD_DISBURSEMENT_SUCCESSED:
      return { ...state, leadDisbursement: action.payload };

    case type.GET_EFFECTIVE_PARTNER_SUCCESSED:
      return { ...state, effectivePartner: action.payload };

    default:
      return { ...state };
  }
};

export default Partner;
