/* AUTH */
export const LOGIN_USER = 'auth/LOGIN_USER';
export const LOGIN_USER_SUCCESSED = 'auth/LOGIN_USER_SUCCESSED';
export const LOGIN_USER_FAILED = 'auth/LOGIN_USER_FAILED';

export const REGISTER_USER = 'auth/REGISTER_USER';
export const REGISTER_USER_SUCCESSED = 'auth/REGISTER_USER_SUCCESSED';
export const REGISTER_USER_FAILED = 'auth/REGISTER_USER_FAILED';

export const UPDATE_USER = 'auth/UPDATE_USER';
export const UPDATE_USER_SUCCESSED = 'auth/UPDATE_USER_SUCCESSED';
export const UPDATE_USER_FAILED = 'auth/UPDATE_USER_FAILED';

export const LOGOUT_USER = 'auth/LOGOUT_USER';
export const REMOVE_USER_INFO = 'auth/REMOVE_USER_INFO';

export const FORGET_PASSWORD = 'auth/FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESSED = 'auth/FORGET_PASSWORD_SUCCESSED';
export const FORGET_PASSWORD_FAILED = 'auth/FORGET_PASSWORD_FAILED';

export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESSED = 'auth/REFRESH_TOKEN_SUCCESSED';
export const REFRESH_TOKEN_FAILED = 'auth/REFRESH_TOKEN_FAILED';

export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESSED = 'auth/CHANGE_PASSWORD_SUCCESSED';
export const CHANGE_PASSWORD_FAILED = 'auth/CHANGE_PASSWORD_FAILED';

export const SET_DARK_MODE = 'auth/SET_DARK_MODE';

export const SET_WEB_PHONE = 'auth/SET_WEB_PHONE';

export const GET_STRINGEE_TOKEN = 'auth/GET_STRINGEE_TOKEN';
export const GET_STRINGEE_TOKEN_SUCCESSED = 'auth/GET_STRINGEE_TOKEN_SUCCESSED';
