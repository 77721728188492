// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { handleLoading } from 'redux/layout/actions';
import toast from 'react-hot-toast';
import * as type from './constants';
import * as actions from './actions';
import * as api from './api';

/* Lead sagas */

function* deleteLead(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.deleteLead, action.payload);

    yield put(actions.deleteLeadSuccessed(response));

    // yield put(actions.fetchLeads());
  } catch (error) {
    yield put(actions.deleteLeadFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateLeadStatus(action) {
  yield put(handleLoading(true));

  const { leadId, data } = action.payload;

  try {
    const response = yield call(api.updateLeadStatus, leadId, data);

    yield put(actions.updateLeadStatusSuccessed(response));
  } catch (error) {
    yield put(actions.updateLeadStatusFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateLeadAssignee(action) {
  yield put(handleLoading(true));

  const { leadId, data } = action.payload;

  try {
    const response = yield call(api.updateLeadAssignee, leadId, data);

    yield put(actions.updateLeadAssigneeSuccessed(response));
  } catch (error) {
    yield put(actions.updateLeadAssigneeFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchLeadListStatus(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchLeadListStatus);

    yield put(actions.fetchLeadListStatusSuccessed(response));
  } catch (error) {
    yield put(actions.fetchLeadListStatusFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchLeadListResult(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchLeadListResult);

    yield put(actions.fetchLeadListResultSuccessed(response));
  } catch (error) {
    yield put(actions.fetchLeadListResultFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateLeadDemand(action) {
  yield put(handleLoading(true));

  const { leadId, data, setStatus = () => {} } = action.payload;

  try {
    const response = yield call(api.updateLeadDemand, leadId, data);

    yield put(actions.updateLeadDemandSuccessed(response));
    setStatus(true);
    toast.success('Cập nhật nhu cầu lead thành công');
  } catch (error) {
    yield put(actions.updateLeadDemandFailed(error));
    setStatus(false);
    toast.error('Cập nhật nhu cầu lead thất bại');
  }

  yield put(handleLoading(false));
}

function* assignLead(action) {
  yield put(handleLoading(true));
  const { assignee, data } = action.payload;

  try {
    const response = yield call(api.assignLead, assignee, data);

    yield put(actions.assignLeadSuccessed(response));

    // yield put(actions.fetchLeads());
  } catch (error) {
    yield put(actions.assignLeadFailed(error));
  }

  yield put(handleLoading(false));
}

function* autoAssignLead(action) {
  yield put(handleLoading(true));
  const { callback } = action.payload;

  try {
    const response = yield call(api.autoAssignLead);

    yield put(actions.autoAssignLeadSuccessed(response));

    callback && callback();
  } catch (error) {
    yield put(actions.autoAssignLeadFailed(error));
  }

  yield put(handleLoading(false));
}

/* Lead settings */

function* fetchLeadSettingChecklist() {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchLeadSettingChecklist);
    yield put(actions.fetchLeadSettingChecklistSuccessed(res));
  } catch (error) {
    yield put(actions.fetchLeadSettingFailed(error));
  }

  yield put(handleLoading(false));
}

function* createLeadSettingChecklist(action) {
  const { data, setStatus = () => {} } = action.payload;

  try {
    yield call(api.createLeadSettingChecklist, data);

    toast.success('Tạo mới thành công.');
    setStatus(true);
  } catch (error) {
    yield put(actions.createLeadSettingChecklistFailed(error));
    toast.error('Tạo mới thất bại.');
    setStatus(false);
  }
}

function* updateLeadSettingChecklist(action) {
  const { name, data, setStatus = () => {} } = action.payload;

  try {
    yield call(api.updateLeadSettingChecklist, name, data);

    toast.success('Cập nhật thành công.');
    setStatus(true);
  } catch (error) {
    yield put(actions.updateLeadSettingChecklistFailed(error));
    toast.error('Cập nhật thất bại.');
    setStatus(false);
  }
}

function* deleteLeadSettingChecklist(action) {
  const { name, setStatus = () => {} } = action.payload;

  try {
    yield call(api.deleteLeadSettingChecklist, name);
    toast.success('Xóa thành công.');
    setStatus(true);
  } catch (error) {
    yield put(actions.deleteLeadSettingChecklistFailed(error));
    toast.error('Xóa thất bại.');
    setStatus(false);
  }
}

function* fetchLeadSettingAssignStrategy(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchLeadSettingAssignStrategy);
    yield put(actions.fetchLeadSettingAssignStrategySuccessed(res));
  } catch (error) {
    yield put(actions.fetchLeadSettingAssignStrategyFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateLeadSettingAssignStrategy(action) {
  yield put(handleLoading(true));
  const assignStrategy = action.payload;

  try {
    const res = yield call(api.updateLeadSettingAssignStrategy, assignStrategy);

    toast.success('Cập nhật thành công');
    yield put(actions.updateLeadSettingAssignStrategySuccessed(res));
  } catch (error) {
    yield put(actions.updateLeadSettingAssignStrategyFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchLeadDocuments(action) {
  yield put(handleLoading(true));
  const { leadId, pagination } = action.payload;

  try {
    const response = yield call(api.fetchLeadDocuments, leadId, pagination);

    yield put(actions.fetchLeadDocumentsSuccessed(response));
  } catch (error) {
    yield put(actions.fetchLeadDocumentsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchRequiredDocumentTypes(action) {
  yield put(handleLoading(true));
  const { productCode } = action.payload;

  try {
    const response = yield call(api.fetchRequiredDocumentTypes, productCode);

    yield put(actions.fetchRequiredDocumentTypesSuccessed(response));
  } catch (error) {
    yield put(actions.fetchRequiredDocumentTypesFailed(error));
  }

  yield put(handleLoading(false));
}

function* uploadLeadDocument(action) {
  yield put(handleLoading(true));
  const { leadId, documentType, documentName, file, setStatus = () => {} } = action.payload;

  try {
    const response = yield call(api.uploadLeadDocument, leadId, documentType, documentName, file);

    yield put(actions.fetchLeadDocuments(leadId, {}));
    // yield put(actions.uploadLeadDocumentSuccessed(response));
    toast.success('Tải lên thành công');
    setStatus(true);
  } catch (error) {
    yield put(actions.fetchLeadDocumentsFailed(error));
    setStatus(false);
    toast.error('Tải lên thất bại');
  }

  yield put(handleLoading(false));
}

function* fetchLeadImportHistory(action) {
  yield put(handleLoading(true));
  const { pagination } = action.payload;

  try {
    const response = yield call(api.fetchLeadImportHistory, pagination);

    yield put(actions.fetchLeadImportHistorySuccessed(response));
  } catch (error) {
    yield put(actions.fetchLeadImportHistoryFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchLeadImportHistoryItems(action) {
  yield put(handleLoading(true));
  const { code, pagination, filter } = action.payload;

  try {
    const response = yield call(api.fetchLeadImportHistoryItems, code, pagination, filter);

    yield put(actions.fetchLeadImportHistoryItemsSuccessed(response));
  } catch (error) {
    yield put(actions.fetchLeadImportHistoryItemsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchLeadReportCount(action) {
  yield put(handleLoading(true));
  const { leadType, groupIds } = action.payload;

  try {
    const response = yield call(api.fetchLeadReportCount, leadType, groupIds);
    yield put(actions.fetchLeadReportCountSuccessed(response));
  } catch (error) {
    yield put(actions.fetchLeadReportCountFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchAllProduct(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchAllProduct);
    yield put(actions.fetchAllProductSuccessed(response));
  } catch (error) {
    yield put(actions.fetchAllProductFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchMission(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchMission);
    yield put(actions.fetchMissionSuccessed(response));
  } catch (error) {
    yield put(actions.fetchMissionFailed(error));
  }
  yield put(handleLoading(false));
}

function* fetchRecallList(action) {
  const { filter, pagination, loading = () => {} } = action.payload;

  loading(true);
  try {
    const response = yield call(api.fetchRecallList, filter, pagination);
    yield put(actions.fetchRecallListSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchRecallListFailed(error));
  }
  loading(false);
}

function* fetchRecallResult(action) {
  const { filter, pagination, loading = () => {} } = action.payload;

  loading(true);
  try {
    const response = yield call(api.fetchRecallResult, filter, pagination);
    yield put(actions.fetchRecallResultSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchRecallResultFailed(error));
  }
  loading(false);
}

function* fetchRuleActive(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchRuleActive);
    yield put(actions.fetchAllRuleActiveSuccessed(response));
  } catch (error) {
    yield put(actions.fetchAllRuleActiveFailed(error));
  }
  yield put(handleLoading(false));
}

function* updateAutoAssignedLead(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.autoAssignedLead, action.payload);
    yield put(actions.autoAssignedLeadSuccessed(response));
    toast.success('Phân bổ thành công!');
  } catch (error) {
    yield put(actions.autoAssignedLeadFailed(error));
    toast.error('Phân bổ thất bại!');
  }
  yield put(handleLoading(false));
}

function* updateAutoAssignedLeadFilter(action) {
  yield put(handleLoading(true));
  try {
    yield call(api.autoAssignedLeadFilter, action.payload);
    yield put(actions.autoAssignedLeadFilterSuccessed(action.payload?.username));
    toast.success('Phân bổ thành công!');
  } catch (error) {
    yield put(actions.autoAssignedLeadFilterFailed(error));
    toast.error('Phân bổ thất bại!');
  }
  yield put(handleLoading(false));
}

function* fetchAssignLeadManual(action) {
  const { username, leads } = action.payload;
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchAssignLeadManual, username, leads);
    yield put(actions.assignLeadManualSuccessed(response));
    toast.success('Phân bổ thành công!');
  } catch (error) {
    yield put(actions.assignLeadManualFailed(error));
    toast.error('Phân bổ thất bại!');
  }
  yield put(handleLoading(false));
}

function* fetchAssignLeadAuto(action) {
  const { ruleId, leads } = action.payload;
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchAssignLeadAuto, ruleId, leads);
    yield put(actions.assignLeadAutoSuccessed(response));
    toast.success('Phân bổ thành công!');
  } catch (error) {
    yield put(actions.assignLeadAutoFailed(error));
    toast.error('Phân bổ thất bại!');
  }
  yield put(handleLoading(false));
}

function* settingAutoAssign(action) {
  try {
    const res = yield call(api.settingsAutoAssign, action.payload);
    yield put(actions.settingUpdateAutoAssignSuccessed(res));
    toast.success('Cài đặt thành công');
  } catch (error) {
    yield put(actions.settingUpdateAutoAssignFailed(error));
    toast.error('Cài đặt thất bại');
  }
}

function* fetchVariable(action) {
  try {
    const response = yield call(api.fetchVariable, action.payload);
    yield put(actions.fetchVariableSuccessed(response));
  } catch (error) {
    yield put(actions.fetchVariableFailed(error));
  }
}

function* SendEmailActivity(action) {
  yield put(handleLoading(true));

  // const { leadId, target, title, content } = action.payload;
  try {
    const response = yield call(api.SendEmailActivity, action.payload);

    toast.success('Gửi mail thành công!');
    yield put(actions.SendEmailActivitySuccessed(response));
  } catch (error) {
    toast.error('Gửi mail thất bại!');
    yield put(actions.SendEmailActivityFailed(error));
  }

  yield put(handleLoading(false));
}

function* getNumberTargetKPI(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchNumberTargetKPI, action.payload);
    const response = {
      ...res,
      kpiInfo: action.payload.kpiInfo,
    };

    yield put(actions.getNumberTargetKPISuccessed(response));
  } catch (error) {
    yield put(actions.getNumberTargetKPIFailed(error));
  }

  yield put(handleLoading(false));
}

function* getReportLead(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchReportLead, action.payload);

    yield put(actions.getReportLeadSuccessed(response));
  } catch (error) {
    yield put(actions.getReportLeadFailed(error));
  }

  yield put(handleLoading(false));
}

function* getReportLeadActivity(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchReportLeadActivity, action.payload);

    yield put(actions.getReportLeadActivitySuccessed(response));
  } catch (error) {
    yield put(actions.getReportLeadActivityFailed(error));
  }

  yield put(handleLoading(false));
}

function* getReportLeadMcas(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchReportLeadMcas, action.payload);

    yield put(actions.getReportLeadMcasSuccessed(response));
  } catch (error) {
    yield put(actions.getReportLeadMcasFailed(error));
  }

  yield put(handleLoading(false));
}

function* getReportLeadDistributedAmount(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchReportLeadDisbursedAmount, action.payload);

    yield put(actions.getReportLeadDistributedAmountSuccessed(response));
  } catch (error) {
    yield put(actions.getReportLeadDistributedAmountFailed(error));
  }

  yield put(handleLoading(false));
}

function* getReportLeadDistributed(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchReportLeadDisbursedLead, action.payload);

    yield put(actions.getReportLeadDistributedSuccessed(response));
  } catch (error) {
    yield put(actions.getReportLeadDistributedFailed(error));
  }

  yield put(handleLoading(false));
}

function* getReportLeadPartner(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchReportLeadPartner, action.payload);

    yield put(actions.getReportLeadPartnerSuccessed(response));
  } catch (error) {
    yield put(actions.getReportLeadPartnerFailed(error));
  }

  yield put(handleLoading(false));
}

function* getDashboardSummary(action) {
  try {
    const response = yield call(api.fetchDashboardSummary, action.payload);

    yield put(actions.getDashboardSummarySuccessed(response));
  } catch (error) {
    yield put(actions.getDashboardSummaryFailed(error));
  }
}

function* getDashboardEmployeeSummary(action) {
  try {
    const response = yield call(api.fetchDashboardEmployeeSummary, action.payload);

    yield put(actions.getDashboardEmployeeSummarySuccessed(response));
  } catch (error) {
    yield put(actions.getDashboardEmployeeSummaryFailed(error));
  }
}

function* getDashboardPosSummary(action) {
  try {
    const response = yield call(api.fetchDashboardPosSummary, action.payload);

    yield put(actions.getDashboardPosSummarySuccessed(response));
  } catch (error) {
    yield put(actions.getDashboardPosSummaryFailed(error));
  }
}

function* getDashboardDisbursement(action) {
  try {
    const response = yield call(api.fetchDashboardCountMonth, action.payload);

    yield put(actions.getDashboardDisbursementSuccessed(response));
  } catch (error) {
    yield put(actions.getDashboardDisbursementFailed(error));
  }
}

function* getDashboardDisbursementKpiMonthSum(action) {
  try {
    const response = yield call(api.getKpiMonthly, action.payload);

    yield put(actions.getKpiMonthlySuccessed(response));
  } catch (error) {
    yield put(actions.getDashboardDisbursementFailed(error));
  }
}

function* getDashboardDisbursementProduct(action) {
  try {
    const response = yield call(api.fetchDashboardDisbursedProduct, action.payload);

    yield put(actions.getDashboardDisbursementProductSuccessed(response));
  } catch (error) {
    yield put(actions.getDashboardDisbursementProductFailed(error));
  }
}

//
function* getDashboardEmployeeRating(action) {
  try {
    const response = yield call(api.fetchDashboardEmployeeRating, action.payload);
    yield put(actions.getDashboardEmployeeRatingSuccessed(response));
  } catch (error) {
    yield put(actions.getDashboardEmployeeRatingFailed(error));
  }
}

function* getDashboardPosRating(action) {
  try {
    const response = yield call(api.fetchDashboardPosRating, action.payload);
    yield put(actions.getDashboardPosRatingSuccessed(response));
  } catch (error) {
    yield put(actions.getDashboardPosRatingFailed(error));
  }
}

function* fetchLeadFieldNote(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchLeadFieldNote, action.payload);

    yield put(actions.fetchLeadFieldNoteSuccessed(response));
  } catch (error) {
    yield put(actions.fetchLeadFieldNoteFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateLeadFieldNote(action) {
  yield put(handleLoading(true));

  const { leadId, data, callback } = action.payload;

  try {
    const response = yield call(api.updateLeadFieldNote, leadId, data);

    yield put(actions.updateLeadFieldNoteSuccessed(response));
    toast.success('Cập nhật thẩm định thực địa thành công');
    callback && callback();
  } catch (error) {
    yield put(actions.updateLeadFieldNoteFailed(error));
    toast.error('Cập nhật thẩm định thực địa thất bại');
  }

  yield put(handleLoading(false));
}

function* fetchAllInsurance(action) {
  yield put(handleLoading(true));
  try {
    const response = yield call(api.fetchAllInsurance, action.payload);

    yield put(actions.fetchAllInsuranceSuccessed(response));
  } catch (error) {
    yield put(actions.fetchAllInsuranceFailed(error));
  }
  yield put(handleLoading(false));
}

function* fetchCarRegister(action) {
  const { image, loading = () => {} } = action.payload;
  yield put(handleLoading(true));
  loading(true);

  try {
    const response = yield call(api.fetchImageCarRegister, image);

    toast.success('Gọi kết quả thành công');
    yield put(actions.fetchCarRegisterSuccessed(response));
  } catch (error) {
    toast.error(String(error));
    yield put(actions.fetchCarRegisterFailed(error));
  }

  loading(false);
  yield put(handleLoading(false));
}

function* fetchCarDamage(action) {
  const { imageCarDamage, loading = () => {} } = action.payload;
  yield put(handleLoading(true));
  loading(true);

  try {
    const response = yield call(api.fetchImageCarDamage, imageCarDamage);

    toast.success('Gọi kết quả thành công');
    yield put(actions.fetchCarDamageSuccessed(response));
  } catch (error) {
    toast.error(String(error));
    yield put(actions.fetchCarDamageFailed(error));
  }

  loading(false);
  yield put(handleLoading(false));
}

function* fetchCarFront(action) {
  const { imageFront, loading = () => {} } = action.payload;
  yield put(handleLoading(true));
  loading(true);

  try {
    const response = yield call(api.fetchImageCarDamage, imageFront);

    toast.success('Gọi kết quả thành công');
    yield put(actions.fetchCarFrontSuccessed(response));
  } catch (error) {
    toast.error(String(error));
    yield put(actions.fetchCarFrontFailed(error));
  }

  loading(false);
  yield put(handleLoading(false));
}

function* fetchCarBack(action) {
  const { imageBack, loading = () => {} } = action.payload;
  yield put(handleLoading(true));
  loading(true);

  try {
    const response = yield call(api.fetchImageCarDamage, imageBack);

    toast.success('Gọi kết quả thành công');
    yield put(actions.fetchCarBackSuccessed(response));
  } catch (error) {
    toast.error(String(error));
    yield put(actions.fetchCarBackFailed(error));
  }

  loading(false);
  yield put(handleLoading(false));
}

function* fetchCarLeft(action) {
  const { imageLeft, loading = () => {} } = action.payload;
  yield put(handleLoading(true));
  loading(true);

  try {
    const response = yield call(api.fetchImageCarDamage, imageLeft);

    toast.success('Gọi kết quả thành công');
    yield put(actions.fetchCarLeftSuccessed(response));
  } catch (error) {
    toast.error(String(error));
    yield put(actions.fetchCarLeftFailed(error));
  }

  loading(false);
  yield put(handleLoading(false));
}

function* fetchCarRight(action) {
  const { imageRight, loading = () => {} } = action.payload;
  yield put(handleLoading(true));
  loading(true);

  try {
    const response = yield call(api.fetchImageCarDamage, imageRight);

    toast.success('Gọi kết quả thành công');
    yield put(actions.fetchCarRightSuccessed(response));
  } catch (error) {
    toast.error(String(error));
    yield put(actions.fetchCarRightFailed(error));
  }

  loading(false);
  yield put(handleLoading(false));
}

export function* watchLeads() {
  yield takeEvery(type.UPDATE_LEAD_DEMAND, updateLeadDemand);
  yield takeEvery(type.FETCH_REQUIRED_DOCUMENT_TYPES, fetchRequiredDocumentTypes);
  yield takeEvery(type.DELETE_LEAD, deleteLead);
  yield takeEvery(type.UPDATE_LEAD_STATUS, updateLeadStatus);
  yield takeEvery(type.UPDATE_LEAD_ASSIGNEE, updateLeadAssignee);
  yield takeEvery(type.FETCH_LEAD_LIST_STATUS, fetchLeadListStatus);
  yield takeEvery(type.FETCH_LEAD_LIST_RESULT, fetchLeadListResult);
  yield takeEvery(type.ASSIGN_LEAD, assignLead);
  yield takeEvery(type.AUTO_ASSIGN_LEAD, autoAssignLead);
  yield takeEvery(type.FETCH_LEAD_IMPORT_HISTORY, fetchLeadImportHistory);
  yield takeEvery(type.FETCH_LEAD_IMPORT_HISTORY_ITEMS, fetchLeadImportHistoryItems);
  yield takeEvery(type.FETCH_LEAD_REPORT_COUNT, fetchLeadReportCount);
  yield takeEvery(type.FETCH_ALL_PRODUCT, fetchAllProduct);
  yield takeEvery(type.FETCH_MISSION, fetchMission);

  yield takeEvery(type.FETCH_RECALL_RESULT, fetchRecallResult);
  yield takeEvery(type.FETCH_RECALL_LIST, fetchRecallList);
  yield takeEvery(type.FETCH_RULE_ACTIVE, fetchRuleActive);

  yield takeEvery(type.FETCH_ASSIGN_LEAD_AUTO, fetchAssignLeadAuto);
  yield takeEvery(type.FETCH_ASSIGN_LEAD_MANUAL, fetchAssignLeadManual);

  yield takeEvery(type.SETTING_AUTO_ASSIGN, settingAutoAssign);

  yield takeEvery(type.FETCH_VARIABLE, fetchVariable);
  yield takeEvery(type.SEND_EMAIL_ACTIVITY, SendEmailActivity);

  yield takeEvery(type.GET_NUMBER_TARGET_KPI, getNumberTargetKPI);
  yield takeEvery(type.REPORT_LEAD, getReportLead);
  yield takeEvery(type.REPORT_LEAD_ACTIVITY, getReportLeadActivity);
  yield takeEvery(type.REPORT_LEAD_MCAS, getReportLeadMcas);
  yield takeEvery(type.REPORT_LEAD_DISTRIBUTED_AMOUNT, getReportLeadDistributedAmount);
  yield takeEvery(type.REPORT_LEAD_DISTRIBUTED_LEAD, getReportLeadDistributed);
  yield takeEvery(type.REPORT_LEAD_PARTNER, getReportLeadPartner);

  yield takeEvery(type.GET_DASHBOARD_SUMMARY, getDashboardSummary);
  yield takeEvery(type.GET_DASHBOARD_EMPLOYEE_SUMMARY, getDashboardEmployeeSummary);
  yield takeEvery(type.GET_DASHBOARD_POS_SUMMARY, getDashboardPosSummary);
  yield takeEvery(type.GET_DASHBOARD_DISBURSEMENT, getDashboardDisbursement);
  yield takeEvery(type.GET_KPI_MONTHLY, getDashboardDisbursementKpiMonthSum);
  yield takeEvery(type.GET_DASHBOARD_DISBURSEMENT_PRODUCT, getDashboardDisbursementProduct);
  yield takeEvery(type.GET_DASHBOARD_EMPLOYEE_RATING, getDashboardEmployeeRating);
  yield takeEvery(type.GET_DASHBOARD_POS_RATING, getDashboardPosRating);

  yield takeEvery(type.FETCH_LEAD_FIELD_NOTE, fetchLeadFieldNote);
  yield takeEvery(type.FETCH_ALL_INSURANCE, fetchAllInsurance);
  yield takeEvery(type.UPDATE_LEAD_FIELD_NOTE, updateLeadFieldNote);

  // CAR REGISTER
  yield takeEvery(type.FETCH_CAR_REGISTER, fetchCarRegister);

  // CAR DAMAGE
  yield takeEvery(type.FETCH_CAR_DAMAGE, fetchCarDamage);
  yield takeEvery(type.FETCH_CAR_FRONT, fetchCarFront);
  yield takeEvery(type.FETCH_CAR_BACK, fetchCarBack);
  yield takeEvery(type.FETCH_CAR_LEFT, fetchCarLeft);
  yield takeEvery(type.FETCH_CAR_RIGHT, fetchCarRight);
}

export function* watchSettingLead() {
  yield takeEvery(type.LEAD_SETTINGS_CREATE_CHECKLIST, createLeadSettingChecklist);
  yield takeEvery(type.LEAD_SETTINGS_UPDATE_CHECKLIST, updateLeadSettingChecklist);
  yield takeEvery(type.LEAD_SETTINGS_DELETE_CHECKLIST, deleteLeadSettingChecklist);
  yield takeEvery(type.LEAD_SETTINGS_FETCH_CHECKLIST, fetchLeadSettingChecklist);

  yield takeEvery(type.LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY, fetchLeadSettingAssignStrategy);
  yield takeEvery(type.LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY, updateLeadSettingAssignStrategy);
  yield takeEvery(type.UPDATE_AUTO_ASSIGN_UNALLOCATED, updateAutoAssignedLead);
  yield takeEvery(type.UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER, updateAutoAssignedLeadFilter);
}

export function* watchLeadDocument() {
  yield takeEvery(type.FETCH_LEAD_DOCUMENTS, fetchLeadDocuments);
  yield takeEvery(type.UPLOAD_LEAD_DOCUMENT, uploadLeadDocument);
}

function* LeadSaga() {
  yield all([fork(watchLeads), fork(watchSettingLead), fork(watchLeadDocument)]);
}

export default LeadSaga;
