// @flow
import * as type from './constants';

export const fetchImportJobs = (dataType, jobStatus, page, itemPerPage) => ({
  type: type.FETCH_IMPORT_JOBS,
  payload: { dataType, jobStatus, page, itemPerPage },
});
export const fetchImportJobsSuccessed = (data) => ({
  type: type.FETCH_IMPORT_JOBS_SUCCESSED,
  payload: data,
});
export const fetchImportJobsFailed = (error) => ({
  type: type.FETCH_IMPORT_JOBS_FAILED,
  payload: error,
});

export const fetchImportJobInfo = (id) => ({
  type: type.FETCH_IMPORT_JOB_INFO,
  payload: { id },
});
export const fetchImportJobInfoSuccessed = (data) => ({
  type: type.FETCH_IMPORT_JOB_INFO_SUCCESSED,
  payload: data,
});
export const fetchImportJobInfoFailed = (error) => ({
  type: type.FETCH_IMPORT_JOB_INFO_FAILED,
  payload: error,
});

export const fetchImportJobContent = (id, page, pageSize, filter) => ({
  type: type.FETCH_IMPORT_JOB_CONTENT,
  payload: { id, page, pageSize, filter },
});
export const fetchImportJobContentSuccessed = (data) => ({
  type: type.FETCH_IMPORT_JOB_CONTENT_SUCCESSED,
  payload: data,
});
export const fetchImportJobContentFailed = (error) => ({
  type: type.FETCH_IMPORT_JOB_CONTENT_FAILED,
  payload: error,
});

export const importData = (data, fileName, dataType, executedBy) => ({
  type: type.IMPORT_DATA,
  payload: { data, fileName, dataType, executedBy },
});
export const importDataSuccessed = (data) => ({
  type: type.IMPORT_DATA_SUCCESSED,
  payload: data,
});
export const importDataFailed = (error) => ({
  type: type.IMPORT_DATA_FAILED,
  payload: error,
});

export const checkImportData = (data, dataType) => ({
  type: type.CHECK_IMPORT_DATA,
  payload: { data, dataType },
});
export const checkImportDataSuccessed = (data) => ({
  type: type.CHECK_IMPORT_DATA_SUCCESSED,
  payload: data,
});
export const checkImportDataFailed = (error) => ({
  type: type.CHECK_IMPORT_DATA_FAILED,
  payload: error,
});
