export const GET_ALL_KPI = 'kpi/GET_ALL_KPI';
export const GET_ALL_KPI_SUCCESSED = 'kpi/GET_ALL_KPI_SUCCESSED';
export const GET_ALL_KPI_FAILED = 'kpi/GET_ALL_KPI_FAILED';

export const GET_ALL_NUMBER_KPI = 'kpi/GET_ALL_NUMBER_KPI';
export const GET_ALL_NUMBER_KPI_SUCCESSED = 'kpi/GET_ALL_NUMBER_KPI_SUCCESSED';
export const GET_ALL_NUMBER_KPI_FAILED = 'kpi/GET_ALL_NUMBER_KPI_FAILED';

export const UPDATE_KPI = 'kpi/UPDATE_KPI';
export const UPDATE_KPI_SUCCESSED = 'kpi/UPDATE_KPI_SUCCESSED';
export const UPDATE_KPI_FAILED = 'kpi/UPDATE_KPI_FAILED';

export const GET_DETAIL_KPI = 'kpi/GET_DETAIL_KPI';
export const GET_DETAIL_KPI_SUCCESSED = 'kpi/GET_DETAIL_KPI_SUCCESSED';
export const GET_DETAIL_KPI_FAILED = 'kpi/GET_DETAIL_KPI_FAILED';

export const CREATE_KPI_NUMBER = 'kpi/CREATE_KPI_NUMBER';
export const CREATE_KPI_NUMBER_SUCCESSED = 'kpi/CREATE_KPI_NUMBER_SUCCESSED';
export const CREATE_KPI_NUMBER_FAILED = 'kpi/CREATE_KPI_NUMBER_FAILED';

export const GET_LOG_FILTER = 'kpi/GET_LOG_FILTER';
export const GET_LOG_FILTER_SUCCESSED = 'kpi/GET_LOG_FILTER_SUCCESSED';
export const GET_LOG_FILTER_FAILED = 'kpi/GET_LOG_FILTER_FAILED';

export const GET_ALL_NUMBER = 'kpi/GET_ALL_NUMBER';
export const GET_ALL_NUMBER_SUCCESSED = 'kpi/GET_ALL_NUMBER_SUCCESSED';
export const GET_ALL_NUMBER_FAILED = 'kpi/GET_ALL_NUMBER_FAILED';
