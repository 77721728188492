// @flow
import { ComponentType, LazyExoticComponent } from 'react';

import { isEmbeddedUser, isUserAuthenticated } from 'helpers/authUtils';
import { resetLoading } from 'redux/actions';

// Layouts
const AuthLayout = lazy(() => import('layouts/Auth'));
const EmbeddedLayout = lazy(() => import('layouts/Embedded'));
const VerticalLayout = lazy(() => import('layouts/Vertical'));

const getLayout = (): LazyExoticComponent<ComponentType<any>> => {
  if (!isUserAuthenticated()) {
    return AuthLayout;
  }

  if (isEmbeddedUser()) {
    return EmbeddedLayout;
  }

  return VerticalLayout;
};

const withLayout = (WrappedComponent: LazyExoticComponent<ComponentType> | ComponentType): ComponentType => {
  return function Layout(props) {
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(resetLoading());
    }, [dispatch]);

    const Layout = getLayout();

    return (
      <Layout>
        <WrappedComponent {...props} />
      </Layout>
    );
  };
};

export default withLayout;
