import { createSlice } from '@reduxjs/toolkit';
import { ListWithPagination, Pagination } from 'redux/types';
import { Insurance, InsuranceDetail, Product, ProductGroup } from './types';

interface ProductSate {
  error: any;
  insuranceList: ListWithPagination<Product>;
  productList: ListWithPagination<Insurance>;
  insuranceDetail: InsuranceDetail;
  productDetail: any;
  productGroupList: ProductGroup[];
  // TODO: check type
  productDocuments: any[];
}

const defaultPagination: Pagination = {
  maxItems: 10,
  skipCount: 0,
};

const defaultInsuranceDetail = {
  amountMaximum: 0,
  amountMinimum: 0,
  code: '',
  endDate: '',
  id: '',
  logoUrl: '',
  name: '',
  number: 0,
  productGroupId: '',
  rate: 0,
  restTime: '',
  startDate: '',
  status: '',
};

const INIT_STATE: ProductSate = {
  error: null,
  productList: { list: { entries: [], pagination: defaultPagination } },
  insuranceList: { list: { entries: [], pagination: defaultPagination } },
  insuranceDetail: defaultInsuranceDetail,
  productDetail: {},
  productGroupList: [],
  productDocuments: [],
};

const productSlice = createSlice({
  name: 'product',
  initialState: INIT_STATE,
  reducers: {
    actionSucceeded: (state, action) => {
      state.error = null;
    },
    actionFailed: (state, action) => {
      state.error = action.payload;
    },
    fetchFailed: (state, action) => {
      state.error = action.payload;
    },
    // Product

    fetchProductDocuments: (state) => {},
    fetchProductDocumentsSucceeded: (state, action) => {
      state.productDocuments = action.payload;
      state.error = null;
    },

    updateProduct: (state, action) => {},
    deleteProduct: (state, action) => {},

    createProductGroup: (state, action) => {},
    updateProductGroup: (state, action) => {},
    deleteProductGroup: (state, action) => {},

    uploadProductDocument: (state, action) => {},

    updateStatusProduct: (state, action) => {},

    // Insurance
    createInsurance: (state, action) => {},
    updateInsurance: (state, action) => {},
    deleteInsurance: (state, action) => {},
    fetchInsuranceDetailSucceeded: (state, action) => {
      state.insuranceDetail = action.payload;
      state.error = null;
    },
  },
});

export const {
  actionSucceeded,
  actionFailed,
  fetchFailed,

  fetchInsuranceDetailSucceeded,
  fetchProductDocuments,
  fetchProductDocumentsSucceeded,

  createInsurance,
  updateInsurance,
  deleteInsurance,

  updateProduct,
  deleteProduct,

  createProductGroup,
  updateProductGroup,
  deleteProductGroup,

  uploadProductDocument,
  updateStatusProduct,
} = productSlice.actions;

export default productSlice.reducer;
