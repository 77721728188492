import { AutoGraphRounded } from '@mui/icons-material';

import { NxRoute } from './types';

const Kpi = lazy(() => import('pages/kpi'));

const kpiRoutes: NxRoute = {
  path: '/kpi',
  name: 'menuParent:kpi',
  icon: AutoGraphRounded,
  Component: Kpi,
};

export default kpiRoutes;
