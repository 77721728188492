import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { prepareAuthHeaders } from 'helpers/api';
import { MappingFieldType, MappingType } from './type';

export const mappingFieldApi = createApi({
  reducerPath: 'mappingFieldApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['mappingFieldList', 'mappingFieldDetail'],
  endpoints: (builder) => ({
    fetchAllMappingField: builder.query<MappingType[], void>({
      query: () => '/ocr/mapping/get-all',
      providesTags: ['mappingFieldList'],
    }),
    fetchAllMappingBydatasourceCode: builder.query<MappingType[], string>({
      query: (code) => `/ocr/mapping/datasource/${code}`,
      providesTags: ['mappingFieldList'],
    }),
    fetchMappingField: builder.query<MappingType, string>({
      query: (code) => `/ocr/mapping/${code}`,
      providesTags: ['mappingFieldList'],
      // query: (code) => '/ocr/mapping/get-all',
      // transformResponse: (response: MappingType[], meta, code) => {
      // 	return response.find((mappingField) => mappingField.code === code)
      // }
    }),
    setMappingField: builder.mutation<MappingType, Omit<MappingType, 'id'>>({
      query: (data) => ({
        url: '/ocr/mapping/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mappingFieldList'],
    }),
    updateMappingField: builder.mutation<MappingType, MappingType>({
      query: (data) => ({
        url: `/ocr/mapping/update/${data.id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mappingFieldList'],
    }),
    deleteMappingField: builder.mutation<any, string>({
      query: (id) => ({
        url: `ocr/mapping/delete/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['mappingFieldList'],
    }),

    // field items
    fetchMappingFieldItems: builder.query<MappingFieldType[], string>({
      query: (code) => `/ocr/mapping/item/${code}`,
      providesTags: ['mappingFieldDetail'],
    }),
    setMappingFieldItem: builder.mutation<MappingFieldType, Omit<MappingFieldType, 'id'>>({
      query: (data) => ({
        url: '/ocr/mapping/item/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mappingFieldDetail'],
    }),
    updateMappingFieldItem: builder.mutation<MappingFieldType, MappingFieldType>({
      query: (data) => ({
        url: `/ocr/mapping/item/update/${data.id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mappingFieldDetail'],
    }),
    deleteMappingFieldItem: builder.mutation<any, string>({
      query: (id) => ({
        url: `/ocr/mapping/item/delete/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['mappingFieldDetail'],
    }),
  }),
});

export const {
  useFetchAllMappingFieldQuery,
  useFetchMappingFieldQuery,
  useFetchAllMappingBydatasourceCodeQuery,
  useSetMappingFieldMutation,
  useUpdateMappingFieldMutation,
  useDeleteMappingFieldMutation,

  useFetchMappingFieldItemsQuery,
  useSetMappingFieldItemMutation,
  useUpdateMappingFieldItemMutation,
  useDeleteMappingFieldItemMutation,
} = mappingFieldApi;
