export const loadScript = (src) => {
  return new Promise(function (resolve, reject) {
    const script = document.createElement('script');
    let r = false;
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.onerror = function (err) {
      reject(err, script);
    };
    // eslint-disable-next-line no-multi-assign
    script.onload = script.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        resolve();
      }
    };
    document.body.appendChild(script);
  });
};
