import { fetchJSON } from 'helpers/api';
import { DEFAULT_PAGINATION, ENP } from 'constants/api';

/* customer group API */

export const fetchGroups = () => {
  const options = {
    headers: {},
  };
  //
  return fetchJSON(`${ENP.CUSTOMER_API}/customer-group/get-all`, options);
};

export const fetchGroup = (groupId) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/customer-group/get-all/${groupId}`, options);
};

export const createGroup = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {},
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/groups`, options);
};

export const updateGroup = (groupId, data) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {},
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/customer-group/${groupId}`, options);
};

export const deleteGroup = (groupId) => {
  const options = {
    method: 'DELETE',
    headers: {},
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/customer-group/${groupId}`, options);
};

/* customer API */
// DANH SACH KHACH HANG
export const fetchCustomers = (filter, pagination = DEFAULT_PAGINATION) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(filter),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/customer/filter`, options, pagination);
};

export const fetchCustomer = (customerId) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/customer/${customerId}`, options);
};

export const fetchListContractCustomer = (customerId, pagination = DEFAULT_PAGINATION) => {
  const options = {
    method: 'GET',
    headers: {},
  };
  const url = `/lead/customer/${customerId}`;

  return fetchJSON(ENP.CUSTOMER_API + url, options, pagination);
};

export const createCustomer = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/customer/create`, options);
};

export const updateCustomer = (customerId, data) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/customer/${customerId}`, options);
};

export const deleteCustomer = (customerId) => {
  const options = {
    method: 'DELETE',
    headers: {},
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/customer/${customerId}`, options);
};

export const fetchDocuments = (customerId) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/customer/${customerId}/document`, options);
};

export const uploadDocument = (customerId, data) => {
  const formData = new FormData();
  formData.append('file', data.file);

  const options = {
    method: 'POST',
    body: formData,
    headers: {},
  };

  return fetchJSON(`${ENP.CUSTOMER_API}/customer/${customerId}/document/${data.type}`, options);
};

export const importCustomer = (data, checkOnly) => {
  const options = {
    method: 'POST',
    body: data,
    headers: {},
  };
  return fetchJSON(`${ENP.CUSTOMER_API}/customer/import${checkOnly ? '/check' : ''}`, options);
};
