import { fetchJSON } from 'helpers/api';
import { ENP } from 'constants/api';

const ADMIN_API = 'https://api-dev.nexusti.vn/crm/admin/customer-account/get-list';

/* API Gateway APIs */
export const fetchAPIGateways = () => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.GATEWAY_API}/control/get-all`, options);
};

export const fetchAPIGateway = (id) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.GATEWAY_API}/control/${id}`, options);
};

export const createAPIGateway = (data) => {
  const options = {
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.GATEWAY_API}/control/create`, options);
};

export const updateAPIGateway = (data) => {
  const options = {
    body: JSON.stringify(data),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.GATEWAY_API}/control/${data.id}`, options);
};

export const testAPIGateway = (data) => {
  const { method, urlPath, headers, params, body } = data;
  const options = {
    method,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body,
  };

  const url = new URL(`${ENP.GATEWAY_API}/control/test/${urlPath}`);
  url.search = new URLSearchParams(params).toString();
  return fetchJSON(url, options);
};

/** ********************************************************************************************************** */
/* API Product */
export const fetchListProduct = (params) => {
  const option = {
    headers: {},
  };

  return fetchJSON(`${ENP.PRODUCT_API}/product/get-all/pagination`, option, params);
};

export const fetchListProductHybrid = (params) => {
  const option = {
    headers: {},
  };

  return fetchJSON(`${ENP.PRODUCT_API}/product/get-all/hybrid`, option, params);
};
export const filterUsersProduct = (data) => {
  const options = {
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ADMIN_API}/filter`, options);
};

export const filterAllUsersProduct = () => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ADMIN_API}/all`, options);
};

export const fetchProductForms = (productCode) => {
  const option = {
    headers: {},
  };

  return fetchJSON(`${ENP.PRODUCT_API}/product/${productCode}/form`, option);
};

export const deleteProductGroup = (id) => {
  const options = {
    method: 'DELETE',
    headers: {},
  };

  return fetchJSON(`${ENP.PRODUCT_API}/product/group/${id}`, options);
};

export const calProductRepayPlan = (code, amount, term, rate, insuranceRate) => {
  const params = { amount, term, rate, insuranceRate };

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.PRODUCT_API}/product/repay-plan/${code}`, options, params);
};
