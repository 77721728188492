import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { prepareAuthHeaders } from 'helpers/api';
import { ListWithPagination, Pagination } from 'redux/types';
import {
  ActivityFilter,
  ChartListData,
  ConversionFilterPayload,
  DashboardFilter,
  DisbursedAmountSummary,
  DisbursedLeadSummary,
} from './types';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    fetchActivityCodeCount: builder.query<Record<string, number>, ActivityFilter>({
      query: (filter) => ({
        url: `activity/report/code/count`,
        method: 'POST',
        body: filter,
      }),
    }),
    fetchActivityCodeCountEachUser: builder.query<Record<string, Record<string, number>>, ActivityFilter>({
      query: (filter) => ({
        url: `activity/report/code/count/user`,
        method: 'POST',
        body: filter,
      }),
    }),
    fetchActivityReport: builder.query<
      ListWithPagination<any>,
      { filter: Partial<ActivityFilter>; pagination: Pagination }
    >({
      query: ({ filter, pagination }) => ({
        url: `activity/report`,
        method: 'POST',
        body: filter,
        params: pagination,
      }),
    }),

    fetchDashboardLeadConversion: builder.query<ChartListData<number>, ConversionFilterPayload>({
      query: ({ id, filter }) => ({
        url: `lead-conversion/report/dashboard/${id}`,
        method: 'POST',
        body: filter,
      }),
    }),

    fetchDashboardDisbursementSum: builder.query<DisbursedAmountSummary, Partial<DashboardFilter>>({
      query: (filter) => ({
        url: `lead/disbursement/report/dashboard/sum`,
        method: 'POST',
        body: filter,
      }),
    }),
    fetchDashboardDisbursementSumMonthly: builder.query<DisbursedAmountSummary[], Partial<DashboardFilter>>({
      query: (filter) => ({
        url: `lead/disbursement/report/dashboard/month/sum`,
        method: 'POST',
        body: filter,
      }),
    }),

    fetchDashboardDisbursementCount: builder.query<DisbursedLeadSummary, Partial<DashboardFilter>>({
      query: (filter) => ({
        url: `lead/disbursement/report/dashboard/count`,
        method: 'POST',
        body: filter,
      }),
    }),
    fetchDashboardDisbursementCountMonthly: builder.query<DisbursedLeadSummary[], Partial<DashboardFilter>>({
      query: (filter) => ({
        url: `lead/disbursement/report/dashboard/month/count`,
        method: 'POST',
        body: filter,
      }),
    }),

    fetchDashboardLeadCountDailyByCampaign: builder.query<
      ChartListData<number>,
      { campaignCode: string; filter: Partial<DashboardFilter> }
    >({
      query: ({ campaignCode, filter }) => ({
        url: `campaign/report/day/count/${campaignCode}`,
        method: 'POST',
        body: filter,
      }),
    }),
  }),
});

export const {
  useFetchActivityCodeCountQuery,
  useFetchActivityCodeCountEachUserQuery,
  useFetchActivityReportQuery,

  useFetchDashboardLeadConversionQuery,

  useFetchDashboardDisbursementSumQuery,
  useFetchDashboardDisbursementSumMonthlyQuery,
  useFetchDashboardDisbursementCountQuery,
  useFetchDashboardDisbursementCountMonthlyQuery,

  useFetchDashboardLeadCountDailyByCampaignQuery,
} = reportApi;
