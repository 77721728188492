import { DashboardOutlined } from '@mui/icons-material';
import { NxRoute } from './types';

const CrmDashboard = lazy(() => import('pages/dashboard'));

const dashboardRoute: NxRoute = {
  path: '/dashboard',
  name: 'menuConfig:dashboard',
  Component: CrmDashboard,
  icon: DashboardOutlined,
  exact: true,
};

export default dashboardRoute;
