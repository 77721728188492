const chainParse = (obj: any, attrArr: any[], defaultValue: any = null) => {
  if (!obj || typeof obj !== 'object') {
    return defaultValue;
  }

  let cloneObj = { ...obj };

  for (const item of attrArr) {
    cloneObj = cloneObj[item];
    if (typeof cloneObj === 'undefined' || cloneObj === null || cloneObj === '') return defaultValue;
  }

  return cloneObj;
};

export { chainParse as cp };
