import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { prepareAuthHeaders } from 'helpers/api';
import { isV1BE } from 'helpers/envUtils';
import { CustomFieldTabDetail, CustomFieldTabGroupDetail } from './type';

export const customFieldApi = createApi({
  reducerPath: 'customFieldApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['customFieldTagGroups'],
  endpoints: (builder) => ({
    fetchCustomFieldTabs: builder.query({
      query: () => `setting/custom-field/tab/list`,
    }),
    fetchCustomFieldTabGroups: builder.query<CustomFieldTabGroupDetail[], void>(
      isV1BE()
        ? {
            query: () => `setting/custom-field/tab/list`,
            providesTags: ['customFieldTagGroups'],
            transformResponse: (response: CustomFieldTabDetail[]) => [{ name: 'Thông tin khách hàng', tabs: response }],
          }
        : {
            query: () => `setting/custom-field/tab/group/list`,
            providesTags: ['customFieldTagGroups'],
          }
    ),

    updateCustomFieldTab: builder.mutation({
      query: (data) => ({
        url: `setting/custom-field-tab`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),

    createCustomField: builder.mutation({
      query: (data) => ({
        url: `setting/custom-field/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),
    updateCustomField: builder.mutation({
      query: (data) => ({
        url: `setting/custom-field/${data.name}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),
    deleteCustomField: builder.mutation({
      query: (name: string) => ({
        url: `setting/custom-field/${name}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),

    updateFieldOrder: builder.mutation({
      query: (data: { source: string; target: string }) => ({
        url: `setting/custom-field/order`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),

    updateFieldTabOrder: builder.mutation({
      query: (data: { source: string; target: string }) => ({
        url: `setting/custom-field-tab/order`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),
  }),
});

export const {
  useFetchCustomFieldTabsQuery,
  useFetchCustomFieldTabGroupsQuery,
  useUpdateCustomFieldTabMutation,

  useCreateCustomFieldMutation,
  useUpdateCustomFieldMutation,
  useDeleteCustomFieldMutation,

  useUpdateFieldOrderMutation,
  useUpdateFieldTabOrderMutation,
} = customFieldApi;
