export const USER_STATES = {
  available: 'AVAILABLE',
  inCall: 'IN_CALL',
  meeting: 'MEETING',
  lunchTime: 'LUNCH_TIME',
  other: 'OTHER',
  pause: 'PAUSE',
  inactive: 'INACTIVE',
  unknown: 'UNKNOWN',
};

export const USER_STATE_LABELS = {
  AVAILABLE: {
    label: 'Trực tuyến',
  },
  IN_CALL: {
    label: 'Trong cuộc gọi',
  },
  MEETING: {
    label: 'Trong cuộc họp',
  },
  LUNCH_TIME: {
    label: 'Nghỉ trưa',
  },
  OTHER: {
    label: 'Khác',
  },
  PAUSE: {
    label: 'Tạm dừng',
  },
  INACTIVE: {
    label: 'Ngoại tuyến',
  },
  UNKNOWN: {
    label: 'Không xác định',
  },
};

export const renderUserStateLabel = (state) =>
  state ? (
    <span>
      {state.icon} {state.label}
    </span>
  ) : (
    'N/A'
  );
