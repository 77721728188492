// @flow
import * as type from './constants';

/* Address actions */
// TODO: remove address action

export const fetchDistricts = (provinceId, callback = () => {}) => ({
  type: type.FETCH_DISTRICTS,
  payload: { provinceId, callback },
});
export const fetchDistrictsSuccessed = (data) => ({
  type: type.FETCH_DISTRICTS_SUCCESSED,
  payload: data,
});
export const fetchDistrictsFailed = (error) => ({
  type: type.FETCH_DISTRICTS_FAILED,
  payload: error,
});
export const saveDistrictsToMap = (provinceId, districts) => ({
  type: type.SAVE_DISTRICTS,
  payload: { provinceId, districts },
});

export const clearDistricts = () => ({ type: type.CLEAR_DISTRICTS });
export const clearDistrictsSuccessed = () => ({ type: type.CLEAR_DISTRICTS_SUCCESSED });

export const fetchWards = (districtId, callback = () => {}) => ({
  type: type.FETCH_WARDS,
  payload: { districtId, callback },
});
export const fetchWardsSuccessed = (data) => ({ type: type.FETCH_WARDS_SUCCESSED, payload: data });
export const fetchWardsFailed = (error) => ({ type: type.FETCH_WARDS_FAILED, payload: error });

export const clearWards = () => ({ type: type.CLEAR_WARDS });
export const clearWardsSuccessed = () => ({ type: type.CLEAR_WARDS_SUCCESSED });

/* Category common */

export const fetchCommonCategory = () => ({ type: type.FETCH_COMMON_CATEGORY });
export const fetchCommonCategorySuccessed = (data) => ({
  type: type.FETCH_COMMON_CATEGORY_SUCCESSED,
  payload: data,
});
export const fetchCommonCategoryFailed = (error) => ({
  type: type.FETCH_COMMON_CATEGORY_FAILED,
  payload: error,
});

export const fetchCategoryValues = (categoryId) => ({
  type: type.FETCH_CATEGORY_VALUES,
  payload: categoryId,
});
export const fetchCategoryValuesSuccessed = (data) => ({
  type: type.FETCH_CATEGORY_VALUES_SUCCESSED,
  payload: data,
});
export const fetchCategoryValuesFailed = (error) => ({
  type: type.FETCH_CATEGORY_VALUES_FAILED,
  payload: error,
});

export const createCategory = (data) => ({ type: type.CREATE_CATEGORY, payload: data });
export const createCategorySuccessed = () => ({ type: type.CREATE_CATEGORY_SUCCESSED });
export const createCategoryFailed = (error) => ({
  type: type.CREATE_CATEGORY_FAILED,
  payload: error,
});

export const updateCategory = (data) => ({ type: type.UPDATE_CATEGORY, payload: data });
export const updateCategorySuccessed = (data) => ({
  type: type.UPDATE_CATEGORY_SUCCESSED,
  payload: data,
});
export const updateCategoryFailed = (error) => ({
  type: type.UPDATE_CATEGORY_FAILED,
  payload: error,
});

export const deleteCategory = (id, categoryId) => ({
  type: type.DELETE_CATEGORY,
  payload: { id, categoryId },
});
export const deleteCategorySuccessed = (id) => ({
  type: type.DELETE_CATEGORY_SUCCESSED,
  payload: id,
});
export const deleteCategoryFailed = (error) => ({
  type: type.DELETE_CATEGORY_FAILED,
  payload: error,
});

/* Other */

export const fetchIPGeo = () => ({ type: type.FETCH_IP_GEO });
export const fetchIPGeoSuccessed = (data) => ({ type: type.FETCH_IP_GEO_SUCCESSED, payload: data });
export const fetchIPGeoFailed = (error) => ({ type: type.FETCH_IP_GEO_FAILED, payload: error });

export const fetchAppList = () => ({ type: type.FETCH_APP_LIST, payload: {} });
export const fetchAppListSuccess = (appList) => ({
  type: type.FETCH_APP_LIST_SUCCESS,
  payload: appList,
});

export const fetchNotifications = (callback) => ({
  type: type.FETCH_NOTIFICATIONS,
  payload: { callback },
});
export const fetchNotificationsSuccess = (appList) => ({
  type: type.FETCH_NOTIFICATIONS_SUCCESS,
  payload: appList,
});

export const fetchUnseenNotifications = (callback) => ({
  type: type.FETCH_UNSEEN_NOTIFICATIONS,
  payload: { callback },
});
export const fetchUnseenNotificationsSuccess = (appList) => ({
  type: type.FETCH_UNSEEN_NOTIFICATIONS_SUCCESS,
  payload: appList,
});

export const subscribeUser = (token) => ({ type: type.SUBSCRIBE_USER, payload: { token } });
export const subscribeUserSuccess = (data) => ({
  type: type.SUBSCRIBE_USER_SUCCESS,
  payload: data,
});
export const subscribeUserFailed = (error) => ({
  type: type.SUBSCRIBE_USER_FAILED,
  payload: error,
});

export const unsubscribeUser = (token) => ({ type: type.UNSUBSCRIBE_USER, payload: { token } });
export const unsubscribeUserSuccess = () => ({ type: type.UNSUBSCRIBE_USER_SUCCESS });
export const unsubscribeUserFailed = (error) => ({
  type: type.UNSUBSCRIBE_USER_FAILED,
  payload: error,
});

export const updateNotification = (id) => ({ type: type.UPDATE_NOTIFICATION, payload: { id } });

export const clearCache = (params, options = {}) => ({
  type: type.CLEAR_CACHE,
  payload: { params, options: options ?? {} },
});

export const fetchRouteConfig = (options) => ({
  type: type.FETCH_ROUTE_CONFIG,
  payload: { options: options ?? {} },
});

export const fetchRouteConfigSuccessed = (data) => ({
  type: type.FETCH_ROUTE_CONFIG_SUCCESSED,
  payload: data,
});

export const updateRouteConfig = (data) => ({
  type: type.UPDATE_ROUTE_CONFIG,
  payload: data,
});

export const updateActiveMenuKey = (data) => ({
  type: type.UPDATE_ACTIVE_MENU_KEYS,
  payload: data,
});
