import { loadScript } from 'helpers/scriptUtils';

const SIP_URL = `${import.meta.env.PUBLIC_URL}/js/sip-0.7.3.js`;
const WEB_PHONE_URL = `${import.meta.env.PUBLIC_URL}/js/webphone.js`;

// const SIP_DOMAIN = 'voice.f88.vn'; //"800255", "123456a@"
// const SIP_DOMAIN = 'cloud.telebot.vn'; //"526", "450967577197"
// const SIP_DOMAIN = 'cloud6.telebot.vn'; //"180", "1234567890"
// const SIP_DOMAIN = 'call.metechvn.com'; //"1008", "nD6*c3t*ahqR"

const PbxDialerComponent = ({
  webPhone,
  domain,
  username,
  password,
  isCalling,
  setStateLabel = () => {},
  setTotalSeconds = () => {},
  updateWebPhone = () => {},
  setConnected = () => {},
  onCallId = () => {},
  setIsCalling = () => {},
  setIsIncoming = () => {},
  setIsMuted = () => {},
  setIsHeld = () => {},
  callRequest,
  answerRequest,
  endCallRequest,
  muteRequest,
  holdRequest,
  unmuteRequest,
  unholdRequest,
  setCallRequest = () => {},
  setAnswerRequest = () => {},
  setEndCallRequest = () => {},
  setMuteRequest = () => {},
  setUnmuteRequest = () => {},
  setHoldRequest = () => {},
  setUnholdRequest = () => {},
}) => {
  let totalSeconds;

  const media = new Audio();
  const ringing = new Audio('https://sd6.telebot.vn/lib/webphone/audio/ringing.mp3?01');
  const tone = new Audio('https://sd6.telebot.vn/lib/webphone/audio/tone.mp3');

  useEffect(() => {
    if (hasCallUser() && !webPhone) {
      runScript();
      ringing.loop = true;
      ringing.load();

      tone.loop = true;
      tone.load();
    }
  }, []);

  const runScript = () => {
    loadScript(SIP_URL)
      .then(() => {
        loadScript(WEB_PHONE_URL)
          .then(() => {
            updateWebPhone(new window.WebPhone());
          })
          .catch((error) => {
            console.log('Error when loading script: ', error);
          });
      })
      .catch((error) => {
        console.log('Error when loading script: ', error);
      });
  };

  useEffect(() => {
    if (webPhone && webPhone.connected == null) {
      webPhone.initSipClient(username, password, domain);

      webPhone.ua.on('connected', function () {
        onIsConnected(false);
        console.log('pbx connected');
      });

      webPhone.ua.on('registered', function () {
        onIsConnected(true);
        console.log('pbx registered');
      });

      webPhone.ua.on('unregistered', function () {
        onIsConnected(false);
        console.log('pbx unregistered');
      });

      webPhone.ua.on('invite', function (session) {
        console.log('pbx invite');
        if (!isCalling) {
          ringing.play();

          setIsCalling(true);
          setIsIncoming(true);
          setStateLabel('Cuộc gọi đến');

          webPhone.session = session;
          setUpListeners(session);
        } else {
          session.reject();
        }
      });
    }
  }, [webPhone]);

  const onIsConnected = (isConnected) => {
    setConnected(isConnected);
  };

  useEffect(() => {
    if (callRequest && callRequest.target && webPhone) {
      call(callRequest.target);
    }
    setCallRequest(false);
  }, [callRequest]);

  useEffect(() => {
    answerRequest && answerCall();
    setAnswerRequest(false);
  }, [answerRequest]);

  useEffect(() => {
    endCallRequest && endCall();
    setEndCallRequest(false);
  }, [endCallRequest]);

  useEffect(() => {
    muteRequest && mute();
    setMuteRequest(false);
  }, [muteRequest]);

  useEffect(() => {
    unmuteRequest && unmute();
    setUnmuteRequest(false);
  }, [unmuteRequest]);

  useEffect(() => {
    holdRequest && hold();
    setHoldRequest(false);
  }, [holdRequest]);

  useEffect(() => {
    unholdRequest && unhold();
    setUnholdRequest(false);
  }, [unholdRequest]);

  function call(toNumber, callback = () => {}) {
    const callId = webPhone.makecall(toNumber);

    if (webPhone.session) {
      console.log('pbx call');
      tone.play();
      setIsCalling(true);
      setIsIncoming(false);
      setStateLabel('Đang gọi');

      totalSeconds = 0;
      setTotalSeconds(totalSeconds);
      setUpListeners(webPhone.session);
    }

    onCallId(callId);
  }

  const setUpListeners = (session) => {
    let intervalId;
    console.log('pbx set up listener');

    session.on('accepted', function () {
      console.log('pbx accepted');
      tone.pause();
      ringing.pause();
      setStateLabel('Đã kết nối');

      totalSeconds = 0;
      setTotalSeconds(totalSeconds);
      intervalId = setInterval(addTotalSeconds, 1000);
      session.mediaHandler.render({ remote: media });
    });

    session.mediaHandler.on('addStream', function () {
      session.mediaHandler.render({ remote: media });
    });

    session.on('bye', function () {
      tone.pause();
      ringing.pause();
      setStateLabel('Kết thúc');
      clearInterval(intervalId);
      setIsCalling(false);
      console.log('pbx bye');
    });

    session.on('failed', function () {
      tone.pause();
      ringing.pause();
      setStateLabel('Thất bại');
      clearInterval(intervalId);
      setIsCalling(false);
      console.log('pbx failed');
    });
  };

  const endCall = () => {
    console.log('pbx hangup');
    webPhone.hangup();
  };

  const answerCall = () => {
    console.log('pbx answer');
    ringing.pause();
    setIsIncoming(false);
    webPhone.answer();
  };

  const mute = () => {
    // webPhone.mute();
  };

  const unmute = () => {
    // webPhone.unmute();
  };

  const hold = () => {
    // webPhone.hold();
  };

  const unhold = () => {
    // webPhone.unhold();
  };

  const addTotalSeconds = () => {
    totalSeconds++;
    setTotalSeconds(totalSeconds);
  };

  const hasCallUser = () => {
    return username && password;
  };

  return '';
};

export default PbxDialerComponent;
