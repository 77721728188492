import * as type from './constants';

/* Customer group actions */
// export const fetchGroups = () => ({type: type.FETCH_GROUPS});
// export const fetchGroupsSuccessed = (data) => ({type: type.FETCH_GROUPS_SUCCESSED, payload: data});
// export const fetchGroupsFailed = (error) => ({type: type.FETCH_GROUPS_FAILED, payload: error});

// export const fetchGroup = (groupId) => ({type: type.FETCH_GROUP, payload: groupId});
// export const fetchGroupSuccessed = (data) => ({type: type.FETCH_GROUP_SUCCESSED, payload: data});
// export const fetchGroupFailed = (error) => ({type: type.FETCH_GROUP_FAILED, payload: error});

// export const createGroup = (data) => ({type: type.CREATE_GROUP, payload: data});
// export const createGroupSuccessed = (data) => ({type: type.CREATE_GROUP_SUCCESSED, payload: data});
// export const createGroupFailed = (error) => ({type: type.CREATE_GROUP_FAILED, payload: error});

// export const updateGroup = (groupId, data) => ({type: type.UPDATE_GROUP, payload: {groupId, data}});
// export const updateGroupSuccessed = (data) => ({type: type.UPDATE_GROUP_SUCCESSED, payload: data});
// export const updateGroupFailed = (error) => ({type: type.UPDATE_GROUP_FAILED, payload: error});

// export const deleteGroup = (groupId) => ({type: type.DELETE_GROUP, payload: groupId});
// export const deleteGroupSuccessed = (data) => ({type: type.DELETE_GROUP_SUCCESSED, payload: data});
// export const deleteGroupFailed = (error) => ({type: type.DELETE_GROUP_FAILED, payload: error});

/* Customer actions */
// Danh Sách Khách Hàng
export const fetchCustomers = (filter, pagination) => ({
  type: type.FETCH_CUSTOMERS,
  payload: { filter, pagination },
});
export const fetchCustomersSuccessed = (data) => ({
  type: type.FETCH_CUSTOMERS_SUCCESSED,
  payload: data,
});
export const fetchCustomersFailed = (error) => ({
  type: type.FETCH_CUSTOMERS_FAILED,
  payload: error,
});
// Khách Hàng
export const fetchCustomer = (customerId) => ({ type: type.FETCH_CUSTOMER, payload: customerId });
export const fetchCustomerSuccessed = (data) => ({
  type: type.FETCH_CUSTOMER_SUCCESSED,
  payload: data,
});
export const fetchCustomerFailed = (error) => ({
  type: type.FETCH_CUSTOMER_FAILED,
  payload: error,
});
// DS hợp đồng khách hàng
export const fetchListContractCustomer = (customerId, pagination) => ({
  type: type.FETCH_LIST_CONTRACT_CUSTOMER,
  payload: { customerId, pagination },
});
export const fetchListContractCustomerSuccessed = (data) => ({
  type: type.FETCH_LIST_CONTRACT_CUSTOMER_SUCCESSED,
  payload: data,
});
export const fetchListContractCustomerFailed = (error) => ({
  type: type.FETCH_LIST_CONTRACT_CUSTOMER_FAILED,
  payload: error,
});

export const createCustomer = (data) => ({ type: type.CREATE_CUSTOMER, payload: data });
export const createCustomerSuccessed = (data) => ({
  type: type.CREATE_CUSTOMER_SUCCESSED,
  payload: data,
});
export const createCustomerFailed = (error) => ({
  type: type.CREATE_CUSTOMER_FAILED,
  payload: error,
});

export const updateCustomer = (customerId, data) => ({
  type: type.UPDATE_CUSTOMER,
  payload: { customerId, data },
});
export const updateCustomerSuccessed = (data) => ({
  type: type.UPDATE_CUSTOMER_SUCCESSED,
  payload: data,
});
export const updateCustomerFailed = (error) => ({
  type: type.UPDATE_CUSTOMER_FAILED,
  payload: error,
});

export const deleteCustomer = (customerId) => ({ type: type.DELETE_CUSTOMER, payload: customerId });
export const deleteCustomerSuccessed = (data) => ({
  type: type.DELETE_CUSTOMER_SUCCESSED,
  payload: data,
});
export const deleteCustomerFailed = (error) => ({
  type: type.DELETE_CUSTOMER_FAILED,
  payload: error,
});

export const fetchDocuments = (customerId) => ({ type: type.FETCH_DOCUMENTS, payload: customerId });
export const fetchDocumentsSuccessed = (data) => ({
  type: type.FETCH_DOCUMENTS_SUCCESSED,
  payload: data,
});
export const fetchDocumentsFailed = (error) => ({
  type: type.FETCH_DOCUMENTS_FAILED,
  payload: error,
});

export const uploadDocument = (customerId, data) => ({
  type: type.UPLOAD_DOCUMENT,
  payload: { customerId, data },
});
export const uploadDocumentSuccessed = (data) => ({
  type: type.UPLOAD_DOCUMENT_SUCCESSED,
  payload: data,
});
export const uploadDocumentFailed = (error) => ({
  type: type.UPLOAD_DOCUMENT_FAILED,
  payload: error,
});

export const setOpenCreateCustomer = (status) => ({
  type: type.SET_OPEN_CREATE_CUSTOMER,
  payload: status,
});
