import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

const languageMap = {
  en: {
    key: 'en',
    native: 'English',
  },
  vi: {
    key: 'vi',
    native: 'Việt Nam',
  },
};

export const languages = [languageMap.en, languageMap.vi];

const defaultLanguage = languageMap.vi.key;
const currentLanguage = localStorage.getItem('i18nextLng');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language: string, namespace: string) => {
      return import(`./assets/locales/${language}/${namespace}.json`);
    })
  )
  .init({
    lng: currentLanguage ?? defaultLanguage,
    ns: ['app'],
    defaultNS: 'app',
    fallbackNS: 'app',
    fallbackLng: 'vi',
    keySeparator: ':',
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
  });

export default i18n;

export const useAppTranslation = () => {
  const partnerCode = import.meta.env.VITE_PARTNER_CODE;
  return useTranslation(partnerCode?.toLowerCase());
};
