// @flow
import * as type from './constants';

const INIT_STATE = {
  users: {},
  allUser: {},
  usersIncludeDeleted: {},
  user: {},

  groupTypes: [],
  groupByType: [],
  usersByGroup: [],
  usersByRoles: [],
  paramTables: [],
  managedMembers: [],
  managedMembersIncludeDeleted: [],
  notifications: {},

  leadFlow: {
    statusList: [],
    resultList: [],
    taskList: [],
  },

  workflows: [],
  workflow: {},
  allWorkflows: [],

  roles: [],
  groups: [],
  modules: {},
  groupsWithUsers: [],

  activityTypes: [],
  activityLogs: {},
  events: [],

  bucketSettings: [],
  activityCodeSettings: [],

  activityCodeLeadSettings: [],
  relationshipSettings: [],
  checkInLocationSettings: [],
  orgInfo: [],
  orgAvatar: null,
  groupLocals: [],
  rawGroupLocals: [],
  locations: [],
  groupTree: [],
  userListByRoles: [],
  allTypeFunnel: [],
};

const Admin = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* User cases */
    case type.FETCH_USERS:
      return { ...state };
    case type.FETCH_USERS_SUCCESSED:
      return { ...state, users: action.payload, allUser: action.payload, error: null };
    case type.FETCH_USERS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_USERS_INCLUDE_DELETED:
      return { ...state };
    case type.FETCH_USERS_INCLUDE_DELETED_SUCCESSED:
      return { ...state, usersIncludeDeleted: action.payload, error: null };
    case type.FETCH_USERS_INCLUDE_DELETED_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_USERS_BY_FILTER:
      return { ...state };
    case type.FETCH_USERS_BY_FILTER_SUCCESSED:
      return { ...state, users: action.payload, error: null };
    case type.FETCH_USERS_BY_FILTER_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_USER_DETAIL:
      return { ...state };
    case type.FETCH_USER_DETAIL_SUCCESSED:
      return { ...state, user: action.payload, error: null };
    case type.FETCH_USER_DETAIL_FAILED:
      return { ...state, error: action.payload };
    case type.CLEAR_USER_DETAIL:
      return { ...state, user: {}, error: action.payload };

    case type.FETCH_USERS_BY_GROUP:
      return { ...state, usersByGroup: [] };
    case type.FETCH_USERS_BY_GROUP_SUCCESSED:
      return { ...state, usersByGroup: action.payload, error: null };
    case type.FETCH_USERS_BY_GROUP_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_USERS_BY_ROLES:
      return { ...state };
    case type.FETCH_USERS_BY_ROLES_SUCCESSED:
      return { ...state, usersByRoles: action.payload, error: null };
    case type.FETCH_USERS_BY_ROLES_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_MANAGED_MEMBERS:
      return { ...state };
    case type.FETCH_MANAGED_MEMBERS_SUCCESSED:
      return { ...state, managedMembers: action.payload, error: null };
    case type.FETCH_MANAGED_MEMBERS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_MANAGED_MEMBERS_INCULDE_DELETED:
      return { ...state };
    case type.FETCH_MANAGED_MEMBERS_INCULDE_DELETED_SUCCESSED:
      return { ...state, managedMembersIncludeDeleted: action.payload, error: null };
    case type.FETCH_MANAGED_MEMBERS_INCULDE_DELETED_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_USER:
      return { ...state };
    case type.CREATE_USER_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_USER_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_USER:
      return { ...state };
    case type.UPDATE_USER_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_USER_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_STATUS_USER:
      return { ...state };
    case type.UPDATE_STATUS_USER_SUCCESSED:
      return { ...state, users: action.payload, error: null };
    case type.UPDATE_STATUS_USER_FAILED:
      return { ...state, error: action.payload };

    case type.CHANGE_PWD_USER:
      return { ...state };
    case type.CHANGE_PWD_USER_SUCCESSED:
      return { ...state, error: null };
    case type.CHANGE_PWD_USER_FAILED:
      return { ...state, error: action.payload };

    case type.ACTIVE_USER:
      return { ...state };
    case type.ACTIVE_USER_SUCCESSED:
      return { ...state, error: null };
    case type.ACTIVE_USER_FAILED:
      return { ...state, error: action.payload };

    case type.DEACTIVE_USER:
      return { ...state };
    case type.DEACTIVE_USER_SUCCESSED:
      return { ...state, error: null };
    case type.DEACTIVE_USER_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_USER:
      return { ...state };
    case type.DELETE_USER_SUCCESSED:
      return { ...state, error: null };
    case type.DELETE_USER_FAILED:
      return { ...state, error: action.payload };

    case type.RESTORE_USER:
      return { ...state };
    case type.RESTORE_USER_SUCCESSED:
      return { ...state, error: null };
    case type.RESTORE_USER_FAILED:
      return { ...state, error: action.payload };

    case type.SET_LEADER_USER:
      return { ...state };
    case type.SET_LEADER_USER_SUCCESSED:
      return { ...state, error: null };
    case type.SET_LEADER_USER_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_USER_PERMISSIONS:
      return { ...state };
    case type.UPDATE_USER_PERMISSIONS_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_USER_PERMISSIONS_FAILED:
      return { ...state, error: action.payload };

    /* Role cases */
    case type.FETCH_ROLES:
      return { ...state };
    case type.FETCH_ROLES_SUCCESSED:
      return { ...state, roles: action.payload, error: null };
    case type.FETCH_ROLES_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_ROLE:
      return { ...state };
    case type.CREATE_ROLE_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_ROLE_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_ROLE:
      return { ...state };
    case type.UPDATE_ROLE_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_ROLE_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_ROLE:
      return { ...state };
    case type.DELETE_ROLE_SUCCESSED:
      return { ...state, error: null };
    case type.DELETE_ROLE_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_ROLES_PERMISSION:
      return { ...state };
    case type.UPDATE_ROLES_PERMISSION_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_ROLES_PERMISSION_FAILED:
      return { ...state, error: action.payload };

    /* Group cases */
    case type.FETCH_GROUP_TYPES:
      return { ...state };
    case type.FETCH_GROUP_TYPES_SUCCESSED:
      return { ...state, groupTypes: action.payload, error: null };

    case type.FETCH_GROUPS_BY_TYPE:
      return { ...state };
    case type.FETCH_GROUPS_BY_TYPE_SUCCESSED:
      return { ...state, groupByType: action.payload, error: null };
    case type.FETCH_GROUPS_BY_TYPE_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_GROUPS:
      return { ...state };
    case type.FETCH_GROUPS_SUCCESSED:
      return { ...state, groups: action.payload, error: null };
    case type.FETCH_GROUPS_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_GROUP:
      return { ...state };
    case type.CREATE_GROUP_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_GROUP_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_GROUP:
      return { ...state };
    case type.UPDATE_GROUP_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_GROUP_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_GROUP:
      return { ...state };
    case type.DELETE_GROUP_SUCCESSED:
      return { ...state, error: null };
    case type.DELETE_GROUP_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_GROUPS_WITH_USERS:
      return { ...state };
    case type.FETCH_GROUPS_WITH_USERS_SUCCESSED:
      return { ...state, groupsWithUsers: action.payload, error: null };
    case type.FETCH_GROUPS_WITH_USERS_FAILED:
      return { ...state, error: action.payload };

    /* Resource cases */
    case type.FETCH_CLIENT_ROLES:
      return { ...state };
    case type.FETCH_CLIENT_ROLES_SUCCESSED:
      return { ...state, modules: action.payload, error: null };
    case type.FETCH_CLIENT_ROLES_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_CLIENT_ROLE:
      return { ...state, roles: action.payload };
    case type.UPDATE_CLIENT_ROLE_SUCCESSED:
      return { ...state, roles: action.payload, error: null };
    case type.UPDATE_CLIENT_ROLE_FAILED:
      return { ...state, error: action.payload };

    /* Param table cases */
    case type.FETCH_PARAM_TABLES:
      return { ...state };
    case type.FETCH_PARAM_TABLES_SUCCESSED:
      return { ...state, paramTables: action.payload, error: null };
    case type.FETCH_PARAM_TABLES_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_PARAM_TABLE_DETAIL:
      return { ...state };
    case type.FETCH_PARAM_TABLE_DETAIL_SUCCESSED:
      return { ...state, paramTable: action.payload, error: null };
    case type.FETCH_PARAM_TABLE_DETAIL_FAILED:
      return { ...state, error: action.payload };
    case type.CLEAR_PARAM_TABLE_DETAIL:
      return { ...state, paramTable: {}, error: action.payload };

    case type.FETCH_PARAM_TABLE_ENTRIES:
      return { ...state };
    case type.FETCH_PARAM_TABLE_ENTRIES_SUCCESSED: {
      const { id, data } = action.payload;
      let { paramTables } = state;
      paramTables = paramTables.map((item) => {
        if (item.id === id) {
          item.entries = data;
        }

        return item;
      });

      return { ...state, paramTables, error: null };
    }
    case type.FETCH_PARAM_TABLE_ENTRIES_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_PARAM_TABLE:
      return { ...state };
    case type.UPDATE_PARAM_TABLE_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_PARAM_TABLE_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_PARAM_TABLE:
      return { ...state };
    case type.DELETE_PARAM_TABLE_SUCCESSED:
      return { ...state, error: null };
    case type.DELETE_PARAM_TABLE_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_PARAM_TABLE:
      return { ...state };
    case type.CREATE_PARAM_TABLE_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_PARAM_TABLE_FAILED:
      return { ...state, error: action.payload };

    /* Notification */
    case type.FETCH_NOTIFICATION_TEMPLATE:
      return { ...state };
    case type.FETCH_NOTIFICATION_TEMPLATE_SUCCESSED:
      return { ...state, notifications: action.payload, error: null };
    case type.FETCH_NOTIFICATION_TEMPLATE_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_TEMPLATE:
      return { ...state };
    case type.CREATE_TEMPLATE_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_TEMPLATE_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_TEMPLATE:
      return { ...state };
    case type.UPDATE_TEMPLATE_SUCCESSED:
      return { ...state, error: null };
    case type.UPDATE_TEMPLATE_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_TEMPLATE:
      return { ...state };
    case type.DELETE_TEMPLATE_SUCCESSED:
      return { ...state, error: null };
    case type.DELETE_TEMPLATE_FAILED:
      return { ...state, error: action.payload };

    /* Activity logs */
    case type.FETCH_ACTIVITY_TYPE:
      return { ...state };
    case type.FETCH_ACTIVITY_TYPE_SUCCESSED:
      return { ...state, activityTypes: action.payload, error: null };
    case type.FETCH_ACTIVITY_TYPE_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ACTIVITY_LOGS:
      return { ...state };
    case type.FETCH_ACTIVITY_LOGS_SUCCESSED:
      return { ...state, activityLogs: action.payload, error: null };
    case type.FETCH_ACTIVITY_LOGS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ACTIVITY_LOGS_BY_FILTER:
      return { ...state };
    case type.FETCH_ACTIVITY_LOGS_BY_FILTER_SUCCESSED:
      return { ...state, activityLogs: action.payload, error: null };
    case type.FETCH_ACTIVITY_LOGS_BY_FILTER_FAILED:
      return { ...state, error: action.payload };

    /* Table flow */
    case type.FETCH_CURRENT_LEAD_FLOW:
      return { ...state };
    case type.FETCH_CURRENT_LEAD_FLOW_SUCCESSED:
      return { ...state, leadFlow: action.payload, error: null };
    case type.FETCH_CURRENT_LEAD_FLOW_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_LEAD_FLOW:
      return { ...state };
    case type.CREATE_LEAD_FLOW_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_LEAD_FLOW_FAILED:
      return { ...state, error: action.payload };

    /* Workflow */
    case type.FETCH_WORKFLOWS:
      return { ...state };
    case type.FETCH_WORKFLOWS_SUCCESSED:
      return { ...state, workflows: action.payload, error: null };
    case type.FETCH_WORKFLOWS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ALL_WORKFLOWS_SUCCESSED:
      return { allWorkflows: action.payload };

    case type.FETCH_WORKFLOW:
      return { ...state };
    case type.FETCH_WORKFLOW_SUCCESSED:
      return { ...state, workflow: action.payload, error: null };
    case type.FETCH_WORKFLOW_FAILED:
      return { ...state, error: action.payload };

    case type.DEPLOY_WORKFLOW:
      return { ...state };
    case type.DEPLOY_WORKFLOW_SUCCESSED:
      return { ...state, workflow: action.payload, error: null };
    case type.DEPLOY_WORKFLOW_FAILED:
      return { ...state, error: action.payload };

    case type.ACTIVATE_WORKFLOW:
      return { ...state };
    case type.ACTIVATE_WORKFLOW_SUCCESSED:
      return { ...state, error: null };
    case type.ACTIVATE_WORKFLOW_FAILED:
      return { ...state, error: action.payload };

    case type.DEACTIVATE_WORKFLOW:
      return { ...state };
    case type.DEACTIVATE_WORKFLOW_SUCCESSED:
      return { ...state, error: null };
    case type.DEACTIVATE_WORKFLOW_FAILED:
      return { ...state, error: action.payload };

    /* Calendar Settings */
    case type.FETCH_DAY_OFF_EVENT:
      return { ...state };
    case type.FETCH_DAY_OFF_EVENT_SUCCESSED:
      return { ...state, events: action.payload, error: null };
    case type.FETCH_DAY_OFF_EVENT_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_DAY_OFF_EVENT:
      return { ...state };
    case type.CREATE_DAY_OFF_EVENT_SUCCESSED:
      return { ...state, error: null };
    case type.CREATE_DAY_OFF_EVENT_FAILED:
      return { ...state, error: action.payload };

    case type.DELETE_DAY_OFF_EVENT:
      return { ...state };
    case type.DELETE_DAY_OFF_EVENT_SUCCESSED:
      return { ...state, error: null };
    case type.DELETE_DAY_OFF_EVENT_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CALENDAR_SETTING:
      return { ...state };
    case type.FETCH_CALENDAR_SETTING_SUCCESSED:
      return { ...state, calendarSetting: action.payload, error: null };
    case type.FETCH_CALENDAR_SETTING_FAILED:
      return { ...state, error: action.payload };

    case type.SET_CALENDAR_SETTING:
      return { ...state };
    case type.SET_CALENDAR_SETTING_SUCCESSED:
      return { ...state, error: null };
    case type.SET_CALENDAR_SETTING_FAILED:
      return { ...state, error: action.payload };

    /** Settings */
    case type.FETCH_ALL_KPI:
      return { ...state };
    case type.FETCH_ALL_KPI_SUCCESSED:
      return { ...state, kpiSettings: action.payload, error: null };
    case type.FETCH_ALL_KPI_FAILED:
      return { ...state, error: action.payload };

    case type.SET_KPI:
      return { ...state };
    case type.SET_KPI_SUCCESSED:
      return { ...state, error: null };
    case type.SET_KPI_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ALL_BUCKET:
      return { ...state };
    case type.FETCH_ALL_BUCKET_SUCCESSED:
      return { ...state, bucketSettings: action.payload, error: null };
    case type.FETCH_ALL_BUCKET_FAILED:
      return { ...state, error: action.payload };

    case type.SET_BUCKET:
      return { ...state };
    case type.SET_BUCKET_SUCCESSED:
      return { ...state, error: null };
    case type.SET_BUCKET_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ALL_ACTIVITY_CODE:
      return { ...state };
    case type.FETCH_ALL_ACTIVITY_CODE_SUCCESSED:
      return { ...state, activityCodeSettings: action.payload, error: null };
    case type.FETCH_ALL_ACTIVITY_CODE_FAILED:
      return { ...state, error: action.payload };

    case type.SET_ACTIVITY_CODE:
      return { ...state };
    case type.SET_ACTIVITY_CODE_SUCCESSED:
      return { ...state, error: null };
    case type.SET_ACTIVITY_CODE_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ALL_LEAD_ACTIVITY_CODE:
      return { ...state };
    case type.FETCH_ALL_LEAD_ACTIVITY_CODE_SUCCESSED:
      return { ...state, activityCodeLeadSettings: action.payload, error: null };
    case type.FETCH_ALL_LEAD_ACTIVITY_CODE_FAILED:
      return { ...state, error: action.payload };

    case type.SET_LEAD_ACTIVITY_CODE:
      return { ...state };
    case type.SET_LEAD_ACTIVITY_CODE_SUCCESSED:
      return { ...state, error: null };
    case type.SET_LEAD_ACTIVITY_CODE_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ALL_RELATIONSHIP:
      return { ...state };
    case type.FETCH_ALL_RELATIONSHIP_SUCCESSED:
      return { ...state, relationshipSettings: action.payload, error: null };
    case type.FETCH_ALL_RELATIONSHIP_FAILED:
      return { ...state, error: action.payload };

    case type.SET_RELATIONSHIP:
      return { ...state };
    case type.SET_RELATIONSHIP_SUCCESSED:
      return { ...state, error: null };
    case type.SET_RELATIONSHIP_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ALL_CHECK_IN_LOCATION:
      return { ...state };
    case type.FETCH_ALL_CHECK_IN_LOCATION_SUCCESSED:
      return { ...state, checkInLocationSettings: action.payload, error: null };
    case type.FETCH_ALL_CHECK_IN_LOCATION_FAILED:
      return { ...state, error: action.payload };

    case type.SET_CHECK_IN_LOCATION:
      return { ...state };
    case type.SET_CHECK_IN_LOCATION_SUCCESSED:
      return { ...state, error: null };
    case type.SET_CHECK_IN_LOCATION_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ORG_INFO:
      return { ...state };
    case type.FETCH_ORG_INFO_SUCCESSED:
      return { ...state, orgInfo: action.payload, error: null };
    case type.FETCH_ORG_INFO_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ORG_AVATAR:
      return { ...state };
    case type.FETCH_ORG_AVATAR_SUCCESSED:
      return { ...state, orgAvatar: action.payload, error: null };
    case type.FETCH_ORG_AVATAR_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_ORG_INFO:
      return { ...state };
    case type.UPDATE_ORG_INFO_SUCCESSED:
      return { ...state, orgInfo: action.payload, error: null };
    case type.UPDATE_ORG_INFO_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ALL_GROUP_LOCAL:
      return { ...state };
    case type.FETCH_ALL_GROUP_LOCAL_SUCCESSED:
      return {
        ...state,
        groupLocals: action.payload.groupLocals,
        rawGroupLocals: action.payload.rawGroupLocals,
        error: null,
      };
    case type.FETCH_ALL_GROUP_LOCAL_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_ALL_LOCATION:
      return { ...state };
    case type.FETCH_ALL_LOCATION_SUCCESSED:
      return { ...state, locations: action.payload, error: null };
    case type.FETCH_ALL_LOCATION_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_NESTED_PROVINCE_SUCCESSED:
      const province = action.payload.result.map((e) => ({ ...e, childGroups: [] }));
      return {
        ...state,
        locations: state.locations.map((e) => ({
          ...e,
          childGroups: e.id === action.payload.regionId && e.childGroups.length === 0 ? province : e.childGroups,
        })),
      };

    case type.FETCH_NESTED_DISTRICT_SUCCESSED:
      const districts = action.payload.result.map((e) => ({
        ...e,
        provinceId: action.payload.provinceId,
        childGroups: [],
      }));
      return {
        ...state,
        locations: state.locations.map((e) => ({
          ...e,
          childGroups: e.childGroups.map((k) => ({
            ...k,
            childGroups: k.id === action.payload.provinceId && k.childGroups.length === 0 ? districts : k.childGroups,
          })),
        })),
      };

    case type.FETCH_NESTED_WARD_SUCCESSED:
      return {
        ...state,
        locations: state.locations.map((loc) => ({
          ...loc,
          childGroups: loc.childGroups.map((pro) => ({
            ...pro,
            childGroups: pro.childGroups.map((dis) => ({
              ...dis,
              childGroups: dis.id === action.payload.districtId ? action.payload.result : dis.childGroups,
            })),
          })),
        })),
      };

    case type.FETCH_RECURSION_PROVINCE_SUCCESSED:
      return {
        ...state,
        locations: state.locations.map((e) => ({
          ...e,
          childGroups: e.id === action.payload.regionId ? action.payload.data : e.childGroups,
        })),
      };

    case type.UPDATE_LOCATION_SUCCESSED:
      return { ...state };

    case type.FETCH_GROUP_TREE_SUCCESSED:
      return { ...state, groupTree: [action.payload] };

    case type.UPDATE_USER_STATUS_SUCCESSED:
      return { ...state, userState: action.payload.state };

    case type.GET_USER_STATUS_SUCCESSED:
      return { ...state, userState: action.payload.state };

    case type.GET_USERS_BY_ROLES_SUCCESSED:
      return { ...state, userListByRoles: action.payload };

    case type.UPDATE_LEADER_SUCCESSED:
      return { ...state };

    case type.GET_ALL_TYPE_FUNNEL_SUCCESSED:
      return { ...state, allTypeFunnel: action.payload };

    default:
      return { ...state };
  }
};

export default Admin;
