import { DataSourceType, ResponseDocDatasource, UploadDocumentDataSource } from 'redux/datasource/type';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { prepareAuthHeaders } from 'helpers/api';
import { DataSourceField } from './type';

export const datasourceApi = createApi({
  reducerPath: 'datasourceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['datasourceList', 'datasourceDetail'],
  endpoints: (builder) => ({
    // data source
    fetchAllDataSource: builder.query<DataSourceType[], void>({
      query: () => '/ocr/datasource/get-all',
      providesTags: ['datasourceList'],
    }),
    fetchDataSource: builder.query<DataSourceType | undefined, string>({
      query: (code) => `/ocr/datasource/${code}`,
      providesTags: ['datasourceList'],
      // query: (id) => ({
      //   url: '/ocr/datasource/get-all',
      // }),
      // transformResponse: (response: DataSourceType[], meta, id) => {
      //   return response.find((datasource) => datasource.code === id);
      // }
    }),
    setDataSource: builder.mutation<Omit<DataSourceType, 'id'>, DataSourceType>({
      query: (data) => ({
        url: '/ocr/datasource/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['datasourceList'],
    }),
    updateDataSource: builder.mutation<DataSourceType, DataSourceType>({
      query: (data) => {
        const { id } = data;
        return {
          url: `/ocr/datasource/update/${id}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['datasourceList'],
    }),
    deleteDataSource: builder.mutation<any, string>({
      query: (id) => ({
        url: `/ocr/datasource/delete/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['datasourceList'],
    }),
    // fields
    fetchDataSourceFields: builder.query<DataSourceField[], string>({
      query: (code) => `/ocr/datasource/field/${code}`,
      providesTags: ['datasourceDetail'],
    }),
    setDataSourceField: builder.mutation<DataSourceField, Omit<DataSourceField, 'id' | 'sourceEntity'>>({
      query: (data) => {
        return {
          url: '/ocr/datasource/field/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['datasourceDetail'],
    }),
    updateDataSourceField: builder.mutation<DataSourceField, DataSourceField>({
      query: (data) => {
        const { id } = data;
        return {
          url: `/ocr/datasource/field/update/${id}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['datasourceDetail'],
    }),
    deleteDataSourceField: builder.mutation<void, string>({
      query: (id) => ({
        url: `/ocr/datasource/field/delete/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['datasourceDetail'],
    }),

    // preview
    uploadDataSourceDocument: builder.mutation<ResponseDocDatasource, UploadDocumentDataSource>({
      query: (payload) => {
        const { file, dataSourceCode } = payload;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('datasourceCode', dataSourceCode);

        return {
          url: '/ocr/get-data/file',
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useFetchAllDataSourceQuery,
  useFetchDataSourceQuery,
  useSetDataSourceMutation,
  useUpdateDataSourceMutation,
  useDeleteDataSourceMutation,

  useFetchDataSourceFieldsQuery,
  useSetDataSourceFieldMutation,
  useUpdateDataSourceFieldMutation,
  useDeleteDataSourceFieldMutation,

  useUploadDataSourceDocumentMutation,
} = datasourceApi;
