// GỌI API THÔNG QUA ACTION KHÔNG SỬ DỤNG API TRỰC TIẾP
import * as type from './constants';
/* Matrix Menu actions */
export const initMatrixMenu = () => ({
  type: type.INIT_MENU,
  payload: {},
});

export const initMatrixMenuSuccess = (menuItems) => ({
  type: type.INIT_MENU_SUCCESS,
  payload: { menuItems },
});

export const changeActiveMatrixMenuFromLocation = () => ({
  type: type.CHANGE_ACTIVE_MENU_FROM_LOCATION,
  payload: {},
});

export const changeActiveMatrixMenuFromLocationSuccess = (activatedMenuItemIds) => ({
  type: type.CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS,
  payload: { activatedMenuItemIds },
});

/* API Gateway actions */
export const fetchAPIGateways = () => ({
  type: type.FETCH_API_GATEWAYS,
});
export const fetchAPIGatewaysSuccessed = (data) => ({
  type: type.FETCH_API_GATEWAYS_SUCCESSED,
  payload: data,
});
export const fetchAPIGatewaysFailed = (error) => ({
  type: type.FETCH_API_GATEWAYS_FAILED,
  payload: error,
});

export const fetchAPIGateway = (data) => ({
  type: type.FETCH_API_GATEWAY,
  payload: data,
});
export const fetchAPIGatewaySuccessed = (data) => ({
  type: type.FETCH_API_GATEWAY_SUCCESSED,
  payload: data,
});
export const fetchAPIGatewayFailed = (error) => ({
  type: type.FETCH_API_GATEWAY_FAILED,
  payload: error,
});

export const createAPIGateway = (data) => ({
  type: type.CREATE_API_GATEWAY,
  payload: data,
});
export const createAPIGatewaySuccessed = (data) => ({
  type: type.CREATE_API_GATEWAY_SUCCESSED,
  payload: data,
});
export const createAPIGatewayFailed = (error) => ({
  type: type.CREATE_API_GATEWAY_FAILED,
  payload: error,
});

export const updateAPIGateway = (data) => ({
  type: type.UPDATE_API_GATEWAY,
  payload: data,
});
export const updateAPIGatewaySuccessed = (data) => ({
  type: type.UPDATE_API_GATEWAY_SUCCESSED,
  payload: data,
});
export const updateAPIGatewayFailed = (error) => ({
  type: type.UPDATE_API_GATEWAY_FAILED,
  payload: error,
});

export const testAPIGateway = (data) => ({
  type: type.TEST_API_GATEWAY,
  payload: data,
});
export const testAPIGatewaySuccessed = (data) => ({
  type: type.TEST_API_GATEWAY_SUCCESSED,
  payload: data,
});
export const testAPIGatewayFailed = (error) => ({
  type: type.TEST_API_GATEWAY_FAILED,
  payload: error,
});

// **************************************************************************************************
/* Product Action  */

export const fetchListProductHybrid = (params) => ({
  type: type.FETCH_LIST_PRODUCT_HYBRID,
  payload: params,
});

export const fetchListProductHybridSuccessed = (data) => ({
  type: type.FETCH_LIST_PRODUCT_HYBRID_SUCCESSED,
  payload: data,
});

export const fetchListProductHybridFailed = (error) => ({
  type: type.FETCH_LIST_PRODUCT_HYBRID_FAILED,
  payload: error,
});

export const setUsersAllDefault = () => ({
  type: type.SET_USERS_ALL_DEFAULT,
});

export const filterAllUsersProduct = () => ({
  type: type.FILTER_ALL_USERS_PRODUCT,
});

export const filterAllUsersProductSuccessed = (data) => ({
  type: type.FILTER_ALL_USERS_PRODUCT_SUCCESSED,
  payload: data,
});

export const filterAllUsersProductFailed = (error) => ({
  type: type.FILTER_ALL_USERS_PRODUCT_FAILED,
  payload: error,
});

export const filterUsersProduct = (formDataFilter, key, index) => ({
  type: type.FILTER_USERS_PRODUCT,
  payload: { formDataFilter, key, index },
});

export const filterUsersProductSuccessed = (data, key = '', index = null) => ({
  type: type.FILTER_USERS_PRODUCT_SUCCESSED,
  payload: { data, key, index },
});

export const filterUsersProductFailed = (error) => ({
  type: type.FILTER_USERS_PRODUCT_FAILED,
  payload: error,
});

export const fetchProductForms = (productCode) => ({
  type: type.FETCH_PRODUCT_FORMS,
  payload: productCode,
});

export const fetchProductFormsSuccessed = (data) => ({
  type: type.FETCH_PRODUCT_FORMS_SUCCESSED,
  payload: data,
});

export const fetchProductFormsFailed = (error) => ({
  type: type.FETCH_PRODUCT_FORMS_FAILED,
  payload: error,
});

export const calProductRepayPlan = (code, amount, term) => ({
  type: type.CAL_PRODUCT_REPAY_PLAN,
  payload: { code, amount, term },
});

export const calProductRepayPlanSuccessed = (data) => ({
  type: type.CAL_PRODUCT_REPAY_PLAN_SUCCESSED,
  payload: data,
});

export const calProductRepayPlanFailed = (error) => ({
  type: type.CAL_PRODUCT_REPAY_PLAN_FAILED,
  payload: error,
});
