// @flow
/* eslint-disable no-unused-vars */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { handleLoading } from 'redux/layout/actions';
import toast from 'react-hot-toast';
import { GROUP_MANAGER_TABLE_ID } from 'constants/api';
import { addressSort } from 'redux/common/saga';
import { fetchDistricts, fetchWards } from 'redux/common/api';
import { clone, uniqBy } from 'helpers/utils';
import { isAvailableArray } from 'helpers/arrayUtils';
import { cloneDeep } from 'lodash-es';
import * as apiCommon from '../common/api';
import * as api from './api';
import * as actions from './actions';
import * as type from './constants';

/* Admin sagas */
function* fetchUsers(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchUsers, action.payload);
    yield put(actions.fetchUsersSuccessed(response));
  } catch (error) {
    yield put(actions.fetchUsersFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchUsersIncludeDeleted(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchUsersIncludeDeleted, action.payload);

    yield put(actions.fetchUsersIncludeDeletedSuccessed(response));
  } catch (error) {
    yield put(actions.fetchUsersIncludeDeletedFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchUsersByFilter(action) {
  yield put(handleLoading(true));
  const { filter, pagination } = action.payload;

  try {
    const response = yield call(api.fetchUsersByFilter, filter, pagination);

    yield put(actions.fetchUsersByFilterSuccessed(response));
  } catch (error) {
    yield put(actions.fetchUsersByFilterFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchUsersByGroup(action) {
  yield put(handleLoading(true));

  const { group, options } = action.payload;
  options?.loading && options?.loading(true);
  try {
    const response = yield call(api.fetchUsersByGroup, group);

    options?.callback && options?.callback(response);

    if (options?.noRedux !== true) {
      yield put(actions.fetchUsersByGroupSuccessed(response));
    }
  } catch (error) {
    yield put(actions.fetchUsersByGroupFailed(error));
  }

  options?.loading && options?.loading(false);
  yield put(handleLoading(false));
}

function* fetchUsersByRoles(action) {
  yield put(handleLoading(true));

  try {
    let groupManagerResponse = yield call(api.fetchParamTableEntries, GROUP_MANAGER_TABLE_ID);
    groupManagerResponse = groupManagerResponse.map((item) => item.value);

    const rolesString = groupManagerResponse.find((item) => item.ROLE === action.payload).ROLE_MANAGER;
    const managerRoles = rolesString.split(',');
    let totalResponse = [];

    for (const managerRole of managerRoles) {
      const response = yield call(api.fetchUsersByRole, managerRole.trim());
      totalResponse = totalResponse.concat(response);
    }

    yield put(actions.fetchUsersByRolesSuccessed(totalResponse));
  } catch (error) {
    yield put(actions.fetchUsersByRolesFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchUserDetail(payload) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchUserDetail, payload);

    yield put(actions.fetchUserDetailSuccessed(response));
  } catch (error) {
    yield put(actions.fetchUserDetailFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchManagedMembers() {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchManagedMembers);

    yield put(actions.fetchManagedMembersSuccessed(response));
  } catch (error) {
    yield put(actions.fetchManagedMembersFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchManagedMembersIncludeDeleted() {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchManagedMembersIncludeDeleted);

    yield put(actions.fetchManagedMembersIncludeDeletedSuccessed(response));
  } catch (error) {
    yield put(actions.fetchManagedMembersIncludeDeletedFailed(error));
  }

  yield put(handleLoading(false));
}

function* changePassword(action) {
  yield put(handleLoading(true));
  const { id, pwd, pagination, filter } = action.payload;

  try {
    const response = yield call(api.changePassword, id, pwd);

    yield put(actions.changePasswordSuccessed(response));
    toast.success('Cập nhật password thành công');

    yield put(actions.fetchUsersByFilter(filter, pagination));
  } catch (error) {
    yield put(actions.changePasswordFailed(error));
    toast.error('Cập nhật password thất bại');
  }

  yield put(handleLoading(false));
}

function* activeUser(action) {
  yield put(handleLoading(true));
  const { id, pagination, filter } = action.payload;

  try {
    const response = yield call(api.activeUser, id);
    toast.success('Mở khóa tài khoản thành công');

    yield put(actions.fetchUsersByFilter(filter, pagination));
  } catch (error) {
    yield put(actions.activeUserFailed(error));
  }

  yield put(handleLoading(false));
}

function* deactiveUser(action) {
  yield put(handleLoading(true));
  const { id, pagination, filter } = action.payload;

  try {
    const response = yield call(api.deactiveUser, id);
    toast.success('Khóa tài khoản thành công');

    yield put(actions.fetchUsersByFilter(filter, pagination));
  } catch (error) {
    yield put(actions.deactiveUserFailed(error));
  }

  yield put(handleLoading(false));
}

function* deleteUser(action) {
  yield put(handleLoading(true));
  const { id, pagination, filter } = action.payload;

  try {
    const response = yield call(api.deleteUser, id);
    toast.success('Xóa tài khoản thành công');

    yield put(actions.fetchUsersByFilter(filter, pagination));
  } catch (error) {
    yield put(actions.deleteUserFailed(error));
  }

  yield put(handleLoading(false));
}

function* restoreUser(action) {
  yield put(handleLoading(true));
  const { id, pagination, filter } = action.payload;

  try {
    const response = yield call(api.activeUser, id);
    toast.success('Khôi phục tài khoản thành công');

    yield put(actions.fetchUsersByFilter(filter, pagination));
  } catch (error) {
    yield put(actions.restoreUserFailed(error));
  }

  yield put(handleLoading(false));
}

function* setLeaderUser(action) {
  yield put(handleLoading(true));
  const { userId, groupId } = action.payload;

  try {
    yield call(api.setLeaderUser, userId);

    yield put(actions.fetchGroups());
  } catch (error) {
    yield put(actions.deleteUserFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateUserPermissions(action) {
  yield put(handleLoading(true));
  const { id, data, pagination, filter } = action.payload;

  try {
    const response = yield call(api.updateUserPermissions, id, { ...data });

    yield put(actions.updateUserPermissionsSuccessed(response));
    toast.success('Cập nhật user thành công');

    yield put(actions.fetchUsersByFilter(filter, pagination));
  } catch (error) {
    yield put(actions.updateUserPermissionsFailed(error));
    toast.error('Cập nhật user thất bại');
  }

  yield put(handleLoading(false));
}

export function* watchFetchUsers() {
  yield takeEvery(type.FETCH_USERS, fetchUsers);
  yield takeEvery(type.FETCH_USERS_INCLUDE_DELETED, fetchUsersIncludeDeleted);
  yield takeEvery(type.FETCH_USERS_BY_FILTER, fetchUsersByFilter);
  yield takeEvery(type.FETCH_USERS_BY_GROUP, fetchUsersByGroup);
  yield takeEvery(type.FETCH_USERS_BY_ROLES, fetchUsersByRoles);
  yield takeEvery(type.FETCH_MANAGED_MEMBERS, fetchManagedMembers);
  yield takeEvery(type.FETCH_MANAGED_MEMBERS_INCULDE_DELETED, fetchManagedMembersIncludeDeleted);
  yield takeEvery(type.FETCH_USER_DETAIL, fetchUserDetail);
  yield takeEvery(type.CHANGE_PWD_USER, changePassword);
  yield takeEvery(type.ACTIVE_USER, activeUser);
  yield takeEvery(type.DEACTIVE_USER, deactiveUser);
  yield takeEvery(type.DELETE_USER, deleteUser);
  yield takeEvery(type.RESTORE_USER, restoreUser);
  yield takeEvery(type.UPDATE_USER_PERMISSIONS, updateUserPermissions);
  yield takeEvery(type.SET_LEADER_USER, setLeaderUser);
}

/* Role sagas */
function* fetchRoles(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchRoles, action);
    yield put(actions.fetchRolesSuccessed(response));
  } catch (error) {
    yield put(actions.fetchRolesFailed(error));
  }

  yield put(handleLoading(false));
}

function* createRole(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.createRole, action.payload);
    toast.success('Tạo mới chức vụ thành công');

    yield put(actions.fetchRoles(action.payload.role));
  } catch (error) {
    yield put(actions.createRoleFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateRole(action) {
  yield put(handleLoading(true));
  const { id, data } = action.payload;

  try {
    const response = yield call(api.updateRolePermission, { ...data });
    const role = yield call(api.updateRole, id, { ...data });
    toast.success('Cập nhật chức vụ thành công');

    yield put(actions.fetchRoles());
  } catch (error) {
    toast.error('Cập nhật chức vụ không thành công');

    yield put(actions.updateRoleFailed(error));
  }

  yield put(handleLoading(false));
}

function* deleteRole(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.deleteRole, action.payload);
    toast.success('Xóa chức vụ thành công');

    yield put(actions.fetchRoles());
  } catch (error) {
    yield put(actions.deleteRoleFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateRolesPermission(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.updateRolesPermission, action.payload);
    // toast.success('Cập nhật phân quyền chức vụ thành công');

    yield put(actions.fetchRoles());
  } catch (error) {
    yield put(actions.updateRoleFailed(error));
  }

  yield put(handleLoading(false));
}

export function* watchRoles() {
  yield takeEvery(type.FETCH_ROLES, fetchRoles);
  yield takeEvery(type.CREATE_ROLE, createRole);
  yield takeEvery(type.UPDATE_ROLE, updateRole);
  yield takeEvery(type.DELETE_ROLE, deleteRole);
  yield takeEvery(type.UPDATE_ROLES_PERMISSION, updateRolesPermission);
}

/* Group sagas */
function* fetchGroupTypes(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchGroupTypes, action.payload);
    yield put(actions.fetchGroupTypesSuccessed(response));
  } catch (error) {
    yield put(actions.fetchGroupsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchGroupsByType(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchGroupsByType, action.payload);
    yield put(actions.fetchGroupsByTypeSuccessed(response));
  } catch (error) {
    yield put(actions.fetchGroupsByTypeFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchGroups(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchGroups, action);
    yield put(actions.fetchGroupsSuccessed(response));
  } catch (error) {
    yield put(actions.fetchGroupsFailed(error));
  }

  yield put(handleLoading(false));
}

function* createGroup(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.createGroup, action.payload);
    toast.success('Tạo mới nhóm thành công');

    yield put(actions.fetchGroups());
  } catch (error) {
    toast.error(error.message);

    yield put(actions.createGroupFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateGroup(action) {
  yield put(handleLoading(true));
  const { id, data } = action.payload;

  try {
    const response = yield call(api.updateGroup, id, { ...data });

    yield put(actions.updateGroupSuccessed(response));
    toast.success('Cập nhật nhóm thành công');

    yield put(actions.fetchGroups());
  } catch (error) {
    yield put(actions.updateGroupFailed(error));
  }

  yield put(handleLoading(false));
}

function* deleteGroup(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.deleteGroup, action.payload);
    toast.success('Xóa nhóm thành công');

    yield put(actions.fetchGroups());
  } catch (error) {
    yield put(actions.deleteGroupFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchGroupsWithUsers(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchGroupsWithUsers, action);
    yield put(actions.fetchGroupsWithUsersSuccessed(response));
  } catch (error) {
    yield put(actions.fetchGroupsWithUsersFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchGroupTree(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchGroupTree, action);
    yield put(actions.fetchGroupsTreeSuccessed(response));
  } catch (error) {
    yield put(actions.fetchGroupsTreeFailed(error));
  }

  yield put(handleLoading(false));
}

export function* watchGroups() {
  yield takeEvery(type.FETCH_GROUP_TYPES, fetchGroupTypes);
  yield takeEvery(type.FETCH_GROUPS_BY_TYPE, fetchGroupsByType);
  yield takeEvery(type.FETCH_GROUPS, fetchGroups);
  yield takeEvery(type.CREATE_GROUP, createGroup);
  yield takeEvery(type.UPDATE_GROUP, updateGroup);
  yield takeEvery(type.DELETE_GROUP, deleteGroup);
  yield takeEvery(type.FETCH_GROUPS_WITH_USERS, fetchGroupsWithUsers);
  yield takeEvery(type.FETCH_GROUP_TREE, fetchGroupTree);
}

/* Resource sagas */
function* fetchClientRoles(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchClientRoles, action);
    yield put(actions.fetchClientRolesSuccessed(response));
  } catch (error) {
    yield put(actions.fetchClientRolesFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateClientRole(action) {
  yield put(actions.updateClientRoleSuccessed(action.payload));
}

export function* watchResources() {
  yield takeEvery(type.FETCH_CLIENT_ROLES, fetchClientRoles);
  yield takeEvery(type.UPDATE_CLIENT_ROLE, updateClientRole);
}

/* Param table */
function* fetchParamTables() {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchParamTables);

    yield put(actions.fetchParamTablesSuccessed(response));
  } catch (error) {
    yield put(actions.fetchParamTablesFailed(error));
    toast.error('Lấy danh sách bảng thất bại');
  }

  yield put(handleLoading(false));
}

function* fetchParamTableDetail(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchParamTableDetail, action.payload);

    yield put(actions.fetchParamTableDetailSuccessed(response));
  } catch (error) {
    yield put(actions.fetchParamTableDetailFailed(error));
    toast.error('Lấy dữ liệu bảng thất bại');
  }

  yield put(handleLoading(false));
}

function* deleteParamTable(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.deleteParamTable, action.payload);

    yield put(actions.deleteParamTableSuccessed(response));
    toast.success('Xóa bảng thành công');

    yield put(actions.fetchParamTables());
  } catch (error) {
    yield put(actions.deleteParamTableFailed(error));
    toast.error('Lấy dữ liệu bảng thất bại');
  }

  yield put(handleLoading(false));
}

function* createParamTable(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.createParamTable, action.payload);

    yield put(actions.createParamTableSuccessed(response));
    toast.success('Tạo mới bảng thành công');

    yield put(actions.fetchParamTables());
  } catch (error) {
    yield put(actions.createParamTableFailed(error));
    toast.error('Tạo mới bảng thất bại');
  }

  yield put(handleLoading(false));
}

function* updateParamTable(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.updateParamTable, action.payload);

    yield put(actions.updateParamTableSuccessed(response));
    toast.success('Cập nhật bảng thành công');

    yield put(actions.fetchParamTables());
  } catch (error) {
    yield put(actions.updateParamTableFailed(error));
    toast.error('Cập nhật bảng thất bại');
  }

  yield put(handleLoading(false));
}

function* fetchParamTableEntries(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchParamTableEntries, action.payload);

    yield put(actions.fetchParamTableEntriesSuccessed(action.payload, response));
    // yield put(actions.fetchParamTableEntriesSuccessed(action.payload, []));
  } catch (error) {
    yield put(actions.fetchParamTableEntriesFailed(error));
    toast.error('Lấy dữ liệu bảng thất bại');
  }

  yield put(handleLoading(false));
}

function* createParamTableEntries(action) {
  yield put(handleLoading(true));

  try {
    const { table, data } = action.payload;
    const response = yield call(api.createParamTableEntries, table, data);

    yield put(actions.createParamTableEntriesSuccessed(table));
    toast.success('Tạo mới dữ liệu bảng thành công');

    yield put(actions.fetchParamTableEntries(table));
  } catch (error) {
    yield put(actions.createParamTableFailed(error));
    toast.error('Tạo mới dữ liệu bảng thất bại');
  }

  yield put(handleLoading(false));
}

function* updateParamTableEntries(action) {
  yield put(handleLoading(true));

  try {
    const { table, entry, data } = action.payload;
    const response = yield call(api.updateParamTableEntries, table, entry, data);

    yield put(actions.updateParamTableEntriesSuccessed(table));
    toast.success('Cập nhật dữ liệu bảng thành công');

    yield put(actions.fetchParamTableEntries(table));
  } catch (error) {
    yield put(actions.updateParamTableEntriesFailed(error));
    toast.error('Cập nhật dữ liệu bảng thất bại');
  }

  yield put(handleLoading(false));
}

function* deleteParamTableEntries(action) {
  yield put(handleLoading(true));

  try {
    const { table, entry } = action.payload;
    const response = yield call(api.deleteParamTableEntries, table, entry);

    yield put(actions.deleteParamTableEntriesSuccessed(table));
    toast.success('Xóa dữ liệu bảng thành công');

    yield put(actions.fetchParamTableEntries(table));
  } catch (error) {
    yield put(actions.createParamTableFailed(error));
    toast.error('Xóa dữ liệu bảng thất bại');
  }

  yield put(handleLoading(false));
}

export function* watchParamTable() {
  yield takeEvery(type.FETCH_PARAM_TABLES, fetchParamTables);
  yield takeEvery(type.FETCH_PARAM_TABLE_DETAIL, fetchParamTableDetail);
  yield takeEvery(type.CREATE_PARAM_TABLE, createParamTable);
  yield takeEvery(type.UPDATE_PARAM_TABLE, updateParamTable);
  yield takeEvery(type.DELETE_PARAM_TABLE, deleteParamTable);
  yield takeEvery(type.FETCH_PARAM_TABLE_ENTRIES, fetchParamTableEntries);
  yield takeEvery(type.CREATE_PARAM_TABLE_ENTRIES, createParamTableEntries);
  yield takeEvery(type.UPDATE_PARAM_TABLE_ENTRIES, updateParamTableEntries);
  yield takeEvery(type.DELETE_PARAM_TABLE_ENTRIES, deleteParamTableEntries);
}

function* fetchNotificationTemplate(action) {
  yield put(handleLoading(true));

  const { params } = action.payload;

  try {
    const res = yield call(api.fetchNotificationTemplate, params);
    yield put(actions.fetchNotificationTemplateSuccessed(res));
  } catch (error) {
    yield put(actions.fetchNotificationTemplateFailed(error));
  }

  yield put(handleLoading(false));
}

function* createTemplate(action) {
  yield put(handleLoading(true));
  const { data, setState = () => {} } = action.payload;

  try {
    const res = yield call(api.createTemplate, data);

    yield put(actions.createTemplateSuccessed(res));
    toast.success('Tạo mẫu thông báo thành công.');
    setState(false);
  } catch (error) {
    yield put(actions.createTemplateFailed(error));
    toast.error('Tạo mẫu thông báo thất bại.');
    // setState(false);
  }

  yield put(handleLoading(false));
}

function* updateTemplate(action) {
  yield put(handleLoading(true));
  const { id, data, setState = () => {} } = action.payload;

  try {
    const res = yield call(api.updateTemplate, id, data);

    yield put(actions.updateTemplateSuccessed(res));
    toast.success('Cập nhật mẫu thông báo thành công.');
    setState(false);
  } catch (error) {
    yield put(actions.updateTemplateFailed(error));
    toast.error('Cập nhật mẫu thông báo thất bại.');
    // setState(true);
  }

  yield put(handleLoading(false));
}

function* deleteNotificationTemplate(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.deleteNotificationTemplate, action.payload);
    yield put(actions.deleteNotificationTemplateSuccessed, res);

    toast.success('Xóa mẫu thành công');
  } catch (error) {
    yield put(actions.deleteNotificationTemplateFailed, error);
    toast.error('Xóa mẫu thất bại');
  }

  yield put(handleLoading(false));
}

export function* watchNotification() {
  yield takeEvery(type.UPDATE_TEMPLATE, updateTemplate);
  yield takeEvery(type.FETCH_NOTIFICATION_TEMPLATE, fetchNotificationTemplate);
  yield takeEvery(type.CREATE_TEMPLATE, createTemplate);
  yield takeEvery(type.DELETE_TEMPLATE, deleteNotificationTemplate);
}

function* fetchActivityTypes(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchActivityTypes);
    yield put(actions.fetchActivityTypesSuccessed(res));
  } catch (error) {
    yield put(actions.fetchActivityTypesFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchActivityLogs(action) {
  yield put(handleLoading(true));

  const { params } = action.payload;

  try {
    const res = yield call(api.fetchActivityLogs, params);
    yield put(actions.fetchActivityLogsSuccessed(res));
  } catch (error) {
    yield put(actions.fetchActivityLogsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchActivityLogsByFilter(action) {
  yield put(handleLoading(true));

  const { data, params } = action.payload;

  try {
    const res = yield call(api.fetchActivityLogsByFilter, data, params);
    yield put(actions.fetchActivityLogsByFilterSuccessed(res));
  } catch (error) {
    yield put(actions.fetchActivityLogsByFilterFailed(error));
  }

  yield put(handleLoading(false));
}

export function* watchActivityLog() {
  yield takeEvery(type.FETCH_ACTIVITY_TYPE, fetchActivityTypes);
  yield takeEvery(type.FETCH_ACTIVITY_LOGS, fetchActivityLogs);
  yield takeEvery(type.FETCH_ACTIVITY_LOGS_BY_FILTER, fetchActivityLogsByFilter);
}

function* fetchCurrentLeadFlow(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchCurrentFlow, 'lead');
    yield put(actions.fetchCurrentLeadFlowSuccessed(res));
  } catch (error) {
    yield put(actions.fetchCurrentLeadFlowFailed(error));
  }

  yield put(handleLoading(false));
}

function* createLeadFlow(action) {
  yield put(handleLoading(true));

  const { jsonPayload, xmlFile, isDeploy, setStatus = () => {} } = action.payload;

  try {
    const res = yield call(api.createFlow, 'lead', jsonPayload);

    if (isDeploy) {
      yield call(api.deployWorkflow, xmlFile);
    }

    yield put(actions.createLeadFlowSuccessed(res));
    toast.success('Lưu luồng thành công.');
    setStatus(true);
  } catch (error) {
    yield put(actions.createLeadFlowFailed(error));
    toast.error('Lưu luồng thất bại.');
    setStatus(false);
  }

  yield put(handleLoading(false));
}

function* fetchWorkflows(action) {
  const { data, options } = action.payload;
  const { loading, onSuccess, onFailure } = options;

  loading && loading(true);
  try {
    const res = yield call(api.fetchWorkflows, data);
    yield put(actions.fetchWorkflowsSuccessed(res));
    onSuccess && onSuccess(res);
  } catch (error) {
    yield put(actions.fetchWorkflowsFailed(error));
    onFailure && onFailure(error);
  }

  loading && loading(false);
}

function* fetchAllWorkflows(action) {
  const { options } = action.payload;
  const { loading, onSuccess, onFailure } = options;

  loading && loading(true);
  try {
    const res = yield call(api.fetchWorkflows, {});
    const workflows = isAvailableArray(res?.content) ? res.content : [];
    yield put(actions.fetchAllWorkflowsSuccessed(workflows));
    onSuccess && onSuccess(res);
  } catch (error) {
    onFailure && onFailure(error);
  }

  loading && loading(false);
}

function* fetchWorkflow(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchWorkflow, action.payload);
    yield put(actions.fetchWorkflowSuccessed(res));
  } catch (error) {
    yield put(actions.fetchWorkflowFailed(error));
  }

  yield put(handleLoading(false));
}

function* deployWorkflow(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.deployWorkflow, action.payload);
    yield put(actions.deployWorkflowSuccessed(res));
  } catch (error) {
    yield put(actions.deployWorkflowFailed(error));
  }

  yield put(handleLoading(false));
}

function* activateWorkflow(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.activateWorkflow, action.payload);
    yield put(actions.activateWorkflowSuccessed(res));

    yield put(actions.fetchWorkflows());
  } catch (error) {
    yield put(actions.activateWorkflowFailed(error));
  }

  yield put(handleLoading(false));
}

function* deactivateWorkflow(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.deactivateWorkflow, action.payload);
    yield put(actions.deactivateWorkflowSuccessed(res));

    yield put(actions.fetchWorkflows());
  } catch (error) {
    yield put(actions.deactivateWorkflowFailed(error));
  }

  yield put(handleLoading(false));
}

export function* watchWorkflow() {
  yield takeEvery(type.FETCH_CURRENT_LEAD_FLOW, fetchCurrentLeadFlow);
  yield takeEvery(type.CREATE_LEAD_FLOW, createLeadFlow);
  yield takeEvery(type.FETCH_WORKFLOWS, fetchWorkflows);
  yield takeEvery(type.FETCH_ALL_WORKFLOWS, fetchAllWorkflows);
  yield takeEvery(type.FETCH_WORKFLOW, fetchWorkflow);
  yield takeEvery(type.DEPLOY_WORKFLOW, deployWorkflow);
  yield takeEvery(type.ACTIVATE_WORKFLOW, activateWorkflow);
  yield takeEvery(type.DEACTIVATE_WORKFLOW, deactivateWorkflow);
}

function* fetchDayOffEvent(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchDayOffEvent, action.payload);
    yield put(actions.fetchDayOffEventSuccessed(res));
  } catch (error) {
    yield put(actions.fetchDayOffEventFailed(error));
  }

  yield put(handleLoading(false));
}

function* createDayOffEvent(action) {
  yield put(handleLoading(true));
  const { event, timeRange } = action.payload;

  try {
    const res = yield call(api.createDayOffEvent, event);
    yield put(actions.createDayOffEventSuccessed(res));

    yield put(actions.fetchDayOffEvent(timeRange));
  } catch (error) {
    yield put(actions.createDayOffEventFailed(error));
  }

  yield put(handleLoading(false));
}

function* deleteDayOffEvent(action) {
  yield put(handleLoading(true));
  const { id, timeRange } = action.payload;

  try {
    const res = yield call(api.deleteDayOffEvent, id);
    yield put(actions.deleteDayOffEventSuccessed(res));

    yield put(actions.fetchDayOffEvent(timeRange));
  } catch (error) {
    yield put(actions.deleteDayOffEventFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchCalendarSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchCalendarSetting, action.payload);
    yield put(actions.fetchCalendarSettingSuccessed(res));
  } catch (error) {
    yield put(actions.fetchCalendarSettingFailed(error));
  }

  yield put(handleLoading(false));
}

function* setCalendarSetting(action) {
  yield put(handleLoading(true));
  const { key, value } = action.payload;

  try {
    const res = yield call(api.setCalendarSetting, key, value);
    yield put(actions.setCalendarSettingSuccessed(res));
  } catch (error) {
    yield put(actions.setCalendarSettingFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchKpiSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchAllKpiSetting, action.payload);
    yield put(actions.fetchAllKpiSuccessed(res));
  } catch (error) {
    yield put(actions.fetchAllKpiFailed(error));
  }

  yield put(handleLoading(false));
}

function* setKpiSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.setKpiSetting, action.payload);
    yield put(actions.fetchAllKpi());
    yield put(actions.setKpiSuccessed(res));
  } catch (error) {
    yield put(actions.setKpiFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchBucketSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchBucketSetting, action.payload);
    yield put(actions.fetchAllBucketSuccessed(res));
  } catch (error) {
    yield put(actions.fetchAllBucketFailed(error));
  }

  yield put(handleLoading(false));
}

function* setBucketSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.setBucketSetting, action.payload);
    yield put(actions.fetchAllBucket());
    yield put(actions.setBucketSuccessed(res));
  } catch (error) {
    yield put(actions.setBucketFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchActivityCodeSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchActivityCodeSetting, action.payload);
    yield put(actions.fetchAllActivityCodeSuccessed(res));
  } catch (error) {
    yield put(actions.fetchAllActivityCodeFailed(error));
  }

  yield put(handleLoading(false));
}

function* setActivityCodeSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.setActivityCodeSetting, action.payload);
    yield put(actions.fetchAllActivityCode());
    yield put(actions.setActivityCodeSuccessed(res));
  } catch (error) {
    yield put(actions.setActivityCodeFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchActivityCodeLeadSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchActivityCodeLeadSetting, action.payload);
    yield put(actions.fetchAllLeadActivityCodeSuccessed(res));
  } catch (error) {
    yield put(actions.fetchAllLeadActivityCodeFailed(error));
  }

  yield put(handleLoading(false));
}

function* setActivityCodeLeadSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.setActivityCodeLeadSetting, action.payload);
    yield put(actions.fetchAllLeadActivityCode());
    yield put(actions.setLeadActivityCodeSuccessed(res));
  } catch (error) {
    yield put(actions.setLeadActivityCodeFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchRelationshipSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchRelationshipSetting, action.payload);
    yield put(actions.fetchAllRelationshipSuccessed(res));
  } catch (error) {
    yield put(actions.fetchAllRelationshipFailed(error));
  }

  yield put(handleLoading(false));
}

function* setRelationshipSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.setRelationshipSetting, action.payload);
    yield put(actions.fetchAllRelationship());
    yield put(actions.setRelationshipSuccessed(res));
  } catch (error) {
    yield put(actions.setRelationshipFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchCheckInLocationSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchCheckInLocationSetting, action.payload);
    yield put(actions.fetchAllCheckInLocationSuccessed(res));
  } catch (error) {
    yield put(actions.fetchAllCheckInLocationFailed(error));
  }

  yield put(handleLoading(false));
}

function* setCheckInLocationSetting(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.setCheckInLocationSetting, action.payload);
    yield put(actions.fetchAllCheckInLocation());
    yield put(actions.setCheckInLocationSuccessed(res));
  } catch (error) {
    yield put(actions.setCheckInLocationFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchOrgInfo(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchOrgInfo, action.payload);
    yield put(actions.fetchOrgInfoSuccessed(res));
  } catch (error) {
    yield put(actions.fetchOrgInfoFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchOrgAvatar(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchOrgAvatar, action.payload);
    yield put(actions.fetchOrgAvatarSuccessed(res));
  } catch (error) {
    yield put(actions.fetchOrgAvatarFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateOrgInfo(action) {
  const { data, file } = action.payload;
  yield put(handleLoading(true));

  try {
    const res = yield call(api.updateOrgInfo, data);

    if (file) {
      yield call(api.updateOrgAvater, file);
    }

    yield put(actions.updateOrgInfoSuccessed(res));
    toast.success('Cập nhật thành công');
  } catch (error) {
    yield put(actions.updateOrgInfoFailed(error));
    toast.error('Cập nhật thất bại');
  }

  yield put(handleLoading(false));
}

// TODO: remove this
// function* fetchAllGroupLocal(action) {
//   yield put(handleLoading(true));

//   try {
//     const res = yield call(api.fetchAllGroupLocal, action.payload);

//     let provinceIds = [];
//     let districtIds = [];

//     res.forEach((e) => {
//       provinceIds.push(e.provinceId);
//       districtIds.push(e.districtId);
//     });

//     // get unique
//     provinceIds = [...new Set(provinceIds)];
//     districtIds = [...new Set(districtIds)];

//     const districtRes = yield all(provinceIds.map((e) => call(fetchDistricts, e)));
//     const wardRes = yield all(districtIds.map((e) => call(fetchWards, e)));
//     const provinces = yield call(fetchProvinces);

//     let treeLocation = [...new Set(res.map((e) => e.provinceId))].map((e) => ({
//       name: provinces.find((province) => province.id === e).name,
//       id: e,
//       uniqueId: `province-${e}`,
//       childGroups: [],
//     }));

//     const districts = uniqBy(
//       clone(res).map((e) => ({
//         name: districtRes.flat().find((district) => district.id === e.districtId).name,
//         id: e.districtId,
//         uniqueId: `district-${e.districtId}`,
//         parent: e.provinceId,
//         provinceId: e.provinceId,
//       })),
//       'id'
//     );

//     const ward = cloneDeep(res).map((e) => ({
//       name: wardRes.flat().find((district) => district.id === e.subDistrictId)?.name,
//       id: e.subDistrictId,
//       uniqueId: `ward-${e.subDistrictId}`,
//       parent: e.districtId,
//     }));

//     treeLocation = treeLocation.map((e) => {
//       districts.forEach((k) => {
//         if (e.id === k.parent) {
//           e.childGroups.push({
//             id: k.id,
//             name: k.name,
//             provinceId: k.provinceId,
//             uniqueId: k.uniqueId,
//             childGroups: [],
//           });
//         }
//       });
//       return e;
//     });

//     treeLocation = treeLocation.map((e) => {
//       e.childGroups.map((t) => {
//         ward.forEach((k) => {
//           if (t.id === k.parent) {
//             t.childGroups.push({ id: k.id, name: k.name, uniqueId: k.uniqueId });
//           }
//         });
//         return t;
//       });
//       return e;
//     });

//     yield put(actions.fetchAllGroupLocalSuccessed({ groupLocals: treeLocation, rawGroupLocals: res }));
//   } catch (error) {
//     yield put(actions.fetchAllGroupLocalFailed(error));
//   }

//   yield put(handleLoading(false));
// }

// function* fetchAllLocation(action) {
//   yield put(handleLoading(true));

//   try {
//     let data = [];
//     const res = yield call(apiCommon.fetchRegions);
//     data = res.map((item) => ({ ...item, uniqueId: `region-${item.id}`, childGroups: [] }));
//     yield put(actions.fetchAllLocationSuccessed(data));
//   } catch (error) {
//     yield put(actions.fetchAllLocationFailed(error));
//   }

//   yield put(handleLoading(false));
// }

function* fetchNestedDistricts(action) {
  yield put(handleLoading(true));
  // const { districtId, callback = () => { } } = action.payload;

  try {
    const response = yield call(apiCommon.fetchDistricts, action.payload);
    const result = addressSort(response);
    yield put(actions.fetchNestedDistrictSuccessed({ result, provinceId: action.payload }));
    // callback(result);
  } catch (error) {
    yield put(actions.fetchNestedDistrictFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchNestedWard(action) {
  yield put(handleLoading(true));
  // const { districtId, callback = () => { } } = action.payload;
  try {
    const response = yield call(apiCommon.fetchWards, action.payload);
    const result = addressSort(response);
    yield put(actions.fetchNestedWardSuccessed({ result, districtId: action.payload }));
    // callback(result);
  } catch (error) {
    yield put(actions.fetchNestedWardFailed(error));
  }

  yield put(handleLoading(false));
}

// function* updateLocation(action) {
//   yield put(handleLoading(true));
//   // const { districtId, callback = () => { } } = action.payload;
//   try {
//     yield call(api.updateLocation, action.payload);
//     yield put(actions.fetchAllGroupLocal(action.payload.id));
//     // callback(result);
//   } catch (error) {
//     yield put(actions.updateLocationFailed(error));
//   }

//   yield put(handleLoading(false));
// }

// function* fetchRecursionProvince(action) {
//   yield put(handleLoading(true));
//   const { regionId, cb } = action.payload;

//   try {
//     let response = yield call(apiCommon.fetchProvincesByRegion, regionId);

//     response = response.map((e) => ({ ...e, uniqueId: `province-${e.id}` }));

//     let districtRes = yield all(response.map((e) => call(apiCommon.fetchDistricts, e.id)));
//     districtRes = districtRes.map((e, i) =>
//       e.map((t) => ({
//         ...t,
//         provinceId: response[i].id,
//         uniqueId: `district-${t.id}`,
//         childGroups: [],
//       }))
//     );

//     const districtsFlat = districtRes.flat();

//     let wardsRes = yield all(districtsFlat.map((e) => call(apiCommon.fetchWards, e.id)));
//     wardsRes = wardsRes.map((e, i) =>
//       e.map((t) => ({
//         ...t,
//         districtId: districtsFlat[i].id,
//         uniqueId: `ward-${t.id}`,
//         provinceId: districtsFlat[i].provinceId,
//       }))
//     );

//     districtsFlat.forEach((e, i) => {
//       e.childGroups = wardsRes[i];
//     });

//     districtRes = districtRes.map((e, i) => e.map((t) => districtsFlat.find((k) => k.id === t.id) ?? t));

//     response.forEach((e, i) => {
//       e.childGroups = districtRes[i];
//     });

//     const checkedIds = [];
//     const recursiveList = (arr) => {
//       arr.forEach((e) => {
//         if (e.districtId && e.provinceId) {
//           checkedIds.push(e.uniqueId);
//         }
//         if (e.childGroups?.length > 0) {
//           recursiveList(e.childGroups);
//         }
//       });
//     };
//     recursiveList(response);

//     cb && cb(checkedIds);

//     yield put(actions.fetchRecursionProvinceSuccessed({ regionId, data: response }));

//     // const result = addressSort(response);
//     // yield put(actions.fetchNestedProvinceSuccessed({result, regionId: action.payload}));
//     // callback(result);
//   } catch (error) {
//     // yield put(actions.fetchNestedProvinceFailed(error));
//   }

//   yield put(handleLoading(false));
// }

function* updateUserStatus(action) {
  // yield put(handleLoading(true));
  // const { districtId, callback = () => { } } = action.payload;
  try {
    const response = yield call(api.updateUserStatus, action.payload);
    // localStorage.setItem("current_state", action.payload)
    // callback(result);
    yield put(actions.updateUserStatusSuccessed(response));
    // toast.success("Chuyển trạng thái thành công")
  } catch (error) {
    yield put(actions.updateLocationFailed(error));
    // toast.error("Chuyển trạng thái thất bại")
  }

  // yield put(handleLoading(false));
}

function* getUserStatus(action) {
  try {
    const response = yield call(api.getUserStatus);
    yield put(actions.getUserStatusSuccessed(response));
  } catch (error) {
    yield put(actions.updateLocationFailed(error));
  }

  // yield put(handleLoading(false));
}

function* getUsersByRole(action) {
  // yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchUsersByRole, action.payload);
    yield put(actions.getUserByRoleSuccessed(response));
  } catch (error) {
    yield put(actions.getUserByRoleFailed(error));
  }

  // yield put(handleLoading(false));
}

// TT
function* getAllTypeFunnel(action) {
  try {
    const response = yield call(api.fetchAllTypeFunnel, action.payload);
    if (response) {
      const responseConvert = response.map((item) => {
        return {
          ...item,
          label: item?.name || '',
          value: item?.id || '',
        };
      });

      yield put(actions.getAllTypeFunnelSuccessed(responseConvert));
    }
  } catch (error) {
    yield put(actions.getAllTypeFunnelFailed(error));
  }
}

function* updateLeader(action) {
  const { accountId, data } = action.payload;
  try {
    yield call(api.setLeaderUser, accountId);
    yield put(actions.updateLeaderSuccessed(data));
    toast.success('Cập nhật cán bộ quản lý thành công');
  } catch (error) {
    yield put(actions.updateLeaderFailed(error));
    toast.error('Cập nhật cán bộ quản lý thất bại');
  }
}

export function* watchSetting() {
  yield takeEvery(type.FETCH_DAY_OFF_EVENT, fetchDayOffEvent);
  yield takeEvery(type.CREATE_DAY_OFF_EVENT, createDayOffEvent);
  yield takeEvery(type.DELETE_DAY_OFF_EVENT, deleteDayOffEvent);
  yield takeEvery(type.FETCH_CALENDAR_SETTING, fetchCalendarSetting);
  yield takeEvery(type.SET_CALENDAR_SETTING, setCalendarSetting);
  yield takeEvery(type.FETCH_ALL_KPI, fetchKpiSetting);
  yield takeEvery(type.SET_KPI, setKpiSetting);
  yield takeEvery(type.FETCH_ALL_BUCKET, fetchBucketSetting);
  yield takeEvery(type.SET_BUCKET, setBucketSetting);
  yield takeEvery(type.FETCH_ALL_ACTIVITY_CODE, fetchActivityCodeSetting);
  yield takeEvery(type.SET_ACTIVITY_CODE, setActivityCodeSetting);
  yield takeEvery(type.FETCH_ALL_LEAD_ACTIVITY_CODE, fetchActivityCodeLeadSetting);
  yield takeEvery(type.SET_LEAD_ACTIVITY_CODE, setActivityCodeLeadSetting);
  yield takeEvery(type.FETCH_ALL_RELATIONSHIP, fetchRelationshipSetting);
  yield takeEvery(type.SET_RELATIONSHIP, setRelationshipSetting);
  yield takeEvery(type.FETCH_ALL_CHECK_IN_LOCATION, fetchCheckInLocationSetting);
  yield takeEvery(type.SET_CHECK_IN_LOCATION, setCheckInLocationSetting);
  yield takeEvery(type.FETCH_ORG_INFO, fetchOrgInfo);
  yield takeEvery(type.FETCH_ORG_AVATAR, fetchOrgAvatar);
  yield takeEvery(type.UPDATE_ORG_INFO, updateOrgInfo);
  yield takeEvery(type.FETCH_NESTED_DISTRICT, fetchNestedDistricts);
  yield takeEvery(type.FETCH_NESTED_WARD, fetchNestedWard);
  // yield takeEvery(type.UPDATE_LOCATION, updateLocation);
  yield takeEvery(type.UPDATE_USER_STATUS, updateUserStatus);
  yield takeEvery(type.GET_USER_STATUS, getUserStatus);
  yield takeEvery(type.GET_USERS_BY_ROLES, getUsersByRole);
  yield takeEvery(type.UPDATE_LEADER, updateLeader);
  yield takeEvery(type.GET_ALL_TYPE_FUNNEL, getAllTypeFunnel);
}

function* adminSaga() {
  yield all([
    fork(watchFetchUsers),
    fork(watchRoles),
    fork(watchGroups),
    fork(watchResources),
    fork(watchParamTable),
    fork(watchNotification),
    fork(watchActivityLog),
    fork(watchWorkflow),
    fork(watchSetting),
  ]);
}

export default adminSaga;
