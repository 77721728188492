import { SettingsOutlined } from '@mui/icons-material';
import { CALENDAR_SETTING, CALENDAR_SVC, KPI_VIEW, LEAD_SVC, LOGGER_SVC, LOGGER_VIEW_ALL } from 'constants/roles';
import { NxRoute } from './types';

const Setting = lazy(() => import('pages/settings/manage'));
const CompanyInfoSetting = lazy(() => import('pages/settings/company-info'));
const BasicDataSetting = lazy(() => import('pages/settings/basic-data'));
const FlowManagementSetting = lazy(() => import('pages/settings/flow-management'));
const LeadManagementSetting = lazy(() => import('pages/settings/lead-management'));
const SystemManagementSetting = lazy(() => import('pages/settings/system-management'));
const DefaultSettings = lazy(() => import('pages/settings/default-setting'));
const ActivityHistory = lazy(() => import('pages/admin/activity-history/activity-history'));

const settingRoutes: NxRoute = {
  path: '/settings',
  name: 'menuParent:settings',
  icon: SettingsOutlined,
  Component: Setting,
  children: [
    {
      path: '/settings/company-info',
      name: 'menu:settings:company-info',
      Component: CompanyInfoSetting,
    },
    {
      path: '/settings/basicdata/:tabName',
      name: 'menu:settings:basicdata',
      Component: BasicDataSetting,
    },
    {
      path: '/settings/flowmanagement/:tabName',
      name: 'menu:settings:flowmanagement',
      Component: FlowManagementSetting,
    },
    {
      path: '/settings/leadmanagement/kpi-settings',
      name: 'menu:settings:leadmanagement',
      Component: LeadManagementSetting,
      resource: LEAD_SVC,
      roles: [KPI_VIEW],
    },
    {
      path: '/settings/leadmanagement/:tabName',
      name: 'menu:settings:leadmanagement',
      Component: LeadManagementSetting,
      children: [
        {
          path: '/settings/leadmanagement/:tabName/:param',
          name: 'menu:settings:leadmanagement',
          Component: LeadManagementSetting,
        },
      ],
    },
    {
      path: '/settings/systemmanagement/history',
      name: 'menu:settings:systemmanagement',
      Component: ActivityHistory,
      resource: LOGGER_SVC,
      roles: [LOGGER_VIEW_ALL],
    },
    {
      path: '/settings/systemmanagement/calendar',
      name: 'menu:settings:systemmanagement',
      Component: SystemManagementSetting,
      resource: CALENDAR_SVC,
      roles: [CALENDAR_SETTING],
    },
    {
      path: '/settings/systemmanagement/:tabName',
      name: 'menu:settings:systemmanagement',
      Component: SystemManagementSetting,
    },
    {
      path: '/settings/default-setting',
      name: 'menu:settings:company-info',
      Component: DefaultSettings,
    },
  ],
};

export default settingRoutes;
