import { ComponentType, ReactElement } from 'react';
import { hasRolesOr, isUserAuthenticated } from 'helpers/authUtils';
import useRouteConfig from './hooks/useRouters';

/**
 * A custom private route component that wraps the Route component and adds
 * authentication and role-based access control.
 * If the user is not authenticated, redirects to the login page.
 * If the user doesn't have required roles, redirects to an error page.
 */

interface PrivateRouteProps {
  Component: ComponentType;
  resource?: string;
  roles?: string[];
  isAllowed?: boolean;
}

const Error403 = lazy(() => import('pages/other/Error403'));

function PrivateRoute({ Component, resource = '', roles = [], isAllowed = true }: PrivateRouteProps): ReactElement {
  const props = useParams();
  const location = useLocation();
  const { hasMenu } = useRouteConfig();

  if (!isUserAuthenticated()) {
    return <Navigate to="/account/login" state={{ from: location }} />;
  }

  if (!hasMenu()) {
    return <Navigate to="/landing" state={{ from: location }} />;
  }

  if (!isAllowed || !hasRolesOr(resource, roles)) {
    return <Error403 />;
  }

  return <Component {...props} />;
}

export default PrivateRoute;
