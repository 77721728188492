import { activityCodeApi } from './activity-code/apiSlice';
import { adminApi } from './admin/apiSlice';
import { commonApi } from './common/apiSlice';
import { customFieldApi } from './custom-field/apiSlice';
import { datasourceApi } from './datasource/apiSlice';
import { demandCustomFieldApi } from './demand-custom-field/apiSlice';
import { documentApi } from './document/apiSlice';
import { leadApi } from './lead/apiSlice';
import { mappingFieldApi } from './mapping/apiSlice';
import { partnerApi } from './partner/apiSlice';
import { productApi } from './product/apiSlice';
import { reportApi } from './report/apiSlice';
import { settingApi } from './settings/apiSlice';

const appMiddlewares = [
  activityCodeApi.middleware,
  customFieldApi.middleware,
  demandCustomFieldApi.middleware,
  documentApi.middleware,
  leadApi.middleware,
  productApi.middleware,
  reportApi.middleware,
  settingApi.middleware,
  commonApi.middleware,
  partnerApi.middleware,
  datasourceApi.middleware,
  mappingFieldApi.middleware,
  adminApi.middleware,
];

export default appMiddlewares;
