/* CUSTOMERs */
export const FETCH_GROUPS = 'customers/FETCH_GROUPS';
export const FETCH_GROUPS_SUCCESSED = 'customers/FETCH_GROUPS_SUCCESSED';
export const FETCH_GROUPS_FAILED = 'customers/FETCH_GROUPS_FAILED';
export const CLEAR_GROUPS = 'customers/CLEAR_GROUPS';

export const FETCH_GROUP = 'customers/FETCH_GROUP';
export const FETCH_GROUP_SUCCESSED = 'customers/FETCH_GROUP_SUCCESSED';
export const FETCH_GROUP_FAILED = 'customers/FETCH_GROUP_FAILED';

export const CREATE_GROUP = 'customers/CREATE_GROUP';
export const CREATE_GROUP_SUCCESSED = 'customers/CREATE_GROUP_SUCCESSED';
export const CREATE_GROUP_FAILED = 'customers/CREATE_GROUP_FAILED';

export const UPDATE_GROUP = 'customers/UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESSED = 'customers/UPDATE_GROUP_SUCCESSED';
export const UPDATE_GROUP_FAILED = 'customers/UPDATE_GROUP_FAILED';

export const DELETE_GROUP = 'customers/DELETE_GROUP';
export const DELETE_GROUP_SUCCESSED = 'customers/DELETE_GROUP_SUCCESSED';
export const DELETE_GROUP_FAILED = 'customers/DELETE_GROUP_FAILED';

export const FETCH_CUSTOMERS = 'customers/FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESSED = 'customers/FETCH_CUSTOMERS_SUCCESSED';
export const FETCH_CUSTOMERS_FAILED = 'customers/FETCH_CUSTOMERS_FAILED';

export const FETCH_CUSTOMER = 'customers/FETCH_CUSTOMER';
export const FETCH_CUSTOMER_SUCCESSED = 'customers/FETCH_CUSTOMER_SUCCESSED';
export const FETCH_CUSTOMER_FAILED = 'customers/FETCH_CUSTOMER_FAILED';

export const FETCH_LIST_CONTRACT_CUSTOMER = 'customers/FETCH_LIST_CONTRACT_CUSTOMER';
export const FETCH_LIST_CONTRACT_CUSTOMER_SUCCESSED = 'customers/FETCH_LIST_CONTRACT_CUSTOMER_SUCCESSED';
export const FETCH_LIST_CONTRACT_CUSTOMER_FAILED = 'customers/FETCH_LIST_CONTRACT_CUSTOMER_FAILED';

export const CREATE_CUSTOMER = 'customers/CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESSED = 'customers/CREATE_CUSTOMER_SUCCESSED';
export const CREATE_CUSTOMER_FAILED = 'customers/CREATE_CUSTOMER_FAILED';

export const UPDATE_CUSTOMER = 'customers/UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESSED = 'customers/UPDATE_CUSTOMER_SUCCESSED';
export const UPDATE_CUSTOMER_FAILED = 'customers/UPDATE_CUSTOMER_FAILED';

export const DELETE_CUSTOMER = 'customers/DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESSED = 'customers/DELETE_CUSTOMER_SUCCESSED';
export const DELETE_CUSTOMER_FAILED = 'customers/DELETE_CUSTOMER_FAILED';

export const FETCH_DOCUMENTS = 'customers/FETCH_DOCUMENTS';
export const FETCH_DOCUMENTS_SUCCESSED = 'customers/FETCH_DOCUMENTS_SUCCESSED';
export const FETCH_DOCUMENTS_FAILED = 'customers/FETCH_DOCUMENTS_FAILED';

export const UPLOAD_DOCUMENT = 'customers/UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_SUCCESSED = 'customers/UPLOAD_DOCUMENT_SUCCESSED';
export const UPLOAD_DOCUMENT_FAILED = 'customers/UPLOAD_DOCUMENT_FAILED';

export const SET_OPEN_CREATE_CUSTOMER = 'customers/SET_OPEN_CREATE_CUSTOMER';
