import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { ListWithPagination, Pagination } from 'redux/types';
import { prepareAuthHeaders } from 'helpers/api';
import { DocumentTemplate, DocumentType, DocumentTypeGroup, UploadDocumentTemplatePayload } from './types';

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['activeDocumentTypes', 'documentTypePage', 'documentTemplatePage', 'documentTemplateList'],
  endpoints: (builder) => ({
    fetchDocumentTypeGroups: builder.query<DocumentTypeGroup[], void>({
      query: () => `document-type/group`,
    }),
    fetchActiveDocumentTypes: builder.query<ListWithPagination<DocumentType>, Pagination>({
      query: (pagination) => ({
        url: `document-type/active`,
        params: pagination,
      }),
      providesTags: ['activeDocumentTypes'],
    }),
    fetchDocumentTypePage: builder.query<ListWithPagination<DocumentType>, Pagination>({
      query: (pagination) => ({
        url: `document-type/paging`,
        params: pagination,
      }),
      providesTags: ['documentTypePage'],
    }),
    setDocumentType: builder.mutation<DocumentType, DocumentType>({
      query: (data) => ({
        url: `document-type`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['documentTypePage'],
    }),
    deleteDocumentType: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `document-type/delete/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['documentTypePage'],
    }),

    fetchAllDocumentTemplate: builder.query<DocumentTemplate[], void>({
      query: () => `document-template`,
      providesTags: ['documentTemplateList'],
    }),
    fetchDocumentTemplatePage: builder.query<ListWithPagination<DocumentTemplate>, Pagination>({
      query: (pagination) => ({
        url: `document-template/paging`,
        params: pagination,
      }),
      providesTags: ['documentTemplatePage'],
    }),
    uploadDocumentTemplate: builder.mutation<void, UploadDocumentTemplatePayload>({
      query: (payload) => {
        const { file, documentTemplate } = payload;
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: `document-template/save`,
          method: 'POST',
          body: formData,
          params: documentTemplate,
        };
      },
      invalidatesTags: ['documentTemplatePage', 'documentTemplateList'],
    }),
  }),
});

export const {
  useFetchDocumentTypeGroupsQuery,

  useFetchActiveDocumentTypesQuery,
  useFetchDocumentTypePageQuery,
  useSetDocumentTypeMutation,
  useDeleteDocumentTypeMutation,

  useFetchAllDocumentTemplateQuery,
  useFetchDocumentTemplatePageQuery,
  useUploadDocumentTemplateMutation,
} = documentApi;
