import { Box, LinearProgress } from '@mui/material';
import './index.scss';
// import logo from 'assets/images/logo.png';

function Loader() {
  return (
    <div className="loader">
      <div className="loader__content">
        <img src="/pwa-192x192.png" alt="" height="100" />
      </div>
      <Box width={200}>
        <LinearProgress />
      </Box>
    </div>
  );
}

export default Loader;
