import { AssessmentOutlined } from '@mui/icons-material';
import {
  ACTIVITY_VIEW_REPORT,
  ASSIGN_REPORT_VIEW,
  CONVERSION_REPORT_VIEW,
  DISBURSEMENT_REPORT_VIEW,
  DISBURSEMENT_REPORT_VIEW_GROUP,
  DISBURSEMENT_REPORT_VIEW_OWNER,
  LEAD_SVC,
  PARTNER_REPORT,
} from 'constants/roles';
import { NxRoute } from './types';

const CrmDashboard = lazy(() => import('pages/dashboard'));
const ActivityReport = lazy(() => import('pages/report/activity-report'));
const CallReport = lazy(() => import('pages/report/call-report'));
const EfficientPartner = lazy(() => import('pages/report/efficient-partner'));
const ReportStaff = lazy(() => import('pages/report/staff-report'));
const DepartmentStaff = lazy(() => import('pages/report/department-report'));
const BusinessResult = lazy(() => import('pages/report/business-result'));
const EfficientConvertLead = lazy(() => import('pages/report/efficient-convert-lead'));
const DistributeLead = lazy(() => import('pages/report/distribute-lead'));

const reportRoutes: NxRoute = {
  path: '/report',
  name: 'menuConfig:reportConfig:report',
  icon: AssessmentOutlined,
  children: [
    {
      path: '/report/dashboard',
      name: 'menuConfig:reportConfig:dashboard',
      Component: CrmDashboard,
      roles: [],
    },
    {
      path: '/report/activity-report',
      name: 'menuConfig:reportConfig:activity-report',
      Component: ActivityReport,
      resource: LEAD_SVC,
      roles: [ACTIVITY_VIEW_REPORT],
    },
    {
      path: '/report/call-report',
      name: 'menuConfig:reportConfig:call-report',
      Component: CallReport,
      resource: LEAD_SVC,
      roles: [ACTIVITY_VIEW_REPORT],
    },
    {
      path: '/report/efficient-partner',
      name: 'menuConfig:reportConfig:efficient-partner',
      Component: EfficientPartner,
      resource: LEAD_SVC,
      roles: [PARTNER_REPORT],
    },
    {
      path: '/report/staff-report',
      name: 'menuConfig:reportConfig:staff-report',
      Component: ReportStaff,
      resource: LEAD_SVC,
      roles: [DISBURSEMENT_REPORT_VIEW_OWNER],
    },
    {
      path: '/report/department-report',
      name: 'menuConfig:reportConfig:department-report',
      Component: DepartmentStaff,
      resource: LEAD_SVC,
      roles: [DISBURSEMENT_REPORT_VIEW_GROUP],
    },
    {
      path: '/report/business-result',
      name: 'menuConfig:reportConfig:business-result',
      Component: BusinessResult,
      resource: LEAD_SVC,
      roles: [DISBURSEMENT_REPORT_VIEW],
    },
    {
      path: '/report/efficient-convert-lead',
      name: 'menuConfig:reportConfig:efficient-convert-lead',
      Component: EfficientConvertLead,
      resource: LEAD_SVC,
      roles: [CONVERSION_REPORT_VIEW],
    },
    {
      path: '/report/distribute-lead',
      name: 'menuConfig:reportConfig:distribute-lead',
      Component: DistributeLead,
      resource: LEAD_SVC,
      roles: [ASSIGN_REPORT_VIEW],
    },
  ],
};

export default reportRoutes;
