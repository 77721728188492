// @flow
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { handleLoading } from 'redux/layout/actions';
/* eslint-disable no-unused-vars */
import { DEFAULT_PAGINATION } from 'constants/api';
import toast from 'react-hot-toast';
import * as type from './constants';
import * as actions from './actions';
import * as api from './api';

/* Customer group sagas */
// đang lỗi API này
// function* fetchGroups() {
//     yield put(handleLoading(true));
//     try {
//         const response = yield call(api.fetchGroups);
//         if (response) {
//             yield put(actions.fetchGroupsSuccessed(response));
//         }
//         yield put(handleLoading(false));
//     } catch (error) {
//         yield put(actions.fetchGroupsFailed(error));
//     }

//     yield put(handleLoading(false));
// }

// function* fetchGroup(action) {
//     yield put(handleLoading(true));

//     try {
//         const response = yield call(api.fetchGroup, action.payload);

//         yield put(actions.fetchGroupSuccessed(response));
//     } catch (error) {
//         yield put(actions.fetchGroupFailed(error));
//     }

//     yield put(handleLoading(false));
// }

// function* createGroup(action) {
//     yield put(handleLoading(true));

//     try {
//         const response = yield call(api.createGroup, action.payload);

//         yield put(actions.createGroupSuccessed(response));
//         yield put(actions.fetchGroup());
//     } catch (error) {
//         yield put(actions.createGroupFailed(error));
//     }

//     yield put(handleLoading(false));
// }

// function* updateGroup(action) {
//     yield put(handleLoading(true));
//     const { groupId, data } = action.payload;

//     try {
//         const response = yield call(api.updateGroup, groupId, data);

//         yield put(actions.updateGroupSuccessed(response));
//         yield put(actions.fetchGroup());
//     } catch (error) {
//         yield put(actions.updateGroupFailed(error));
//     }

//     yield put(handleLoading(false));
// }

// function* deleteGroup(action) {
//     yield put(handleLoading(true));

//     try {
//         const response = yield call(api.deleteGroup, action.payload);

//         yield put(actions.deleteGroupSuccessed(response));
//         yield put(actions.fetchGroup());
//     } catch (error) {
//         yield put(actions.deleteGroupFailed(error));
//     }

//     yield put(handleLoading(false));
// }

// export function* watchGroup() {
//     yield takeLatest(type.FETCH_GROUPS, fetchGroups);
//     yield takeLatest(type.FETCH_GROUP, fetchGroup);
//     yield takeLatest(type.CREATE_GROUP, createGroup);
//     yield takeLatest(type.UPDATE_GROUP, updateGroup);
//     yield takeLatest(type.DELETE_GROUP, deleteGroup);
// };

/* Customer sagas */
// LAY DANH SACH KHACH HANG
function* fetchCustomers(action) {
  yield put(handleLoading(true));
  const { filter, pagination } = action.payload;

  try {
    const response = yield call(api.fetchCustomers, filter, pagination);
    yield put(actions.fetchCustomersSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchCustomersFailed(error));
  }

  yield put(handleLoading(false));
}

// LAY THONG TIN KHACH HANG
function* fetchCustomer(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchCustomer, action.payload);

    yield put(actions.fetchCustomerSuccessed(response));
  } catch (error) {
    yield put(actions.fetchCustomerFailed(error));
  }

  yield put(handleLoading(false));
}

// LAY ds HOP DONG cua KHACH HANG
function* fetchListContractCustomer(action) {
  const { customerId, pagination } = action.payload;

  try {
    const response = yield call(api.fetchListContractCustomer, customerId, pagination);

    yield put(actions.fetchListContractCustomerSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchListContractCustomerFailed(error));
  }
}

function* createCustomer(action) {
  yield put(handleLoading(true));

  const activeGroupId = yield select((state) => state.Customer.group.id);

  try {
    const response = yield call(api.createCustomer, action.payload);

    yield put(actions.createCustomerSuccessed(response));

    yield put(actions.setOpenCreateCustomer(false));

    if (activeGroupId && activeGroupId === action.payload.groupId) {
      yield put(actions.fetchCustomers(activeGroupId));
    } else {
      yield put(actions.fetchCustomers());
    }
  } catch (error) {
    yield put(actions.createCustomerFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateCustomer(action) {
  yield put(handleLoading(true));
  const { customerId, data } = action.payload;
  const activeGroupId = yield select((state) => state.Customer.group.id);

  try {
    const response = yield call(api.updateCustomer, customerId, data);

    yield put(actions.updateCustomerSuccessed(response));

    yield put(actions.setOpenCreateCustomer(false));

    if (activeGroupId && activeGroupId === action.payload.groupId) {
      yield put(actions.fetchCustomers(activeGroupId));
    } else {
      yield put(actions.fetchCustomers());
    }
  } catch (error) {
    yield put(actions.updateCustomerFailed(error));
  }

  yield put(handleLoading(false));
}

function* deleteCustomer(action) {
  yield put(handleLoading(true));
  const activeGroupId = yield select((state) => state.Customer.group.id);

  try {
    const response = yield call(api.deleteCustomer, action.payload);

    yield put(actions.deleteCustomerSuccessed(response));

    if (activeGroupId && activeGroupId === action.payload) {
      yield put(actions.fetchCustomers(activeGroupId));
    } else {
      yield put(actions.fetchCustomers());
    }
  } catch (error) {
    yield put(actions.deleteCustomerFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchDocuments(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchDocuments, action.payload);

    yield put(actions.fetchDocumentsSuccessed(response));
  } catch (error) {
    yield put(actions.fetchDocumentsFailed(error));
  }

  yield put(handleLoading(false));
}

function* uploadDocument(action) {
  yield put(handleLoading(true));

  const { customerId, data } = action.payload;

  try {
    const response = yield call(api.uploadDocument, customerId, data);

    yield put(actions.uploadDocumentSuccessed(response));

    yield put(actions.fetchDocuments(customerId));
  } catch (error) {
    yield put(actions.uploadDocumentFailed(error));
  }

  yield put(handleLoading(false));
}

export function* watchCustomer() {
  yield takeLatest(type.FETCH_CUSTOMERS, fetchCustomers);
  yield takeLatest(type.FETCH_CUSTOMER, fetchCustomer);
  yield takeLatest(type.CREATE_CUSTOMER, createCustomer);
  yield takeLatest(type.UPDATE_CUSTOMER, updateCustomer);
  yield takeLatest(type.DELETE_CUSTOMER, deleteCustomer);
  yield takeLatest(type.FETCH_DOCUMENTS, fetchDocuments);
  yield takeLatest(type.UPLOAD_DOCUMENT, uploadDocument);
  yield takeLatest(type.FETCH_LIST_CONTRACT_CUSTOMER, fetchListContractCustomer);
}

function* CustomerSaga() {
  yield all([
    // fork(watchGroup),
    fork(watchCustomer),
  ]);
}

export default CustomerSaga;
