import { DashboardOutlined } from '@mui/icons-material';
import { NxRoute } from './types';

const CrmDashboard = lazy(() => import('pages/dashboard'));

const embeddedRoute: NxRoute = {
  path: '/embedded/dashboard',
  name: 'menuConfig:dashboard',
  Component: CrmDashboard,
  icon: DashboardOutlined,
  exact: true,
  isPublic: true,
};

export default embeddedRoute;
