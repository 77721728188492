// import colors from 'tailwindcss/colors';
import { colors as MuiColors } from '@mui/material';
import { IThemeOptions } from './themeTypes';

const colors: Record<string, any> = MuiColors;
export const themeColor = colors[THEME_COLOR] || colors.blue;

const textLight = {
  primary: 'rgba(52, 49, 76, 1)',
  secondary: 'rgba(52, 49, 76, 0.54)',
  disabled: 'rgba(52, 49, 76, 0.38)',
  hint: 'rgba(52, 49, 76, 0.38)',
};

export const themeColors: { light: IThemeOptions; dark: IThemeOptions } = {
  light: {
    palette: {
      mode: 'light',
      primary: {
        main: themeColor[700],
        contrastText: '#ffffff',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#ecedf3',

            scrollbarColor: '#777777 #FAFAFA',

            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              width: 15,
              backgroundColor: '#FAFAFA',
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: 8,
              backgroundColor: '#777777',
              minHeight: 24,
              border: '4px solid #FAFAFA',
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              backgroundColor: '#8A8A8A',
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              backgroundColor: '#8A8A8A',
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#8A8A8A',
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: '#FAFAFA',
            },
          },
        },
      },
    },
  },
  dark: {
    palette: {
      mode: 'dark',
      primary: {
        main: themeColor[700],
        contrastText: '#ffffff',
      },
      background: {
        paper: '#222A45',
        default: '#1a2038',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarColor: '#6b6b6b #2b2b2b',

            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              width: 15,
              backgroundColor: '#2b2b2b',
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: 8,
              backgroundColor: '#6b6b6b',
              minHeight: 24,
              border: '4px solid #2b2b2b',
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: '#2b2b2b',
            },
          },
        },
      },
    },
  },
};
