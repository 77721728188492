export const FETCH_IMPORT_JOBS = 'collection/FETCH_IMPORT_JOBS';
export const FETCH_IMPORT_JOBS_SUCCESSED = 'collection/FETCH_IMPORT_JOBS_SUCCESSED';
export const FETCH_IMPORT_JOBS_FAILED = 'collection/FETCH_IMPORT_JOBS_FAILED';

export const FETCH_IMPORT_JOB_INFO = 'collection/FETCH_IMPORT_JOB_INFO';
export const FETCH_IMPORT_JOB_INFO_SUCCESSED = 'collection/FETCH_IMPORT_JOB_INFO_SUCCESSED';
export const FETCH_IMPORT_JOB_INFO_FAILED = 'collection/FETCH_IMPORT_JOB_INFO_FAILED';

export const FETCH_IMPORT_JOB_CONTENT = 'collection/FETCH_IMPORT_JOB_CONTENT';
export const FETCH_IMPORT_JOB_CONTENT_SUCCESSED = 'collection/FETCH_IMPORT_JOB_CONTENT_SUCCESSED';
export const FETCH_IMPORT_JOB_CONTENT_FAILED = 'collection/FETCH_IMPORT_JOB_CONTENT_FAILED';

export const IMPORT_DATA = 'collection/IMPORT_DATA';
export const IMPORT_DATA_SUCCESSED = 'collection/IMPORT_DATA_SUCCESSED';
export const IMPORT_DATA_FAILED = 'collection/IMPORT_DATA_FAILED';

export const CHECK_IMPORT_DATA = 'collection/CHECK_IMPORT_DATA';
export const CHECK_IMPORT_DATA_SUCCESSED = 'collection/CHECK_IMPORT_DATA_SUCCESSED';
export const CHECK_IMPORT_DATA_FAILED = 'collection/CHECK_IMPORT_DATA_FAILED';
