import { isUserAuthenticated } from 'helpers/authUtils';

const LandingPage = lazy(() => import('pages/landing'));

function LandingRoute() {
  const location = useLocation();

  if (!isUserAuthenticated()) {
    return <Navigate to="/account/login" state={{ from: location }} />;
  }

  return <LandingPage />;
}

export default LandingRoute;
