/* Address */

export const FETCH_DISTRICTS = 'common/FETCH_DISTRICTS';
export const FETCH_DISTRICTS_SUCCESSED = 'common/FETCH_DISTRICTS_SUCCESSED';
export const FETCH_DISTRICTS_FAILED = 'common/FETCH_DISTRICTS_FAILED';
export const SAVE_DISTRICTS = 'common/SAVE_DISTRICTS';

export const CLEAR_DISTRICTS = 'common/CLEAR_DISTRICTS';
export const CLEAR_DISTRICTS_SUCCESSED = 'common/CLEAR_DISTRICTS_SUCCESSED';

export const FETCH_WARDS = 'common/FETCH_WARDS';
export const FETCH_WARDS_SUCCESSED = 'common/FETCH_WARDS_SUCCESSED';
export const FETCH_WARDS_FAILED = 'common/FETCH_WARDS_FAILED';

export const CLEAR_WARDS = 'common/CLEAR_WARDS';
export const CLEAR_WARDS_SUCCESSED = 'common/CLEAR_WARDS_SUCCESSED';

/* Category common */

export const FETCH_COMMON_CATEGORY = 'common/FETCH_COMMON_CATEGORY';
export const FETCH_COMMON_CATEGORY_SUCCESSED = 'common/FETCH_COMMON_CATEGORY_SUCCESSED';
export const FETCH_COMMON_CATEGORY_FAILED = 'common/FETCH_COMMON_CATEGORY_FAILED';

export const FETCH_CATEGORY_VALUES = 'common/FETCH_CATEGORY_VALUES';
export const FETCH_CATEGORY_VALUES_SUCCESSED = 'common/FETCH_CATEGORY_VALUES_SUCCESSED';
export const FETCH_CATEGORY_VALUES_FAILED = 'common/FETCH_CATEGORY_VALUES_FAILED';

export const CREATE_CATEGORY = 'common/CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESSED = 'common/CREATE_CATEGORY_SUCCESSED';
export const CREATE_CATEGORY_FAILED = 'common/CREATE_CATEGORY_FAILED';

export const UPDATE_CATEGORY = 'common/UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESSED = 'common/UPDATE_CATEGORY_SUCCESSED';
export const UPDATE_CATEGORY_FAILED = 'common/UPDATE_CATEGORY_FAILED';

export const DELETE_CATEGORY = 'common/DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESSED = 'common/DELETE_CATEGORY_SUCCESSED';
export const DELETE_CATEGORY_FAILED = 'common/DELETE_CATEGORY_FAILED';

/* Other */

export const FETCH_IP_GEO = 'common/FETCH_IP_GEO';
export const FETCH_IP_GEO_SUCCESSED = 'common/FETCH_IP_GEO_SUCCESSED';
export const FETCH_IP_GEO_FAILED = 'common/FETCH_IP_GEO_FAILED';
export const CLEAR_IP_GEO = 'common/CLEAR_IP_GEO';

export const FETCH_APP_LIST = 'common/FETCH_APP_LIST';
export const FETCH_APP_LIST_SUCCESS = 'common/FETCH_APP_LIST_SUCCESS';

export const FETCH_NOTIFICATIONS = 'common/FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'common/FETCH_NOTIFICATIONS_SUCCESS';

export const FETCH_UNSEEN_NOTIFICATIONS = 'common/FETCH_UNSEEN_NOTIFICATIONS';
export const FETCH_UNSEEN_NOTIFICATIONS_SUCCESS = 'common/FETCH_UNSEEN_NOTIFICATIONS_SUCCESS';

export const SUBSCRIBE_USER = 'common/SUBSCRIBE_USER';
export const SUBSCRIBE_USER_SUCCESS = 'common/SUBSCRIBE_USER_SUCCESS';
export const SUBSCRIBE_USER_FAILED = 'common/SUBSCRIBE_USER_FAILED';

export const UNSUBSCRIBE_USER = 'common/UNSUBSCRIBE_USER';
export const UNSUBSCRIBE_USER_SUCCESS = 'common/UNSUBSCRIBE_USER_SUCCESS';
export const UNSUBSCRIBE_USER_FAILED = 'common/UNSUBSCRIBE_USER_FAILED';

export const UPDATE_NOTIFICATION = 'common/UPDATE_NOTIFICATION';

export const CLEAR_CACHE = 'common/CLEAR_CACHE';

export const FETCH_ROUTE_CONFIG = 'common/FETCH_ROUTE_CONFIG';
export const FETCH_ROUTE_CONFIG_SUCCESSED = 'common/FETCH_ROUTE_CONFIG_SUCCESSED';

export const UPDATE_ROUTE_CONFIG = 'common/UPDATE_ROUTE_CONFIG';
export const UPDATE_ACTIVE_MENU_KEYS = 'common/UPDATE_ACTIVE_MENU_KEYS';
