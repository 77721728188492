import { Dispatch, SetStateAction } from 'react';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Pagination } from 'redux/types';
import toast from 'react-hot-toast';
import * as api from './api';
import {
  actionFailed,
  actionSucceeded,
  createInsurance,
  createProductGroup,
  deleteInsurance,
  deleteProduct,
  deleteProductGroup,
  fetchFailed,
  fetchProductDocuments,
  fetchProductDocumentsSucceeded,
  updateInsurance,
  updateProduct,
  updateProductGroup,
  updateStatusProduct,
  uploadProductDocument,
} from './slice';
import { Filter, Insurance, Product, ProductGroup, ProductUpdate } from './types';

const handleLoading = (status: boolean) => ({
  type: 'LOADING',
  payload: status,
});

// ***********************************************************************************************************
// Product

function* fetchProductDocumentsSaga(action: PayloadAction<any>): Generator {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchProductDocuments, action.payload);

    yield put(fetchProductDocumentsSucceeded(res));
  } catch (error) {
    yield put(fetchFailed(error));
  }

  yield put(handleLoading(false));
}

function* deleteProductSaga(
  action: PayloadAction<{
    productCode: string;
    loading: (loading: boolean) => void;
    setState: Dispatch<SetStateAction<any>>;
  }>
): Generator {
  const { productCode, loading = () => {}, setState = () => {} } = action.payload;
  loading(true);

  try {
    const res = yield call(api.deleteProduct, productCode);

    yield put(actionSucceeded(res));
    setState(true);
  } catch (error) {
    yield put(actionFailed(error));
    setState(false);
  }
  loading(false);
}

function* deleteProductGroupSaga(
  action: PayloadAction<{ id: string; loading: (loading: boolean) => void; setState: Dispatch<SetStateAction<any>> }>
): Generator {
  const { id, loading = () => {}, setState = () => {} } = action.payload;

  loading(true);
  try {
    const res = yield call(api.deleteProductGroup, id);

    yield put(actionSucceeded(res));
    toast.success('Xóa nhóm sản phẩm thành công');
    setState(true);
  } catch (error) {
    yield put(actionFailed(error));
    toast.error('Xóa nhóm sản phẩm thất bại');
    setState(false);
  }
  loading(false);
}

function* updateProductGroupSaga(
  action: PayloadAction<{
    id: string;
    data: ProductGroup;
    options: { loading: (loading: boolean) => void; onSuccess: (res: any) => void; onFailure: (error: any) => void };
  }>
): Generator {
  const { id, data, options } = action.payload;
  const { loading, onSuccess, onFailure } = options;

  loading && loading(true);
  try {
    const res = yield call(api.updateProductGroup, id, data);

    yield put(actionSucceeded(res));
    toast.success('Cập nhật nhóm sản phẩm thành công');
    onSuccess && onSuccess(res);
  } catch (error) {
    yield put(actionFailed(error));
    toast.error('Cập nhật nhóm sản phẩm thất bại');
    onFailure && onFailure(error);
  }

  loading && loading(false);
}

function* createProductGroupSaga(
  action: PayloadAction<{
    data: ProductGroup;
    options: { loading: (loading: boolean) => void; onSuccess: (res: any) => void; onFailure: (error: any) => void };
  }>
): Generator {
  const { data, options } = action.payload;
  const { loading, onSuccess, onFailure } = options;

  loading && loading(true);
  try {
    const res = yield call(api.createProductGroup, data);

    yield put(actionSucceeded(res));
    toast.success('Tạo nhóm sản phẩm thành công');
    onSuccess && onSuccess(res);
  } catch (error) {
    yield put(actionFailed(error));
    toast.error('Tạo nhóm sản phẩm thất bại');
    onFailure && onFailure(error);
  }
  loading && loading(false);
}

// ***********************************************************************************************************
// Insuarance

function* createInsuranceSaga(
  action: PayloadAction<{
    data: Insurance;
    uploadDocuments: any;
    loading: (loading: boolean) => void;
    setState: Dispatch<SetStateAction<any>>;
  }>
): Generator {
  const { data, uploadDocuments, loading = () => {}, setState = () => {} } = action.payload;

  loading(true);
  try {
    const res = yield call(api.createInsurance, data);
    yield put(actionSucceeded(res));
    setState(true);
  } catch (error) {
    yield put(actionFailed(error));
    setState(false);
  }
  loading(false);
}

function* updateInsuranceSaga(
  action: PayloadAction<{
    productCode: string;
    data: ProductUpdate;
    loading: (loading: boolean) => void;
    setState: Dispatch<SetStateAction<any>>;
  }>
): Generator {
  const { productCode, data, loading = () => {}, setState = () => {} } = action.payload;

  loading(true);
  try {
    const productData = { ...data, logoUrl: undefined, logoFile: undefined };
    const res = yield call(api.updateInsurance, productCode, productData);
    yield put(actionSucceeded(res));
    setState(true);
  } catch (error) {
    yield put(actionFailed(error));
    setState(false);
  }
  loading(false);
}

function* deleteInsuranceSaga(
  action: PayloadAction<{
    productCode: string;
    loading: (loading: boolean) => void;
    setState: Dispatch<SetStateAction<any>>;
  }>
): Generator {
  const { productCode, loading = () => {}, setState = () => {} } = action.payload;
  loading(true);
  try {
    const res = yield call(api.deleteInsurance, productCode);
    yield put(actionSucceeded(res));
    setState(true);
  } catch (error) {
    yield put(actionFailed(error));
    setState(false);
  }
  loading(false);
}

function* uploadProductDocumentSaga(
  action: PayloadAction<{
    productCode: string;
    file: any;
    pagination: Pagination;
    setState: Dispatch<SetStateAction<any>>;
  }>
): Generator {
  const { productCode, file, pagination, setState = () => {} } = action.payload;

  yield put(handleLoading(true));
  try {
    const response = yield call(api.uploadProductDocument, productCode, file, pagination);

    yield put(actionSucceeded(response));
    toast.success('Tải lên tài liệu thành công');
    setState(true);
  } catch (error) {
    yield put(actionFailed(error));
    toast.error('Tải lên tài liệu thất bại');
    setState(false);
  }

  yield put(handleLoading(false));
}

function* updateStatusProductSaga(
  action: PayloadAction<{
    productCode: string;
    status: string;
    loading: (loading: boolean) => void;
    setState: Dispatch<SetStateAction<any>>;
  }>
): Generator {
  const { productCode, status, loading = () => {}, setState = () => {} } = action.payload;

  loading(true);
  try {
    const res = yield call(api.updateStatusProduct, productCode, status);

    yield put(actionSucceeded(res));
    setState(true);
  } catch (error) {
    yield put(actionFailed(error));
    setState(false);
  }
  loading(false);
}

function* watchProduct() {
  yield takeEvery(fetchProductDocuments.type, fetchProductDocumentsSaga);

  yield takeEvery(deleteProduct.type, deleteProductSaga);

  yield takeEvery(createInsurance.type, createInsuranceSaga);
  yield takeEvery(updateInsurance.type, updateInsuranceSaga);
  yield takeEvery(deleteInsurance.type, deleteInsuranceSaga);

  yield takeEvery(createProductGroup.type, createProductGroupSaga);
  yield takeEvery(updateProductGroup.type, updateProductGroupSaga);
  yield takeEvery(deleteProductGroup.type, deleteProductGroupSaga);

  yield takeEvery(uploadProductDocument.type, uploadProductDocumentSaga);
  yield takeEvery(updateStatusProduct.type, updateStatusProductSaga);
}

function* productSaga() {
  yield all([fork(watchProduct)]);
}

export default productSaga;
