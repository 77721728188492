import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { prepareAuthHeaders } from 'helpers/api';
import { ConversionFunnelDef } from './types';

export const settingApi = createApi({
  reducerPath: 'settingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['conversionFunnelList', 'autoAssignRuleList'],
  endpoints: (builder) => ({
    fetchConversionFunnelList: builder.query<ConversionFunnelDef[], void>({
      query: () => `conversion-funnel/get-all`,
      providesTags: ['conversionFunnelList'],
    }),

    fetchAutoAssignRuleList: builder.query<any, void>({
      query: () => `auto-assign/rule/get-all`,
      providesTags: ['autoAssignRuleList'],
    }),

    createAutoAssignRule: builder.mutation({
      query: (data) => ({
        url: `auto-assign/rule/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['autoAssignRuleList'],
    }),
    updateAutoAssignRule: builder.mutation({
      query: (data) => ({
        url: `auto-assign/rule/update/${data.id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['autoAssignRuleList'],
    }),

    activateAutoAssignRule: builder.mutation({
      query: ({ id, active }) => ({
        url: `auto-assign/rule/${active ? 'activate' : 'deactivate'}/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['autoAssignRuleList'],
    }),
    deleteAutoAssignRule: builder.mutation({
      query: (id) => ({
        url: `auto-assign/rule/delete/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['autoAssignRuleList'],
    }),
  }),
});

export const {
  useFetchConversionFunnelListQuery,
  useFetchAutoAssignRuleListQuery,
  useCreateAutoAssignRuleMutation,
  useUpdateAutoAssignRuleMutation,
  useActivateAutoAssignRuleMutation,
  useDeleteAutoAssignRuleMutation,
} = settingApi;
