/* Calendar */
export const FETCH_CALENDAR_EVENTS = 'collection/FETCH_CALENDAR_EVENTS';
export const FETCH_CALENDAR_EVENTS_SUCCESSED = 'collection/FETCH_CALENDAR_EVENTS_SUCCESSED';
export const FETCH_CALENDAR_EVENTS_FAILED = 'collection/FETCH_CALENDAR_EVENTS_FAILED';

export const FETCH_CALENDAR_EVENTS_BY_DATE = 'collection/FETCH_CALENDAR_EVENTS_BY_DATE';
export const FETCH_CALENDAR_EVENTS_BY_DATE_SUCCESSED = 'collection/FETCH_CALENDAR_EVENTS_BY_DATE_SUCCESSED';
export const FETCH_CALENDAR_EVENTS_BY_DATE_FAILED = 'collection/FETCH_CALENDAR_EVENTS_BY_DATE_FAILED';

export const FETCH_CALENDAR_EVENT = 'collection/FETCH_CALENDAR_EVENT';
export const FETCH_CALENDAR_EVENT_SUCCESSED = 'collection/FETCH_CALENDAR_EVENT_SUCCESSED';
export const FETCH_CALENDAR_EVENT_FAILED = 'collection/FETCH_CALENDAR_EVENT_FAILED';

export const CREATE_CALENDAR_EVENT = 'collection/CREATE_CALENDAR_EVENT';
export const CREATE_CALENDAR_EVENT_SUCCESSED = 'collection/CREATE_CALENDAR_EVENT_SUCCESSED';
export const CREATE_CALENDAR_EVENT_FAILED = 'collection/CREATE_CALENDAR_EVENT_FAILED';

export const UPDATE_CALENDAR_EVENT = 'collection/UPDATE_CALENDAR_EVENT';
export const UPDATE_CALENDAR_EVENT_SUCCESSED = 'collection/UPDATE_CALENDAR_EVENT_SUCCESSED';
export const UPDATE_CALENDAR_EVENT_FAILED = 'collection/UPDATE_CALENDAR_EVENT_FAILED';

export const DELETE_CALENDAR_EVENT = 'collection/DELETE_CALENDAR_EVENT';
export const DELETE_CALENDAR_EVENT_SUCCESSED = 'collection/DELETE_CALENDAR_EVENT_SUCCESSED';
export const DELETE_CALENDAR_EVENT_FAILED = 'collection/DELETE_CALENDAR_EVENT_FAILED';
