// @flow
import * as layoutConstants from 'constants/layout';
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  HIDE_RIGHT_SIDEBAR,
  LOADING,
  RESET_LOADING,
  SHOW_RIGHT_SIDEBAR,
  TOGGLE_RIGHT_SIDEBAR,
} from './constants';

const INIT_STATE = {
  layoutType: layoutConstants.LAYOUT_HORIZONTAL,
  layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
  leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT,
  leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
  showRightSidebar: false,
  loading: 0,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload,
      };
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      };
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload ? state.loading + 1 : state.loading > 0 ? state.loading - 1 : 0,
      };
    case RESET_LOADING:
      return {
        ...state,
        loading: 0,
      };
    default:
      return state;
  }
};

export default Layout;
