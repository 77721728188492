import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { prepareAuthHeaders } from 'helpers/api';
import { ListWithPagination, Pagination } from 'redux/types';
import { PartnerDetail } from './types';

export const partnerApi = createApi({
  reducerPath: 'partnerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['partners'],
  endpoints: (builder) => ({
    fetchAllPartners: builder.query<PartnerDetail, void>({
      query: () => `partner/get-all`,
      providesTags: ['partners'],
    }),
    filterPartners: builder.query<ListWithPagination<PartnerDetail>, { filters: any; pagination: Pagination }>({
      query: ({ filters, pagination }) => ({
        url: `partner/filter`,
        method: 'POST',
        params: pagination,
        body: filters,
      }),
      providesTags: ['partners'],
    }),
    fetchAllPartnersByGroup: builder.query<PartnerDetail[], string>({
      query: (groupId) => `partner/group/${groupId}`,
      providesTags: ['partners'],
    }),

    fetchPartnerDetail: builder.query<PartnerDetail, string>({
      query: (id) => `partner/${id}`,
    }),

    createPartner: builder.mutation<PartnerDetail, PartnerDetail>({
      query: (partner) => ({
        url: `partner/create`,
        method: 'POST',
        body: partner,
      }),
      invalidatesTags: ['partners'],
    }),
    updatePartner: builder.mutation<PartnerDetail, PartnerDetail>({
      query: (partner) => ({
        url: `partner/${partner.id}/update`,
        method: 'PUT',
        body: partner,
      }),
      invalidatesTags: ['partners'],
    }),
  }),
});

export const {
  useFetchAllPartnersQuery,
  useFilterPartnersQuery,
  useFetchAllPartnersByGroupQuery,
  useFetchPartnerDetailQuery,

  useCreatePartnerMutation,
  useUpdatePartnerMutation,
} = partnerApi;
