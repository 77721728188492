import { cloneDeep } from 'lodash-es';
import { NxRoute } from './types';
import authRoutes from './authRoutes';
import calendarRoutes from './calendarRoutes';
import customerRoutes from './customerRoutes';
import expertiseRoutes from './expertiseRoutes';
import kpiRoutes from './kpiRoutes';
import marketingRoutes from './marketingRoutes';
import productRoutes from './productRoutes';
import reportRoutes from './reportRoutes';
import sellRoutes from './sellRoutes';
import settingRoutes from './settingRoutes';
import dashboardRoute from './dashboardRoute';
import homeRoute from './homeRoute';
import embeddedRoute from './embeddedRoute';

const allRoutes = [
  homeRoute,
  authRoutes,
  embeddedRoute,
  dashboardRoute,
  reportRoutes,
  productRoutes,
  marketingRoutes,
  sellRoutes,
  customerRoutes,
  expertiseRoutes,
  calendarRoutes,
  kpiRoutes,
  settingRoutes,
];

const getAllFlattenRoutes = (routes: NxRoute[]): NxRoute[] => {
  const children: NxRoute[] = [];

  routes.forEach((route) => {
    if (route.children) {
      const result = getAllFlattenRoutes(route.children);
      children.push(...result);
      delete route.children;
    }
    children.push(route);
  });

  return children;
};

const getRenderedRoutes = (routes: NxRoute[]): NxRoute[] => {
  const children: NxRoute[] = [];

  routes.forEach((route) => {
    if (route.children) {
      const result = getRenderedRoutes(route.children);
      children.push(...result);
      delete route.children;
    }

    if (route.Component) {
      children.push(route);
    }
  });

  return children;
};

const allFlattenRoutes = getAllFlattenRoutes(cloneDeep(allRoutes));

const renderedRoutes = getRenderedRoutes(cloneDeep(allRoutes));

const publicRoutes = renderedRoutes.filter((route) => route.isPublic);
const privateRoutes = renderedRoutes.filter((route) => !route.isPublic);

export { allFlattenRoutes, publicRoutes, privateRoutes };
