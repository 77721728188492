// @flow
import * as type from './constants';

export const loginUser = ({ username, password }, options) => ({
  type: type.LOGIN_USER,
  payload: { username, password, options: options ?? {} },
});

export const loginUserSuccess = (user) => ({
  type: type.LOGIN_USER_SUCCESSED,
  payload: user,
});

export const loginUserFailed = (error) => ({
  type: type.LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (fullname, email, password) => ({
  type: type.REGISTER_USER,
  payload: { fullname, email, password },
});

export const registerUserSuccess = (user) => ({
  type: type.REGISTER_USER_SUCCESSED,
  payload: user,
});

export const registerUserFailed = (error) => ({
  type: type.REGISTER_USER_FAILED,
  payload: error,
});

export const updateProfile = (profile, avatar) => ({
  type: type.UPDATE_USER,
  payload: { profile, avatar },
});

export const updateProfileSuccess = (user) => ({
  type: type.UPDATE_USER_SUCCESSED,
  payload: user,
});

export const updateProfileFailed = (error) => ({
  type: type.UPDATE_USER_FAILED,
  payload: error,
});

export const logoutUser = () => ({
  type: type.LOGOUT_USER,
});

export const removeUserInfo = () => ({
  type: type.REMOVE_USER_INFO,
});

export const forgetPassword = (username) => ({
  type: type.FORGET_PASSWORD,
  payload: { username },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
  type: type.FORGET_PASSWORD_SUCCESSED,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
  type: type.FORGET_PASSWORD_FAILED,
  payload: error,
});

export const refreshToken = (token) => ({
  type: type.REFRESH_TOKEN,
  payload: token,
});

export const refreshTokenSuccessed = (newToken) => ({
  type: type.REFRESH_TOKEN,
  payload: newToken,
});

export const refreshTokenFailed = (error) => ({
  type: type.REFRESH_TOKEN_FAILED,
  payload: error,
});

export const changeUserPassword = (payload, navigate) => ({
  type: type.CHANGE_PASSWORD,
  payload: { payload, navigate },
});

export const changeUserPasswordSuccess = (passwordResetStatus) => ({
  type: type.CHANGE_PASSWORD_SUCCESSED,
  payload: passwordResetStatus,
});

export const changeUserPasswordFailed = (error) => ({
  type: type.CHANGE_PASSWORD_FAILED,
  payload: error,
});

export const setWebPhone = (webPhone) => ({
  type: type.SET_WEB_PHONE,
  payload: webPhone,
});

export const setDarkMode = (isDarkMode) => ({
  type: type.SET_DARK_MODE,
  payload: isDarkMode,
});

export const getStringeeToken = (username) => ({
  type: type.GET_STRINGEE_TOKEN,
  payload: username,
});

export const getStringeeTokenSuccessed = (token) => ({
  type: type.GET_STRINGEE_TOKEN_SUCCESSED,
  payload: token,
});
