// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { handleLoading } from 'redux/layout/actions';
import toast from 'react-hot-toast';
import * as type from './constants';
import * as actions from './actions';
import * as api from './api';

/* Account debt sagas */
function* fetchCampaigns(action) {
  const { pagination, loading = () => {} } = action.payload;

  loading(true);
  try {
    const response = yield call(api.fetchCampaigns, pagination);

    yield put(actions.fetchCampaignsSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchCampaignsFailed(error));
  }
  loading(false);
}

function* fetchCampaignsByFilter(action) {
  const { filter, pagination, loading = () => {} } = action.payload;

  loading(true);
  try {
    const response = yield call(api.fetchCampaignsByFilter, filter || {}, pagination);

    yield put(actions.fetchCampaignsSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchCampaignsFailed(error));
  }
  loading(false);
}

function* fetchCampaignDetail(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchCampaignDetail, action.payload);

    yield put(actions.fetchCampaignDetailSuccessed(response));
  } catch (error) {
    yield put(actions.fetchCampaignDetailFailed(error));
  }

  yield put(handleLoading(false));
}

function* createCampaign(action) {
  yield put(handleLoading(true));
  const { data, setState = () => {} } = action.payload;

  try {
    const response = yield call(api.createCampaign, data);

    yield put(actions.createCampaignSuccessed(response));
    toast.success('Tạo chiến dịch thành công');
    setState(true);
  } catch (error) {
    yield put(actions.createCampaignFailed(error));
    toast.error('Tạo chiến dịch thất bại');
    setState(false);
  }

  yield put(handleLoading(false));
}

function* updateCampaign(action) {
  yield put(handleLoading(true));

  const { campaignCode, data, setState = () => {} } = action.payload;

  try {
    const response = yield call(api.updateCampaign, campaignCode, data);

    yield put(actions.updateCampaignSuccessed(response));
    toast.success('Cập nhật chiến dịch thành công');
    setState(true);
  } catch (error) {
    yield put(actions.updateCampaignFailed(error));
    toast.error('Cập nhật chiến dịch thất bại');
    setState(false);
  }

  yield put(handleLoading(false));
}

function* deleteCampaign(action) {
  const { campaignCode, setState = () => {}, loading = () => {} } = action.payload;

  loading(true);
  try {
    const response = yield call(api.deleteCampaign, campaignCode);

    yield put(actions.deleteCampaignSuccessed(response));
    setState(true);
  } catch (error) {
    yield put(actions.deleteCampaignFailed(error));
    setState(false);
  }
  loading(false);
}

function* updateCampaignStatus(action) {
  const { campaignCode, newStatus, setState = () => {}, loading = () => {} } = action.payload;

  loading(true);
  try {
    yield call(api.updateCampaignStatus, campaignCode, newStatus);
    toast.success('Cập nhật trạng thái thành công');
    setState(true);
  } catch (error) {
    toast.error('Cập nhật trạng thái thất bại');
    setState(false);
  }
  loading(false);
}

// Campaign partner
function* fetchCampaignPartners(action) {
  yield put(handleLoading(true));
  try {
    const response = yield call(api.fetchCampaignPartners, action.payload);
    yield put(actions.fetchCampaignPartnersSuccessed(response));
  } catch (error) {
    yield put(actions.fetchCampaignPartnersFailed(error));
  }
  yield put(handleLoading(false));
}

function* createCampaignPartner(action) {
  yield put(handleLoading(true));
  const { data, setState = () => {} } = action.payload;

  try {
    const response = yield call(api.createCampaignPartner, data);

    yield put(actions.createCampaignPartnerSuccessed(response));
    toast.success('Tạo đối tác chiến dịch thành công');
    setState(true);
  } catch (error) {
    yield put(actions.createCampaignPartnerFailed(error));
    toast.error('Tạo đối tác chiến dịch thất bại');
    setState(false);
  }

  yield put(handleLoading(false));
}

function* updateCampaignPartner(action) {
  yield put(handleLoading(true));

  const { data, setState = () => {} } = action.payload;

  try {
    const response = yield call(api.updateCampaignPartner, data);

    yield put(actions.updateCampaignPartnerSuccessed(response));
    toast.success('Cập nhật đối tác chiến dịch thành công');
    setState(true);
  } catch (error) {
    yield put(actions.updateCampaignPartnerFailed(error));
    toast.error('Cập nhật đối tác chiến dịch thất bại');
    setState(false);
  }

  yield put(handleLoading(false));
}

function* deleteCampaignPartner(action) {
  yield put(handleLoading(true));
  const { campaignCode, productCode, setState = () => {} } = action.payload;

  try {
    const response = yield call(api.deleteCampaignPartner, campaignCode, productCode);

    yield put(actions.deleteCampaignPartnerSuccessed(response));
    setState(true);
  } catch (error) {
    yield put(actions.deleteCampaignPartnerFailed(error));
    setState(false);
  }

  yield put(handleLoading(false));
}

function* fetchCampaignCode(action) {
  yield put(handleLoading(true));

  const { cate, campaignId } = action.payload;

  try {
    const response = yield call(api.fetchCampaignCode, cate, campaignId);
    yield put(actions.fetchCampaignCodeSuccessed({ cate, data: response }));
  } catch (error) {
    yield put(actions.fetchCampaignCodeFailed(error));
  }

  yield put(handleLoading(false));
}

function* createCampaignCode(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.createCampaignCode, action.payload);

    yield put(actions.createCampaignCodeSuccessed(response));
    yield put(
      actions.fetchCampaignCode(
        action.payload.type === 'REFERRAL' ? 'referral' : 'promotion',
        action.payload.campaignId
      )
    );
  } catch (error) {
    yield put(actions.createCampaignCodeFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateCampaignCode(action) {
  yield put(handleLoading(true));
  const { codeId, data } = action.payload;

  try {
    const response = yield call(api.updateCampaignCode, codeId, data);

    yield put(actions.updateCampaignCodeSuccessed(response));
    yield put(
      actions.fetchCampaignCode(
        action.payload.type === 'REFERRAL' ? 'referral' : 'promotion',
        action.payload.campaignId
      )
    );
  } catch (error) {
    yield put(actions.updateCampaignCodeFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchCampaignGroups(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchCampaignGroups, action.payload);

    yield put(actions.fetchCampaignGroupsSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchCampaignGroupsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchCampaignGroupDetail(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchCampaignGroupDetail, action.payload);

    yield put(actions.fetchCampaignGroupDetailSuccessed(response));
  } catch (error) {
    yield put(actions.fetchCampaignGroupDetailFailed(error));
  }

  yield put(handleLoading(false));
}

function* createCampaignGroup(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.createCampaignGroup, action.payload);

    yield put(actions.createCampaignGroupSuccessed(response));
    yield put(actions.fetchCampaignGroups());
  } catch (error) {
    yield put(actions.createCampaignGroupFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateCampaignGroup(action) {
  yield put(handleLoading(true));
  const { codeId, data } = action.payload;

  try {
    const response = yield call(api.updateCampaignGroup, codeId, data);

    yield put(actions.updateCampaignGroupSuccessed(response));
    yield put(actions.fetchCampaignGroups());
  } catch (error) {
    yield put(actions.updateCampaignGroupFailed(error));
  }

  yield put(handleLoading(false));
}

function* reportTopPartnerCount(action) {
  try {
    const response = yield call(api.reportTopPartnerCount, action.payload);

    yield put(actions.reportTopPartnerCountSuccessed(response));
  } catch (error) {
    yield put(actions.reportTopPartnerCountFailed(error));
  }
}

function* leadStatusCount(action) {
  try {
    const response = yield call(api.leadStatusCount, action.payload);

    yield put(actions.leadStatusCountSuccessed(response));
  } catch (error) {
    yield put(actions.leadStatusCountFailed(error));
  }
}

function* disbursement(action) {
  try {
    const response = yield call(api.disbursement, action.payload);

    yield put(actions.disbursementSuccessed(response));
  } catch (error) {
    yield put(actions.disbursementFailed(error));
  }
}

function* leadValidCount(action) {
  try {
    const response = yield call(api.leadValidCount, action.payload);

    yield put(actions.leadValidCountSuccessed(response));
  } catch (error) {
    yield put(actions.leadValidCountFailed(error));
  }
}

export function* watchCampaigns() {
  yield takeEvery(type.FETCH_CAMPAIGN_PARTNERS, fetchCampaignPartners);
  yield takeEvery(type.CREATE_CAMPAIGN_PARTNER, createCampaignPartner);
  yield takeEvery(type.UPDATE_CAMPAIGN_PARTNER, updateCampaignPartner);
  yield takeEvery(type.DELETE_CAMPAIGN_PARTNER, deleteCampaignPartner);

  yield takeEvery(type.UPDATE_CAMPAIGN_STATUS, updateCampaignStatus);
  yield takeEvery(type.FETCH_CAMPAIGNS, fetchCampaigns);
  yield takeEvery(type.FETCH_CAMPAIGNS_BY_FILTER, fetchCampaignsByFilter);
  yield takeEvery(type.FETCH_CAMPAIGN_DETAIL, fetchCampaignDetail);
  yield takeEvery(type.CREATE_CAMPAIGN, createCampaign);
  yield takeEvery(type.UPDATE_CAMPAIGN, updateCampaign);
  yield takeEvery(type.DELETE_CAMPAIGN, deleteCampaign);
  yield takeEvery(type.FETCH_CAMPAIGN_CODE, fetchCampaignCode);
  yield takeEvery(type.CREATE_CAMPAIGN_CODE, createCampaignCode);
  yield takeEvery(type.UPDATE_CAMPAIGN_CODE, updateCampaignCode);
  yield takeEvery(type.FETCH_CAMPAIGN_GROUPS, fetchCampaignGroups);
  yield takeEvery(type.FETCH_CAMPAIGN_GROUP_DETAIL, fetchCampaignGroupDetail);
  yield takeEvery(type.CREATE_CAMPAIGN_GROUP, createCampaignGroup);
  yield takeEvery(type.UPDATE_CAMPAIGN_GROUP, updateCampaignGroup);

  yield takeEvery(type.FETCH_CAMPAIGN_DETAIL, reportTopPartnerCount);
  yield takeEvery(type.FETCH_CAMPAIGN_DETAIL, leadStatusCount);
  yield takeEvery(type.FETCH_CAMPAIGN_DETAIL, disbursement);
  yield takeEvery(type.FETCH_CAMPAIGN_DETAIL, leadValidCount);
}

function* campaignSaga() {
  yield all([fork(watchCampaigns)]);
}

export default campaignSaga;
