import { NxRoute } from './types';

const Home = lazy(() => import('pages/home'));

const homeRoute: NxRoute = {
  path: '/',
  name: 'Trang chủ',
  Component: Home,
  exact: true,
  isAllowed: true,
};

export default homeRoute;
