// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { handleLoading } from 'redux/layout/actions';
import { cp } from 'helpers/chainParse';
import toast from 'react-hot-toast';
import * as type from './constants';
import * as actions from './actions';
import * as api from './api';

/* Calendar sagas */
function* fetchCalendarEvents(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchCalendarEvents, action.payload);

    yield put(actions.fetchCalendarEventsSuccessed(cp(response, ['list'], [])));
  } catch (error) {
    yield put(actions.fetchCalendarEventsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchCalendarEventsByDate(action) {
  // yield put(handleLoading(true));
  const { data, callback } = action.payload;
  callback(true);

  try {
    const response = yield call(api.fetchCalendarEventsByDate, data);

    yield put(actions.fetchCalendarEventsByDateSuccessed(cp(response, ['list'], [])));
  } catch (error) {
    yield put(actions.fetchCalendarEventsByDateFailed(error));
  }

  callback(false);
  // yield put(handleLoading(false));
}

function* fetchCalendarEvent(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchCalendarEvent, action.payload);

    yield put(actions.fetchCalendarEventSuccessed(response));
  } catch (error) {
    yield put(actions.fetchCalendarEventFailed(error));
  }

  yield put(handleLoading(false));
}

function* createCalendarEvent(action) {
  yield put(handleLoading(true));

  const { data, setState = () => {} } = action.payload;
  try {
    const response = yield call(api.createCalendarEvent, data);

    yield put(actions.createCalendarEventSuccessed(response));
    toast.success('Tạo sự kiện thành công.');
    setState(true);
  } catch (error) {
    yield put(actions.createCalendarEventFailed(error));
    toast.error('Tạo sự kiện thất bại.');
    setState(false);
  }

  yield put(handleLoading(false));
}

function* updateCalendarEvent(action) {
  yield put(handleLoading(true));
  const { eventId, data, setState = () => {} } = action.payload;
  try {
    const response = yield call(api.updateCalendarEvent, eventId, data);

    yield put(actions.updateCalendarEventSuccessed(response));
    toast.success('Cập nhật sự kiện thành công.');
    setState(true);
  } catch (error) {
    yield put(actions.updateCalendarEventFailed(error));
    toast.error('Cập nhật sự kiện thất bại.');
    setState(false);
  }

  yield put(handleLoading(false));
}

function* deleteCalendarEvent(action) {
  yield put(handleLoading(true));
  const { eventId, setState = () => {} } = action.payload;
  try {
    const response = yield call(api.deleteCalendarEvent, eventId);

    yield put(actions.deleteCalendarEventSuccessed(response));
    toast.success('Xóa sự kiện thành công.');
    setState(true);
  } catch (error) {
    yield put(actions.deleteCalendarEventFailed(error));
    toast.error('Xóa sự kiện thất bại.');
    setState(false);
  }

  yield put(handleLoading(false));
}

export function* watchCalendarEvent() {
  yield takeEvery(type.FETCH_CALENDAR_EVENTS, fetchCalendarEvents);
  yield takeEvery(type.FETCH_CALENDAR_EVENTS_BY_DATE, fetchCalendarEventsByDate);
  yield takeEvery(type.FETCH_CALENDAR_EVENT, fetchCalendarEvent);
  yield takeEvery(type.CREATE_CALENDAR_EVENT, createCalendarEvent);
  yield takeEvery(type.UPDATE_CALENDAR_EVENT, updateCalendarEvent);
  yield takeEvery(type.DELETE_CALENDAR_EVENT, deleteCalendarEvent);
}

function* adminSaga() {
  yield all([fork(watchCalendarEvent)]);
}

export default adminSaga;
