import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import adminSaga from './admin/saga';
import collectionSaga from './collection/saga';
import matrixSaga from './matrix/saga';
import partnerSaga from './partner/saga';
import campaignSaga from './campaign/saga';
import customerSaga from './customer/saga';
import leadSaga from './lead/saga';
import commonSaga from './common/saga';
import importSaga from './import/saga';
import productSaga from './product/saga';
import kpiSaga from './kpi/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    layoutSaga(),
    adminSaga(),
    collectionSaga(),
    matrixSaga(),
    partnerSaga(),
    campaignSaga(),
    customerSaga(),
    leadSaga(),
    commonSaga(),
    importSaga(),
    productSaga(),
    kpiSaga(),
  ]);
}
