import { ThemeProvider, createTheme } from '@mui/material';
import { ColorModeContext } from 'ColorMode';
import { merge } from 'lodash-es';
import { IThemeOptions } from './themeTypes';
import { themeColors } from './themeColors';
import themeOptions from './themeOptions';

interface AppThemeProps {
  children: React.ReactNode;
}

export default function AppTheme({ children }: AppThemeProps) {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      mode,
    }),
    [mode]
  );

  const theme = useMemo(
    () => createTheme(merge({ cssVariables: true }, themeColors[mode], themeOptions) as IThemeOptions),
    [mode]
  );

  // Prevent zooming input on iOS
  theme.typography.body1 = {
    ...theme.typography.body1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme} disableTransitionOnChange>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
