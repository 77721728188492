import {
  add,
  addMonths,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMonths,
  endOfMonth,
  format,
  getMonth,
  getYear,
  parse,
  startOfDay,
  startOfMonth,
  sub,
  subMonths,
} from 'date-fns';
import vi from 'date-fns/locale/vi';
import { TFunction } from 'i18next';

export const dateFormat = 'dd/MM/yyyy';
export const formatMonthYear = 'MM, yyyy';
export const datetimeFormat = 'dd/MM/yyyy HH:mm:ss';
// TODO: remove this use toISO
export const iso8601Format = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";

type DateInput = Date | string | number | null | undefined;

const convertToValidDate = (date: DateInput) => {
  if (!date) return null;
  date = new Date(date);
  if (Number.isNaN(date.valueOf())) {
    return null;
  }
  return date;
};

export const formatDate = (date: DateInput, formatStr = dateFormat, invalidStr = 'N/A') => {
  // date ? moment(date).format(formatStr) : invalidStr;
  date = convertToValidDate(date);
  return date ? format(new Date(date), formatStr) : invalidStr;
};

export const formatLocalDate = (
  date: string | Date,
  formatStr = dateFormat,
  invalidStr = 'N/A',
  timezone = 'Asia/Ho_Chi_Minh'
) =>
  // date ? moment(date).local().format(formatStr) : invalidStr;
  date ? format(new Date(date), formatStr) : invalidStr;

export const toISO = (date: DateInput) => {
  // return moment(date).utc().format(iso8601Format);

  date = convertToValidDate(date);
  return date ? date.toISOString().replace('Z', '+0000') : '';
  // return !Number.isNaN(date.valueOf()) ? format(date, iso8601Format) : '';
};

export const convertToDate = (dateString: string, formatString: string) => {
  return parse(dateString, formatString, new Date());
};

export const currentDate = (formatStr = dateFormat) =>
  //  moment().format(formatStr);
  format(new Date(), formatStr);

export const subDate = (type = 'days', day: number, formatStr = dateFormat) => {
  // return moment().subtract(day, type).format(formatStr);

  const result = sub(new Date(), { [type]: day });
  return format(result, formatStr);
};

export const addDate = (type = 'days', day: number, formatStr = dateFormat) => {
  // return moment().add(day, type).format(formatStr);

  const result = add(new Date(), { [type]: day });
  return format(result, formatStr);
};

export const addDaysFromDateSubmit = (date: Date, type = 'days', day: number, formatStr = iso8601Format) => {
  // return moment(date).add(day, type).format(formatStr);

  const result = add(date, { [type]: day });
  return format(result, formatStr);
};
export const subDateFormatFullTime = (startDate: string | Date, endDate: string | Date, hasHour = false) => {
  // const starts = moment(new Date(startDate));
  // const ends = moment(new Date(endDate));

  // const duration = moment.duration(ends.diff(starts));
  // const data = duration._data;

  // if (hasHour)
  //   return data.months
  //     ? `${data.months} tháng ${data.days} ngày ${data.hours ? `${data.hours} giờ` : ''} `
  //     : `${data.days} ngày ${data.hours ? `${data.hours} giờ` : ''}`;

  // const rs = data.months ? `${data.months} tháng ${data.days} ngày` : `${data.days} ngày`;
  // return rs;

  const starts = new Date(startDate);
  const ends = new Date(endDate);

  const months = differenceInMonths(ends, starts);
  const days = differenceInCalendarDays(ends, starts) - months * 30;
  const hours = differenceInHours(ends, starts) - months * 30 * 24 - days * 24;

  if (hasHour)
    return months
      ? `${months} tháng ${days} ngày ${hours ? `${hours} giờ` : ''} `
      : `${days} ngày ${hours ? `${hours} giờ` : ''}`;

  const rs = months ? `${months} tháng ${days} ngày` : `${days} ngày`;
  return rs;
};

export const rangeDate = (firstDaysAgo: number, secondDaysAgo: number) => [
  subDate('days', firstDaysAgo),
  subDate('days', secondDaysAgo),
];

export const rangeNextDate = (firstDaysAgo: number, secondDaysAgo: number) => [
  addDate('days', firstDaysAgo),
  addDate('days', secondDaysAgo),
];

export const firstDayOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth(), 1);
export const lastDayOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 1);

export const firstDayOfMonthFormat = (date: DateInput, formatString = datetimeFormat) => {
  date = convertToValidDate(date);
  return date ? format(startOfMonth(date), formatString) : 'N/A';
};
export const lastDayOfMonthFormat = (date: DateInput, formatString = datetimeFormat) => {
  date = convertToValidDate(date);
  return date ? format(endOfMonth(date), formatString) : 'N/A';
};

export const firstDayOfMonthISOformat = () =>
  // moment(new Date()).startOf('month').format(iso8601Format);
  format(startOfMonth(new Date()), iso8601Format);
export const lastDayOfMonthISOformat = () =>
  // moment(new Date()).endOf('month').format(iso8601Format);
  format(endOfMonth(new Date()), iso8601Format);

export const firstDayOfYear = (date: Date) => new Date(date.getFullYear(), 0, 1);
export const lastDayOfYear = (date: Date) => new Date(date.getFullYear() + 1, 0, 1);

export const rangeLastMonth = () =>
  //  [
  //   moment().subtract(1, 'months').startOf('month').format(dateFormat),
  //   moment().subtract(1, 'months').endOf('month').format(dateFormat),
  // ];
  [
    format(startOfMonth(subMonths(new Date(), 1)), dateFormat),
    format(endOfMonth(subMonths(new Date(), 1)), dateFormat),
  ];

export const rangeNextMonth = () =>
  // [
  //   moment().add(1, 'months').startOf('month').format(dateFormat),
  //   moment().add(1, 'months').endOf('month').format(dateFormat),
  // ];
  [
    format(startOfMonth(addMonths(new Date(), 1)), dateFormat),
    format(endOfMonth(addMonths(new Date(), 1)), dateFormat),
  ];

export const rangeCurrentMonth = () =>
  // [moment().startOf('month').format(dateFormat), currentDate()];
  [format(startOfMonth(new Date()), dateFormat), format(new Date(), dateFormat)];

export const rangeFullDaysCurrentMonth = () =>
  //  [
  //   moment().startOf('month').format(dateFormat),
  //   moment().endOf('month').format(dateFormat),
  // ];
  [format(startOfMonth(new Date()), dateFormat), format(endOfMonth(new Date()), dateFormat)];

export const menuFilter = [
  {
    label: 'Hôm nay',
    radioValue: 'today',
    value: [currentDate()],
  },
  {
    label: 'Hôm qua',
    radioValue: 'yesterday',
    value: [subDate('days', 1)],
  },
  {
    label: '7 ngày vừa qua',
    radioValue: 'last7days',
    value: rangeDate(7, 0),
  },
  {
    label: '30 ngày vừa qua',
    radioValue: 'last30days',
    value: rangeDate(30, 0),
  },
  {
    label: 'Tháng này',
    radioValue: 'currentMonth',
    value: rangeCurrentMonth(),
  },
  {
    label: 'Tháng trước',
    radioValue: 'lastMonth',
    value: rangeLastMonth(),
  },
];

export const menuFilterLoanPayment = [
  {
    label: 'Hôm nay',
    radioValue: 'today',
    value: [currentDate()],
  },
  {
    label: 'Ngày mai',
    radioValue: 'tomorrow',
    value: [addDate('days', 1)],
  },
  {
    label: '7 ngày tới',
    radioValue: 'next7days',
    value: rangeNextDate(7, 0),
  },
  {
    label: '30 ngày tới',
    radioValue: 'next30days',
    value: rangeNextDate(30, 0),
  },
  {
    label: 'Tháng này',
    radioValue: 'currentMonth',
    value: rangeFullDaysCurrentMonth(),
  },
  {
    label: 'Tháng sau',
    radioValue: 'nextMonth',
    value: rangeNextMonth(),
  },
];

export const renderMonth = (date: DateInput, t: TFunction) => {
  // const month = moment(date).month();
  // const year = moment(date).year();

  date = convertToValidDate(date);
  if (!date) return '';

  const month = getMonth(date);
  const year = getYear(date);

  if (month !== null && year !== null) return `${t(`months:month${month + 1}`)}, ${year}`;
  return '';
};

export const getPeriodMonth = (previousMonthCount: number, t: TFunction) =>
  // ({
  //   value: previousMonthCount,
  //   label: renderMonth(moment().subtract(previousMonthCount, 'months').startOf('month'), t),

  //   fromDate: moment().subtract(previousMonthCount, 'months').startOf('month').format('yyyy-MM-dd'),
  //   toDate: moment().subtract(previousMonthCount, 'months').endOf('month').subtract(1, 'days').format('yyyy-MM-dd'),
  // });
  ({
    value: previousMonthCount,
    label: format(startOfMonth(subMonths(new Date(), previousMonthCount)), `MMMM, yyyy`, { locale: vi }),
    fromDate: format(startOfMonth(subMonths(new Date(), previousMonthCount)), dateFormat),
    toDate: format(endOfMonth(subMonths(new Date(), previousMonthCount)), dateFormat),
  });

export const getPeriodMonthList = (t: TFunction) => {
  const PERIOD_MONTH_LIST = [];
  for (let i = 0; i < 12; i += 1) {
    PERIOD_MONTH_LIST.push(getPeriodMonth(i, t));
  }

  return PERIOD_MONTH_LIST;
};

// export const clearTime = (date: DateInput) => {
//   const result = convertToValidDate(date);
//   if (!result) return null;
//   result.setHours(0);
//   result.setMinutes(0);
//   result.setSeconds(0);
//   result.setMilliseconds(0);
//   return result;
// };

export const toMaxOfDay = (date: DateInput) => {
  // const result = clearTime(date);
  let result = convertToValidDate(date);
  result = result ? startOfDay(result) : null;

  if (!result) return null;
  result.setDate(result.getDate() + 1);
  result.setSeconds(result.getSeconds() - 1);
  return result;
};
