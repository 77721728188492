export const arrayMove = (arr: any[], sourceIndex: number, targetIndex: number) => {
  if (targetIndex >= arr.length) return;
  arr.splice(targetIndex, 0, arr.splice(sourceIndex, 1)[0]);
};

export const eventComparator = (event1: any, event2: any) => {
  if (new Date(event1.startTime) < new Date(event2.startTime)) return -1;
  if (new Date(event1.startTime) > new Date(event2.startTime)) return 1;
  return 0;
};

export const sortDateArray = <T>(array: T[], comparator: (a: T, b: T) => number) => {
  if (!Array.isArray(array) || array.length < 2) return array;

  const sortedArray = [...array];
  sortedArray.sort(comparator);

  return sortedArray;
};

export const isAvailableArray = (array: any) => {
  return array !== null && Array.isArray(array) && array.length > 0;
};
