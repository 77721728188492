import styled from '@emotion/styled';

export const ToggleButton = styled.button(
  (props) => `
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 500px;
  width: 65px;
  height: 65px;
  background: #0068e0;
  border: none;
  color: #fff;
  border-radius: 32.5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  z-index: 9999;

  &:hover {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
  }

  ${
    props.opened &&
    `
      bottom: 12px;
      border-top-right-radius: 5px;
    `
  }
`
);

export const Box = styled.div(
  (props) => `
  position: fixed;
  bottom: 80px;
  right: 500px;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  background: #263238;
  border-radius: 15px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(30px);
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.2);
  transition:
    opacity 0.15s ease-in,
    transform 0.25s ease-in,
    border-radius 0.3s ease-in-out,
    visibility 0s linear 0.3s;
  z-index: 9999;

  ${
    props.opened &&
    `
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition:
        opacity 0.15s ease-out,
        transform 0.25s ease-out,
        border-radius 0.3s ease-in-out,
        visibility 0s;
      border-bottom-right-radius: 5px;
    `
  }
`
);

export const Input = styled.input`
  width: 100%;
  margin-bottom: 5px;
  border: none;
  background: #37474f;
  height: 40px;
  padding: 0 15px;
  border-radius: 4px;
  color: #cfd8dc;
`;

export const ButtonsContainer = styled.div`
  font-size: 0;
`;

export const Button = styled.button`
  display: inline-block;
  width: calc(33.33% - 10px);
  font-size: 18px;
  background: transparent;
  border: none;
  color: #cfd8dc;
  padding: 15px 0;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #fff;
  }

  &:nth-child(3n - 1) {
    margin: 0 15px;
  }
`;

export const ActionButton = styled.button`
  display: inline-block;
  width: calc(33.33% - 10px);
  font-size: 18px;
  background: transparent;
  border: none;
  color: #cfd8dc;
  padding: 15px 0;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #fff;
  }

  &:nth-child(3n - 1) {
    margin: 0 15px;
  }
`;

export const CallButton = styled.button`
  margin-top: 5px;
  margin-left: 30%;
  background: #8bc34a;
  color: #fff;
  width: 40%;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  border: none;
  cursor: pointer;

  &:hover {
    background: #7cb342;
  }
`;

export const DeleteButton = styled.button`
  margin-top: 5px;
  margin-left: 7%;
  background: transparent;
  color: #cfd8dc;
  width: 16%;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  border: none;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

export const EndCallButton = styled.button`
  margin-top: 5px;
  margin-left: 30%;
  background: #ff0000;
  color: #fff;
  width: 40%;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  border: none;
  cursor: pointer;

  &:hover {
    background: #a70000;
  }
`;

export const CallInfo = styled.div`
  color: #fff;
  text-align: center;
`;

export const StatusInfo = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TypeSelect = styled.select`
  border: 0;
  background-color: transparent;
  color: #fff;
  text-align: right;

  &:focus-visible {
    outline: none;
  }
`;
