/* AUTH */
/* Manage USER account */
export const FETCH_USERS = 'admin/FETCH_USERS';
export const FETCH_USERS_SUCCESSED = 'admin/FETCH_USERS_SUCCESSED';
export const FETCH_USERS_FAILED = 'admin/FETCH_USERS_FAILED';

export const FETCH_USERS_INCLUDE_DELETED = 'admin/FETCH_USERS_INCLUDE_DELETED';
export const FETCH_USERS_INCLUDE_DELETED_SUCCESSED = 'admin/FETCH_USERS_INCLUDE_DELETED_SUCCESSED';
export const FETCH_USERS_INCLUDE_DELETED_FAILED = 'admin/FETCH_USERS_INCLUDE_DELETED_FAILED';

export const FETCH_USERS_BY_FILTER = 'admin/FETCH_BY_FILTER_USERS';
export const FETCH_USERS_BY_FILTER_SUCCESSED = 'admin/FETCH_USERS_BY_FILTER_SUCCESSED';
export const FETCH_USERS_BY_FILTER_FAILED = 'admin/FETCH_USERS_BY_FILTER_FAILED';

export const FETCH_USER_DETAIL = 'admin/FETCH_USER_DETAIL';
export const FETCH_USER_DETAIL_SUCCESSED = 'admin/FETCH_USER_DETAIL_SUCCESSED';
export const FETCH_USER_DETAIL_FAILED = 'admin/FETCH_USER_DETAIL_FAILED';
export const CLEAR_USER_DETAIL = 'admin/CLEAR_USER_DETAIL';

export const FETCH_USERS_BY_GROUP = 'admin/FETCH_USERS_BY_GROUP';
export const FETCH_USERS_BY_GROUP_SUCCESSED = 'admin/FETCH_USERS_BY_GROUP_SUCCESSED';
export const FETCH_USERS_BY_GROUP_FAILED = 'admin/FETCH_USERS_BY_GROUP_FAILED';

export const FETCH_USERS_BY_ROLES = 'admin/FETCH_USERS_BY_ROLES';
export const FETCH_USERS_BY_ROLES_SUCCESSED = 'admin/FETCH_USERS_BY_ROLES_SUCCESSED';
export const FETCH_USERS_BY_ROLES_FAILED = 'admin/FETCH_USERS_BY_ROLES_FAILED';

export const FETCH_MANAGED_MEMBERS = 'admin/FETCH_MANAGED_MEMBERS';
export const FETCH_MANAGED_MEMBERS_SUCCESSED = 'admin/FETCH_MANAGED_MEMBERS_SUCCESSED';
export const FETCH_MANAGED_MEMBERS_FAILED = 'admin/FETCH_MANAGED_MEMBERS_FAILED';

export const FETCH_MANAGED_MEMBERS_INCULDE_DELETED = 'admin/FETCH_MANAGED_MEMBERS_INCULDE_DELETED';
export const FETCH_MANAGED_MEMBERS_INCULDE_DELETED_SUCCESSED = 'admin/FETCH_MANAGED_MEMBERS_INCULDE_DELETED_SUCCESSED';
export const FETCH_MANAGED_MEMBERS_INCULDE_DELETED_FAILED = 'admin/FETCH_MANAGED_MEMBERS_INCULDE_DELETED_FAILED';

export const CREATE_USER = 'admin/CREATE_USER';
export const CREATE_USER_SUCCESSED = 'admin/CREATE_USER_SUCCESSED';
export const CREATE_USER_FAILED = 'admin/CREATE_USER_FAILED';

export const UPDATE_USER = 'admin/UPDATE_USER';
export const UPDATE_USER_SUCCESSED = 'admin/UPDATE_USER_SUCCESSED';
export const UPDATE_USER_FAILED = 'admin/UPDATE_USER_FAILED';

export const UPDATE_STATUS_USER = 'admin/UPDATE_STATUS_USER';
export const UPDATE_STATUS_USER_SUCCESSED = 'admin/UPDATE_STATUS_USER_SUCCESSED';
export const UPDATE_STATUS_USER_FAILED = 'admin/UPDATE_STATUS_USER_FAILED';

export const CHANGE_PWD_USER = 'admin/CHANGE_PWD_USER';
export const CHANGE_PWD_USER_SUCCESSED = 'admin/CHANGE_PWD_USER_SUCCESSED';
export const CHANGE_PWD_USER_FAILED = 'admin/CHANGE_PWD_USER_FAILED';

export const ACTIVE_USER = 'admin/ACTIVE_USER';
export const ACTIVE_USER_SUCCESSED = 'admin/ACTIVEE_USER_SUCCESSED';
export const ACTIVE_USER_FAILED = 'admin/ACTIVE_USER_FAILED';

export const DEACTIVE_USER = 'admin/DEACTIVE_USER';
export const DEACTIVE_USER_SUCCESSED = 'admin/DEACTIVEE_USER_SUCCESSED';
export const DEACTIVE_USER_FAILED = 'admin/DEACTIVE_USER_FAILED';

export const DELETE_USER = 'admin/DELETE_USER';
export const DELETE_USER_SUCCESSED = 'admin/DELETE_USER_SUCCESSED';
export const DELETE_USER_FAILED = 'admin/DELETE_USER_FAILED';

export const RESTORE_USER = 'admin/RESTORE_USER';
export const RESTORE_USER_SUCCESSED = 'admin/RESTORE_USER_SUCCESSED';
export const RESTORE_USER_FAILED = 'admin/RESTORE_USER_FAILED';

export const SET_LEADER_USER = 'admin/SET_LEADER_USER';
export const SET_LEADER_USER_SUCCESSED = 'admin/SET_LEADER_USER_SUCCESSED';
export const SET_LEADER_USER_FAILED = 'admin/SET_LEADER_USER_FAILED';

export const UPDATE_USER_PERMISSIONS = 'admin/UPDATE_USER_PERMISSIONS';
export const UPDATE_USER_PERMISSIONS_SUCCESSED = 'admin/UPDATE_USER_PERMISSIONS_SUCCESSED';
export const UPDATE_USER_PERMISSIONS_FAILED = 'admin/UPDATE_USER_PERMISSIONS_FAILED';

/* Manage role */
export const FETCH_ROLES = 'admin/FETCH_ROLES';
export const FETCH_ROLES_SUCCESSED = 'admin/FETCH_ROLES_SUCCESSED';
export const FETCH_ROLES_FAILED = 'admin/FETCH_ROLES_FAILED';

export const CREATE_ROLE = 'admin/CREATE_ROLE';
export const CREATE_ROLE_SUCCESSED = 'admin/CREATE_ROLE_SUCCESSED';
export const CREATE_ROLE_FAILED = 'admin/CREATE_ROLE_FAILED';

export const UPDATE_ROLE = 'admin/CUPDATE_ROLE';
export const UPDATE_ROLE_SUCCESSED = 'admin/UPDATE_ROLE_SUCCESSED';
export const UPDATE_ROLE_FAILED = 'admin/UPDATE_ROLE_FAILED';

export const DELETE_ROLE = 'admin/DELETE_ROLE';
export const DELETE_ROLE_SUCCESSED = 'admin/DELETE_ROLE_SUCCESSED';
export const DELETE_ROLE_FAILED = 'admin/DELETE_ROLE_FAILED';

export const UPDATE_ROLES_PERMISSION = 'admin/UPDATE_ROLES_PERMISSION';
export const UPDATE_ROLES_PERMISSION_SUCCESSED = 'admin/UPDATE_ROLES_PERMISSION_SUCCESSED';
export const UPDATE_ROLES_PERMISSION_FAILED = 'admin/UPDATE_ROLES_PERMISSION_FAILED';

/* Manage group */

export const FETCH_GROUP_TYPES = 'admin/FETCH_GROUP_TYPES';
export const FETCH_GROUP_TYPES_SUCCESSED = 'admin/FETCH_GROUP_TYPES_SUCCESSED';

export const FETCH_GROUPS_BY_TYPE = 'admin/FETCH_GROUPS_BY_TYPE';
export const FETCH_GROUPS_BY_TYPE_SUCCESSED = 'admin/FETCH_GROUPS_BY_TYPE_SUCCESSED';
export const FETCH_GROUPS_BY_TYPE_FAILED = 'admin/FETCH_GROUPS_BY_TYPE_FAILED';

export const FETCH_GROUPS = 'admin/FETCH_GROUPS';
export const FETCH_GROUPS_SUCCESSED = 'admin/FETCH_GROUPS_SUCCESSED';
export const FETCH_GROUPS_FAILED = 'admin/FETCH_GROUPS_FAILED';

export const CREATE_GROUP = 'admin/CREATE_GROUP';
export const CREATE_GROUP_SUCCESSED = 'admin/CREATE_GROUP_SUCCESSED';
export const CREATE_GROUP_FAILED = 'admin/CREATE_GROUP_FAILED';

export const UPDATE_GROUP = 'admin/UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESSED = 'admin/UPDATE_GROUP_SUCCESSED';
export const UPDATE_GROUP_FAILED = 'admin/UPDATE_GROUP_FAILED';

export const DELETE_GROUP = 'admin/DELETE_GROUP';
export const DELETE_GROUP_SUCCESSED = 'admin/DELETE_GROUP_SUCCESSED';
export const DELETE_GROUP_FAILED = 'admin/DELETE_GROUP_FAILED';

export const FETCH_GROUPS_WITH_USERS = 'admin/FETCH_GROUPS_WITH_USERS';
export const FETCH_GROUPS_WITH_USERS_SUCCESSED = 'admin/FETCH_GROUPS_WITH_USERS_SUCCESSED';
export const FETCH_GROUPS_WITH_USERS_FAILED = 'admin/FETCH_GROUPS_WITH_USERS_FAILED';

export const FETCH_GROUP_TREE = 'admin/FETCH_GROUP_TREE';
export const FETCH_GROUP_TREE_SUCCESSED = 'admin/FETCH_GROUP_TREE_SUCCESSED';
export const FETCH_GROUP_TREE_FAILED = 'admin/FETCH_GROUP_TREE_FAILED';

/* Manage resource */

export const FETCH_CLIENT_ROLES = 'admin/FETCH_CLIENT_ROLES';
export const FETCH_CLIENT_ROLES_SUCCESSED = 'admin/FETCH_CLIENT_ROLES_SUCCESSED';
export const FETCH_CLIENT_ROLES_FAILED = 'admin/FETCH_CLIENT_ROLES_FAILED';

export const UPDATE_CLIENT_ROLE = 'admin/UPDATE_CLIENT_ROLE';
export const UPDATE_CLIENT_ROLE_SUCCESSED = 'admin/UPDATE_CLIENT_ROLE_SUCCESSED';
export const UPDATE_CLIENT_ROLE_FAILED = 'admin/UPDATE_CLIENT_ROLE_FAILED';

/* Param table */
export const FETCH_PARAM_TABLES = 'admin/FETCH_PARAM_TABLES';
export const FETCH_PARAM_TABLES_SUCCESSED = 'admin/FETCH_PARAM_TABLES_SUCCESSED';
export const FETCH_PARAM_TABLES_FAILED = 'admin/FETCH_PARAM_TABLES_FAILED';

export const FETCH_PARAM_TABLE_DETAIL = 'admin/FETCH_PARAM_TABLE_DETAIL';
export const FETCH_PARAM_TABLE_DETAIL_SUCCESSED = 'admin/FETCH_PARAM_TABLE_DETAIL_SUCCESSED';
export const FETCH_PARAM_TABLE_DETAIL_FAILED = 'admin/FETCH_PARAM_TABLE_DETAIL_FAILED';
export const CLEAR_PARAM_TABLE_DETAIL = 'admin/CLEAR_PARAM_TABLE_DETAIL';

export const UPDATE_PARAM_TABLE = 'admin/UPDATE_PARAM_TABLE';
export const UPDATE_PARAM_TABLE_SUCCESSED = 'admin/UPDATE_PARAM_TABLE_SUCCESSED';
export const UPDATE_PARAM_TABLE_FAILED = 'admin/UPDATE_PARAM_TABLE_FAILED';

export const DELETE_PARAM_TABLE = 'admin/DELETE_PARAM_TABLE';
export const DELETE_PARAM_TABLE_SUCCESSED = 'admin/DELETE_PARAM_TABLE_SUCCESSED';
export const DELETE_PARAM_TABLE_FAILED = 'admin/DELETE_PARAM_TABLE_FAILED';

export const CREATE_PARAM_TABLE = 'admin/CREATE_PARAM_TABLE';
export const CREATE_PARAM_TABLE_SUCCESSED = 'admin/CREATE_PARAM_TABLE_SUCCESSED';
export const CREATE_PARAM_TABLE_FAILED = 'admin/CREATE_PARAM_TABLE_FAILED';

export const FETCH_PARAM_TABLE_ENTRIES = 'admin/FETCH_PARAM_TABLE_ENTRIES';
export const FETCH_PARAM_TABLE_ENTRIES_SUCCESSED = 'admin/FETCH_PARAM_TABLE_ENTRIES_SUCCESSED';
export const FETCH_PARAM_TABLE_ENTRIES_FAILED = 'admin/FETCH_PARAM_TABLE_ENTRIES_FAILED';

export const UPDATE_PARAM_TABLE_ENTRIES = 'admin/UPDATE_PARAM_TABLE_ENTRIES';
export const UPDATE_PARAM_TABLE_ENTRIES_SUCCESSED = 'admin/UPDATE_PARAM_TABLE_ENTRIES_SUCCESSED';
export const UPDATE_PARAM_TABLE_ENTRIES_FAILED = 'admin/UPDATE_PARAM_TABLE_ENTRIES_FAILED';

export const DELETE_PARAM_TABLE_ENTRIES = 'admin/DELETE_PARAM_TABLE_ENTRIES';
export const DELETE_PARAM_TABLE_ENTRIES_SUCCESSED = 'admin/DELETE_PARAM_TABLE_ENTRIES_SUCCESSED';
export const DELETE_PARAM_TABLE_ENTRIES_FAILED = 'admin/DELETE_PARAM_TABLE_ENTRIES_FAILED';

export const CREATE_PARAM_TABLE_ENTRIES = 'admin/CREATE_PARAM_TABLE_ENTRIES';
export const CREATE_PARAM_TABLE_ENTRIES_SUCCESSED = 'admin/CREATE_PARAM_TABLE_ENTRIES_SUCCESSED';
export const CREATE_PARAM_TABLE_ENTRIES_FAILED = 'admin/CREATE_PARAM_TABLE_ENTRIES_FAILED';

/* Notification */
export const FETCH_NOTIFICATION_TEMPLATE = 'admin/FETCH_NOTIFICATION_TEMPLATE';
export const FETCH_NOTIFICATION_TEMPLATE_SUCCESSED = 'admin/FETCH_NOTIFICATION_TEMPLATE_SUCCESSED';
export const FETCH_NOTIFICATION_TEMPLATE_FAILED = 'admin/FETCH_NOTIFICATION_TEMPLATE_FAILED';

export const CREATE_TEMPLATE = 'admin/CREATE_TEMPLATE';
export const CREATE_TEMPLATE_SUCCESSED = 'admin/CREATE_TEMPLATE_SUCCESSED';
export const CREATE_TEMPLATE_FAILED = 'admin/CREATE_TEMPLATE_FAILED';

export const UPDATE_TEMPLATE = 'admin/UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_SUCCESSED = 'admin/UPDATE_TEMPLATE_SUCCESSED';
export const UPDATE_TEMPLATE_FAILED = 'admin/UPDATE_TEMPLATE_FAILED';

export const DELETE_TEMPLATE = 'admin/DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESSED = 'admin/DELETE_TEMPLATE_SUCCESSED';
export const DELETE_TEMPLATE_FAILED = 'admin/DELETE_TEMPLATE_FAILED';

/* Activity log */
export const FETCH_ACTIVITY_TYPE = 'admin/FETCH_ACTIVITY_TYPE';
export const FETCH_ACTIVITY_TYPE_SUCCESSED = 'admin/FETCH_ACTIVITY_TYPE_SUCCESSED';
export const FETCH_ACTIVITY_TYPE_FAILED = 'admin/FETCH_ACTIVITY_TYPE_FAILED';

export const FETCH_ACTIVITY_LOGS = 'admin/FETCH_ACTIVITY_LOGS';
export const FETCH_ACTIVITY_LOGS_SUCCESSED = 'admin/FETCH_ACTIVITY_LOGS_SUCCESSED';
export const FETCH_ACTIVITY_LOGS_FAILED = 'admin/FETCH_ACTIVITY_LOGS_FAILED';

export const FETCH_ACTIVITY_LOGS_BY_FILTER = 'admin/FETCH_ACTIVITY_LOGS_BY_FILTER';
export const FETCH_ACTIVITY_LOGS_BY_FILTER_SUCCESSED = 'admin/FETCH_ACTIVITY_LOGS_BY_FILTER_SUCCESSED';
export const FETCH_ACTIVITY_LOGS_BY_FILTER_FAILED = 'admin/FETCH_ACTIVITY_LOGS_BY_FILTER_FAILED';

/* Table flow */
export const FETCH_CURRENT_LEAD_FLOW = 'admin/FETCH_CURRENT_LEAD_FLOW';
export const FETCH_CURRENT_LEAD_FLOW_SUCCESSED = 'admin/FETCH_CURRENT_LEAD_FLOW_SUCCESSED';
export const FETCH_CURRENT_LEAD_FLOW_FAILED = 'admin/FETCH_CURRENT_LEAD_FLOW_FAILED';

export const CREATE_LEAD_FLOW = 'admin/CREATE_LEAD_FLOW';
export const CREATE_LEAD_FLOW_SUCCESSED = 'admin/CREATE_LEAD_FLOW_SUCCESSED';
export const CREATE_LEAD_FLOW_FAILED = 'admin/CREATE_LEAD_FLOW_FAILED';

/* Workflow */
export const FETCH_WORKFLOWS = 'admin/FETCH_WORKFLOWS';
export const FETCH_WORKFLOWS_SUCCESSED = 'admin/FETCH_WORKFLOWS_SUCCESSED';
export const FETCH_WORKFLOWS_FAILED = 'admin/FETCH_WORKFLOWS_FAILED';

export const FETCH_ALL_WORKFLOWS = 'admin/FETCH_ALL_WORKFLOWS';
export const FETCH_ALL_WORKFLOWS_SUCCESSED = 'admin/FETCH_ALL_WORKFLOWS_SUCCESSED';

export const FETCH_WORKFLOW = 'admin/FETCH_WORKFLOW';
export const FETCH_WORKFLOW_SUCCESSED = 'admin/FETCH_WORKFLOW_SUCCESSED';
export const FETCH_WORKFLOW_FAILED = 'admin/FETCH_WORKFLOW_FAILED';

export const DEPLOY_WORKFLOW = 'admin/DEPLOY_WORKFLOW';
export const DEPLOY_WORKFLOW_SUCCESSED = 'admin/DEPLOY_WORKFLOW_SUCCESSED';
export const DEPLOY_WORKFLOW_FAILED = 'admin/DEPLOY_WORKFLOW_FAILED';

export const ACTIVATE_WORKFLOW = 'admin/ACTIVATE_WORKFLOW';
export const ACTIVATE_WORKFLOW_SUCCESSED = 'admin/ACTIVATE_WORKFLOW_SUCCESSED';
export const ACTIVATE_WORKFLOW_FAILED = 'admin/ACTIVATE_WORKFLOW_FAILED';

export const DEACTIVATE_WORKFLOW = 'admin/DEACTIVATE_WORKFLOW';
export const DEACTIVATE_WORKFLOW_SUCCESSED = 'admin/DEACTIVATE_WORKFLOW_SUCCESSED';
export const DEACTIVATE_WORKFLOW_FAILED = 'admin/DEACTIVATE_WORKFLOW_FAILED';

/* Calendar settings */
export const FETCH_DAY_OFF_EVENT = 'admin/FETCH_DAY_OFF_EVENT';
export const FETCH_DAY_OFF_EVENT_SUCCESSED = 'admin/FETCH_DAY_OFF_EVENT_SUCCESSED';
export const FETCH_DAY_OFF_EVENT_FAILED = 'admin/FETCH_DAY_OFF_EVENT_FAILED';

export const CREATE_DAY_OFF_EVENT = 'admin/CREATE_DAY_OFF_EVENT';
export const CREATE_DAY_OFF_EVENT_SUCCESSED = 'admin/CREATE_DAY_OFF_EVENT_SUCCESSED';
export const CREATE_DAY_OFF_EVENT_FAILED = 'admin/CREATE_DAY_OFF_EVENT_FAILED';

export const DELETE_DAY_OFF_EVENT = 'admin/DELETE_DAY_OFF_EVENT';
export const DELETE_DAY_OFF_EVENT_SUCCESSED = 'admin/DELETE_DAY_OFF_EVENT_SUCCESSED';
export const DELETE_DAY_OFF_EVENT_FAILED = 'admin/DELETE_DAY_OFF_EVENT_FAILED';

export const FETCH_CALENDAR_SETTING = 'admin/FETCH_CALENDAR_SETTING';
export const FETCH_CALENDAR_SETTING_SUCCESSED = 'admin/FETCH_CALENDAR_SETTING_SUCCESSED';
export const FETCH_CALENDAR_SETTING_FAILED = 'admin/FETCH_CALENDAR_SETTING_FAILED';

export const SET_CALENDAR_SETTING = 'admin/SET_CALENDAR_SETTING';
export const SET_CALENDAR_SETTING_SUCCESSED = 'admin/SET_CALENDAR_SETTING_SUCCESSED';
export const SET_CALENDAR_SETTING_FAILED = 'admin/SET_CALENDAR_SETTING_FAILED';

/** Settings */
export const FETCH_ALL_KPI = 'admin/FETCH_ALL_KPI';
export const FETCH_ALL_KPI_SUCCESSED = 'admin/FETCH_ALL_KPI_SUCCESSED';
export const FETCH_ALL_KPI_FAILED = 'admin/FETCH_ALL_KPI_FAILED';

export const SET_KPI = 'admin/SET_KPI';
export const SET_KPI_SUCCESSED = 'admin/SET_KPI_SUCCESSED';
export const SET_KPI_FAILED = 'admin/SET_KPI_FAILED';

export const FETCH_ALL_BUCKET = 'admin/FETCH_ALL_BUCKET';
export const FETCH_ALL_BUCKET_SUCCESSED = 'admin/FETCH_ALL_BUCKET_SUCCESSED';
export const FETCH_ALL_BUCKET_FAILED = 'admin/FETCH_ALL_BUCKET_FAILED';

export const SET_BUCKET = 'admin/SET_BUCKET';
export const SET_BUCKET_SUCCESSED = 'admin/SET_BUCKET_SUCCESSED';
export const SET_BUCKET_FAILED = 'admin/SET_BUCKET_FAILED';

export const FETCH_ALL_ACTIVITY_CODE = 'admin/FETCH_ALL_ACTIVITY_CODE';
export const FETCH_ALL_ACTIVITY_CODE_SUCCESSED = 'admin/FETCH_ALL_ACTIVITY_CODE_SUCCESSED';
export const FETCH_ALL_ACTIVITY_CODE_FAILED = 'admin/FETCH_ALL_ACTIVITY_CODE_FAILED';

export const SET_ACTIVITY_CODE = 'admin/SET_ACTIVITY_CODE';
export const SET_ACTIVITY_CODE_SUCCESSED = 'admin/SET_ACTIVITY_CODE_SUCCESSED';
export const SET_ACTIVITY_CODE_FAILED = 'admin/SET_ACTIVITY_CODE_FAILED';

export const FETCH_ALL_LEAD_ACTIVITY_CODE = 'admin/FETCH_ALL_LEAD_ACTIVITY_CODE';
export const FETCH_ALL_LEAD_ACTIVITY_CODE_SUCCESSED = 'admin/FETCH_ALL_LEAD_ACTIVITY_CODE_SUCCESSED';
export const FETCH_ALL_LEAD_ACTIVITY_CODE_FAILED = 'admin/FETCH_ALL_LEAD_ACTIVITY_CODE_FAILED';

export const SET_LEAD_ACTIVITY_CODE = 'admin/SET_LEAD_ACTIVITY_CODE';
export const SET_LEAD_ACTIVITY_CODE_SUCCESSED = 'admin/SET_LEAD_ACTIVITY_CODE_SUCCESSED';
export const SET_LEAD_ACTIVITY_CODE_FAILED = 'admin/SET_LEAD_ACTIVITY_CODE_FAILED';

export const FETCH_ALL_RELATIONSHIP = 'admin/FETCH_ALL_RELATIONSHIP';
export const FETCH_ALL_RELATIONSHIP_SUCCESSED = 'admin/FETCH_ALL_RELATIONSHIP_SUCCESSED';
export const FETCH_ALL_RELATIONSHIP_FAILED = 'admin/FETCH_ALL_RELATIONSHIP_FAILED';

export const SET_RELATIONSHIP = 'admin/SET_RELATIONSHIP';
export const SET_RELATIONSHIP_SUCCESSED = 'admin/SET_RELATIONSHIP_SUCCESSED';
export const SET_RELATIONSHIP_FAILED = 'admin/SET_RELATIONSHIP_FAILED';

export const FETCH_ALL_CHECK_IN_LOCATION = 'admin/FETCH_ALL_CHECK_IN_LOCATION';
export const FETCH_ALL_CHECK_IN_LOCATION_SUCCESSED = 'admin/FETCH_ALL_CHECK_IN_LOCATION_SUCCESSED';
export const FETCH_ALL_CHECK_IN_LOCATION_FAILED = 'admin/FETCH_ALL_CHECK_IN_LOCATION_FAILED';

export const SET_CHECK_IN_LOCATION = 'admin/SET_CHECK_IN_LOCATION';
export const SET_CHECK_IN_LOCATION_SUCCESSED = 'admin/SET_CHECK_IN_LOCATION_SUCCESSED';
export const SET_CHECK_IN_LOCATION_FAILED = 'admin/SET_CHECK_IN_LOCATION_FAILED';

export const FETCH_ORG_INFO = 'admin/FETCH_ORG_INFO';
export const FETCH_ORG_INFO_SUCCESSED = 'admin/FETCH_ORG_INFO_SUCCESSED';
export const FETCH_ORG_INFO_FAILED = 'admin/FETCH_ORG_INFO_FAILED';

export const UPDATE_ORG_INFO = 'admin/UPDATE_ORG_INFO';
export const UPDATE_ORG_INFO_SUCCESSED = 'admin/UPDATE_ORG_INFO_SUCCESSED';
export const UPDATE_ORG_INFO_FAILED = 'admin/UPDATE_ORG_INFO_FAILED';

export const FETCH_ORG_AVATAR = 'admin/FETCH_ORG_AVATAR';
export const FETCH_ORG_AVATAR_SUCCESSED = 'admin/FETCH_ORG_AVATAR_SUCCESSED';
export const FETCH_ORG_AVATAR_FAILED = 'admin/FETCH_ORG_AVATAR_FAILED';

export const FETCH_ALL_GROUP_LOCAL = 'admin/FETCH_ALL_GROUP_LOCAL';
export const FETCH_ALL_GROUP_LOCAL_SUCCESSED = 'admin/FETCH_ALL_GROUP_LOCAL_SUCCESSED';
export const FETCH_ALL_GROUP_LOCAL_FAILED = 'admin/FETCH_ALL_GROUP_LOCAL_FAILED';

export const FETCH_ALL_LOCATION = 'admin/FETCH_ALL_LOCATION';
export const FETCH_ALL_LOCATION_SUCCESSED = 'admin/FETCH_ALL_LOCATION_SUCCESSED';
export const FETCH_ALL_LOCATION_FAILED = 'admin/FETCH_ALL_LOCATION_FAILED';

export const FETCH_NESTED_DISTRICT = 'admin/FETCH_NESTED_DISTRICT';
export const FETCH_NESTED_DISTRICT_SUCCESSED = 'admin/FETCH_NESTED_DISTRICT_SUCCESSED';
export const FETCH_NESTED_DISTRICT_FAILED = 'admin/FETCH_NESTED_DISTRICT_FAILED';

export const FETCH_NESTED_PROVINCE = 'admin/FETCH_NESTED_PROVINCE';
export const FETCH_NESTED_PROVINCE_SUCCESSED = 'admin/FETCH_NESTED_PROVINCE_SUCCESSED';
export const FETCH_NESTED_PROVINCE_FAILED = 'admin/FETCH_NESTED_PROVINCE_FAILED';

export const FETCH_NESTED_WARD = 'admin/FETCH_NESTED_WARD';
export const FETCH_NESTED_WARD_SUCCESSED = 'admin/FETCH_NESTED_WARD_SUCCESSED';
export const FETCH_NESTED_WARD_FAILED = 'admin/FETCH_NESTED_WARD_FAILED';

export const UPDATE_LOCATION = 'admin/UPDATE_LOCATION';
export const UPDATE_LOCATION_SUCCESSED = 'admin/UPDATE_LOCATION_SUCCESSED';
export const UPDATE_LOCATION_FAILED = 'admin/UPDATE_LOCATION_FAILED';

export const FETCH_RECURSION_PROVINCE = 'admin/FETCH_RECURSION_PROVINCE';
export const FETCH_RECURSION_PROVINCE_SUCCESSED = 'admin/FETCH_RECURSION_PROVINCE_SUCCESSED';
export const FETCH_RECURSION_PROVINCE_FAILED = 'admin/FETCH_RECURSION_PROVINCE_FAILED';

export const UPDATE_USER_STATUS = 'amin/UPDATE_USER_STATUS';
export const UPDATE_USER_STATUS_SUCCESSED = 'amin/UPDATE_USER_STATUS_SUCCESSED';
export const UPDATE_USER_STATUS_FAILED = 'amin/UPDATE_USER_STATUS_FAILED';

export const GET_USER_STATUS = 'amin/GET_USER_STATUS';
export const GET_USER_STATUS_SUCCESSED = 'amin/GET_USER_STATUS_SUCCESSED';
export const GET_USER_STATUS_FAILED = 'amin/GET_USER_STATUS_FAILED';

export const GET_USERS_BY_ROLES = 'amin/GET_USERS_BY_ROLES';
export const GET_USERS_BY_ROLES_SUCCESSED = 'amin/GET_USERS_BY_ROLES_SUCCESSED';
export const GET_USERS_BY_ROLES_FAILED = 'amin/GET_USERS_BY_ROLES_FAILED';

export const UPDATE_LEADER = 'amin/UPDATE_LEADER';
export const UPDATE_LEADER_SUCCESSED = 'amin/UPDATE_LEADER_SUCCESSED';
export const UPDATE_LEADER_FAILED = 'amin/UPDATE_LEADER_FAILED';

export const GET_ALL_TYPE_FUNNEL = 'amin/GET_ALL_TYPE_FUNNEL';
export const GET_ALL_TYPE_FUNNEL_SUCCESSED = 'amin/GET_ALL_TYPE_FUNNEL_SUCCESSED';
export const GET_ALL_TYPE_FUNNEL_FAILED = 'amin/GET_ALL_TYPE_FUNNEL_FAILED';
