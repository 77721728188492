import { fetchJSON } from 'helpers/api';
import { ENP } from 'constants/api';

/* Loan debt API */
export const fetchAccounts = (params) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.LEAD_API}/partner`, options, params);
};

export const fetchPayments = (params) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.LEAD_API}/partner`, options, params);
};

export const fetchLeadValid = ({ code, params }) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/lead-valid/count/${code}`, options, params);
};

export const fetchLeadStatus = ({ code, params }) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/lead-status/count/${code}`, options, params);
};

export const fetchLeadReportCount = ({ code, params }) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/day/count/${code}`, options, params);
};

export const fetchEffectivePartner = (data) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/disbursed/month`, options);
};

export const fetchLeadDisbursement = ({ code, params }) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/disbursement/${code}`, options, params);
};
