import * as type from './constants';

export const getAllKpi = () => ({
  type: type.GET_ALL_KPI,
  payload: {},
});
export const getAllKpiSuccess = (data) => ({
  type: type.GET_ALL_KPI_SUCCESSED,
  payload: data,
});
export const getAllKpiFailed = (error) => ({
  type: type.GET_ALL_KPI_FAILED,
  payload: error,
});

export const getAllMonthKpi = (id, options) => ({
  type: type.GET_ALL_NUMBER_KPI,
  payload: { data: id, options: options ?? {} },
});
export const getAllMonthKpiSuccess = (data) => ({
  type: type.GET_ALL_NUMBER_KPI_SUCCESSED,
  payload: data,
});
export const getAllMonthKpiFailed = (error) => ({
  type: type.GET_ALL_NUMBER_KPI_FAILED,
  payload: error,
});

export const updateKpi = (data, options = {}) => ({
  type: type.UPDATE_KPI,
  payload: { data: data ?? {}, options: options ?? {} },
});
export const updateKpiSuccess = (data) => ({
  type: type.UPDATE_KPI_SUCCESSED,
  payload: data,
});
export const updateKpiFailed = (error) => ({
  type: type.UPDATE_KPI_FAILED,
  payload: error,
});

export const getDetailKpi = (data, options = {}) => ({
  type: type.GET_DETAIL_KPI,
  payload: { data, options: options ?? {} },
});
export const getDetailKpiSuccess = (data) => ({
  type: type.GET_DETAIL_KPI_SUCCESSED,
  payload: data,
});
export const getDetailKpiFailed = (error) => ({
  type: type.GET_DETAIL_KPI_FAILED,
  payload: error,
});

export const createKpiNumber = (data, options = {}) => ({
  type: type.CREATE_KPI_NUMBER,
  payload: { data, options: options ?? {} },
});
export const createKpiNumberSuccess = (data) => ({
  type: type.CREATE_KPI_NUMBER_SUCCESSED,
  payload: data,
});
export const createKpiNumberFailed = (error) => ({
  type: type.CREATE_KPI_NUMBER_FAILED,
  payload: error,
});

export const getLogFilter = (data) => ({
  type: type.GET_LOG_FILTER,
  payload: data,
});
export const getLogFilterSuccess = (data) => ({
  type: type.GET_LOG_FILTER_SUCCESSED,
  payload: data,
});
export const getLogFilterFailed = (error) => ({
  type: type.GET_LOG_FILTER_FAILED,
  payload: error,
});

export const getAllNumber = (data) => ({
  type: type.GET_ALL_NUMBER,
  payload: data,
});
export const getAllNumberSuccess = (data) => ({
  type: type.GET_ALL_NUMBER_SUCCESSED,
  payload: data,
});
export const getAllNumberFailed = (error) => ({
  type: type.GET_ALL_NUMBER_FAILED,
  payload: error,
});
