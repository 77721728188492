import { NxRoute } from './types';

const Error403 = lazy(() => import('pages/other/Error403'));
const Error404 = lazy(() => import('pages/other/Error404'));

const errorRoutes: NxRoute[] = [
  {
    path: '/error-403',
    name: 'Login',
    Component: Error403,
    isPublic: true,
  },
  {
    path: '/error-404',
    name: 'Login',
    Component: Error404,
    isPublic: true,
  },
];

export default errorRoutes;
