import { fetchJSON } from 'helpers/api';
import { ENP } from 'constants/api';

export const fetchImportJobs = (dataType, jobStatus, pageIndex, pageSize) => {
  const options = {
    method: 'GET',
    headers: {},
  };

  return fetchJSON(`${ENP.COLLECTION_IMPORT_API}/import/jobs`, options, {
    dataType,
    pageIndex,
    pageSize,
  });
};

export const fetchImportJobInfo = (id) => {
  const options = {
    method: 'GET',
    headers: {},
  };

  return fetchJSON(`${ENP.COLLECTION_IMPORT_API}/import/job/${id}`, options);
};

export const fetchImportJobContent = (id, pageIndex, pageSize, filter) => {
  const options = {
    method: 'GET',
    headers: {},
  };
  return fetchJSON(`${ENP.COLLECTION_IMPORT_API}/import/job/${id}/records`, options, {
    ...filter,
    pageIndex,
    pageSize,
  });
};

export const importData = (data, excelFileName, dataType, executedBy) => {
  const options = {
    body: data,
    method: 'POST',
    headers: {},
  };

  return fetchJSON(`${ENP.COLLECTION_IMPORT_API}/import/json`, options, {
    dataType,
    excelFileName,
    executedBy,
  });
};

export const checkImportData = (data, dataType) => {
  const options = {
    body: data,
    method: 'POST',
    headers: {},
  };

  return fetchJSON(`${ENP.COLLECTION_API}/import/${dataType}/check`, options);
};
