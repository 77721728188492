import { PeopleAltOutlined } from '@mui/icons-material';
import { NxRoute } from './types';

// Customer
const CustomerManage = lazy(() => import('pages/customer/manage')); // danh sách (ql)
const CustomerDetail = lazy(() => import('pages/customer/detail')); // chi tiết

const customerRoutes: NxRoute = {
  path: '/customer',
  name: 'menuConfig:customerConfig:customer',
  icon: PeopleAltOutlined,
  children: [
    {
      path: '/customer/manage',
      name: 'menuConfig:customerConfig:manage',
      Component: CustomerManage,
      roles: [],
    },
    {
      path: '/customers/:id',
      Component: CustomerDetail,
      exact: false,
    },
  ],
};

export default customerRoutes;
