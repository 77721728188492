// LOCAL ENVIRONMENT
export const LOCAL_API = 'http://localhost';
export const ENP_LOCAL = {
  /* Authentication */
  AUTH_API: 'https://idm.nexusti.pro/auth/realms/test-collection/protocol/openid-connect',

  /* Services */
  SYS_ACC_API: `${LOCAL_API}:8091`,
  COLLECTION_API: `${LOCAL_API}/collection/v1`,
  CUSTOMER_API: `${LOCAL_API}:8088`,
  COLLECTION_DASHBOARD_API: `${LOCAL_API}/collection/v2/dashboard`,

  LEAD_API: `${LOCAL_API}:8088`,
  OPPORTUNITY_API: `${LOCAL_API}:8090`,
  CRM_API: `${LOCAL_API}:8087`,
  COMMON_API: `${LOCAL_API}:8081`,
  MARKETING_API: `${LOCAL_API}:8085`,
  CALENDAR_API: `${LOCAL_API}:8086`,
  NOTIFICATION_API: `${LOCAL_API}:8089`,
  PRODUCT_API: `${LOCAL_API}:8084`,
  SCORE_API: `${LOCAL_API}:9093`,
  GATEWAY_API: `${LOCAL_API}:9095`,
  WORKFLOW_API: `${LOCAL_API}:9096`,

  MATRIX_API: `${LOCAL_API}:9094`,
  LOG_API: `${LOCAL_API}:8055`,

  CRM_MCAS_API: `${LOCAL_API}:8066/v1`,

  /* UNIVERSEE */
  UNIVS_API: `http://103.143.206.222:4041`,

  /* CALL CENTER */
  CGV_PBX: `https://fccom1.telebot.vn`,
  CALL_GATEWAY_API: `${LOCAL_API}:8011`,

  STRINGEE_AUTH_API: `${LOCAL_API}:8022`,
};

export const AUTH_URL = import.meta.env.VITE_API_AUTH;
export const AUTH_REALM = import.meta.env.VITE_AUTH_REALM;
export const LOCAL_API_D = import.meta.env.VITE_API_END_POINT;
export const ENP_LOCAL_D = {
  /* Authentication */
  AUTH_API: `${AUTH_URL}/auth/realms/${AUTH_REALM}/protocol/openid-connect`,

  /* Services */
  SYS_ACC_API: `${LOCAL_API_D}/iam`,
  COLLECTION_API: `${LOCAL_API_D}/collection/v1`,
  COLLECTION_DASHBOARD_API: `${LOCAL_API_D}/collection/v2/dashboard`,
  COLLECTION_DASHBOARD_V3_API: `${LOCAL_API_D}/collection//v3/dashboard`,
  COLLECTION_IMPORT_API: `http://192.168.5.4:8001/v1`,

  CUSTOMER_API: `${LOCAL_API_D}/lead`,
  LEAD_API: `${LOCAL_API_D}/lead`,
  OPPORTUNITY_API: `${LOCAL_API_D}/opportunity`,
  COMMON_API: `${LOCAL_API_D}/common`,
  MARKETING_API: `${LOCAL_API_D}/marketing`,
  CALENDAR_API: `${LOCAL_API_D}/calendar`,
  NOTIFICATION_API: `${LOCAL_API_D}/notification`,
  PRODUCT_API: `${LOCAL_API_D}/product`,
  SCORE_API: `${LOCAL_API_D}/scoring`,
  GATEWAY_API: `${LOCAL_API_D}/gateway`,
  WORKFLOW_API: `${LOCAL_API_D}/workflow`,

  MATRIX_API: `${LOCAL_API_D}/matrix`,
  LOG_API: `${LOCAL_API_D}/log`,

  CRM_MCAS_API: `${LOCAL_API_D}/mcas/v1`,

  /* UNIVERSEE */
  UNIVS_API: `http://103.143.206.222:4041`,

  /* CALL CENTER */
  CGV_PBX: `https://fccom1.telebot.vn`,
  CALL_GATEWAY_API: `http://localhost:8011`,

  STRINGEE_AUTH_API: `http://localhost:8022`,
};

export const ENP = ENP_LOCAL_D;

export const LOAN_DEBT_IMPORT_MAPPING_COLUMNS_TABLE_ID = 'da39d999-0f70-4bdc-9c10-94a1f548f731';
export const PAYMENT_IMPORT_MAPPING_COLUMNS_TABLE_ID = '6275f824-cb1d-4b42-8629-28cbacf37ab9';

export const COLLECTION_DASHBOARD_API = `${LOCAL_API}/collection/v2/dashboard`;

export const GROUP_MANAGER_TABLE_ID = '7200a484-dfa7-4f84-ab17-4b7dfc1fc2e5';

/* GOOGLE API */
/* Ip geo by ipify */
// Check available requests: https://geo.ipify.org/subscriptions
export const IPIFY_TOKEN = 'at_M0KpXbZ9d9CrCuEOuu6L6dCZ0tktP'; // refill at 01/08/2020 - tuannda94@gmail.com
export const IPIFY_IP_API = 'https://cors-anywhere.herokuapp.com/https://api.ipify.org/';
export const IPIFY_GEO_API = 'https://cors-anywhere.herokuapp.com/https://geo.ipify.org/api/v1';

/* google, leaflet */
export const MAP_TYPE = 'leaflet';

/* Viet map API_KEY */
export const VI_MAP_API_KEY = 'a48e89172110cfb7eff9b3bf3fbd4754198b4af16bfb1840';

/* Google map API_KEY */
export const GG_MAP_API_KEY = 'AIzaSyDAdFdNngc4s4oob2VqR9L1F82HhFLAa0I';

export const FB_TOKEN =
  'EAAAAZAw4FxQIBAGXTj5137jqLrDOoaHf9EOmZBw9wLboxZCKv2IAx3ZCiFPZCa3mnj1JIrcEkGi1z9Re1x2kdKZAczFeTRZC63MKZBPdZAlOOJlBUXSpMkhcmzBDGSHWQolWJSuJlc6urIfodFn0HFw4pHZAbfVhEoXl1LZCSKgROVl8gZDZD';

export const STRINGEE_PHONE_NUMBER = '842899996535';

// custom auth body params
export const CLIENT_ID = import.meta.env.VITE_CLIENT_ID;
export const CLIENT_SECRET = import.meta.env.VITE_CLIENT_SECRET;
export const LOGIN_PARAMS = {
  grant_type: 'password',
  client_id: CLIENT_ID,
  client_secret: CLIENT_SECRET,
};

export const REFRESH_TOKEN_PARAMS = {
  grant_type: 'refresh_token',
  client_id: CLIENT_ID,
  client_secret: CLIENT_SECRET,
};

export const LOGOUT_PARAMS = {
  client_id: CLIENT_ID,
  client_secret: CLIENT_SECRET,
};

export const DEFAULT_PAGINATION = {
  skipCount: 0, // => số items đã bỏ qua tính từ đầu. page number = skipCount / totalItems + (skipCount % totalItems)
  maxItems: 10, // => số items lớn nhất có thể có trong trang
  sort: 'code,asc',
};

export const DEFAULT_LIST_RESPONSE = {
  entries: [],
  pagination: {
    skipCount: 0,
    maxItems: 20,
    count: 8,
    hasMoreItems: false,
    totalItems: 8,
  },
};

export const DEFAULT_IMPORT_DATA = {
  fileName: '',
  submitData: {
    data: [],
    mapper: {},
    dedup: {
      method: '',
      attributes: [],
    },
    paramTableId: '',
  },
};

export const DEFAULT_LOAN_DEBT_FILTER = {
  provinces: [],
  districts: [],
  subDistricts: [],
  bomBuckets: [],
  collectionStatus: [],
  activityStatus: [],
  promiseStatus: [],
  assignees: [],
};
