// @flow
import { DEFAULT_LIST_RESPONSE } from 'constants/api';
import * as type from './constants';

const INIT_STATE = {
  /* Calendar */
  allEvents: { ...DEFAULT_LIST_RESPONSE },
  event: {},
};

const Collection = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Calendar cases */
    case type.FETCH_CALENDAR_EVENTS:
      return { ...state };
    case type.FETCH_CALENDAR_EVENTS_SUCCESSED:
      return { ...state, allEvents: action.payload, error: null };
    case type.FETCH_CALENDAR_EVENTS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CALENDAR_EVENTS_BY_DATE:
      return { ...state };
    case type.FETCH_CALENDAR_EVENTS_BY_DATE_SUCCESSED:
      return { ...state, allEvents: action.payload, error: null };
    case type.FETCH_CALENDAR_EVENTS_BY_DATE_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_CALENDAR_EVENT:
      return { ...state };
    case type.FETCH_CALENDAR_EVENT_SUCCESSED:
      return { ...state, event: action.payload, error: null };
    case type.FETCH_CALENDAR_EVENT_FAILED:
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};

export default Collection;
