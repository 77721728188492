import * as type from './constants';

/* Accounts actions */
export const fetchAccounts = (pagination) => ({ type: type.FETCH_ACCOUNTS, payload: pagination });
export const fetchAccountsSuccessed = (data) => ({
  type: type.FETCH_ACCOUNTS_SUCCESSED,
  payload: data,
});
export const fetchAccountsFailed = (error) => ({
  type: type.FETCH_ACCOUNTS_FAILED,
  payload: error,
});
export const clearAccounts = () => ({ type: type.CLEAR_ACCOUNTS });

export const fetchPayments = (pagination) => ({ type: type.FETCH_PAYMENTS, payload: pagination });
export const fetchPaymentsSuccessed = (data) => ({
  type: type.FETCH_PAYMENTS_SUCCESSED,
  payload: data,
});
export const fetchPaymentsFailed = (error) => ({
  type: type.FETCH_PAYMENTS_FAILED,
  payload: error,
});
export const clearPayments = () => ({ type: type.CLEAR_PAYMENTS });

export const fetchLeadValid = (data) => ({ type: type.FETCH_LEAD_VALID, payload: data });
export const fetchLeadValidSuccessed = (data) => ({
  type: type.FETCH_LEAD_VALID_SUCCESSED,
  payload: data,
});
export const fetchLeadValidFailed = (error) => ({
  type: type.FETCH_LEAD_VALID_FAILED,
  payload: error,
});

export const fetchLeadStatus = (data) => ({ type: type.FETCH_LEAD_STATUS, payload: data });
export const fetchLeadStatusSuccessed = (data) => ({
  type: type.FETCH_LEAD_STATUS_SUCCESSED,
  payload: data,
});
export const fetchLeadStatusFailed = (error) => ({
  type: type.FETCH_LEAD_STATUS_FAILED,
  payload: error,
});

export const fetchLeadReportDayCount = (data) => ({
  type: type.FETCH_LEAD_REPORT_DAY_COUNT,
  payload: data,
});
export const fetchLeadReportDayCountSuccessed = (data) => ({
  type: type.FETCH_LEAD_REPORT_DAY_COUNT_SUCCESSED,
  payload: data,
});
export const fetchLeadReportDayCountFailed = (error) => ({
  type: type.FETCH_LEAD_REPORT_DAY_COUNT_FAILED,
  payload: error,
});

export const fetchLeadDisbursement = (data) => ({
  type: type.FETCH_LEAD_DISBURSEMENT,
  payload: data,
});
export const fetchLeadDisbursementSuccessed = (data) => ({
  type: type.FETCH_LEAD_DISBURSEMENT_SUCCESSED,
  payload: data,
});
export const fetchLeadDisbursementFailed = (error) => ({
  type: type.FETCH_LEAD_DISBURSEMENT_FAILED,
  payload: error,
});

export const fetchEffectivePartner = (data) => ({
  type: type.GET_EFFECTIVE_PARTNER,
  payload: data,
});
export const fetchEffectivePartnerSuccessed = (data) => ({
  type: type.GET_EFFECTIVE_PARTNER_SUCCESSED,
  payload: data,
});
export const fetchEffectivePartnerFailed = (error) => ({
  type: type.GET_EFFECTIVE_PARTNER_FAILED,
  payload: error,
});
