import { SellOutlined } from '@mui/icons-material';
import { CAMPAIGN_VIEW_LIST, LEAD_SVC, PARTNER_VIEW_LIST } from 'constants/roles';
import { NxRoute } from './types';

// Accounts
const Partner = lazy(() => import('pages/partner/partner-manage'));
const PartnerDetail = lazy(() => import('pages/partner/partner-detail'));

// Campaigns
const Campaign = lazy(() => import('pages/campaign/manage'));
const CampaignDetail = lazy(() => import('pages/campaign/detail'));

const marketingRoutes: NxRoute = {
  path: '/marketing',
  name: 'menuConfig:marketingConfig:marketing',
  icon: SellOutlined,
  exact: false,
  children: [
    {
      path: '/marketing/partner',
      name: 'menuConfig:marketingConfig:partner',
      Component: Partner,
      resource: LEAD_SVC,
      roles: [PARTNER_VIEW_LIST],
      children: [
        {
          path: '/marketing/partner/:id',
          Component: PartnerDetail,
          exact: false,
        },
      ],
    },
    {
      path: '/marketing/campaign',
      name: 'menuConfig:marketingConfig:campaign',
      Component: Campaign,
      resource: LEAD_SVC,
      roles: [CAMPAIGN_VIEW_LIST],
      children: [
        {
          path: '/marketing/campaign/:code',
          Component: CampaignDetail,
          exact: false,
        },
      ],
    },
  ],
};

export default marketingRoutes;
