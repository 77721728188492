// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { handleLoading } from 'redux/layout/actions';
import toast from 'react-hot-toast';
import * as type from './constants';
import * as api from './api';
import * as actions from './actions';

/* API Gateway sagas */
function* fetchAPIGateways(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchAPIGateways);

    yield put(actions.fetchAPIGatewaysSuccessed(response));
  } catch (error) {
    yield put(actions.fetchAPIGatewaysFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchAPIGateway(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchAPIGateway, action.payload);

    yield put(actions.fetchAPIGatewaySuccessed(response));
  } catch (error) {
    yield put(actions.fetchAPIGatewayFailed(error));
  }

  yield put(handleLoading(false));
}

function* createAPIGateway(action) {
  yield put(handleLoading(true));

  try {
    yield call(api.createAPIGateway, action.payload);

    toast.success('Tạo mới gateway thành công');
  } catch (error) {
    yield put(actions.createAPIGatewayFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateAPIGateway(action) {
  yield put(handleLoading(true));

  try {
    yield call(api.updateAPIGateway, action.payload);

    toast.success('Cập nhật gateway thành công');
  } catch (error) {
    yield put(actions.updateAPIGatewayFailed(error));
  }

  yield put(handleLoading(false));
}

function* testAPIGateway(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.testAPIGateway, action.payload);
    yield put(actions.testAPIGatewaySuccessed(response));
  } catch (error) {
    yield put(actions.testAPIGatewayFailed(error));
  }

  yield put(handleLoading(false));
}

export function* watchAPIGateway() {
  yield takeEvery(type.FETCH_API_GATEWAYS, fetchAPIGateways);
  yield takeEvery(type.FETCH_API_GATEWAY, fetchAPIGateway);
  yield takeEvery(type.CREATE_API_GATEWAY, createAPIGateway);
  yield takeEvery(type.UPDATE_API_GATEWAY, updateAPIGateway);
  yield takeEvery(type.TEST_API_GATEWAY, testAPIGateway);
}

/* Product */

function* fetchListProductHybrid(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchListProductHybrid, action.payload);
    yield put(actions.fetchListProductHybridSuccessed(res));
  } catch (error) {
    yield put(actions.fetchListProductHybridFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchProductForms(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.fetchProductForms, action.payload);

    yield put(actions.fetchProductFormsSuccessed(res));
  } catch (error) {
    yield put(actions.fetchProductFormsFailed(error));
  }

  yield put(handleLoading(false));
}

function* filterUsersProductSaga(action) {
  yield put(handleLoading(true));
  const { formDataFilter, key, index } = action.payload;

  try {
    const res = yield call(api.filterUsersProduct, formDataFilter);

    yield put(actions.filterUsersProductSuccessed(res, key, index));
  } catch (error) {
    yield put(actions.filterUsersProductFailed(error));
  }

  yield put(handleLoading(false));
}

function* filterAllUsersProductSaga(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.filterAllUsersProduct);

    yield put(actions.filterAllUsersProductSuccessed(res));
  } catch (error) {
    yield put(actions.filterAllUsersProductFailed(error));
  }

  yield put(handleLoading(false));
}

function* calProductRepayPlan(action) {
  yield put(handleLoading(true));
  const { code, amount, term } = action.payload;

  try {
    const res = yield call(api.calProductRepayPlan, code, amount, term);

    yield put(actions.calProductRepayPlanSuccessed(res));
  } catch (error) {
    yield put(actions.calProductRepayPlanFailed(error));
  }
  yield put(handleLoading(false));
}

export function* watchProductMatrix() {
  yield takeEvery(type.FETCH_LIST_PRODUCT_HYBRID, fetchListProductHybrid);
  yield takeEvery(type.FETCH_PRODUCT_FORMS, fetchProductForms);
  yield takeEvery(type.FILTER_USERS_PRODUCT, filterUsersProductSaga);
  yield takeEvery(type.FILTER_ALL_USERS_PRODUCT, filterAllUsersProductSaga);
  yield takeEvery(type.CAL_PRODUCT_REPAY_PLAN, calProductRepayPlan);
}

function* appMenuSaga() {
  yield all([fork(watchAPIGateway), fork(watchProductMatrix)]);
}

export default appMenuSaga;
