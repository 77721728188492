// LƯU THÔNG TIN API VÀO REDUX
// @flow
import { DEFAULT_LIST_RESPONSE } from 'constants/api';
import * as type from './constants';

const INIT_STATE = {
  menu: {},
  gateways: [],
  createGateway: {},
  gatewayResponse: {},
  licenseRegister: {},
  product: {
    productList: { list: DEFAULT_LIST_RESPONSE },
    productGroupList: [],
    allProducts: {
      entries: [],
    },
    productDetail: {},
    ageRanges: [],
    targetLocations: [],
    scoreRanges: [],
    total: [],
    create: {
      code: '',
      amountMinimum: null,
      amountMaximum: null,
      amountStep: null,
      name: '',
      termDayMinimum: null,
      termDayMaximum: null,
      termDayStep: null,
      description: '',
      rates: [
        {
          name: '',
          value: null,
        },
      ],
      managementFee: null,
      overdueFee: null,
      earlySettlementFee: null,
      interestOverdueRate: null,
      initFeeRate: null,
      ageRanges: [
        {
          from: null,
          to: null,
        },
      ],
      targetLocations: [
        {
          region: '',
          province: '',
          district: '',
          subDistrict: '',
        },
      ],
      scoreRanges: [
        {
          from: null,
          to: null,
        },
      ],
      startDate: null,
      endDate: null,
      releaseTime: null,
    },
    repayPlans: {},
    productForms: [],
    productDocuments: [],
  },
  productActive: [],
  settings: {
    leadChannels: {},
    leadSources: {},
    leadCustomFields: {},
    lead: {
      channel: {},
    },
  },
};

const Matrix = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Menu cases */
    case type.INIT_MENU:
      return { ...state, menu: { ...state.menu, ...action.payload } };
    case type.INIT_MENU_SUCCESS:
      return { ...state, menu: { ...state.menu, ...action.payload } };
    case type.CHANGE_ACTIVE_MENU_FROM_LOCATION:
      return { ...state };
    case type.CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS:
      return { ...state, menu: { ...state.menu, ...action.payload } };

    /* API Gateway cases */
    case type.FETCH_API_GATEWAYS:
      return { ...state };
    case type.FETCH_API_GATEWAYS_SUCCESSED:
      return { ...state, gateways: action.payload, error: null };
    case type.FETCH_API_GATEWAYS_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_API_GATEWAY:
      return { ...state };
    case type.FETCH_API_GATEWAY_SUCCESSED:
      return { ...state, createGateway: action.payload, error: null };
    case type.FETCH_API_GATEWAY_FAILED:
      return { ...state, error: action.payload };

    case type.CREATE_API_GATEWAY:
      return { ...state };
    case type.CREATE_API_GATEWAY_SUCCESSED:
      return { ...state, createGateway: action.payload, error: null };
    case type.CREATE_API_GATEWAY_FAILED:
      return { ...state, error: action.payload };

    case type.UPDATE_API_GATEWAY:
      return { ...state };
    case type.UPDATE_API_GATEWAY_SUCCESSED:
      return { ...state, createGateway: action.payload, error: null };
    case type.UPDATE_API_GATEWAY_FAILED:
      return { ...state, error: action.payload };

    case type.TEST_API_GATEWAY:
      return { ...state, gatewayResponse: {} };
    case type.TEST_API_GATEWAY_SUCCESSED:
      return { ...state, gatewayResponse: action.payload, error: null };
    case type.TEST_API_GATEWAY_FAILED:
      return { ...state, error: action.payload };

    /* Product Matrix */

    case type.FETCH_LIST_PRODUCT_HYBRID:
      return { ...state };
    case type.FETCH_LIST_PRODUCT_HYBRID_SUCCESSED:
      return { ...state, productActive: action.payload, error: null };
    case type.FETCH_LIST_PRODUCT_HYBRID_FAILED:
      return { ...state, error: action.payload };

    case type.FETCH_PRODUCT_FORMS:
      return { ...state };
    case type.FETCH_PRODUCT_FORMS_SUCCESSED:
      return {
        ...state,
        product: { ...state.product, productForms: [...action.payload] },
        error: null,
      };
    case type.FETCH_PRODUCT_FORMS_FAILED:
      return { ...state, error: action.payload };

    case type.FILTER_USERS_PRODUCT:
      return { ...state };
    case type.FILTER_USERS_PRODUCT_SUCCESSED:
      const { data, key, index } = action.payload;
      const currentDataUsers = [...state.product[key]];
      currentDataUsers[index] = data;
      return { ...state, product: { ...state.product, [key]: currentDataUsers }, error: null };
    case type.FILTER_USERS_PRODUCT_FAILED:
      return { ...state, error: action.payload };

    case type.SET_USERS_ALL_DEFAULT:
      return {
        ...state,
        product: {
          ...state.product,
          ageRanges: [],
          targetLocations: [],
          scoreRanges: [],
          total: [],
        },
        error: null,
      };

    case type.FILTER_ALL_USERS_PRODUCT:
      return { ...state };
    case type.FILTER_ALL_USERS_PRODUCT_SUCCESSED:
      return { ...state, product: { ...state.product, total: action.payload }, error: null };
    case type.FILTER_ALL_USERS_PRODUCT_FAILED:
      return { ...state, error: action.payload };

    case type.CAL_PRODUCT_REPAY_PLAN:
      return { ...state };
    case type.CAL_PRODUCT_REPAY_PLAN_SUCCESSED:
      return { ...state, product: { ...state.product, repayPlans: action.payload }, error: null };
    case type.CAL_PRODUCT_REPAY_PLAN_FAILED:
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};

export default Matrix;
