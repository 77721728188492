// ĐƯỜNG LINK LƯU THÔNG TIN API
/* MATRIX MENU */
export const INIT_MENU = 'matrix/INIT_MENU';
export const INIT_MENU_SUCCESS = 'matrix/INIT_MENU_SUCCESS';
export const CHANGE_ACTIVE_MENU_FROM_LOCATION = 'matrix/CHANGE_ACTIVE_MENU_FROM_LOCATION';
export const CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS = 'matrix/CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS';

/* API GATEWAY */
export const FETCH_API_GATEWAYS = 'matrix/FETCH_API_GATEWAYS';
export const FETCH_API_GATEWAYS_SUCCESSED = 'matrix/FETCH_API_GATEWAYS_SUCCESSED';
export const FETCH_API_GATEWAYS_FAILED = 'matrix/FETCH_API_GATEWAYS_FAILED';

export const FETCH_API_GATEWAY = 'matrix/FETCH_API_GATEWAY';
export const FETCH_API_GATEWAY_SUCCESSED = 'matrix/FETCH_API_GATEWAY_SUCCESSED';
export const FETCH_API_GATEWAY_FAILED = 'matrix/FETCH_API_GATEWAY_FAILED';

export const CREATE_API_GATEWAY = 'matrix/CREATE_API_GATEWAY';
export const CREATE_API_GATEWAY_SUCCESSED = 'matrix/CREATE_API_GATEWAY_SUCCESSED';
export const CREATE_API_GATEWAY_FAILED = 'matrix/CREATE_API_GATEWAY_FAILED';

export const UPDATE_API_GATEWAY = 'matrix/UPDATE_API_GATEWAY';
export const UPDATE_API_GATEWAY_SUCCESSED = 'matrix/UPDATE_API_GATEWAY_SUCCESSED';
export const UPDATE_API_GATEWAY_FAILED = 'matrix/UPDATE_API_GATEWAY_FAILED';

export const TEST_API_GATEWAY = 'matrix/TEST_API_GATEWAY';
export const TEST_API_GATEWAY_SUCCESSED = 'matrix/TEST_API_GATEWAY_SUCCESSED';
export const TEST_API_GATEWAY_FAILED = 'matrix/TEST_API_GATEWAY_FAILED';

/* PRODUCT */

export const FETCH_LIST_PRODUCT_HYBRID = 'matrix/FETCH_LIST_PRODUCT_HYBRID';
export const FETCH_LIST_PRODUCT_HYBRID_SUCCESSED = 'matrix/FETCH_LIST_PRODUCT_HYBRID_SUCCESSED';
export const FETCH_LIST_PRODUCT_HYBRID_FAILED = 'matrix/FETCH_LIST_PRODUCT_HYBRID_FAILED';

export const SET_USERS_ALL_DEFAULT = 'matrix/SET_USERS_ALL_DEFAULT';

export const FILTER_USERS_PRODUCT = 'matrix/FILTER_USERS_PRODUCT';
export const FILTER_USERS_PRODUCT_SUCCESSED = 'matrix/FILTER_USERS_PRODUCT_SUCCESSED';
export const FILTER_USERS_PRODUCT_FAILED = 'matrix/FILTER_USERS_PRODUCT_FAILED';

export const FILTER_ALL_USERS_PRODUCT = 'matrix/FILTER_ALL_USERS_PRODUCT';
export const FILTER_ALL_USERS_PRODUCT_SUCCESSED = 'matrix/FILTER_ALL_USERS_PRODUCT_SUCCESSED';
export const FILTER_ALL_USERS_PRODUCT_FAILED = 'matrix/FILTER_ALL_USERS_PRODUCT_FAILED';

export const FETCH_PRODUCT_FORMS = 'matrix/FETCH_PRODUCT_FORMS';
export const FETCH_PRODUCT_FORMS_SUCCESSED = 'matrix/FETCH_PRODUCT_FORMS_SUCCESSED';
export const FETCH_PRODUCT_FORMS_FAILED = 'matrix/FETCH_PRODUCT_FORMS_FAILED';

export const CAL_PRODUCT_REPAY_PLAN = 'matrix/CAL_PRODUCT_REPAY_PLAN';
export const CAL_PRODUCT_REPAY_PLAN_SUCCESSED = 'matrix/CAL_PRODUCT_REPAY_PLAN_SUCCESSED';
export const CAL_PRODUCT_REPAY_PLAN_FAILED = 'matrix/CAL_PRODUCT_REPAY_PLAN_FAILED';
