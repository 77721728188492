import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';

const SECRET_KEY = import.meta.env.VITE_SECRET_KEY;

const encrypt = (data: string, secretKey = SECRET_KEY) => {
  return AES.encrypt(data, secretKey).toString();
};

const decrypt = (data: string, secretKey = SECRET_KEY) => {
  const bytes = AES.decrypt(data, secretKey);
  return bytes.toString(encUtf8);
};

export { encrypt, decrypt };
