// @flow
import { getLoggedIn, getLoggedInUser, isDarkMode } from 'helpers/authUtils';
import * as type from './constants';

const INIT_STATE = {
  user: getLoggedInUser(),
  loggedIn: getLoggedIn(),
  isDarkMode: isDarkMode(),
  webPhone: null,
  stringeeToken: null,
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case type.LOGIN_USER:
      return { ...state };
    case type.LOGIN_USER_SUCCESSED:
      return { ...state, ...action.payload, error: null };
    case type.LOGIN_USER_FAILED:
      return { ...state, error: action.payload };
    case type.REGISTER_USER:
      return { ...state };
    case type.REGISTER_USER_SUCCESSED:
      return { ...state, user: action.payload, error: null };
    case type.REGISTER_USER_FAILED:
      return { ...state, error: action.payload };
    case type.UPDATE_USER:
      return { ...state };
    case type.UPDATE_USER_SUCCESSED:
      return { ...state, user: action.payload, error: null };
    case type.UPDATE_USER_FAILED:
      return { ...state, error: action.payload };

    case type.LOGOUT_USER:
      return { ...state, user: null, loggedIn: null };

    case type.REMOVE_USER_INFO:
      return { ...state, user: null, loggedIn: null };

    case type.FORGET_PASSWORD:
      return { ...state };
    case type.FORGET_PASSWORD_SUCCESSED:
      return { ...state, passwordResetStatus: action.payload, error: null };
    case type.FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload };
    case type.REFRESH_TOKEN:
      return { ...state };
    case type.REFRESH_TOKEN_SUCCESSED:
      return { ...state, passwordResetStatus: action.payload, error: null };
    case type.REFRESH_TOKEN_FAILED:
      return { ...state, error: action.payload };
    case type.CHANGE_PASSWORD:
      return { ...state };
    case type.CHANGE_PASSWORD_SUCCESSED:
      return { ...state, error: null };
    case type.CHANGE_PASSWORD_FAILED:
      return { ...state, error: action.payload };
    case type.SET_DARK_MODE:
      return { ...state, isDarkMode: action.payload, error: null };
    case type.SET_WEB_PHONE:
      return { ...state, webPhone: action.payload, error: null };
    case type.GET_STRINGEE_TOKEN:
      return { ...state };
    case type.GET_STRINGEE_TOKEN_SUCCESSED:
      return { ...state, stringeeToken: action.payload, error: null };
    default:
      return { ...state };
  }
};

export default Auth;
