import { CalendarMonthOutlined } from '@mui/icons-material';
import { NxRoute } from './types';

const CalendarComponent = lazy(() => import('pages/collection/calendar'));

const calendarRoutes: NxRoute = {
  path: '/calendar',
  name: 'menuParent:calendar',
  icon: CalendarMonthOutlined,
  Component: CalendarComponent,
  resource: 'svc-calendar',
  // roles: ["u_event"],
};

export default calendarRoutes;
