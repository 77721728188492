import './ReloadPrompt.css';

import { useRegisterSW } from 'virtual:pwa-register/react';

function ReloadPrompt() {
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      // eslint-disable-next-line prefer-template
      console.log('SW Registered: ' + r);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  return (
    <div className="ReloadPrompt-container">
      {(offlineReady || needRefresh) && (
        <div className="ReloadPrompt-toast">
          <div className="ReloadPrompt-message">
            {offlineReady ? (
              <span>App đang ở chế độ offline</span>
            ) : (
              <span>Có bản cập nhật mới, nhấn tải lại để cập nhật.</span>
            )}
          </div>
          {needRefresh && (
            <button type="button" className="ReloadPrompt-toast-button" onClick={() => updateServiceWorker(true)}>
              Tải lại
            </button>
          )}
          <button type="button" className="ReloadPrompt-toast-button" onClick={() => close()}>
            Đóng
          </button>
        </div>
      )}
    </div>
  );
}

export default ReloadPrompt;
